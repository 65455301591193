<div class="auth-container d-flex">

  <div class="container mx-auto align-self-center">

      <div class="row">

          <div class="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
              <div class="auth-cover-bg-image"></div>
              <div class="auth-overlay"></div>
                  
              <div class="auth-cover">

                  <div class="position-relative">

                      <img src="assets/src/assets/img/" alt="auth-img">

                      <h2 class="mt-5 text-white font-weight-bolder px-2">Join Anytime</h2>
                      <p class="text-white px-2">It is easy to setup with great customer experience.</p>
                  </div>
                  
              </div>

          </div>

          <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
              <div class="card">
                  <div class="card-body">





























                      <form
                      class="text-left"
                      *ngIf="!isLoggedIn"
                      name="form"
                      (ngSubmit)="f.form.valid && onSubmit()"
                      #f="ngForm"
                      novalidate
                    >
                      






                            <div class="row">
                              <div class="col-md-12 mb-3">
                                  
                                  <h2>Sign In</h2>
                                  <p>Enter your email and password to login</p>
                                  
                              </div>
                              <div class="col-md-12">
                                  <div class="mb-3">
                                      <label class="form-label">Email</label>
                                      <input
                                      type="text"
                                      class="form-control"
                                      name="username"
                                      [(ngModel)]="form.username"
                                      required
                                      #username="ngModel"
                                    />
                                            <div
                                      class="alert alert-danger"
                                      role="alert"
                                      *ngIf="username.errors && f.submitted"
                                    >
                                      Username is required!
                                    </div>
                                  </div>
                              </div>






  
 
                              <div class="col-12">
                                <div class="mb-4">
                                    <label class="form-label">Password</label>
                                    <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    [(ngModel)]="form.password"
                                    required
                                    minlength="6"
                                    #password="ngModel"
                                  />
                                  <div
                                    class="alert alert-danger"
                                    role="alert"
                                    *ngIf="password.errors && f.submitted"
                                  >
                                    <div *ngIf="password.errors['required']">Password is required</div>
                                    <div *ngIf="password.errors['minlength']">
                                      Password must be at least 6 characters
                                    </div>
                                  </div>
                                </div>
                            </div>






                            <div class="col-12">
                              <div class="mb-3">
                                  <div class="form-check form-check-primary form-check-inline">
                                     <!-- <input class="form-check-input me-3" type="checkbox" id="form-check-default">
                                      <label class="form-check-label" for="form-check-default">
                                          Remember me
                                      </label> -->
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-12">
                              <div class="mb-4">
                                  <button type="sumbit" class="btn btn-primary w-100">SIGN IN</button>
                              </div>
                          </div>

                          <div class="col-12">
                              <div class="text-center">
                                <!--  <p class="mb-0">Dont't have an account ? <a href="javascript:void(0);" class="text-warning">Sign Up</a></p> -->
                              </div>
                          </div>


        
                              </div>
  
                              <!-- <p class="signup-link">Not registered ? <a href="auth_register_boxed.html">Create an account</a></p> -->
                              <div class="form-group">
                                  <div
                                    class="alert alert-danger"
                                    role="alert"
                                    *ngIf="f.submitted && isLoginFailed"
                                  >
                                    Login failed: {{ errorMessage }}
                                  </div>
                                </div>
                                
                         
                   </form>
                   <div class="alert alert-success" *ngIf="isLoggedIn">
                      Logged in as {{ myusername }}.
                    </div>

                    







                      
                  </div>
              </div>
          </div>
          
      </div>
      
  </div>

</div>




