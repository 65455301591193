


                <!-- CONTENT AREA -->

                <div class="flex-row layout-top-spacing">
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Report Viewer</li>
                        </ol>
                    </nav>
                    </div>


                    <!-- <div class="d-flex layout-top-spacing flex-row-reverse">
                        <button id="add-work-platforms" [routerLink]="['/geomap/maps/newMap']" class="btn btn-rounded btn-primary">+ New Map</button>
                    </div> -->


                    <div style="display:block;" id="polyTableLoader" class="flex-row">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col">

                                </div>
                                <div class="col">
                                    <div style="text-align:center;">

                                    <div  class="lds-dual-ring"></div>
                                    <h3 id="loadingTablePercent"></h3>
                                    </div>
                                </div>
                                <div class="col">

                                </div>
                              </div>
                        </div>
                    </div>

                    <div class="row layout-top-spacing" id="cancel-row">

                        <div  style="display:none;" id="mapTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                            <div class="widget widget-card-standard-dropdown">
                                <div class="widget-content">
                                    <div class="w-header">
                                        <div class="w-info">
                                            <h6 class="value">{{loadedReportData.report_name}}</h6>
                                        </div>


                                        <div class="task-action">

                                            <div class="dropdown pt-2" style="float:right;">
                                                <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                                </a>

                                                <div class="dropdown-menu left" aria-labelledby="expenses" style="will-change: transform;">
                                                    <a class="dropdown-item" href="javascript:void(0);" (click)="exportExcel()">Export Excel</a>
                                                </div>
                                            </div>
                                            <div class="" style="width:150px; float:right;">
                                                <button id="add-work-platforms" [routerLink]="['geomap/forms/newform']" class="btn btn-rounded btn-primary">Edit Report</button>
                                        </div>

                                        </div>
                                    </div>


                                    <div class="w-content">

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <table *ngIf="displayTable"  datatable="polyDTTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="html5-extension" class="table table-hover non-hover" style="width:100%; min-height:75px;">
                                                <thead>
                                                    <tr>
                                                        <ng-container *ngFor="let header of selectColumns">
                                                            <th scope="col">{{header.name}}</th>
                                                        </ng-container>

                                                    </tr>

                                                </thead>
                                                <tbody id="mapsTableBody">


                                                    <ng-container *ngIf="showTableData">
                                                        <tr *ngFor="let row of dataRows">
                                                            <ng-container *ngIf="getSortedDataRow(row) | async as user">
                                                            <td *ngFor="let data of user">{{data}}</td>
                                                            </ng-container>
                                                        </tr>
                                                         </ng-container>


                                                </tbody>
                                            </table>

                                            <!--
                                                <div class="handsontable" id="example">
                                                <table class="htCore">
                                                  <colgroup>
                                                    <col style="width: 50px;">
                                                    <col style="width: 60px;">
                                                    <col style="width: 50px;">
                                                    <col style="width: 65px;">
                                                    <col style="width: 50px;">
                                                    <col style="width: 69px;">
                                                  </colgroup>

                                                  <thead>
                                                    <tr>
                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">A</span>
                                                        </div>
                                                      </th>

                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">B</span>
                                                        </div>
                                                      </th>

                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">C</span>
                                                        </div>
                                                      </th>

                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">D</span>
                                                        </div>
                                                      </th>

                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">E</span>
                                                        </div>
                                                      </th>

                                                      <th>
                                                        <div class="relative">
                                                          <span class="colHeader">F</span>
                                                        </div>
                                                      </th>
                                                    </tr>
                                                  </thead>

                                                  <tbody>
                                                    <tr>
                                                      <td class=""><input type="text"></td>

                                                      <td class="inputfield"><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>
                                                    </tr>
                                                    <tr>
                                                      <td class=""><input type="text"></td>

                                                      <td class="inputfield"><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>
                                                    </tr>
                                                    <tr>
                                                      <td class=""><input type="text"></td>

                                                      <td class="inputfield"><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>

                                                      <td class=""><input type="text"></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            -->

                                        </div>

                                    </div>

                                    <div style="display:none" class="w-progress-stats">
                                        <div class="">
                                            <div class="w-lic-count">
                                                <p>1\∞</p>
                                            </div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div class="">
                                            <div class="w-icon">
                                                <p>0%</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <!-- CONTENT AREA -->





