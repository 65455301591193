import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertControlService {

  constructor() { }

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

  generalInfo(title: string, message: string,displayConfirm:boolean): Observable<any>{

    return new Observable(observer => {

  Swal.fire({
    icon: "info",
    titleText: title,
    text: message,
    showCancelButton: false,
    showDenyButton: false,
    showConfirmButton: displayConfirm,
    allowOutsideClick: true,
    backdrop: true,
    confirmButtonText: 'Complete',
   }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
     observer.next(true);
     observer.complete();

    } 
   });

    });
  }

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

  loadingInfo(title: string,displayConfirm:boolean): Observable<any>{

    return new Observable(observer => {

  Swal.fire({
    icon: 'info',
    title:title,
    html: `<style>.lds-dual-ring {
      margin-left: 0 auto;
      display: inline-block;
      width: 160px;
      height: 160px;
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 130px;
      height: 130px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #000;
      border-color: #000 transparent #000 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }</style><div class="lds-dual-ring"></div>`,
    showCancelButton: false,
    showDenyButton: false,
    showConfirmButton: displayConfirm,
    allowOutsideClick: true,
    backdrop: true,
    confirmButtonText: 'Complete',
   }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
     observer.next(true);
     observer.complete();

    } 
   });

    });
  }

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

  successInfo(title: string,displayConfirm:boolean): Observable<any>{

    return new Observable(observer => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: displayConfirm,
    confirmButtonText: "Continue",
    confirmButtonColor: "#0099da",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      observer.next(true);
    }
  });

});
}

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////


  apiError(title: string, message: string): Observable<any>{

    return new Observable(observer => {

      Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Close'
      }).then((result) => {

        if (result.isConfirmed) {
          observer.next({error:true});
          observer.complete();
        }

      });

    });
  }

}
