import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormEntryFileControlService {

  constructor() { }


  base64FileDownload(filename: string,base64: string): void{
  var FileSaver = require('file-saver');

  this.fileExtSearch(base64.split('data:')[1].split(';')[0]).subscribe(async(extention: string)=>{
    FileSaver.saveAs(this.dataURItoBlob(base64), filename+"."+extention);
  });

  }


  private dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;

  }


  private fileExtSearch(dataTag: string): Observable<any>{

    return new Observable(observer => {

      switch (dataTag) {
        case 'text/plain':
          observer.next('txt');
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          observer.next('docx');
          break;
        case 'application/pdf':
          observer.next('pdf');
          break;
        default:
          observer.next("default")
      }
    });

  }
}
