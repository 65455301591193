import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';
declare var $: any;



@Injectable({
  providedIn: 'root'
})
export class RowControlService {

  constructor() { }


///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Package Table Editor
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////



onEdit(ElementID: any, keyed_fields: any):Observable<any>{

  return new Observable(observer => {
  

  var row_public = ElementID[0].getAttribute('insights-ispublic');

    // Set System ID temapaery Var if Present  
    console.log(row_public); 
    if(row_public == 'true'){
      row_public = {true:'selected',false:''};
    }
    else{
      
      row_public = {true:'',false:'selected'};
    }
  
    //console.log("Required:"+input_required);
    
    Swal.fire({
      title: 'Row Settings',
      html: `
      
      
  
  

  <div class="form-group row col-12">
  <label for="input_required" class="col-sm-3 col-form-label" style="color:black;">Public</label>
  <div class="col-sm-9">
  <select name="row_public" class="form-control" id="row_public">
  <option value="true" `+row_public['true']+`> Yes</option>
  <option value="false" `+row_public['false']+`> No</option>
  </select>
  </div>
  </div>
  
  
      `,
      confirmButtonText: 'Save',
      focusConfirm: false,
      width: 650,
      preConfirm: () => {
        const temppop_row_public_val = Swal.getPopup().querySelector('#row_public').value;
       // element.hasAttribute('foo');
       // element.getAttribute('foo');
       // element.setAttribute('foo', value);
      //  element.removeAttribute('foo');
       // if (!login || !password) {
      //    Swal.showValidationMessage(`Please enter login and password`)
       // }
       return ({test:"test",  row_public:temppop_row_public_val});
     }
    }).then((result) => {


     
      // Set the the Value of Row Public 
      console.log(result.value['row_public']);
      if(result.value['row_public'] == 'true'){
        ElementID[0].setAttribute('insights-ispublic', 'true')
      }else{
        ElementID[0].setAttribute('insights-ispublic', 'false');
      }
      console.log(result.value);
      observer.next();
      //this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
    });
  });

}

}
