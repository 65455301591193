import { Component, OnInit } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest  } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as L from 'leaflet';

declare var vanillaSelectBox: any;

// Map Control
import { MapsService } from '../../../../_services/maps.service';
import {PolygonServicesService} from '../../../../_services/modules/geomap/polys/polygon-services.service'

// Form Control
import { FormControlService } from '../../../../_services/modules/geomap/forms/form-control.service';

import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';

import globalSettings from '../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-editpoly',
  templateUrl: './editpoly.component.html',
  styleUrls: ['./editpoly.component.css']
})
export class EditpolyComponent implements OnInit {
  private globalSetting: [] = globalSettings;
  polylist: any;
  mapPolys: any = {};
  map_id: any = this.route.snapshot.paramMap.get('map_id');
  popup: any;
  myMap: any;
  currentClickMarker: any = null;

  form: any = {

  };
  formsListArray: any = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private formControlService: FormControlService,
    private customcssservice: CustomcssService,
    private mapsservice: MapsService,
    private customjsservice: CustomjsService,
    private polygonServicesService: PolygonServicesService
    ) { }

  ngOnInit(): void {
     // Load Custom CSS onto page from Custom Css Service


 this.load_formvalues();

  //Call to Load Forms
  this.loadFormsList();

  this.load_polys();
  }


  load_formvalues(): void{
    this.http.get(this.globalSetting['api_url_console']+'/geomap?api=getpolydetails&polykey='+this.route.snapshot.paramMap.get('poly_id'),{}).subscribe((resp: any) => {
      this.form.poly_name = resp["poly_name"];
      this.form.poly_color = resp["poly_color"];
      this.form.poly_fill_color = resp["poly_fill_color"];
      this.form.poly_fill_opacity = resp["poly_fill_opacity"];
      this.form.poly_radius = resp["poly_radius"];
      if(resp["poly_enable"] == true){this.form.poly_enable = 'true';}else{this.form.poly_enable = 'false';}
      this.form.poly_inputformlink = resp["poly_inputformlink"];
      this.form.poly_inputdefaultform = resp["poly_inputdefaultform"];
      this.form.poly_status = resp["poly_status"];
      this.form.poly_type = resp["poly_type"];
      this.form.poly_input_form_type = resp["poly_input_form_type"];
      
     // this.myMap = this.mapsservice.InitMap(this.myMap,"map",resp["map_lat"],resp["map_long"],resp["map_zoom"]);
     // this.initMap();
      console.log(resp);
      //this.rerender();
  });
  }


  onSubmit(): void {
    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}
    this.form.poly_id = this.route.snapshot.paramMap.get('poly_id');
    this.form.poly_radius = parseFloat(this.form.poly_radius);
    if(this.form.poly_enable == 'true'){this.form.poly_enable = true;}else{this.form.poly_enable = false;}
    const body=JSON.stringify(this.form);
      this.http.post(this.globalSetting['api_url_console']+'/geomap?api=updatepoly',body,{'headers':headers}).subscribe(resp => {
      console.log(resp);
      if(resp['error'] == false){
        console.log(resp);
        Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
          this.router.navigate(['/geomap/maps/listpolys',{map_id:this.route.snapshot.paramMap.get('map_id')}])
        });
      }
      else
      {
        console.log(resp);
        Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{

        });
      }
      //this.rerender();
  });


  }


    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Forms List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    loadFormsList(){
      this.formControlService.getFormList('',true).subscribe((results)=>{

        //console.log(results);
        this.formsListArray = results;

      });

    }



    load_polys(): void{


      const request = new HttpRequest('GET', this.globalSetting['api_url_console']+'/geomap?api=getpolygeojson&polykey='+this.route.snapshot.paramMap.get('poly_id'),  {
        reportProgress: true
      });

      this.http.request(request).subscribe(event => {

        // progress
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.loaded, event.total);
          // event.loaded = bytes transfered
          // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;
          let polyTableLoader = document.getElementById('loadingTablePercent');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
      }
        }

        // finished
        if (event.type === HttpEventType.Response) {
          console.log(event.body);
          var polygon: any = event.body;
          let polyMapLoader = document.getElementById('polyMapLoader');
          polyMapLoader!.style.display = 'none';
         // this.polyFileUploaded = false;
          this.myMap = this.mapsservice.InitMap(this.myMap,"map",<any>polygon['poly_geojson']['features'][0]['geometry']['coordinates'][0][0][0][1],<any>polygon['poly_geojson']['features'][0]['geometry']['coordinates'][0][0][0][0],14);

          //this.rerender();


              this.mapPolys[''+polygon['_id']+''] = L.geoJSON(<any>polygon['poly_geojson'],
             <any>{
                color: "red"
              }).addTo(this.myMap).bindPopup('<h6>'+polygon['poly_name']+'</h6><a href="#" id="DelPoly_'+polygon['_id']+'" class="closebtn edit" data-toggle="modal" data-target="#step-modal"></a>')


              //console.log(resp1);
              //this.rerender();

        }


      })





    }






  



    updatePolygon(): void{


        var tempSelectPoly = '';
       
        this.polygonServicesService.convertGeojsonToPolygonArray(this.polylist).subscribe((results)=>{
        results.forEach(async (polygon: any, index)=>{

          tempSelectPoly = tempSelectPoly + '<option value="'+polygon['poly_id']+'">'+polygon['poly_name']+'</option>';
         
          if((index+1) >= results.length){

            this.customcssservice.load_vanillaSelectBox_Style();
            Swal.fire({
              title: 'New Report',
              html: `<div style="height:300px; width:px;">
              <select id="selectPolygon">
              `+tempSelectPoly+`
              </select>
              </div>
          `,
          width: 500,
          confirmButtonText: 'Continue',
          focusConfirm: false,
          showDenyButton: true,
          showCancelButton: false,
          denyButtonText: `Cancel`,
          preConfirm: () => {
            const polygon_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#selectPolygon')!).value;
           // element.hasAttribute('foo');
           // element.getAttribute('foo');
           // element.setAttribute('foo', value);
          //  element.removeAttribute('foo');
           // if (!login || !password) {
          //    Swal.showValidationMessage(`Please enter login and password`)
           // }
           return ({selected_polygon:polygon_val});
         }
        }).then((result: any) => {
          if (result.isConfirmed) {
          //  this.router.navigate(['/geomap/reports/reportbuilder', {form_id:result.value['selected_polygon']}]);
          //  /geomap/reports/reportbuilder;form_id=632c6974bf758009c5809eaa
          var temppoly = results.filter(data => data.poly_id.toString() == result.value['selected_polygon']);
          console.log(temppoly);
          this.updatePolygonGeoJson(temppoly[0]['poly_id'].toString(),temppoly[0]['poly_name'],temppoly[0]['poly_geojson']);

          } else if (result.isDenied) {
    
          }
          console.log(result.value);
    
        });

            var selectBox = new vanillaSelectBox("#selectPolygon", {
              "keepInlineStyles":true,
              "maxHeight": 200,
              "minWidth":300,
              "search": true,
              "placeHolder": "Choose a Form"
          });

          }
        });

      });
     }



     onFileChange(event) {
      //this.enableUpload = true;
      //this.polyFileUploaded = true;
      //this.displayTable = false;
      let reader = new FileReader();

      if(event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        // Base64 Output
        //reader.readAsDataURL(file);
        reader.readAsText(file);
        reader.onload = async () => {

         // this.formGroup.patchValue({
         //   file: reader.result
         // });

         //this.mainPolyFile = reader.result?.toString();
         //var tempPolys = JSON.parse(this.mainPolyFile);
         this.polylist = JSON.parse(reader.result!.toString());
         console.log(this.polylist);
         this.updatePolygon();
         //const promises: any = [];

         //this.deleteAllPolyForms();
         //this.enableImport = false;
        };
      }
    }


  updatePolygonGeoJson(poly_id:string,poly_name:string,poly_geojson: any): void{
   
    this.form.poly_geojson = '{"type":"FeatureCollection", "features":[{"type":"Feature","id":"ID_00001","geometry":'+JSON.stringify(poly_geojson)+',"properties":{"name":"'+poly_name+'","description":""}}]}';
    this.form.poly_geojson = JSON.parse('{"type":"FeatureCollection", "features":[{"type":"Feature","id":"ID_00001","geometry":'+JSON.stringify(poly_geojson)+',"properties":{"name":"'+poly_name+'","description":""}}]}');
    this.mapPolys[''+poly_id+''] = L.geoJSON(<any>this.form.poly_geojson,
    <any>{
       color: "blue"
     }).addTo(this.myMap).bindPopup('<h6>'+poly_name+'</h6><a href="#" id="DelPoly_'+poly_id+'" class="closebtn edit" data-toggle="modal" data-target="#step-modal"></a>')
  }

}
