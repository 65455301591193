<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/addresslist/addresslists']">Address Lists</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Address List</li>
        </ol>
    </nav>
    </div>


    <div class="row layout-spacing">
      <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
          <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                    </svg></a>
              </li>

              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Address List"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/> </svg> </a>
              </li>
          </ul>
      </div>
  </div>


<div class="row layout-spacing">

  <div  style="" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
    <div class="widget widget-card-standard-dropdown">
        <div class="widget-content">
            <div class="w-header">
                <div class="w-info">
                    <h5 class="value">Settings</h5>
                </div>


            </div>


            <div class="w-content">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="row g-3">


                        <form
                        name="form"
                        #f="ngForm">
                            <div class="row g-3">
                                <div class="form-group col-md-6">
                                    <label *ngIf="addresslist_enable.hasError('required')" for="addresslist_name" style="color:red">Name* (Required)</label>
                                    <label *ngIf="!addresslist_enable.hasError('required')" for="addresslist_name" >Name*</label>
                                    <input type="text" [(ngModel)]="form.addresslist_name" required #addresslist_name="ngModel"  name="addresslist_name" class="form-control" id="addresslist_name" placeholder="Name">
                                </div>
                                <div class="form-group col-md-6">
                                  <label *ngIf="addresslist_enable.hasError('required')" for="addresslist_enable" style="color:red">Pubished* (Required)</label>
                                  <label *ngIf="!addresslist_enable.hasError('required')" for="addresslist_enable" >Pubished*</label>

                                    <select class="form-control" [(ngModel)]="form.addresslist_enable" required #addresslist_enable="ngModel"  id="addresslist_enable" name="addresslist_enable">
                                    <option value="true" selected>Yes</option>
                                    <option value="false">No</option>
                                </select>
                                </div>
                            </div>



                        </form>

                        <div class="form-group col-md-1">

                                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div  style="" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
        <div class="widget widget-card-standard-dropdown">
            <div class="widget-content">
                <div class="w-header">
                    <div class="w-info">
                        <h5 class="value">Upload File (.csv)</h5>
                    </div>


                </div>


                <div class="w-content">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="row g-3">


                            <div class="form-row col-md-12">
                                <div class="form-group col-md-11">
                                <div class="custom-file-container" data-upload-id="myFirstImage">
                                <label>Upload (Single File) </label>
                                <label class="custom-file-container__custom-file" >
                                    <input type="file" [disabled]="" name="poly_geojson" (change)="onFileChange($event)" class="custom-file-container__custom-file__custom-file-input" id="fileInput" accept=".csv">
                                    <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                                    <span class="custom-file-container__custom-file__custom-file-control"></span>
                                </label>
                                <div style="display:none;" class="custom-file-container__image-preview"></div>
                            </div>
                            </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>





    <div  style="" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-top-spacing">
        <div class="widget widget-card-standard-dropdown">
            <div class="widget-content">
                <div class="w-header">
                    <div class="w-info">
                        <h5 class="value">Address List</h5>
                    </div>

                </div>
                <div class="w-content">

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


                        <div style="display:block;" id="polyTable" class="">
                            <table *ngIf="displayTable" datatable [dtOptions]="dtOptions" id="html5-extension" class="table table-hover non-hover" style="width:100%; min-height:250px;">
                                      <thead>
                                          <tr>

                                              <th>Address</th>
                                              <th>City</th>
                                              <th>State</th>
                                              <th>Zip</th>
                                              <th>Longitude</th>
                                              <th>Latitude</th>
                                          </tr>
                                      </thead>
                                      <tbody id="mapsTableBody">

                                          <tr #allTheseThings *ngFor="let row of rows">
                                              <td>{{row.address}}</td>
                                              <td>{{row.city}}</td>
                                              <td>{{row.state}}</td>
                                              <td>{{row.zip}}</td>
                                              <td>{{row.long}}</td>
                                              <td>{{row.lat}}</td>
                                            </tr>


                                      </tbody>
                                  </table>

                      </div>

                    </div>
                </div>
            </div>
        </div>
    </div>






</div>
