import { Component, Input, OnInit,AfterViewInit } from '@angular/core';

import {PackagetablesService} from 'src/app/_services/modules/geomap/packagetables/packagetables.service';

@Component({
  selector: 'app-packagetable-editor',
  templateUrl: './packagetable-editor.component.html',
  styleUrls: ['./packagetable-editor.component.css']
})
export class PackagetableEditorComponent implements OnInit {

  @Input() selectedElementID!: any;
  @Input() keyed_fields!: any;

  tempElementValues: any = {
    input_label:'',
    input_package_table :'',
    input_required: '',
    input_public:'',
    input_keyed_fields:[],
    input_system_id:''

  };

  packageTables: any= [];

  constructor(private packagetablesService:PackagetablesService) { }

  ngOnInit(): void {
    // Load avalible package table options into array fro the Selection Element
    this.loadPackageTables();

    this.tempElementValues['input_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML;
    this.tempElementValues['input_required'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('required');
    this.tempElementValues['input_private_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('private_label');
    this.tempElementValues['input_public'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('insights-ispublic');

    //Start: Needs Added to UI FORM FRONTEND 12-13-2022
    this.tempElementValues['input_fiber_package_table_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('fiber-package-table-id');
    this.tempElementValues['input_copper_package_table_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('copper-package-table-id');
    this.tempElementValues['input_default_package_table_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('default-package-table-id');
    //End:  Needs Added to UI FORM FRONTEND 12-13-2022

    // Set Input Public Status
    if(this.tempElementValues['input_public'] == 'true'){
      this.tempElementValues['input_public'] = 'true';
    }
    else{

      this.tempElementValues['input_public'] = 'false';
    }

    // Set Input Keyed Field Status
    var temparray: string[] = [];
    document.querySelectorAll('[keyed-field]').forEach(async (el: any)=>{
         temparray.push(el.getAttribute('keyed-field'));
     });

     var KeyedText = '';
     console.log( this.keyed_fields);
     this.keyed_fields.forEach(async(key: any)=>{
      if(!temparray.includes(key['name'])){
         this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: false});
      }
       else{
        if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('keyed-field') == key['name']){
           this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: true});
        }
      }

     });

    // Set System ID temapaery Var if Present
    var system_id_val: any = "";
    if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('system-id') !== null){
      this.tempElementValues['input_system_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.getAttribute('system-id');
    }
    else{
      this.tempElementValues['input_system_id'] = "";
    }
  }


loadPackageTables(): void{

this.packagetablesService.getPackageTables('').subscribe(async (packageTables)=>{
this.packageTables = packageTables;
})

}


  updateElement(name: string,el: any): void{
    if(name == 'label'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML = el.target.value;
    }

    else if(name == 'private_label'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('private_label',el.target.value);
    }
  
    else if(name == 'copper_package_table'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('copper-package-table-id',el.target.value);
    }

    else if(name == 'fiber_package_table'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('fiber-package-table-id',el.target.value);
    }

    else if(name == 'default_package_table'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('default-package-table-id',el.target.value);
    }

    else if(name == 'required'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('required',el.target.value);
    }

    else if(name == 'is_public'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('insights-ispublic',el.target.value);
    }

    else if(name == 'system_id'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('system-id',el.target.value);
    }

    else if(name == 'keyed_field'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('table')!.setAttribute('keyed-field',el.target.value);
    }

    }
}
