import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-form-entry-address-search',
  templateUrl: './new-form-entry-address-search.component.html',
  styleUrls: ['./new-form-entry-address-search.component.css']
})
export class NewFormEntryAddressSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
