<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">Form Builder</li>
        </ol>
    </nav>
    </div>



    <div class="row layout-spacing">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
            <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
                <li class="nav-item mr-2">
                    <a class="nav-link mb-1 rounded-circle" id="rounded-circle-pills-icon-home-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-home" role="tab" aria-controls="rounded-circle-pills-icon-home" aria-selected="false"><img height="24px;" width="24px" src="assets/insights/light/img/forms/form-builder/setting.svg"></a>
                </li>
                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-profile" aria-selected="false"><img height="24px;" width="24px" src="assets/insights/light/img/forms/form-builder/form.svg"></a>
                </li>
             <!-- <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" id="rounded-circle-pills-icon-contact-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-contact" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                      </svg></a>
                </li> -->
                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" id="rounded-circle-pills-icon-complete-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-complete" role="tab" aria-controls="rounded-circle-pills-icon-complete" aria-selected="false"><img height="24px;" width="24px" src="assets/insights/light/img/forms/form-builder/check.svg"></a>
                </li>

                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Form"  [matTooltipPosition]="'right'"> <img height="24px;" width="24px" src="assets/insights/light/img/forms/form-builder/save.svg"></a>
                </li>
            </ul>
        </div>
    </div>



<div class="tab-content" id="rounded-circle-pills-icon-tabContent">

    <div class="tab-pane fade active show" id="rounded-circle-pills-icon-profile" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-profile-tab">

            <div class="row layout-spacing">

                    <!-- Content -->
                    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12 layout-top-spacing " style="position: -webkit-sticky; position: sticky;">



                        <div class="card widget-flat testscrool">
                            <div class="card-body">
                                <div class="float-end">
                                    <i class="mdi mdi-pulse widget-icon"></i>
                                </div>
                                <h5 class="fw-normal mt-0 mb-1" title="Growth">Toolbox</h5>
                                <ul class="nav nav-tabs  mt-3 mb-3" id="lineTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="underline-home-tab" data-bs-toggle="tab" href="#underline-home" role="tab" aria-controls="underline-home" aria-selected="true">Layout</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" id="underline-contact-tab" data-bs-toggle="tab" href="#underline-contact" role="tab" aria-controls="underline-contact" aria-selected="false"> Edit</a>
                                    </li>


                                </ul>

                                <div class="tab-content" id="lineTabContent-3">

                                    <div class="tab-pane fade active show" id="underline-home" role="tabpanel" style="overflow-y:auto; height:500px" aria-labelledby="underline-home-tab">

                                      <div style="padding: 0px 30px 30px 30px; ">
                                        <div class="toolbox-element-tab-header">Drag and Drop Elements</div>
                                        <div class="col-12">
                                          <div class="row">

                                            <div id="rowToolbox" class="col-4">
                                                <div type="row1" style="text-align: center;margin-bottom:20px; ">
                                                 <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_one.svg" matTooltip="One Column Row"  [matTooltipPosition]="'right'">
                                                </div>

                                            </div>

                                            <div id="rowToolbox" class="col-4">
                                              <div type="row2" style="text-align:center; margin-bottom:20px;">
                                               <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_two.svg" matTooltip="Two Column Row"  [matTooltipPosition]="'right'">
                                              </div>

                                          </div>

                                          <div id="rowToolbox" class="col-4">
                                            <div type="row3" style="text-align: center;margin-bottom:20px; ">
                                              <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_three.svg" matTooltip="Three Column Row"  [matTooltipPosition]="'right'">
                                             </div>

                                        </div>
                                      </div>

                                      <div class="row">
                                        <div id="rowToolbox" class="col-4">
                                          <div type="row4" style="text-align: center;margin-bottom:20px; ">
                                            <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_four.svg" matTooltip="Four Column Row"  [matTooltipPosition]="'right'">
                                           </div>

                                      </div>

                                      <div id="rowToolbox" class="col-4">
                                        <div type="row5" style="text-align: center;margin-bottom:20px; ">
                                          <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_three.svg" matTooltip="Five Column Row"  [matTooltipPosition]="'right'">
                                         </div>

                                    </div>

                                    <div id="rowToolbox" class="col-4">
                                      <div type="row6" style="text-align: center;margin-bottom:20px; ">
                                        <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/column_three.svg" matTooltip="Six Column Row"  [matTooltipPosition]="'right'">
                                       </div>

                                  </div>

                                        </div>
                                      </div>

                                          <div class="toolbox-element-tab-header">Drag and Drop Elements</div>


                                          <div class="col-12">
                                              <div class="row">

                                                <div id="testNewDrag" class="col-4">
                                                    <div type="textbox" style="text-align: center; margin-bottom:20px; ">
                                                      <div class="toolbox-element-icon">
                                                          <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/text-box.svg" matTooltip="Textbox Field"  [matTooltipPosition]="'right'">
                                                      </div>
                                                    </div>


                                                     <div type="select" style="text-align: center;margin-bottom:20px;">
                                                      <div class="toolbox-element-icon">
                                                      <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/drop-down.svg" matTooltip="Select Field"  [matTooltipPosition]="'right'">
                                                  </div>
                                                  </div>

                                                     <div type="file" style="text-align: center; margin-bottom:20px; ">
                                                      <div class="toolbox-element-icon">
                                                      <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/upload.svg" matTooltip="File Upload Field"  [matTooltipPosition]="'right'">
                                                  </div>
                                                  </div>
    
                                                </div>

                                                <div id="testNewDrag" class="col-4">

                                                  <div type="password" style="text-align: center; margin-bottom:20px;">
                                                    <div class="toolbox-element-icon">
                                                   <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/login.svg" matTooltip="Hidden Field"  [matTooltipPosition]="'right'">
                                                   </div>
                                                  </div>

                                                  <div type="textarea" style="text-align: center;margin-bottom:20px; ">
                                                    <div class="toolbox-element-icon">
                                                    <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/align-center.svg" matTooltip="Text Area Field"  [matTooltipPosition]="'right'">
                                                </div>
                                                </div>

                                                <!--<div type="paragraphbox" style="text-align: center; margin-bottom:20px;">
                                                  <div class="toolbox-element-icon">
                                                   <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/align-center.svg">
                                                  </div>
                                                </div>-->


                                                <div type="packagetable" style="text-align: center; margin-bottom:20px;">
                                                  <div class="toolbox-element-icon">
                                                     <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/grid.svg" matTooltip="Package Table"  [matTooltipPosition]="'right'">
                                                  </div>
                                                </div>

                                              </div>

                                                <div id="testNewDrag" class="col-4">
                                                  <div type="date" style="text-align: center; margin-bottom:20px;">
                                                    <div class="toolbox-element-icon">
                                                    <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/calendar.svg" matTooltip="Date Field"  [matTooltipPosition]="'right'">
                                                   </div>
                                                  </div>

                                                  <!-- <div type="radio" style="text-align: center; margin-bottom:20px;">
                                                    <div class="toolbox-element-icon">
                                                    <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/radio-button.svg">
                                                  </div>
                                                   </div> -->

                                                   <div type="checkbox" style="text-align: center; margin-bottom:20px;">
                                                    <div class="toolbox-element-icon">
                                                    <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/checkbox.svg" matTooltip="Checkbox Field"  [matTooltipPosition]="'right'">
                                                  </div>
                                                   </div>

                                                   <div type="htmlarea" style="text-align: center; margin-bottom:20px;">
                                                    <div class="toolbox-element-icon">
                                                    <img height="45px" width="45px" src="assets/insights/light/img/forms/form-builder/pagination.svg" matTooltip="HTML Area"  [matTooltipPosition]="'right'">
                                                    </div>
                                                   </div>


                                              </div>

                                            </div>
                                          </div>

                                      </div>
                                    </div>


                                    <div class="tab-pane fade" id="underline-profile" role="tabpanel" style="overflow-y:auto; height:500px" aria-labelledby="underline-profile-tab">

                                    </div>

                                    <div  class="tab-pane fade" id="underline-contact" role="tabpanel" aria-labelledby="underline-contact-tab">
                                            <!-- Load Textbox Editor-->
                                           <app-textbox-editor *ngIf="selectedEditorType == 'textbox' || selectedEditorType == 'password'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-textbox-editor>

                                           <app-date-editor *ngIf="selectedEditorType == 'date'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-date-editor>

                                           <app-textarea-editor *ngIf="selectedEditorType == 'textarea'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-textarea-editor>

                                           <app-select-editor *ngIf="selectedEditorType == 'select'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-select-editor>

                                           <app-file-input-editor *ngIf="selectedEditorType == 'file'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-file-input-editor>

                                           <app-checkbox-editor *ngIf="selectedEditorType == 'checkbox'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-checkbox-editor>

                                           <app-radio-editor *ngIf="selectedEditorType == 'radio'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-radio-editor>

                                           <app-packagetable-editor *ngIf="selectedEditorType == 'packagetable'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-packagetable-editor>

                                           <app-htmlarea-editor *ngIf="selectedEditorType == 'htmlarea'" [selectedElementID]="editOnSelectLast['elementID']" [keyed_fields]="getFormkeyedFields()"></app-htmlarea-editor>
                                    </div>

                                </div>
                            </div>
                        </div>



                </div>



                    <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12 layout-top-spacing " style=" ">

                        <div class="skills layout-spacing ">
                            <div class="widget-content widget-content-area ">


                                <div style="background-color: #fff; border-radius: 5px; padding: 20px;
                                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); ">
                            <div class="text-muted empty-form text-center" *ngIf="currFormLayout.length < 1 && infoBanner" style="font-size: 24px;">Add a Row Then Drag & Drop elements to build form.</div>
                                <div id="formBody" class="form-body droppableRow sortablerow">

                                    <ng-container *ngFor="let i of currFormLayout" >

                                        <div type="row" class="row draggableRow" [attr.insights-ispublic]="i.layout_public">
                                            <!-- Single -->

                                            <div *ngFor="let in of columnCounter(i.layout_col_count) ;let colIndex = index" id="insightsDropCol" class="col-lg droppable sortable formbuilder-dynamicrow testNewDrop1">
                                                <ng-container *ngFor="let ii of i.layout_fields[colIndex].col" >

                                                <!-- line 1 -->
                                                <app-form-elements [question]="ii" [form]="form" [type]="'builder'"></app-form-elements>

                                                </ng-container>

                                            </div>


                                        <p class="tools"><a class="edit-row-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p>
                                    </div>


                                    </ng-container>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
<div class="tab-pane fade" id="rounded-circle-pills-icon-home" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-home-tab">

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">




        <div class="row layout-top-spacing" id="cancel-row">

            <div  style="" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-table-with-lic">
                    <div class="widget-content">
                        <div class="w-header">
                            <div class="w-info">
                                <h4 class="value">Form Settings</h4>
                            </div>


                        </div>


                        <div class="w-content">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style=" margin-bottom:3%;">

                                    <div class="row col-12 b-4">
                                        <div class="form-group col-md-6">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Form Name</mat-label>
                                                <input type="text" [formControl]="form.form_name" required matInput  name="form_name" id="form_name" placeholder="Form Name" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="form.form_name.hasError('required')">
                                                Form Name <strong>Required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Form Type</mat-label>
                                                <mat-select [formControl]="form.form_type" [errorStateMatcher]="matcher">
                                                  <mat-option>Clear</mat-option>
                                                  <mat-option value="0">Standard</mat-option>
                                                  <mat-option value="1">Speedtest</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="form.form_type?.hasError('required') ">You must make a selection</mat-error>
                                            </mat-form-field>
                                        </div>
                                       <!-- <div class="form-group col-md-6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Workflow</mat-label>
                                                <mat-select [formControl]="form.form_workflow_id" [errorStateMatcher]="matcher">
                                                  <mat-option value="sdf">None</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="form.form_workflow_id?.hasError('required')">You must make a selection</mat-error>
                                            </mat-form-field>
                                        </div> -->
                                        <div class="form-group col-md-6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Published</mat-label>
                                                <mat-select [formControl]="form.form_enable" [errorStateMatcher]="matcher">
                                                  <mat-option value="true">Yes</mat-option>
                                                  <mat-option value="false">No</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="form.form_enable?.hasError('required')">You must make a selection</mat-error>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <div class="form-group">


                                    </div>


                            </div>

                        </div>


                    </div>
                </div>
            </div>


        </div>





        <div id="toggleAccordion" class="accordion">

          <div class="row layout-top-spacing" id="cancel-row">

          <div class="card">
            <div class="card-header" id="headingOne1">
              <section class="mb-0 mt-0">
                <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionOne" aria-expanded="false" aria-controls="defaultAccordionOne">
                  Optional Settings  <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
                </div>
              </section>
            </div>

            <div id="defaultAccordionOne" class="collapse" aria-labelledby="headingOne1" data-bs-parent="#toggleAccordion" style="">
              <div class="card-body">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style=" margin-bottom:3%;">

                  <div class="row col-12 b-4">

                      <div class="form-group col-md-6">
                          <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>form_header_text_default</mat-label>
                              <input type="text" [formControl]="form.form_settings.form_header_text.default" required matInput  name="form_header_text_default" id="form_header_text_default" placeholder="form_header_text_default" [errorStateMatcher]="matcher">
                              <mat-error *ngIf="form.form_settings.form_header_text.default.hasError('required')">
                                  form_header_text <strong>Required</strong>
                              </mat-error>
                          </mat-form-field>
                      </div>

                      <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>form_header_text_fiber</mat-label>
                            <input type="text" [formControl]="form.form_settings.form_header_text.fiber" required matInput  name="form_header_text_fiber" id="form_header_text_fiber" placeholder="form_header_text_fiber" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="form.form_settings.form_header_text.fiber.hasError('required')">
                                form_header_text <strong>Required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>form_header_text_copper</mat-label>
                            <input type="text" [formControl]="form.form_settings.form_header_text.copper" required matInput  name="form_header_text_copper" id="form_header_text_copper" placeholder="form_header_text_copper" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="form.form_settings.form_header_text.copper.hasError('required')">
                                form_header_text_copper <strong>Required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                      <div class="form-group col-md-6">
                          <mat-form-field appearance="outline">
                              <mat-label>form_header_display</mat-label>
                              <mat-select [formControl]="form.form_settings.form_header_display" [errorStateMatcher]="matcher">
                                <mat-option></mat-option>
                                <mat-option value="true">True</mat-option>
                                <mat-option value="false">False</mat-option>
                              </mat-select>
                              <mat-error *ngIf="form.form_settings.form_header_display?.hasError('required') ">You must make a selection</mat-error>
                          </mat-form-field>
                      </div>

                      <div class="form-group col-md-6">
                          <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>form_header_text_color</mat-label>
                              <input type="text" [formControl]="form.form_settings.form_header_text_color" required matInput  name="form_header_text_color" id="form_header_text_color" placeholder="form_header_text_color" [errorStateMatcher]="matcher">
                              <mat-error *ngIf="form.form_settings.form_header_text_color.hasError('required')">
                                  form_header_text_color <strong>Required</strong>
                              </mat-error>
                          </mat-form-field>
                      </div>

                      <div class="form-group col-md-6">
                          <mat-form-field appearance="outline">
                              <mat-label>form_header_font_weight</mat-label>
                              <mat-select [formControl]="form.form_settings.form_header_font_weight" [errorStateMatcher]="matcher">
                                <mat-option value="bold">Bold</mat-option>
                                <mat-option value="bold">Normal</mat-option>
                              </mat-select>
                              <mat-error *ngIf="form.form_settings.form_header_font_weight?.hasError('required')">You must make a selection</mat-error>
                          </mat-form-field>
                      </div>

                      <div class="form-group col-md-6">
                          <mat-form-field appearance="outline">
                              <mat-label>form_header_font_size</mat-label>
                              <mat-select [formControl]="form.form_settings.form_header_font_size" [errorStateMatcher]="matcher">
                                <mat-option value="24">24</mat-option>
                                <mat-option value="36">36</mat-option>
                              </mat-select>
                              <mat-error *ngIf="form.form_settings.form_header_font_size?.hasError('required')">You must make a selection</mat-error>
                          </mat-form-field>
                      </div>

                      <div class="form-group col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>form_header_location</mat-label>
                            <mat-select [formControl]="form.form_settings.form_header_location" [errorStateMatcher]="matcher">
                              <mat-option value="left">Left</mat-option>
                              <mat-option value="center">Center</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.form_settings.form_header_location?.hasError('required')">You must make a selection</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_submit_button_text</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_submit_button_text" required matInput  name="form_submit_button_text" id="form_submit_button_text" placeholder="form_submit_button_text" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_submit_button_text.hasError('required')">
                              form_submit_button_text <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_submit_button_text_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_submit_button_text_color" required matInput  name="form_submit_button_text_color" id="form_submit_button_text_color" placeholder="form_submit_button_text_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_submit_button_text_color.hasError('required')">
                              form_submit_button_text_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>


                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_submit_button_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_submit_button_color" required matInput  name="form_submit_button_color" id="form_submit_button_color" placeholder="form_submit_button_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_submit_button_color.hasError('required')">
                              form_submit_button_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_element_label_font_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_element_label_font_color" required matInput  name="form_element_label_font_color" id="form_element_label_font_color" placeholder="form_element_label_font_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_element_label_font_color.hasError('required')">
                              form_element_label_font_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field appearance="outline">
                          <mat-label>form_element_label_font_weight</mat-label>
                          <mat-select [formControl]="form.form_settings.form_element_label_font_weight" [errorStateMatcher]="matcher">
                            <mat-option value="bold">Bold</mat-option>
                            <mat-option value="normal">Normal</mat-option>
                          </mat-select>
                          <mat-error *ngIf="form.form_settings.form_element_label_font_weight?.hasError('required')">You must make a selection</mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_element_text_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_element_text_color" required matInput  name="form_element_text_color" id="form_element_text_color" placeholder="form_element_text_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_element_text_color.hasError('required')">
                              form_element_text_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_element_background_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_element_background_color" required matInput  name="form_element_background_color" id="form_element_background_color" placeholder="form_element_background_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_element_background_color.hasError('required')">
                              form_element_background_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>form_element_required_text_color</mat-label>
                          <input type="text" [formControl]="form.form_settings.form_element_required_text_color" required matInput  name="form_element_required_text_color" id="form_element_required_text_color" placeholder="form_element_required_text_color" [errorStateMatcher]="matcher">
                          <mat-error *ngIf="form.form_settings.form_element_required_text_color.hasError('required')">
                              form_element_required_text_color <strong>Required</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="form-group col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>form_package_tb_header_bg_color</mat-label>
                        <input type="text" [formControl]="form.form_settings.form_package_tb_header_bg_color" required matInput  name="form_package_tb_header_bg_color" id="form_package_tb_header_bg_color" placeholder="form_package_tb_header_bg_color" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form.form_settings.form_package_tb_header_bg_color.hasError('required')">
                          form_package_tb_header_bg_color <strong>Required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>form_package_tb_header_text_color</mat-label>
                      <input type="text" [formControl]="form.form_settings.form_package_tb_header_text_color" required matInput  name="form_package_tb_header_text_color" id="form_package_tb_header_text_color" placeholder="form_package_tb_header_text_color" [errorStateMatcher]="matcher">
                      <mat-error *ngIf="form.form_settings.form_package_tb_header_text_color.hasError('required')">
                        form_package_tb_header_text_color <strong>Required</strong>
                      </mat-error>
                  </mat-form-field>
              </div>

              <div class="form-group col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>form_resp_email_msg</mat-label>
                    <input type="text" [formControl]="form.form_settings.form_resp_email_msg" required matInput  name="form_resp_email_msg" id="form_resp_email_msg" placeholder="form_resp_email_msg" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="form.form_settings.form_resp_email_msg.hasError('required')">
                      form_resp_email_msg <strong>Required</strong>
                    </mat-error>
                </mat-form-field>
            </div>




                  </div>

                  <div class="form-group">


                  </div>


          </div>
              </div>
            </div>
          </div>
</div>



<div class="row layout-top-spacing" id="cancel-row">
          <div class="card">
            <div class="card-header" id="headingTwo1">
              <section class="mb-0 mt-0">
                <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionTwo" aria-expanded="false" aria-controls="defaultAccordionTwo">
                  Optional Settings  <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
                </div>
              </section>
            </div>
            <div id="defaultAccordionTwo" class="collapse" aria-labelledby="headingTwo1" data-bs-parent="#toggleAccordion" style="">
                <div class="card-body">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style=" margin-bottom:3%;">

                    <div class="row col-12 b-4">

                  <div class="form-group col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>form_speedtest_continue_dialog_text</mat-label>
                        <input type="text" [formControl]="form.form_settings.form_speedtest_continue_dialog_text" required matInput  name="form_speedtest_continue_dialog_text" id="form_speedtest_continue_dialog_text" placeholder="form_speedtest_continue_dialog_text" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form.form_settings.form_speedtest_continue_dialog_text.hasError('required')">
                          form_speedtest_continue_dialog_text <strong>Required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>form_speedtest_continue_dialog_button_color</mat-label>
                      <input type="text" [formControl]="form.form_settings.form_speedtest_continue_dialog_button_color" required matInput  name="form_speedtest_continue_dialog_button_color" id="form_speedtest_continue_dialog_button_color" placeholder="form_speedtest_continue_dialog_button_color" [errorStateMatcher]="matcher">
                      <mat-error *ngIf="form.form_settings.form_speedtest_continue_dialog_button_color.hasError('required')">
                        form_speedtest_continue_dialog_button_color <strong>Required</strong>
                      </mat-error>
                  </mat-form-field>
              </div>


              <div class="form-group col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>form_speedtest_continue_dialog_button_text</mat-label>
                    <input type="text" [formControl]="form.form_settings.form_speedtest_continue_dialog_button_text" required matInput  name="form_speedtest_continue_dialog_button_text" id="form_speedtest_continue_dialog_button_text" placeholder="form_speedtest_continue_dialog_button_text" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="form.form_settings.form_speedtest_continue_dialog_button_text.hasError('required')">
                      form_speedtest_continue_dialog_button_text <strong>Required</strong>
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="form-group col-md-6">
              <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>form_speedtest_success_dialog_text</mat-label>
                  <input type="text" [formControl]="form.form_settings.form_speedtest_success_dialog_text" required matInput  name="form_speedtest_success_dialog_text" id="form_speedtest_success_dialog_text" placeholder="form_speedtest_success_dialog_text" [errorStateMatcher]="matcher">
                  <mat-error *ngIf="form.form_settings.form_speedtest_success_dialog_text.hasError('required')">
                    form_speedtest_success_dialog_text <strong>Required</strong>
                  </mat-error>
              </mat-form-field>
          </div>


          <div class="form-group col-md-6">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>form_speedtest_success_dialog_button_color</mat-label>
                <input type="text" [formControl]="form.form_settings.form_speedtest_success_dialog_button_color" required matInput  name="form_speedtest_success_dialog_button_color" id="form_speedtest_success_dialog_button_color" placeholder="form_speedtest_success_dialog_button_color" [errorStateMatcher]="matcher">
                <mat-error *ngIf="form.form_settings.form_speedtest_success_dialog_button_color.hasError('required')">
                  form_speedtest_success_dialog_button_color <strong>Required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>form_speedtest_success_dialog_button_text</mat-label>
              <input type="text" [formControl]="form.form_settings.form_speedtest_success_dialog_button_text" required matInput  name="form_speedtest_success_dialog_button_text" id="form_speedtest_success_dialog_button_text" placeholder="form_speedtest_success_dialog_button_text" [errorStateMatcher]="matcher">
              <mat-error *ngIf="form.form_settings.form_speedtest_success_dialog_button_text.hasError('required')">
                form_speedtest_success_dialog_button_text <strong>Required</strong>
              </mat-error>
          </mat-form-field>
      </div>


                      </div>
                    </div>

<!-- Settings Here-->
                </div>
            </div>
          </div>



</div>

<div class="row layout-top-spacing" id="cancel-row">

          <div class="card">
            <div class="card-header" id="headingThree1">
              <section class="mb-0 mt-0">
                <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionThree" aria-expanded="false" aria-controls="defaultAccordionThree">
                  Optional Settings <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
                </div>
              </section>
            </div>
            <div id="defaultAccordionThree" class="collapse" aria-labelledby="headingThree1" data-bs-parent="#toggleAccordion">
              <div class="card-body">
<!-- Settings Here-->
              </div>
            </div>
          </div>


</div>


        </div>


    </div>

</div>


<div class="tab-pane fade" id="rounded-circle-pills-icon-complete" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-complete-tab">



    <div class="row layout-top-spacing" id="cancel-row">

        <div  style="" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-table-with-lic">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">Finished Page</h4>
                        </div>

                        <div class="task-action">
                            <div class="float-left  mr-5">

                        </div>
                            <div class="dropdown float-right mt-2">
                                <a class="dropdown-toggle" href="#" role="button" id="pendingTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="pendingTask" style="will-change: transform;">
                                    <!-- <a class="dropdown-item" href="javascript:void(0);">Copy</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Download CSV</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Download Excel</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Print</a>
                                    <div class="invoice-action" style="display:flex;"><div class="dt-buttons">   <button class="dt-button buttons-csv buttons-html5 btn" tabindex="0" aria-controls="default-ordering" type="button"><span>Export as CSV</span></button> <button class="dt-button buttons-excel buttons-html5 btn" tabindex="0" aria-controls="default-ordering" type="button"><span>Export as Excel</span></button> <button class="dt-button buttons-print btn" tabindex="0" aria-controls="default-ordering" type="button"><span>Print</span></button> </div></div>
                                    --> </div>
                            </div>
                        </div>
                    </div>


                    <div class="w-content">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="min-height:400px; margin-bottom:3%;">
                            <!--<div id="form_CompletedPage" style="width:100%;">
                                <h1 class="ql-align-center">Thank You!</h1>
                            </div> -->
                            <textarea id="tinydefault">{{tinymcehtml}}</textarea>
                        </div>

                    </div>


                </div>
            </div>
        </div>


    </div>




</div>


</div>
