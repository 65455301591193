import { Injectable } from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Observable } from 'rxjs';

//import Global Setting Varitables
import globalSettings from '../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class NiscApiService {
 // Global Settings
 private globalSetting: [] = globalSettings;


  constructor(private http: HttpClient) { }



  getPublicProductCatalog(percentElementId: string,niscData: any): Observable<any>{

    return new Observable(observer => {
  
      const request = new HttpRequest('GET', 'https://rtccom.cloud.coop/services/secured/customer',  {
        reportProgress: true
      });
      
      this.http.request(request).subscribe(event => {
      
        // progress
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.loaded, event.total); 
          // event.loaded = bytes transfered 
          // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;
  
          if(percentElementId !== '' && percentElementId !== undefined){
            let polyTableLoader = document.getElementById(''+percentElementId+'');
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
          }
  
      }
        }
      
        // finished
        if (event.type === HttpEventType.Response) {
          observer.next(event.body);
          console.log(event.body);
        }
      
      });
        
         
     
  
    });
  }





  createNiscSubscriber(userData: any): Observable<any>{

    return new Observable(observer => {
  
      //const { username, password } = this.form;
      const headers = { 'content-type': 'application/json'};
  
      const body=JSON.stringify(userData);
      console.log(body);
        this.http.post('https://api.myininsights.com/nisc/?api=newcustomer',body,{'headers':headers}).subscribe(resp => {
          if(resp['customer'] !== null && resp['customer'] !== undefined){
            console.log(resp);
            Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
              //this.router.navigate(['/geomap/maps/listmaps'])
              observer.next({error:resp['error']});
            }); 
          }
          else
          {
            console.log(resp);
            Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{
              observer.next({error:resp['error']});
            });
          }
        //this.rerender();
    });
  
    });
  }



  createNiscSubscriberWithSo(subscriberData: any): Observable<any>{

    return new Observable(observer => {
  
      //const { username, password } = this.form;
      const headers = { 'content-type': 'application/json'}  ;
  
      const body=JSON.stringify(subscriberData);
        this.http.post(''+this.globalSetting['api_url_console']+'/thirdparty/nisc/subscriber?api=createsubscriberwithso',body,{'headers':headers}).subscribe(resp => {
          if(resp['error'] == false){
            console.log(resp);
            Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
              //this.router.navigate(['/geomap/maps/listmaps'])
              observer.next({error:resp['error']});
            }); 
          }
          else
          {
            console.log(resp);
            Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{
              observer.next({error:resp['error']});
            });
          }
        //this.rerender();
    });
  
    });
  }



}
