<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit Form</li>
        </ol>
    </nav>
    </div>

<div class="row layout-spacing">

    <!-- Content -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area pl-2 pr-2 pb-4 pt-2">
                <div class="d-flex justify-content-between">
                    <h3 class="">New Form</h3>
                    <a href="javascript:void(0);" class="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></a>
                </div>

                <div class="widget-content layout-top-spacing">
                    <form 
                    name="form"
                    (ngSubmit)=" onSubmit()"
                    #f="ngForm">
                        <div class="form-row mb-4">
                            <div class="form-group col-md-6">
                                <label for="inputName1">Name</label>
                                <input type="text" [(ngModel)]="form.form_name" required #form_name="ngModel"  name="map_name" class="form-control" id="inputName1" placeholder="Form Name">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputName1">Type</label>
                                <select class="form-control" [(ngModel)]="form.form_type" required #form_type="ngModel"  id="map_enable" name="map_enable">
                                <option value="0">Normal</option>
                                <option value="1">Speedtest</option>
                            </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputName1">Workflow</label>
                                <select class="form-control" [(ngModel)]="form.form_workflow_id" required #form_workflow_id="ngModel"  id="map_enable" name="map_enable">
                                <option value="true"></option>
                                <option value="false">Select Workflow</option>
                            </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputName1">Address List</label>
                                <select class="form-control" [(ngModel)]="form.form_addresslist_id" required #form_addresslist_id="ngModel"  id="map_enable" name="map_enable">
                                <option *ngFor="let AddressList of addressListArray" [value]="AddressList._id" >
                                  {{AddressList.addresslist_name}}
                                </option>
                                   <!-- <option *ngFor="let AddressList of addressListArray" 
                                    [value]="title.Value" 
                                    [attr.selected]="tableRowValues.Title==title.Text ? true : null">
                                  {{AddressList.addresslist_name}}
                                </option> -->
                            </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputName1">Backup Address Method</label>
                                <select class="form-control" [(ngModel)]="form.form_addresslist_backup_id" required #form_addresslist_backup_id="ngModel"  id="map_enable" name="map_enable">
                                <option value="true"></option>
                                <option value="false">Select Backup Address Method</option>
                            </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputName1">Pubished</label>
                                <select class="form-control" [(ngModel)]="form.form_enable" required #form_enable="ngModel"  id="map_enable" name="map_enable">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            </div>
            
                        </div>

                        <div class="form-group">


                        </div>
                        <button type="submit" class="btn btn-primary mt-3">Create</button>
                    </form>

                </div>
            </div>
        </div>





    </div>



</div>
