import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class FormEntryControlService {

 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(private http: HttpClient) { }

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getFormEntries(percentElementId: string, form_id: any, type: any,limit:number): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentries&format='+type+'&formkey='+form_id+'&limit='+limit+'',  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });




  });
}





//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getFormEntriesWithFilter(percentElementId: string, form_id: any, filters: any, limit: number, type: any): Observable<any>{

  return new Observable(observer => {

    const headers = { 'content-type': 'application/json'}
    var tempJson = {
      row_filters:filters,
      row_return_limit:limit
    };


    this.http.post(''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentrieswithfilters&format='+type+'&formkey='+form_id,JSON.stringify(tempJson),{'headers':headers}).subscribe((retrunBody)=>{


        observer.next(retrunBody);
        //console.log(event.body);


    });




  });
}



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getFormEntriesWithFilter2(percentElementId: string, form_id: any, filters: any, limit: number, starting_index:number, type: any): Observable<any>{

  return new Observable(observer => {

    const headers = { 'content-type': 'application/json'}

    var tempJson = {
      row_filters:filters,
      row_return_limit:limit,
      row_starting_index:starting_index,
      row_format:type
    };


    this.http.post(''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentrieswithfilters2&formkey='+form_id,JSON.stringify(tempJson),{'headers':headers}).subscribe((retrunBody)=>{

        observer.next(retrunBody);
        //console.log(event.body);

    });
  });
}






//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getFormEntry(percentElementId: string, entry_id: any, type: any): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentry&format='+type+'&entry_id='+entry_id+'',  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });




  });
}



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//
//
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



removeNonInputRows(questions: any,callback): void{
  var inputConstructors = ['CheckboxQuestion','DateSelectorQuestion','DropdownQuestion',
  'FileUploadQuestion','HiddenTextboxQuestion','PackageTableQuestion','RadioQuestion',
  'TextAreaQuestion','TextboxQuestion'];

  var rowPromise: any = [];
  questions?.forEach(async(rowArray: any, rowIndex)=>{

    rowPromise.push(new Promise(async(rowResolve) => {

    var colPromise: any = [];
    //console.log(this.questions);
    rowArray.forEach(async(colArray:any, colIndex)=>{

      colPromise.push(new Promise(async(colResolve) => {
          var elementPromise: any= [];
          colArray.forEach(async(elementArray:any, elementIndex)=>{

              elementPromise.push(new Promise(async(elementResolve: any) => {
              //console.log(elementArray);
              //console.log(elementArray.constructor.name);
              elementResolve(elementArray.constructor.name);
              //this.questions!.splice(rowIndex, 1);
              }));
          });
          //setInterval(()=>{ console.log("Level 3");console.log(elementPromise)},10000);
          Promise.all(elementPromise).then(async(elementConstructorNames) => {
            if(elementConstructorNames.length > 0){
            elementConstructorNames.forEach(async(constructName, constIndex)=>{
              if(inputConstructors.includes(constructName)){
                colResolve(true);

              }
              else if((constIndex+1) >= elementConstructorNames.length){
                colResolve(false);

              }
            });
          }
          else{
            colResolve(false);
          }
                  // console.log('Products are ->', products);
          });

        }));
      });
       //setInterval(()=>{ console.log("Level 2");console.log(colPromise)},10000);
      Promise.all(colPromise).then(async(colResults) => {
        colResults.forEach(async(colResult, colIndex)=>{
          if(colResults.includes(true)){
            rowResolve(true);
          }
          else if((colIndex+1) >= colResults.length){
            rowResolve(false);
          }
        });
      });

    }));

  });
   //setInterval(()=>{ console.log("Level 1");console.log(rowPromise)},10000);
  Promise.all(rowPromise).then(async(rowResults) => {
    var removeOffset = 0;
    rowResults.forEach(async(rowResult, rowIndex)=>{
      if(!rowResult){
        questions!.splice((rowIndex - removeOffset), 1);
        removeOffset++;
      }
      if((rowIndex+1) >= rowResults.length){
       //console.log(this.questions);
       //console.log(rowPromise);
       callback();
      }
    });
  });
}


}







