<div class="row layout-top-spacing">
   <!-- <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-chart-three">
            <div class="widget-heading">
                <div class="">
                    <h5 class="">Sub</h5>
                </div>

                <div class="dropdown ">
                    <a class="dropdown-toggle" href="#" role="button" id="uniqueVisitors" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="uniqueVisitors">
                        <a class="dropdown-item" href="javascript:void(0);">View</a>
                        <a class="dropdown-item" href="javascript:void(0);">Update</a>
                        <a class="dropdown-item" href="javascript:void(0);">Download</a>
                    </div>
                </div>
            </div>

            <div class="widget-content">
              <div id="map" style="height: 360px; width:100%;"></div>
            </div>
        </div>
    </div>
 
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-chart-three">
            <div class="widget-heading">
                <div class="">
                    <h5 class="">Activity</h5>
                </div>

                <div class="dropdown ">
                    <a class="dropdown-toggle" href="#" role="button" id="uniqueVisitors" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="uniqueVisitors">
                        <a class="dropdown-item" href="javascript:void(0);">View</a>
                        <a class="dropdown-item" href="javascript:void(0);">Update</a>
                        <a class="dropdown-item" href="javascript:void(0);">Download</a>
                    </div>
                </div>
            </div>

            <div class="widget-content">
                <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
                [labels]="chartOptions.labels"
                [stroke]="chartOptions.stroke"
                [plotOptions]="chartOptions.plotOptions"
                [markers]="chartOptions.markers"
                [fill]="chartOptions.fill"
                [tooltip]="chartOptions.tooltip"
              
              ></apx-chart>
            </div>
            
        </div>
    </div>
    <br>-->
    <iframe src='https://bi.myininsights.com/bi/site/site1/dashboard-designer/199a988a-5e2e-4b44-8388-8689c37a01f9/Telecommunications/testing?isembed=true' id='dashboard-frame' width='100%' height='800px' allowfullscreen frameborder='0'></iframe>
    <iframe src='https://bi.myininsights.com/bi/site/site1/dashboards/199a988a-5e2e-4b44-8388-8689c37a01f9/Telecommunications/testing?isembed=true' id='dashboard-frame' width='100%' height='600px' allowfullscreen frameborder='0'></iframe>   
</div>