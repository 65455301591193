import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';


// Page Only Imports
import { UserControlService } from '../../../_services/modules/administration/users/user-control.service';
import { GroupControlService } from '../../../_services/modules/administration/groups/group-control.service';



declare var $: any;

declare var DualListbox: any;


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {


  matcher = new MyErrorStateMatcher();
    corpGroups: any;
    userForm: any = {

      };

    userGroups: any;
    userImg: any;
    

  constructor(
    private userControlService: UserControlService,
    private groupControlService: GroupControlService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {


      


  setTimeout(function(){ $('.list-group-item.list-group-item-action').last().removeClass('active'); }, 100);
this.loadUserData();

}


getGlobalPermissions(): any{
  this.groupControlService.getGroupList("").subscribe((corpGroups)=>{
    this.corpGroups = corpGroups;
    console.log(this.corpGroups);
   const myTimeout = setTimeout(()=>{this.selectBoxInit();}, 250);

  });
}

selectBoxInit(): void{


  $('.dropify').dropify({
    messages: { 'default': 'Click to Upload or Drag n Drop', 'remove':  '<i class="flaticon-close-fill"></i>', 'replace': 'Upload or Drag n Drop' }
});
this.resetPreview('dropify', ''+this.userImg+'',
'Image.png');
// Save notification messagae
$('#multiple-messages').on('click', function() {
    $.blockUI({
        message: $('.blockui-growl-message'), 
        fadeIn: 700, 
        fadeOut: 700, 
        timeout: 3000, //unblock after 3 seconds
        showOverlay: false, 
        centerY: false, 
        css: { 
            width: '250px',
            backgroundColor: 'transparent',
            top: '12px',
            left: 'auto',
            right: '15px',
            border: 0,
            opacity: .95,
            zIndex: 1200,
        } 
    }); 
});

  let dualListbox = new DualListbox("#lgroups", {

    addEvent: (value) => {
        // Should use the event listeners
        const myArray = value.split("'");
        const myArray1 = myArray[1].split("'");
        let word = myArray1[0];
        //console.log(word);
        this.userForm.user_groups.value.push(word);
        //console.log(this.groupForm.group_permissions.value)
    },
    removeEvent:  (value) => {
        // Should use the event listeners
        
        //console.log(value);
        const myArray = value.split("'");
        const myArray1 = myArray[1].split("'");
        let word = myArray1[0];
        //console.log(word);
        this.userForm.user_groups.value = this.userForm.user_groups.value.filter(e => e !== word);
        //console.log(this.groupForm.group_permissions.value);
        
    },
    availableTitle: "Groups",
    selectedTitle: "Selected Groups",
    addButtonText: "Add",
    removeButtonText: "Remove",
    addAllButtonText: "Add All",
    removeAllButtonText: "Remove All",

    sortable: true,
    upButtonText: "ᐱ",
    downButtonText: "ᐯ",

   // options: [
    //    { text: "Option 1", value: "OPTION1" },
    //    { text: "Option 2", value: "OPTION2" },
     //   { text: "Selected option", value: "OPTION3", selected: true },
   // ],
});

dualListbox.addEventListener("added", function (event) {
   // console.log(event);
   // console.log(event.addedElement);
});
dualListbox.addEventListener("removed", function (event) {
   // console.log(event);
    //console.log(event.removedElement);
});

}

resetPreview(name, src, fname = '') {
  let input = $('input[name="' + name + '"]');
  let wrapper = input.closest('.dropify-wrapper');
  let preview = wrapper.find('.dropify-preview');
  let filename = wrapper.find('.dropify-filename-inner');
  let render = wrapper.find('.dropify-render').html('');

  input.val('').attr('title', fname);
  wrapper.removeClass('has-error').addClass('has-preview');
  filename.html(fname);

  render.append($('<img />').attr('src', src).css('max-height', input.data('height') || ''));
  preview.fadeIn();
}


onSubmit(): void{

console.log(this.userForm.user_profile_img.value);

var tempData = {
  user_id:this.route.snapshot.paramMap.get('user_id'),
  user_firstname: this.userForm.user_firstname.value,
  user_lastname: this.userForm.user_lastname.value,
  user_username: this.userForm.user_username.value,
  user_email: this.userForm.user_email.value,
  user_password: this.userForm.user_password.value,
  user_mfa: this.userForm.user_mfa.value,
  user_position: this.userForm.user_position.value,
  user_enable: Boolean(this.userForm.user_enable.value),
  user_homepage: this.userForm.user_homepage.value,
  user_profile_img: this.userForm.user_profile_img.value,
  user_groups: this.userForm.user_groups.value
};

this.userControlService.updateUser(tempData).subscribe((Results)=>{



});

}


getFile(e) {

  let extensionAllowed = {"png":true,"jpeg":true};
  //console.log(e.target.files);
  if (e.target.files[0].size / 1024 / 1024 > 20) {
    alert("File size should be less than 20MB")
    return;
  }
  if (extensionAllowed) {
    var nam = e.target.files[0].name.split('.').pop();
    if (!extensionAllowed[nam]) {
      alert("Please upload " + Object.keys(extensionAllowed) + " file.")
      return;
    }
  }
  //console.log(e.target.files[0]);
 this.getBase64(e.target.files[0]);
  
}

  getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
  this.userForm.user_profile_img.value  = reader.result;
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}


loadUserData(): void{

  this.userControlService.getUserDetails('',this.route.snapshot.paramMap.get('user_id')).subscribe((userData)=>{
    this.userForm = {
      user_firstname: new FormControl(''+userData['user_firstname']+'', [Validators.required]),
      user_lastname: new FormControl(''+userData['user_lastname']+'', [Validators.required]),
      user_username: new FormControl(''+userData['user_username']+'', [Validators.required]),
      user_email: new FormControl(''+userData['user_email']+'', [Validators.required]),
      user_password: new FormControl(''),
      user_mfa: new FormControl(''+userData['user_mfa']+'', [Validators.required]),
      user_position: new FormControl(''+userData['user_position']+'', [Validators.required]),
      user_enable: new FormControl(''+userData['user_enable']+'', [Validators.required]),
      user_homepage: new FormControl(''+userData['user_homepage']+'', [Validators.required]),
      user_profile_img: new FormControl(''+userData['user_profile_img']+'', [Validators.required]),
      user_groups: new FormControl(userData['user_groups'], [Validators.required])
     
    };
    this.userImg = userData['user_profile_img'];
    this.userGroups = userData['user_groups'];
    this.getGlobalPermissions();
  });

}

}
