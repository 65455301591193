import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolygonServicesService {

  constructor() { }






  convertGeojsonToPolygonArray(geojsonFile: any): Observable<any>{

    return new Observable(observer => {
      
      var tempPolygonArray: any = [];

      const promises: any = [];
      geojsonFile["features"].forEach((polys: any, index:any) => {
  
  
              //console.log("Poly Found");
              //console.log(polys["properties"]);
              // Find the Name and ID from the geojson File Based on Different Names
              if(polys["properties"]["name"] !== undefined && polys["properties"]["name"] !== null){
                if(polys["id"] !== undefined && polys["id"] !== null){
                //console.log("Poly Added");
                tempPolygonArray.push({poly_name:polys["properties"]["name"],poly_geojson:polys["geometry"],poly_id:(index+1)});
              
                }
                else if(polys["properties"]["OBJECTID"] !== undefined && polys["properties"]["OBJECTID"] !== null){
                 // console.log("Poly Added");
                  tempPolygonArray.push({poly_name:polys["properties"]["name"],poly_geojson:polys["geometry"],poly_id:(index+1)});
                
                }
              }
              if((index+1) >= geojsonFile["features"].length){
                observer.next(tempPolygonArray);
                observer.complete();
              }
            
      });
    });

  }

}
