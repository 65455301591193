<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/forms/listpackagetables']">Package Tables</a></li>
            <li class="breadcrumb-item active" aria-current="page">Package Table Editor</li>
        </ol>
    </nav>
    </div>



    <div class="row layout-spacing">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
            <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                      </svg></a>
                </li>

                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Table"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                      </svg> </a>
                </li>
            </ul> 
        </div>
    </div>

  

<div class="tab-content" id="rounded-circle-pills-icon-tabContent">

    <div class="tab-pane fade active show" id="rounded-circle-pills-icon-profile" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-profile-tab">

            <div class="row layout-top-spacing" id="cancel-row">
    
                <div   class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                    <div class="widget widget-card-standard-dropdown">
                        <div class="widget-content">
                            <div class="w-header">
                                <div class="w-info">
                                    <h4 class="value">Table Settings</h4>
                                </div>
                                
    
                            </div>
        
                            <div class="row layout-spacing ">
                                <div class="col-md-6 mt-2">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Table Name</mat-label>
                                        <input type="text" data-lpignore="true" [formControl]="tableData.package_table_name" required matInput name="package_table_name" id="package_table_name" placeholder="Table Name" [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="tableData.package_table_name.hasError('required')">You must Enter a Name</mat-error>
                                    </mat-form-field>
                                    
                                </div>
                                <div class="col-md-6 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Table Type</mat-label>
                                        <mat-select (selectionChange)="onTypeChange()" [formControl]="tableData.package_table_type" [errorStateMatcher]="matcher">
                                          <mat-option value="standard">Standard</mat-option>
                                          <mat-option value="autoprovcap">Auto Provisoning Capable</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="tableData.package_table_name.hasError('required')">You must make a selection</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
        
        
                        </div>
                    </div>
                </div>
        
        
            </div>
            
    
    
    
    
    
    

            <div class="row layout-spacing">

                    <!-- Content -->
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 layout-top-spacing " style="position: -webkit-sticky; position: sticky;">


                     
                        <div class="card widget-flat">
                            <div class="card-body">
                                <div class="float-end">
                                    <i class="mdi mdi-pulse widget-icon"></i>
                                </div>
                                <h5 class="fw-normal mt-0 mb-1" title="Growth"></h5>
                                <ul class="nav nav-tabs  mt-3 mb-3" id="lineTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="underline-home-tab" data-bs-toggle="tab" href="#underline-home" role="tab" aria-controls="underline-home" aria-selected="true">Column</a>
                                    </li>

                                    
                                </ul>
                                
                                <div class="tab-content" id="lineTabContent-3">

                                    <div class="tab-pane fade active show" id="underline-home" role="tabpanel" aria-labelledby="underline-home-tab">
                                        <form class="navbar-form navbar-left pb-3">

                                            <ul id="rowToolbox" class="list-group list-group-icons-meta">
                                                <ng-container *ngFor="let col of TableCols">
                                                <li *ngIf="col.showcol == 'yes'" type="col" class="list-group-item list-group-item-action">
                                                    <div class="media">
                                           
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-md-10">
                                                                    <input type="text" class="form-control" (change)="updateTableValue($event,col.id)" name="col_name_1" id="form_name" placeholder="Column Name" value="{{col.name}}"> 
                                                                </div>

                                                                <div class="col-md-2 pt-3 pb-3">
                                                                    <a *ngIf="col.removeable == 'yes'" href="javascript:void(0);" (click)="delColumn(col.id)">Del</a>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                        
                                                  
                                                </li>
                                            </ng-container>

                                               
                                            </ul>
                                            <br>
                                            <button id="add-work-platforms" (click)="addcol('')" class="btn btn-rounded btn-primary">+ Column</button>
                                        
                                            </form>
                                    </div>

                                    <div class="tab-pane fade" id="underline-profile" role="tabpanel" style="overflow-y:auto; height:600px" aria-labelledby="underline-profile-tab">
                                        <div style="padding: 0px 30px 30px 30px; background-color: #fff;">
                                            <ul id="testNewDrag" class="list-group list-group-icons-meta">

                                                <li type="packagetable" class="list-group-item list-group-item-action">
                                                    <div class="media">
                                                        <div class="d-flex mr-3">
                                                            <mat-icon> table_chart</mat-icon>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="tx-inverse"> Package Table</h6>
                                                        </div>
                                                    </div>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>

                                    <div  class="tab-pane fade" id="underline-contact" role="tabpanel" aria-labelledby="underline-contact-tab">
                                            <!-- Load Textbox Editor-->
                                          

                                    </div>

                                </div>
                            </div>
                        </div>



                </div>



                    <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12 layout-top-spacing " style="overflow-y: auto; overflow-x: hidden; height:70vh;">

                        <div class="skills layout-spacing ">
                            <div class="widget-content widget-content-area ">
                                
                            
                                <div style="background-color: #fff; border-radius: 5px; padding: 20px; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); ">
                                    <div class="table-responsive">
                                <form [formGroup]="form">
                                        <table class="table">
                                            <thead>
                                                <tr *ngIf="showTableHeader">
                                                    <ng-container *ngFor="let col of TableCols">
                                                    <th scope="col" *ngIf="col.showtb == 'yes'" >{{col.name}}</th>
                                                     </ng-container>
                                                    <th scope="col" ></th>
                                                </tr>
                                                <tr aria-hidden="true" class="mt-3 d-block table-row-hidden"></tr>
                                            </thead>
                                            <tbody>
                                            <ng-container formArrayName="tableRowValues">
                                                <ng-container *ngFor="let tableRowValuesGroup of tableRowValues?.controls; let i = index" [formGroupName]="i">
                                                    <!--<h3>Row - {{i + 1}}</h3>-->
                                                    <tr >
                                                        <ng-container *ngFor="let item of getObjectArray()">
                                                        <ng-container *ngIf="getTableShow(item) | async as user">
                                                        <td *ngIf="item !== 'rowId' && user == 'yes'"><input type="text" class="form-control" formControlName="{{item}}" placeholder="" /></td>
                                                        </ng-container>
                                                        </ng-container>
                                                        <td><a href="javascript:void(0);" (click)="delRow(tableRowValuesGroup.value['rowId'])" >Del</a></td>
                                                    </tr>
                                                    </ng-container>
                                                    </ng-container>
                                                </tbody>
                                        </table>
                                </form>
                                        
                                            
                              
                                
                                    <button id="" (click)="addRow(null)" class="btn btn-rounded btn-primary">+ Row</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
<div class="tab-pane fade" id="rounded-circle-pills-icon-home" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-home-tab">


   
</div>
<div class="tab-pane fade" id="rounded-circle-pills-icon-contact" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-contact-tab">


      
</div>


</div>