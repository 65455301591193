import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class DateRangeEditorService {

  constructor() { }

  ////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



filter_Editor(info: any): Observable<any>{

  return new Observable(observer => {
    console.log(info);
  Swal.fire({
    title: 'Date Range Filter',
    html: `

    <div class="row g-3 pb-2">
    <div class="col-md-5">
        <label for="filterStartDate" class="form-label">Start Date</label>
        <select id="filterStartDate" class="form-select">
        <option value="var-alltime">All Time</option>
        <option value="var-today">Today</option>
        <option selected value="var-7-days">Previous 7 Days</option>
        <option value="var-14-days">Previous 14 Days</option>
        <option value="var-30-days">Previous 30 Days</option>
        <option value="var-60-days">Previous 60 Days</option>
        <option value="var-90-days">Previous 90 Days</option>
        <option value="var-365-days">Previous Year</option>
        <option value="custom">Custom</option>

    </select>

    <label for="customfilterStartDate" class="form-label">Start Date</label>
    <input type="text" class="form-control" id="customfilterStartDate">

    </div>

    <div class="col-md-2">
        <label for="fieldcondition" class="form-label"> To </label>
    </div>

    <div class="col-md-5">
    <label for="filterEndDate" class="form-label">End Date</label>
    <select id="filterEndDate" class="form-select">
    <option selected value="var-today">Today</option>
    <option value="var-Yesterday">Yesterday</option>
    <option value="custom">Custom</option>

</select>

<label for="customfilterEndDate" class="form-label">End Date</label>
<input type="text" class="form-control" id="customfilterEndDate">

</div>

</div>

    `,
    confirmButtonText: 'Add Filter',
    focusConfirm: false,
    width: 650,
    preConfirm: () => {

    const temp_input_start_date_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#filterStartDate')).value;
    const temp_input_end_date_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#filterEndDate')).value;
    const temp_input_custom_start_date_val = (<HTMLInputElement>Swal.getPopup()!.querySelector('#customfilterStartDate')).value;
    const temp_input_custom_end_date_val = (<HTMLInputElement>Swal.getPopup()!.querySelector('#customfilterEndDate')).value;
     // element.hasAttribute('foo');
     // element.getAttribute('foo');
     // element.setAttribute('foo', value);
    //  element.removeAttribute('foo');
     // if (!login || !password) {
    //    Swal.showValidationMessage(`Please enter login and password`)
     // }
     return ({input_start_date_val:temp_input_start_date_val, input_end_date_val:temp_input_end_date_val,input_custom_start_date_val:temp_input_custom_start_date_val, input_custom_end_date_val:temp_input_custom_end_date_val});
   }
  }).then((result: any) => {
    console.log(info);

    let tempValue = Object.assign({},info);

    tempValue['filter_type'] = 'daterange';

     if (result.value['input_start_date_val'] !== 'custom'){ tempValue['filter_value_start_date'] = result.value['input_start_date_val']; }
     else{ tempValue['filter_value_start_date'] = result.value['input_custom_start_date_val'];}

     if (result.value['input_end_date_val'] !== 'custom'){ tempValue['filter_value_end_date'] = result.value['input_end_date_val']; }
     else{ tempValue['filter_value_end_date'] = result.value['input_custom_end_date_val'];}

    if(tempValue['filter_parent'] == undefined){info['filter_parent'] = 'form_data.'}
    tempValue['filter_id'] =  tempValue['filter_parent']+""+tempValue['id'];
    console.log("Filter Value");
    console.log(tempValue);
   observer.next(tempValue);

    //this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
  });
});
}
}
