import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-sub-with-so-workflow',
  templateUrl: './new-sub-with-so-workflow.component.html',
  styleUrls: ['./new-sub-with-so-workflow.component.css']
})
export class NewSubWithSoWorkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
