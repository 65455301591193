<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >Administration</a></li>
            <li class="breadcrumb-item "><a href="javascript:void(0);" >Users</a></li>
            <li class="breadcrumb-item active" aria-current="page">New User</li>
        </ol>
    </nav>
    </div>



<div class="account-settings-container layout-top-spacing">

    <div class="account-content">
        <div class="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset="-100">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                    <form id="general-info" class="section general-info">
                        <div class="info">
                            <h6 class="">General Information</h6>
                            <div class="row">
                                <div class="col-lg-11 mx-auto">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-12 col-md-4">
                                            <div class="upload mt-4 pr-md-4">
                                                <input type="file" id="input-file-max-fs" (change)="getFile($event)" class="dropify" data-default-file="assets/assets/img/200x200.jpg" data-max-file-size="2M" />
                                                <p class="mt-2"><i class="flaticon-cloud-upload mr-1"></i> Upload Picture</p>
                                            </div>
                                        </div>
                                        <div class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                            <div class="form">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                       
    
                                                            <mat-form-field class="example-full-width" appearance="outline">
                                                                <mat-label>First Name</mat-label>
                                                                <input type="text" data-lpignore="true" [formControl]="userForm.user_firstname" required matInput  name="user_firstname" id="user_firstname" placeholder="First Name" [errorStateMatcher]="matcher">
                                                                <mat-error *ngIf="userForm.user_firstname.hasError('required')">
                                                                First Name <strong>Required</strong>
                                                                </mat-error>
                                                            </mat-form-field>
                                                          
                                                       
                                                  </div>

                                                    <div class="col-sm-6">
                                                            <mat-form-field class="example-full-width" appearance="outline">
                                                                <mat-label>Last Name</mat-label>
                                                                <input type="text" data-lpignore="true" [formControl]="userForm.user_lastname" required matInput  name="user_lastname" id="user_lastname" placeholder="Last Name" [errorStateMatcher]="matcher">
                                                                <mat-error *ngIf="userForm.user_firstname.hasError('required')">
                                                                Last Name <strong>Required</strong>
                                                                </mat-error>
                                                            </mat-form-field>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                <div class="col-sm-4">

                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                            <mat-label>Email</mat-label>
                                                            <input type="text" data-lpignore="true" [formControl]="userForm.user_email" required matInput  name="user_email" id="user_email" placeholder="Email" [errorStateMatcher]="matcher">
                                                            <mat-error *ngIf="userForm.user_email.hasError('required')">
                                                            Email <strong>Required</strong>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                            <mat-label>Home Path</mat-label>
                                                            <input type="text" data-lpignore="true" [formControl]="userForm.user_homepage" required matInput  name="user_homepage" id="user_homepage" placeholder="Home Path" [errorStateMatcher]="matcher">
                                                            <mat-error *ngIf="userForm.user_email.hasError('required')">
                                                            Home Path <strong>Required</strong>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>MFA</mat-label>
                                                            <mat-select [formControl]="userForm.user_mfa" [errorStateMatcher]="matcher">
                                                              <mat-option value="none">Disabled</mat-option>
                                                              <mat-option value="otp">OTP</mat-option>
                                                              <mat-option value="duo">DUO</mat-option>
                                                            </mat-select>
                                                            <mat-error *ngIf="userForm.user_mfa.hasError('required')">You must make a selection</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group mt-3">

                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Position</mat-label>
                                                        <input type="text" data-lpignore="true" [formControl]="userForm.user_position" required matInput  name="user_position" id="user_position" placeholder="Position" [errorStateMatcher]="matcher">
                                                        <mat-error *ngIf="userForm.user_position.hasError('required')">
                                                        Position <strong>Required</strong>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
                </div>
    
                <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                    <form id="contact" class="section contact">
                        <div class="info">
                            <h5 class="">Login</h5>
                            <div class="row">
                                <div class="col-md-11 mx-auto">
                                    <div class="row">
                                        <div class="col-md-11">

                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Username</mat-label>
                                                <input type="text" data-lpignore="true" [formControl]="userForm.user_username" required matInput  name="user_username" id="user_username" placeholder="Username" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="userForm.user_username.hasError('required')">
                                                Username <strong>Required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-11">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Password</mat-label>
                                                <input type="text" data-lpignore="true" [formControl]="userForm.user_password" required matInput  name="user_password" id="user_password" placeholder="Password" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="userForm.user_password.hasError('required')">
                                                Password <strong>Required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
    
                
    
                <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                    <form id="about" class="section about">
                        <div class="info">
                            <h5 class="">Groups</h5>
                            <div class="row">
                                <div class="col-md-11 mx-auto">
                                    <div class="form-group">
                                    <label for="lgroups">All Groups</label>
                                            <select class="custom-select" [formControl]="userForm.user_groups"  id="lgroups" multiple>
    
                                            
                                                <option *ngFor="let row of corpGroups" [ngValue]="row._id" >{{row.group_name}}</option>
                                                
                                            </select>

                                     
                                    </div>
                                </div>
                                <div class="col-md-11 mx-auto">
                                    <div class="form-group">
    
                                    <a onclick="" (click)="onSubmit()" class="btn btn-primary mt-4">Create</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
    
            </div>
        </div>
    </div>
    
    
    </div>