<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">Report Builder</li>
        </ol>
    </nav>
    </div>



    <div class="row layout-spacing">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
            <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                      </svg></a>
                </li>

                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Table"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                      </svg> </a>
                </li>
            </ul>
        </div>
    </div>



<div class="tab-content" id="rounded-circle-pills-icon-tabContent">

    <div class="tab-pane fade active show" id="rounded-circle-pills-icon-profile" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-profile-tab">

            <div class="row layout-top-spacing" id="cancel-row">

                <div   class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                    <div class="widget widget-card-standard-dropdown">
                        <div class="widget-content">
                            <div class="w-header">
                                <div class="w-info">
                                    <h4 class="value">Report Settings</h4>
                                </div>


                            </div>

                            <div class="row layout-spacing ">
                                <div class="col-md-6 mt-2">

                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Report Name</mat-label>
                                        <input type="text" data-lpignore="true" [formControl]="reportData.report_name" required matInput name="package_table_name" id="package_table_name" placeholder="Table Name" [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="reportData.report_name.hasError('required')">You must Enter a Name</mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Report Type</mat-label>
                                        <mat-select [formControl]="reportData.report_type" [errorStateMatcher]="matcher">
                                          <mat-option value="Table">Table</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="reportData.report_type.hasError('required')">You must make a selection</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>








            <div class="row layout-spacing">

                    <!-- Content -->
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 layout-top-spacing " style="position: -webkit-sticky; position: sticky;">










                        <div class="card widget-flat">
                            <div class="card-body">
                                <div class="float-end">
                                    <i class="mdi mdi-pulse widget-icon"></i>
                                </div>
                                <h5 class="fw-normal mt-0 mb-1" title="Growth"></h5>

                                <ul class="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">

                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Columns</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Conditonal</a>
                                    </li>

                                </ul>

                                <div class="tab-content" id="simpletabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                        <input id="autoComplete" class="form-control">
                                        <br>
                                        <ul id="testNewDrag" class="list-group list-group-icons-meta">
                                            <li *ngFor="let row of selectColumns" [attr.data-id]="row.id" type="textbox" class="list-group-item list-group-item-action">

                                                <div class="media">

                                                    <div class="media-body">
                                                        <h6 class="tx-inverse"> {{row.name}}</h6><a href="">Edit</a> | <a href="javascript:void(0);" (click)="deleteColumn(row.id)">Del</a>

                                                    </div>
                                                </div>
                                            </li>

                                        </ul>


                                    </div>

                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <input id="autoCompleteFilter" class="form-control">
                                        <br>
                                        <ul id="FilterDrag" class="list-group list-group-icons-meta">
                                            <li *ngFor="let row of selectedFilters;let filterIndex = index" [attr.data-id]="'filter-'+row.id" type="textbox" class="list-group-item list-group-item-action">

                                                <div class="media">

                                                    <div class="media-body">
                                                        <h6 class="tx-inverse">{{row.name}} {{row.conditional}} {{row.conditionalvalue}}</h6> <a href="">Edit</a> | <a href="javascript:void(0);" (click)="deleteFilter(filterIndex)">Del</a>

                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>





































                    </div>



                    <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12 layout-top-spacing " style="overflow-y: auto; overflow-x: hidden; height:70vh;">

                        <div class="skills layout-spacing ">
                            <div class="widget widget-card-standard-dropdown">
                                <div class="widget-content">
                                    <div class="w-header">
                                        <div class="w-info">
                                            <h6 class="value">Report</h6>
                                        </div>


                                    </div>
                                    <div class="w-content">

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th *ngFor="let header of selectColumns" scope="col">{{header.name}}</th>
                                                        </tr>
                                                        <tr aria-hidden="true" class="mt-3 d-block table-row-hidden"></tr>
                                                    </thead>
                                                    <tbody>
                                                            <ng-container *ngIf="showTableData">
                                                            <tr *ngFor="let row of dataRows">
                                                                <ng-container *ngIf="getSortedDataRow(row) | async as user">
                                                                <td *ngFor="let data of user">{{data}}</td>
                                                                </ng-container>
                                                            </tr>
                                                             </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
<div class="tab-pane fade" id="rounded-circle-pills-icon-home" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-home-tab">



</div>
<div class="tab-pane fade" id="rounded-circle-pills-icon-contact" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-contact-tab">



</div>


</div>
