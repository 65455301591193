export class QuestionBase<T> {
    value: any|undefined;
    key: string;
    label: string;
    keyed_field: string;
    disabled: boolean;
    required: boolean;
    required_type: string;
    package_table_id:string;
    order: number;
    controlType: string;
    type: string;
    html: string;
    settings:any;
    public:boolean;
    options: {name: string, value: string}[];

    constructor(options: {
        value?: any;
        key?: string;
        keyed_field?: string;
        label?: string;
        disabled?: boolean;
        required?: boolean;
        required_type?: string;
        package_table_id?: string;
        order?: number;
        controlType?: string;
        type?: string;
        html?: string;
        settings?:any;
        public?:boolean;
        options?: {name: string, value: string}[];
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.keyed_field = options.keyed_field || '';
      this.label = options.label || '';
      this.disabled = !!options.disabled || false;
      this.required = !!options.required;
      this.required_type = options.required_type || '';
      this.package_table_id = options.package_table_id || '';
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.html = options.html || '';
      this.settings = options.settings || undefined;
      this.public =  options.public || false;
      this.options = options.options || [];
    }
  }
