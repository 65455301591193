// @ts-ignore: Object is possibly 'null'.
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import * as L from 'leaflet';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//Address Control
import { AddresslistControlService } from '../../../../_services/modules/geomap/addresslists/addresslist-control.service';
// Form Control
import { FormControlService } from '../../../../_services/modules/geomap/forms/form-control.service';
// Map Managmement
import { MapsMarkerService } from '../../../../_services/maps-marker.service';
import { MapsService } from '../../../../_services/maps.service';

// Globals
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';


//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-new-map',
  templateUrl: './new-map.component.html',
  styleUrls: ['./new-map.component.css']
})
export class NewMapComponent implements AfterViewInit,OnInit {
 // Global Settings
 private globalSetting: [] = globalSettings;


  popup: any;
  myMap: any;
  currentClickMarker: any = null;

  form: any = {
    map_polygon_hover_text:"Get Started by Searching your Address Above!",
    map_signup_marker_show_polygon_name:"true",
    map_signup_polygon_text:"Service Available",
    map_address_inside_poly_marker_color:"blue",
    map_address_outside_poly_marker_color:"blue",
    map_address_not_found_link:"",
    map_address_not_found_link_text:"View all RTC Service Areas!",
    map_address_not_found_link_type:"form",
    map_address_not_found_text:"Address Not Found!",
    map_address_outside_marker_text:"Your address is outside of our open grant area(s) that we are requesting community support from currently. If your area becomes and open grant area, we will reach out to you at that time for supporting articles. See if we service your community already.",
    map_address_outside_marker_link:"",
    map_address_outside_marker_link_text:"RTC Service Areas",
    map_address_outside_marker_title:"Outside Of Open Grant Area(s)",
    map_address_outside_marker_link_type:"form",
    map_address_external_search_toggle:"true",
  };
  addressListArray: any = [];
  formsListArray: any = [];
  constructor(
    private mapsservice: MapsService,
    private router: Router,
    private http: HttpClient,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private addresslistcontrolservice: AddresslistControlService,
    private formControlService: FormControlService,
    private allpageGenerals: AllPageGeneralsService
    ) { }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Address List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service


  // Change Top Nav Title on Page Change
   this.allpageGenerals.setTopNavTitle('New Map');



this.myMap = this.mapsservice.InitMap(this.myMap,"map",38.676431,-86.913915,14);

        // Call method to Load Address List
        this.loadAddressList();
        //Call to Load Forms
        this.loadFormsList();
      }


    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Address List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


  ngAfterViewInit(): void {
    this.initMap();
  }



    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Address List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    loadAddressList(){

      this.addresslistcontrolservice.getAddressLists('').subscribe((results)=>{
        this.addressListArray = results;
      });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Forms List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    loadFormsList(){
      this.formControlService.getFormList('',false).subscribe((results)=>{

        //console.log(results);
        this.formsListArray = results;

      });

    }



    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //  Load the Click handler for the map Marker
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////



  private initMap(): void {




    this.myMap.on("click", (e: any) => {
      console.log(e.latlng); // get the coordinates
     // L.popup().setLatLng(e.latlng).setContent('Default Center Point: <br> ' + e.latlng.toString()).openOn(this.myMap);
     if (this.currentClickMarker !== null) {
      this.myMap.removeLayer(this.currentClickMarker);
     }
      this.currentClickMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(this.myMap); // add the marker onclick

        this.form.map_long = e.latlng.lng.toString()

        this.form.map_lat = e.latlng.lat.toString();

        this.form.map_zoom = this.myMap.getZoom().toString();

     // window.document.getElementById('inputLat1').value = e.latlng.lat.toString();

    });
    this.myMap.on("zoomend", (e: any) => {
     // console.log(this.myMap.getZoom().toString()); // get the coordinates

        this.form.map_zoom = this.myMap.getZoom().toString();


    });


  }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //  Submit the Map Data for Entry
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

submitForm(): void{
  (<HTMLButtonElement>document.getElementById('submitMapFormButton')!).click();
}

  onSubmit(): void {
    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}
    this.form.map_lat = parseFloat(this.form.map_lat);
    this.form.map_long = parseFloat(this.form.map_long);
    this.form.map_zoom = parseFloat(this.form.map_zoom);
    if(this.form.map_enable == "true"){this.form.map_enable = true;}else{this.form.map_enable=false;}
    if(this.form.map_address_external_search_toggle == "true"){this.form.map_address_external_search_toggle = true;}else{this.form.map_address_external_search_toggle=false;}
    if(this.form.map_signup_marker_show_polygon_name == "true"){this.form.map_signup_marker_show_polygon_name = true;}else{this.form.map_signup_marker_show_polygon_name=false;}
    const body=JSON.stringify(this.form);
      this.http.post(this.globalSetting['api_url_console']+'/geomap?api=createmap',body,{'headers':headers}).subscribe(resp => {
        if(resp['error'] == false){
          console.log(resp);
          Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
            this.router.navigate(['/geomap/maps/listmaps'])
          });
        }
        else
        {
          console.log(resp);
          Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{

          });
        }
      //this.rerender();
  });


  }

  reloadPage(): void {
   // window.location.reload();
  }



}
