import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxAbstractControlAsModule } from 'ngx-abstract-control-as';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogonComponent } from './modules/mod_auth/login/login.component';

import { DataTablesModule } from "angular-datatables";

import { authInterceptorProviders } from './_helpers/auth.interceptor';

import { ListmapsComponent } from './modules/mod_geomap/maps/list-maps/listmaps.component';
import { ListpolysComponent } from './modules/mod_geomap/maps/list-polygons/listpolys.component';
import { NewPolyComponent } from './modules/mod_geomap/maps/new-poly/new-poly.component';
import { NewMapComponent } from './modules/mod_geomap/maps/new-map/new-map.component';
import { EditpolyComponent } from './modules/mod_geomap/maps/edit-poly/editpoly.component';
import { EditmapComponent } from './modules/mod_geomap/maps/edit-map/editmap.component';

import { ListUsersComponent } from './modules/mod_administration/list-users/list-users.component';
import { ListGroupsComponent } from './modules/mod_administration/list-groups/list-groups.component';
import { NewUserComponent } from './modules/mod_administration/new-user/new-user.component';
import { NewGroupComponent } from './modules/mod_administration/new-group/new-group.component';
import { EditUserComponent } from './modules/mod_administration/edit-user/edit-user.component';
import { EditGroupComponent } from './modules/mod_administration/edit-group/edit-group.component';

//Forms
import { ListformsComponent } from './modules/mod_geomap/forms/forms/list-forms/listforms.component';
import { NewformComponent } from './modules/mod_geomap/forms/forms/new-form/newform.component';
import { FormbuilderComponent } from './modules/mod_geomap/forms/forms/form-builder/formbuilder.component';
import { EditformComponent } from './modules/mod_geomap/forms/forms/edit-form/editform.component';
import { ListFormEntriesComponent } from './modules/mod_geomap/forms/forms/list-form-entries/list-form-entries.component';

import { FormEntriesViewerComponent } from './modules/mod_geomap/forms/forms/form-entries-viewer/form-entries-viewer.component';
import { TextboxEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/textbox-editor/textbox-editor.component';

//Address Lists
import { AddresslistsComponent } from './modules/mod_geomap/address_lists/addresslists/addresslists.component';
import { NewaddresslistComponent } from './modules/mod_geomap/address_lists/newaddresslist/newaddresslist.component';

//Dashboard
import { DashboardGeofiComponent } from './modules/mod_dashboard/geofi/dashboard_geofi/dashboard.component';

//GeoFi
import { ListWorkflowsComponent } from './modules/mod_geomap/workflows/list-workflows/list-workflows.component';
import { FormElementsComponent } from './modules/mod_geomap/forms/forms/form-builder/form-elements/form-elements.component';
import { FormEntryDetailsComponent } from './modules/mod_geomap/forms/forms/form-entry-details/form-entry-details.component';
import { DynamicFormQuestionComponent } from './modules/mod_geomap/forms/forms/form-entry-details/forms/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './modules/mod_geomap/forms/forms/form-entry-details/forms/dynamic-form/dynamic-form.component';

//Client Settings
import { ClientSettingsComponent } from './modules/mod_administration/client-settings/client-settings.component';

//Package Tables
import { ListPackageTablesComponent } from './modules/mod_geomap/forms/package-table/list-package-tables/list-package-tables.component';
import { NewpackagetableComponent } from './modules/mod_geomap/forms/package-table/newpackagetable/newpackagetable.component';
import { EditPackageTableComponent } from './modules/mod_geomap/forms/package-table/edit-package-table/edit-package-table.component';

//Reports
import { ReportBuilderComponent } from './modules/mod_geomap/reports/report-builder/report-builder.component';
import { ListReportsComponent } from './modules/mod_geomap/reports/list-reports/list-reports.component';
import { ReportViewerComponent } from './modules/mod_geomap/reports/report-viewer/report-viewer.component';

//Sch Reports
import { ListScheduledReportsComponent } from './modules/mod_geomap/reports/list-scheduled-reports/list-scheduled-reports.component';
import { CreateScheduledReportComponent } from './modules/mod_geomap/reports/create-scheduled-report/create-scheduled-report.component';

//Email Templets
import { ListEmailTempletsComponent } from './modules/mod_geomap/forms/email-templets/list-email-templets/list-email-templets.component';
import { CreateEmailTempletComponent } from './modules/mod_geomap/forms/email-templets/create-email-templet/create-email-templet.component';

// Form Field Triggers
import { CreateFieldTriggerComponent } from './modules/mod_geomap/forms/forms/field-triggers/create-field-trigger/create-field-trigger.component';
import { ListFieldTriggerComponent } from './modules/mod_geomap/forms/forms/field-triggers/list-field-trigger/list-field-trigger.component';
import { DateEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/date-editor/date-editor.component';
import { TextareaEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/textarea-editor/textarea-editor.component';
import { SelectEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/select-editor/select-editor.component';
import { FileInputEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/file-input-editor/file-input-editor.component';
import { CheckboxEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/checkbox-editor/checkbox-editor.component';
import { HtmlareaEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/htmlarea-editor/htmlarea-editor.component';
import { PackagetableEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/packagetable-editor/packagetable-editor.component';
import { RadioEditorComponent } from './modules/mod_geomap/forms/forms/form-builder/form-element-editors/radio-editor/radio-editor.component';
import { AddressEditorComponent } from './modules/mod_geomap/address_lists/address-editor/address-editor.component';
import { ListTicketsComponent } from './modules/mod_support/support_tickets/list-tickets/list-tickets.component';
import { NewSubscriberWorkflowComponent } from './modules/mod_geomap/workflows/third-party/nisc/new-subscriber-workflow/new-subscriber-workflow.component';
import { NewSubWithSoWorkflowComponent } from './modules/mod_geomap/workflows/third-party/nisc/new-sub-with-so-workflow/new-sub-with-so-workflow.component';
import { NewListEntriesComponent } from './modules/mod_geomap/forms/forms/new-list-entries/new-list-entries.component';
import { FileDownloadComponent } from './modules/mod_geomap/forms/forms/file-download/file-download.component';
// New Form Entry
import { EntryFormComponent } from './modules/mod_geomap/forms/forms/new-form-entry/dynamic-form/dynamic-form.component';
import { FormEntryQuestionComponent } from './modules/mod_geomap/forms/forms/new-form-entry/dynamic-form-question/dynamic-form-question.component';
import { NewFormEntryComponent } from './modules/mod_geomap/forms/forms/new-form-entry/new-form-entry.component';

// Support Tickets
import { NewTicketComponent } from './modules/mod_support/support_tickets/new-ticket/new-ticket.component';
import { NewFormEntryAddressSearchComponent } from './modules/mod_geomap/forms/forms/new-form-entry/new-form-entry-address-search/new-form-entry-address-search.component';
import { ViewMapComponent } from './modules/mod_geomap/maps/view-map/view-map.component';


@NgModule({
  declarations: [
    AppComponent,
    LogonComponent,
    ListmapsComponent,
    ListpolysComponent,
    NewPolyComponent,
    NewMapComponent,
    EditpolyComponent,
    EditmapComponent,
    ListUsersComponent,
    ListGroupsComponent,
    NewUserComponent,
    NewGroupComponent,
    EditUserComponent,
    EditGroupComponent,
    ListformsComponent,
    NewformComponent,
    FormbuilderComponent,
    EditformComponent,
    AddresslistsComponent,
    NewaddresslistComponent,
    DashboardGeofiComponent,
    ListWorkflowsComponent,
    ListFormEntriesComponent,
    FormEntriesViewerComponent,
    FormElementsComponent,
    FormEntryDetailsComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    ClientSettingsComponent,
    TextboxEditorComponent,
    ListPackageTablesComponent,
    NewpackagetableComponent,
    ReportBuilderComponent,
    ListReportsComponent,
    ReportViewerComponent,
    ListEmailTempletsComponent,
    CreateEmailTempletComponent,
    ListScheduledReportsComponent,
    CreateScheduledReportComponent,
    CreateFieldTriggerComponent,
    ListFieldTriggerComponent,
    EditPackageTableComponent,
    DateEditorComponent,
    TextareaEditorComponent,
    SelectEditorComponent,
    FileInputEditorComponent,
    CheckboxEditorComponent,
    HtmlareaEditorComponent,
    PackagetableEditorComponent,
    RadioEditorComponent,
    AddressEditorComponent,
    ListTicketsComponent,
    NewSubscriberWorkflowComponent,
    NewSubWithSoWorkflowComponent,
    NewListEntriesComponent,
    FileDownloadComponent,
    ListFormEntriesComponent,
    NewFormEntryComponent,
    FormEntryQuestionComponent,
    EntryFormComponent,
    NewTicketComponent,
    NewFormEntryAddressSearchComponent,
    ViewMapComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    NgxAbstractControlAsModule,
    NgApexchartsModule,
    MatSliderModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule

  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
