import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
//import { AccountService } from '@app/_services';
//import Global Setting Varitables
import globalSettings from '../_settings/Globle_Settings.json';
import { control } from 'leaflet';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
   // Global Settings
 private globalSetting: [] = globalSettings;

    constructor(
        private router: Router,
        private http: HttpClient,
        private tokenStorageService: TokenStorageService
        //private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any>{

      return new Observable(observer => {
       var isLoggedIn = !!this.tokenStorageService.getToken();
       if(isLoggedIn){
        //const user = this.accountService.userValue;
        this.http.get(''+this.globalSetting['api_url_console']+'/auth?api=useractionpermission&permission_id='+route.data['permissionToken'],{}).pipe(
          timeout(5000),
          catchError(e => {
            // do something on a timeout

            return of(false);
          })
        ).subscribe((resp: any) => {
          console.log(resp);
          if (resp['success']) {
            // authorised so return true
            observer.next(true);
            }else{

            // not logged in so redirect to login page with the return url
            this.tokenStorageService.signOut();
            this.router.navigate(['/logon'], { queryParams: { returnUrl: state.url }});
            observer.next(false);
            }
        });
      }
      else{
        this.router.navigate(['/logon'], { queryParams: { returnUrl: state.url }});
      }
    });
  }
}
