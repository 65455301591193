
                <div class="flex-row layout-top-spacing">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                          <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
                          <li class="breadcrumb-item" [routerLink]="['/geomap/maps/listforms']" aria-current="page">Forms</li>
                          <li class="breadcrumb-item" [routerLink]="['/geomap/form/listentries',{'form_id':this.formSettings['form_id']}]" aria-current="page">Form Entries</li>
                          <li class="breadcrumb-item active" aria-current="page">New Entry</li>
                      </ol>
                  </nav>
                  </div>

                  <div class="row layout-spacing">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
                        <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
                            <li class="nav-item ml-2 mr-2">
                                <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                  </svg></a>
                            </li>

                            <li class="nav-item ml-2 mr-2">
                                <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Form Entry"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/> </svg> </a>
                            </li>
                        </ul>
                    </div>
                </div>


      <div class="row layout-top-spacing" id="cancel-row">

        <div  style="display:block;" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h6 class="value">*New Entry ({{this.formSettings['form_name']}})</h6>
                        </div>

                        <div class="task-action">

                            <div class="dropdown pt-2" style="float:right;">
                                <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                </a>

                                <div class="dropdown-menu left" aria-labelledby="expenses" style="will-change: transform;">

                                </div>
                            </div>
                          </div>

                        </div>
                    </div>
                    <div class="w-content">

                        <div  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                          <ng-container *ngIf="showFormPage">
                            <form *ngIf="showform" (ngSubmit)="onSubmit()" [formGroup]="form">

                              <div class="row layout-spacing" *ngFor="let questionrow of questions" >
                                <div *ngFor="let questioncol of questionrow" class="col-lg">
                                  <div *ngFor="let question of questioncol" >
                                      <app-form-entry-question [question]="question" [form]="form" [fileUploads]="fileUploads" [formSettings]="formSettings" [(conditonalTargetValues)]="conditonalTargetValues" (validateConditionals)="validateConditionals()" (onSuggest)="packageTablesArray($event[0])"></app-form-entry-question>
                                  </div>
                                </div>
                             </div>
                            </form>

                            <div *ngIf="payLoad" class="form-row">
                             <!-- <strong>Saved the following values</strong><br>{{payLoad}} -->
                            </div>
                          </ng-container>

                        </div>

                    </div>
                </div>
            </div>
        </div>

























