import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest  } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
//
import { PolyControlService } from './poly-control.service';
//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';
import { AlertControlService } from '../../../../_services/components/alerts/alert-control.service';


@Injectable({
  providedIn: 'root'
})
export class NewPolygonControlService {

 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(private http: HttpClient,private polyControlService:PolyControlService,private alertControl: AlertControlService) { }



///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

bulkPolygonImport(polygonTable: any): Observable<any>{

  return new Observable(observer => {
  // alert(this.form.poly_geojson);
   //const { username, password } = this.form;
   var count = 0;
   const promises: any = [];
   this.alertControl.loadingInfo("Importing Polygons",false).subscribe((responce: any) => {});
     promises.push(
       new Promise(async (resolve: any) => {
        this.polyControlService.bulkPolygonImport(polygonTable).subscribe((RequestResponce: any)=>{
           resolve(RequestResponce);
         });
     }));

Promise.all(promises).then((reponceValue: any) => {
 //callback(products);
// console.log('Products are ->', products);
if(!reponceValue[0]['error']){
this.alertControl.successInfo("Import Complete",true).subscribe((responce: any) => {
  observer.next(reponceValue[0]);
  observer.complete();
});
}else{
  this.alertControl.apiError("Import Failed",reponceValue[0]['message']).subscribe((responce: any) => {
  observer.next(reponceValue[0]);
  observer.complete();
  });
}

});

});

 }







///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  
//  Just the New Polygon Page DataTables Config
//
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////


dtConfig(): any{
  return {
    "dom":   "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
    //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
    "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
    "<'table-responsive'tr>" +
    "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
  columnDefs: [
    {
       targets: [],
       type: 'string',
       render: function(data, type, full, meta){
          if (type === 'filter' || type === 'sort') {
             var api = new $.fn.dataTable.Api(meta.settings);
             var td = api.cell({row: meta.row, column: meta.col}).node();
             data = $('select, input[type="text"]', td).val();
            // alert(data);
          }

          return data;
       }
    }
 ],
    buttons: {
        buttons: [
            { extend: 'copy', className: 'btn btn-sm' },
            { extend: 'csv', className: 'btn btn-sm' },
            { extend: 'excel', className: 'btn btn-sm' },
            { extend: 'print', className: 'btn btn-sm' }
        ]
    },
    "oLanguage": {
        "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
        "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
        "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        "sSearchPlaceholder": "Search...",
       "sLengthMenu": "Results :  _MENU_",
    },
    "stripeClasses": [],
    "lengthMenu": [5, 10, 20, 50],
    "pageLength": 5 ,
    "processing": true,
    //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
    responsive: true,
    "initComplete":function(settings, json) {
      let polyTable = document.getElementById('polyTable');
      polyTable!.style.display = 'block';
     // let polyTableLoader = document.getElementById('polyTableLoader');
     // polyTableLoader!.style.display = 'none';
    },
  };
}




}
