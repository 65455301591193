<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Form</li>
        </ol>
    </nav>
    </div>

<div class="row layout-spacing">
    <!-- Content -->

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

<div class="row" style="height:80vh">

<iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/14383188/f/dpy2m-27/I6AYKH8OWO138HDW1Z" onwheel="" width="100%" height="100%" style="background: transparent; border: 1px solid #ccc;"></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>

</div>
</div>
</div>