import { Component, Input, OnInit,AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-htmlarea-editor',
  templateUrl: './htmlarea-editor.component.html',
  styleUrls: ['./htmlarea-editor.component.css']
})
export class HtmlareaEditorComponent implements OnInit {
  @Input() selectedElementID!: any;
  @Input() keyed_fields!: any;

  tempElementValues: any = {
    input_public:'',
  };

  constructor() { }

  ngOnInit(): void {

 

  }

}
