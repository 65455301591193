import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';



@Injectable({
  providedIn: 'root'
})
export class AddresslistControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(private http: HttpClient) { }


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Address Lists for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

  getAddressLists(percentElementId: string): Observable<any>{

    return new Observable(observer => {

      const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=listaddresslists',  {
        reportProgress: true
      });

      this.http.request(request).subscribe(event => {

        // progress
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.loaded, event.total);
          // event.loaded = bytes transfered
          // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;

          if(percentElementId !== '' && percentElementId !== undefined){
            let polyTableLoader = document.getElementById(''+percentElementId+'');
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
          }

      }
        }

        // finished
        if (event.type === HttpEventType.Response) {
          observer.next(event.body);
          //console.log(event.body);
        }

      });




    });
  }


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// 
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



  getAddressList(percentElementId: string, addresslist_id: string): Observable<any>{

    return new Observable(observer => {

      const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=getaddresslist&addresslist_id='+addresslist_id,  {
        reportProgress: true
      });

      this.http.request(request).subscribe(event => {

        // progress
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.loaded, event.total);
          // event.loaded = bytes transfered
          // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;

          if(percentElementId !== '' && percentElementId !== undefined){
            let polyTableLoader = document.getElementById(''+percentElementId+'');
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
          }

      }
        }

        // finished
        if (event.type === HttpEventType.Response) {
          observer.next(event.body);
        //  console.log(event.body);
        }

      });




    });
  }
  



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// 
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



getAddressListTable(percentElementId: string, addresslist_id: string): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=getaddresslisttable&addresslist_id='+addresslist_id,  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });




  });
}

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// 
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



  createAddressList(form: any, rows: any): Observable<any>{

    return new Observable(observer => {
        const headers = { 'content-type': 'application/json'}
        form.addresslist_table = rows;
        form.addresslist_enable = Boolean(form.map_enable);
        const body=JSON.stringify(form);
          this.http.post(''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=newaddresslist',body,{'headers':headers}).subscribe(resp => {
          console.log(resp);
          Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
            //this.router.navigate(['/geomap/addresslist/addresslists'])
            observer.next(resp);
          });
          //this.rerender();
      });
    });
  }


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// 
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



editAddressList(addresslist_id: string, form: any, rows: any): Observable<any>{

  return new Observable(observer => {
      const headers = { 'content-type': 'application/json'}
      form.addresslist_id = addresslist_id;
      if(rows.length > 0){

        form.addresslist_table = rows;

      }
      if(form.map_enable == 'true'){form.map_enable = true;}else{form.map_enable = false;}
      const body=JSON.stringify(form);
        this.http.post(''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=editaddresslist',body,{'headers':headers}).subscribe(resp => {
        console.log(resp);
        if(!resp['error'])
        {
          Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
            //this.router.navigate(['/geomap/addresslist/addresslists'])
            observer.next(resp);
          }).then(()=>{
          observer.next({error:resp['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Edit Failed!',
            'error'
          ).then(()=>{
            observer.next({error:resp['error']});
          });
        }
        //this.rerender();
    });
  });
}

  




//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Address List Remove API to Delete List by AddressList ID
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

removeAddressList(addresslist_id: string, withdialog: boolean, percentElementId: string): Observable<any>{

  return new Observable(observer => {


if(withdialog){


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        const request = new HttpRequest('DELETE', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=removeaddresslists&addresslistkey='+addresslist_id+'',  {
          reportProgress: true
         });

    this.http.request(request).subscribe((event: any) => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
        Swal.fire(
          'Deleted!',
          'Your Address List has been Deleted.',
          'success'
        ).then(()=>{
          observer.next({error:event.body['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Your Address List has failed to be Deleted.',
            'error'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
        }
        observer.next(event.body);
        //console.log(event.body);
      }

    });

      }else{
        observer.next({error:true});
      }
    })



  }
  else{



    const request = new HttpRequest('DELETE', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=removeaddresslists&addresslistkey='+addresslist_id+'',  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });

  }









  });
}





//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Address List Disable or Enable API by AddressList ID
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

disEnAddressList(addresslist_id: string, disEn: boolean, withdialog: boolean, percentElementId: string): Observable<any>{

  return new Observable(observer => {



if(withdialog){
var DialogButtoneText = "";
if(disEn){DialogButtoneText = "Enable";}else{DialogButtoneText = "Disable";}
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, '+DialogButtoneText+' it!'
    }).then((result) => {
      if (result.isConfirmed) {

        var tempJson = {
          addresslist_id:addresslist_id,
          addresslist_enable: disEn
        };
        const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=disenaddresslist',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});

    this.http.request(request).subscribe((event: any) => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
        Swal.fire(
          ''+DialogButtoneText+'d!',
          'Your Address List has been '+DialogButtoneText+'.',
          'success'
        ).then(()=>{
          observer.next({error:event.body['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Your Address List has failed to be '+DialogButtoneText+'.',
            'error'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
        }
        observer.next(event.body);
        //console.log(event.body);
      }

    });

      }else{
        observer.next({error:true});
      }
    })



  }
  else{



    var tempJson = {
      addresslist_id:"62a8a7392c537e46b53de342",
      addresslist_enable: true
    };
    const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/addresslists?api=disenaddresslist',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});


    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });

  }









  });
}





}
