

<div [ngClass]="{'pt-3 form-group' : question.type !== 'paragraphbox'}" [formGroup]="form" >
  <fieldset [disabled]="question.disabled">
    <label [attr.for]="question.key" *ngIf="isValid && question.type !== 'checkbox'">{{question.label}} <ng-container *ngIf="question.required"> *</ng-container> <ng-container *ngIf="!phoneVaild && question.required_type=='phone'">(Invalid Phone Number)</ng-container> <ng-container *ngIf="!emailVaild && question.required_type=='email'">(Invalid Email)</ng-container></label>
    <label [attr.for]="question.key" *ngIf="!isValid && question.type !== 'checkbox'"><p [style]="'font-weight:'+this.formSettings['form_element_label_font_weight']+'; color:red;'">{{question.label}} *  <ng-container *ngIf="!phoneVaild && question.required_type=='phone'">(Invalid Phone Number)</ng-container> <ng-container *ngIf="!emailVaild && question.required_type=='email'">(Invalid Email)</ng-container></p></label>
    <!-- <div class="errorMessage" *ngIf="!isValid"><font color="red">{{question.label}} is required</font></div> -->
    <div [ngSwitch]="question.controlType">


      <!-- TEXTBOX TEMPLETE-->

      <input class="form-control" *ngSwitchCase="'textbox'" [formControlName]="question.key"
              [id]="question.key" [type]="question.type">

      <!-- PASSWORD BOX TEMPLETE-->
      <input class="form-control" *ngSwitchCase="'password'" [formControlName]="question.key"
              [id]="question.key" [type]="'password'">

      <!-- TEXTAREA TEMPLETE-->
      <textarea class="form-control" type="textarea" [id]="question.key" rows="4" cols="50" *ngSwitchCase="'textarea'" [formControlName]="question.key"></textarea>

      <!-- SELECT BOX TEMPLETE-->
      <select class="form-control" *ngSwitchCase="'select'" [formControlName]="question.key">
        <option *ngFor="let opt of question.options" [value]="opt.value">{{opt.name}}</option>
      </select>

      <!--Date Selector Templete-->
      <input class="form-control" *ngSwitchCase="'date'" [type]="'date'" [id]="question.key" [formControlName]="question.key" min="{{question.settings['min']}}" max="{{question.settings['max']}}">

     <!--File Input Templete-->
     <ng-container *ngSwitchCase="'file'">
     <input class="form-control" (change)="fileChange($event)" type="file" *ngIf="question.public == false" [id]="question.key" [formControlName]="question.key" accept="*">

     <ng-container *ngIf="question.public == true && this.formSettings['form_files_count'] !== 0">
     <a *ngIf="question.public == true" [href]="'/entry-file-download;entry_id='+formSettings['entry_id']" target="_blank">Download All Files ({{this.formSettings['form_files_count']}})</a>
     </ng-container>
     <ng-container *ngIf="question.public == true && this.formSettings['form_files_count'] == 0">
      No Files to Download
      </ng-container>
    </ng-container>

       <!--Radio Input Templete-->
       <ng-container *ngSwitchCase="'radio'">
        <div *ngFor="let item of question.settings;let Index = index">
        <input type="radio" [id]="question.key+'-'+Index" [formControlName]="question.key" [name]="question.key" value="{{item.value}}" [attr.radio-value]="item.value">
        <label [for]="question.key+'-'+Index">&nbsp;{{item.name}}</label><br>
        </div>
      </ng-container>

     <!--CheckBox Input Templete-->
          <label *ngSwitchCase="'checkbox'">
            <input type="checkbox" [id]="question.key" [formControlName]="question.key">
            <span *ngIf="isValid" >{{question.html}}</span>
            <span *ngIf="!isValid" style="color:red;">{{question.html}}</span>
          </label>

     <!--Package Table Templete START-->

     <ng-container *ngSwitchCase="'packagetable'">
      <div class="table-responsive" style="min-height:50px;">
      <table *ngIf="packageTables !== {}" type="packagetable" class="mt-2 table table-hover" style="width:100%; color:black;" >
        <thead>
        <tr class="geofi-form-pk-tb-header">
          <th></th>
             <ng-container *ngFor="let header of packageTables['headers'] ">
               <th *ngIf="header.public == true">{{header.name}}</th>
             </ng-container>
            </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let dataRow of packageTables['data']">
              <tr (click)="rowSelectRadio($event)">
                <td><input type="radio" [id]="question.key" [formControlName]="question.key" [name]="question.key" value="{{dataRow['col_id']}}"></td>
               <ng-container *ngFor="let data of dataRow['cols_data']">
                <td *ngIf="data.public == true">{{data.value}}</td>
              </ng-container>
            </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      </ng-container>

      <!--Package Table Templete END -->





    </div>

    <!-- <div class="errorMessage" *ngIf="!isValid"><font color="red">{{question.label}} is required</font></div> -->
  </fieldset>
  </div>


