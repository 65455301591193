import { Component, Input, OnInit,AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.css']
})
export class SelectEditorComponent implements OnInit {
  @Input() selectedElementID!: any;
  @Input() keyed_fields!: any;

  tempElementValues: any = {
    input_label:'',
    input_options:'',
    input_multi:'',
    input_required: '',
    input_public:'',
    input_keyed_fields:[],
    input_system_id:''

  };
  constructor() { }

  ngOnInit(): void {
console.log(this.selectedElementID);
    this.tempElementValues['input_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML;
    this.tempElementValues['input_required'] = (<HTMLSelectElement>document.getElementById(this.selectedElementID)!.querySelector('select'))!.getAttribute('required');
    this.tempElementValues['input_private_label'] = (<HTMLSelectElement>document.getElementById(this.selectedElementID)!.querySelector('select'))!.getAttribute('private_label');
    this.tempElementValues['input_multi'] = (<HTMLSelectElement>document.getElementById(this.selectedElementID)!.querySelector('select'))!.multiple;
    this.tempElementValues['input_public'] = (<HTMLSelectElement>document.getElementById(this.selectedElementID)!.querySelector('select'))!.getAttribute('insights-ispublic');


  // Select Options

    var values = Array.from((<HTMLSelectElement>document.getElementById(this.selectedElementID)!.querySelector('select'))!.options).map(e => e.value);
    var select_options = '';
    for (var option of values) {
      if(option !== ''){
      select_options = select_options + option + "\n";
      }
    }
    this.tempElementValues['input_options'] = select_options.slice(0, -1);

    if(this.tempElementValues['input_multi'] == false){

      this.tempElementValues['input_multi'] = 'false';
     }

     else{
      this.tempElementValues['input_multi'] = 'true';
    }


    // Set Input Public Status
    if(this.tempElementValues['input_public'] == 'true'){
      this.tempElementValues['input_public'] = 'true';
    }
    else{

      this.tempElementValues['input_public'] = 'false';
    }

    // Set Input Keyed Field Status
    var temparray: string[] = [];
    document.querySelectorAll('[keyed-field]').forEach(async (el: any)=>{
         temparray.push(el.getAttribute('keyed-field'));
     });

     var KeyedText = '';
     console.log( this.keyed_fields);
     this.keyed_fields.forEach(async(key: any)=>{
      if(!temparray.includes(key['name'])){
         this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: false});
      }
       else{
        if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.getAttribute('keyed-field') == key['name']){
           this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: true});
        }
      }

     });

    // Set System ID temapaery Var if Present
    var system_id_val: any = "";
    if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.getAttribute('system-id') !== null){
      this.tempElementValues['input_system_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.getAttribute('system-id');
    }
    else{
      this.tempElementValues['input_system_id'] = "";
    }
  }



  updateElement(name: string,el: any): void{
    if(name == 'label'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML = el.target.value;
    }



    else if(name == 'select_options'){
      console.log(el.target.value);
      (<HTMLSelectElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('required',el.target.value);


            //Remove Old Options from the Select Field
            var x = (<HTMLSelectElement>document.getElementById(this.selectedElementID))!.querySelector('select')!;
            while(x.length > 0){
              x.remove(x.length-1);
            }
            //Add the Standard Blank First Option
            var x = (<HTMLSelectElement>document.getElementById(this.selectedElementID))!.querySelector('select')!;
            var tempOption = document.createElement("option");
              tempOption.text = "Choose an Option";
              tempOption.value = "";
              x.add(tempOption);
            //Add the new Options form Dialog
            for (var option of el.target.value.split(String.fromCharCode(10))) {
              console.log(option);
              var tempOption = document.createElement("option");
              tempOption.text = ""+option+"";
              tempOption.value = ""+option+"";
              x.add(tempOption);
            }
    }





    else if(name == 'select_multi'){

    console.log(el.target.value);

    //Set the Value of Multiple Select
    if(el.target.value == 'true'){

      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.multiple = true;

     }else{
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.removeAttribute("multiple");
     }

    }

    else if(name == 'private_label'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('private_label',el.target.value);
    }
    else if(name == 'required'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('required',el.target.value);
    }

    else if(name == 'is_public'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('insights-ispublic',el.target.value);
    }

    else if(name == 'system_id'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('system-id',el.target.value);
    }

    else if(name == 'keyed_field'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('select')!.setAttribute('keyed-field',el.target.value);
    }

    }

}
