import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { XlsxExportService } from '../../../file_services/excel/xlsx-file.service';
import { provideImgixLoader } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class SimpleTableService {
  //Display loading Element
  private _tableLoading: boolean = false;
  private _tableReLoading: boolean = false;
  //Default Params
  private _rowLayout: any;
  private _format: any;
  private _formatCountOnly: any;
  private _apiUrl: any;

  // Page Count Parms
  private _currentPage: number = 0;
  private _currentPageLimit: number = 10;
  private _row_starting_index: number = 0;

  //Total Table Size
  private _currentTableSize: number;
  private _currentTablePageCount: number = 0;
  private _currentTableFilters: any = [];
  //Table pagaiton
  private _paginationArray: number[]= [];
  //Public Values
  tableHeaders: any = [];
  tableData: any = [];

  constructor(private http: HttpClient,private xlsxExportService:XlsxExportService) { }

  initTable(format: string| null, apiUrl: string,formatCountOnly: any| null): void{
    this._apiUrl = apiUrl;
    this._format = format;
    this._formatCountOnly = formatCountOnly;
  }

  loadTable(){
    if(this._apiUrl !== undefined && this._apiUrl !== null && this._apiUrl !== null){
      this._tableLoading = true;
      this.getCurrentTableSize();
      this.getHttpReq(this._row_starting_index,this._format).subscribe(async (tableReq)=>{

        this.tableHeaders = [];
        this.tableData = [];
        tableReq['table_headers'].forEach(async (Header_Data: any)=>{
          this.tableHeaders.push(Header_Data);
        });
        if(tableReq['table_data'].length > 0){
        tableReq['table_data'].forEach(async (RowData:any,rowindex) => {
            this.tableData.push(RowData);

            if((rowindex+1) >= tableReq['table_data'].length){
              this._tableLoading = false;
              this.pageCounter();
            }
        });
      }
      else{
        this._tableLoading = false;
        this._currentTableSize = 0;
        this._currentTablePageCount = 0;
      }
      });
    }
  }


  reloadTable(){
    if(this._apiUrl !== undefined && this._apiUrl !== null && this._apiUrl !== null){
      this._tableReLoading = true;
      this.getCurrentTableSize();
      this.getHttpReq(this._row_starting_index,this._format).subscribe(async (tableReq)=>{
        this.tableData = [];
        if(tableReq['table_data'].length > 0){
        tableReq['table_data'].forEach(async (RowData:any,rowindex) => {
            this.tableData.push(RowData);

            if((rowindex+1) >= tableReq['table_data'].length){
              this._tableReLoading = false;
              this.pageCounter();
            }
        });
      }
      else{
        this._tableReLoading = false;
        this._currentTableSize = 0;
        this._currentTablePageCount = 0;
      }
      });
    }
  }

  private getCurrentTableSize(): void{
    this.getHttpReq(this._row_starting_index,this._formatCountOnly).subscribe(async (tableData)=>{
      if(tableData['table_row_count'] > 0){
        this._currentTableSize = tableData['table_row_count'];
        this.calcuatePageCount();
      }
    });
  }

  private getHttpReq(row_starting_index: number,format: string): Observable<any>{
    console.log("workign");
    return new Observable(observer => {

      const headers = { 'content-type': 'application/json'}

      var tempJson = {
        row_filters:this._currentTableFilters,
        row_return_limit:this._currentPageLimit,
        row_starting_index:row_starting_index,
        row_format:format
      };


      this.http.post(this._apiUrl,JSON.stringify(tempJson),{'headers':headers}).subscribe((retrunBody)=>{


          observer.next(retrunBody);
          console.log(retrunBody);


      });



  });
  }

  private calcuatePageCount(): void{
    this._currentTablePageCount = this.up((this._currentTableSize/this._currentPageLimit), 0);
  }

  private updateStartingIndex(): void{
    this._row_starting_index = this._currentPage * this._currentPageLimit;
    this. reloadTable();
  }

  modifyFilter(header_id: string,header_parent:string,elementValue: any):void{
  if (this._currentTableFilters.filter(data => ''+data.id+'' == ''+header_id+'').length >0){
   this._currentTableFilters.filter(data => ''+data.id+'' == ''+header_id+'').forEach(async (filter)=>{
    filter['filter_value'] = elementValue.value;
    this. reloadTable();
   });
  }
  else{
    this._currentTableFilters.push({
      "id" : ""+header_id+"",
      "filter_parent" : ""+header_parent+".",
      "data_type" : "string",
      "filter_type" : "contains",
      "filter_value" : ""+elementValue.value+"",
      "filter_id" : ""+header_parent+""+header_id+""
  });
  this. reloadTable();
  }
  }


  setPageLimit(itemCount: number){
    this._currentPageLimit = itemCount;
    this.calcuatePageCount();
    this.updateStartingIndex();
  }

  setPage(itemCount: number){
    this._currentPage = itemCount;
    this.updateStartingIndex();
  }

  nextPage(): void{
    if(this._currentPage+1 <= this._currentTablePageCount){
      this._currentPage++;

      }
      this.updateStartingIndex();
  }

  prevPage(): void{
    if(this._currentPage>=1){
    this._currentPage--;
    }
    this.updateStartingIndex();
  }

  private up(v, n) {
    return Math.ceil(v * Math.pow(10, n)) / Math.pow(10, n);
}



////////
//
//
//  Table Export Functions
//
//
////////


exportAllData(): void{
  var tempPageCount = this._currentPageLimit;
  this._currentPageLimit = 0;

  this.getHttpReq(0,this._format).subscribe(async (tableReq)=>{
    var tempTabledata: any = [];
    console.log(tableReq);

    if(!tableReq['error']){
    var temprowPromise: any = [];
    tableReq['table_headers'].forEach(async (Header_Data: any)=>{
      temprowPromise.push(new Promise((resolve,reject)=>{
        resolve(Header_Data['value']);
      }));
    });

    Promise.all(temprowPromise).then(async (rowValues)=>{
      tempTabledata.push(rowValues);

      var temprowPromise: any = [];
      tableReq['table_data'].forEach(async (RowData:any,rowindex) => {
        temprowPromise.push(new Promise((rowresolve,rowreject)=>{
        var tempcolPromise: any = [];
        RowData['data'].forEach(async (Row_Data: any)=>{

          tempcolPromise.push(new Promise((resolve,reject)=>{
            resolve(Row_Data['value']);
          }));

        });
        Promise.all(tempcolPromise).then(async (rowValues)=>{
          tempTabledata.push(rowValues);
          rowresolve(null);
        });
      }));

      });
        Promise.all(temprowPromise).then(async (rowValues)=>{

          this.xlsxExportService.exportAsExcelFile(tempTabledata, 'sample');

        });



    });
  }
  
  });
  


}



pageCounter(): void {

 // if(this.currentPageIndex == 0){
  // Array.from(Array(this._currentTablePageCount), (_, i) => i+1);
  //}
  this._paginationArray = [this.currentPageIndex+1];
  //return [1,2,3,this.pageCount];
}

get pageCount(){return this._currentTablePageCount;}
get tableRowCount(){return this._currentTableSize;}
get currentPageIndex(){return this._currentPage;}
get isTblLoading(){return this._tableLoading;}
get isTblReLoading(){return this._tableReLoading;}
get getPagination(){return this._paginationArray;}
get getTotalRows(){return this._currentTableSize;}
}
