import { NgModule } from '@angular/core';
import { RouterModule, Routes,  PreloadAllModules } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LogonComponent } from './modules/mod_auth/login/login.component';

import { ListmapsComponent } from './modules/mod_geomap/maps/list-maps/listmaps.component';
import { ListpolysComponent } from './modules/mod_geomap/maps/list-polygons/listpolys.component';
import { NewMapComponent } from './modules/mod_geomap/maps/new-map/new-map.component';
import { NewPolyComponent } from './modules/mod_geomap/maps/new-poly/new-poly.component';
import { EditmapComponent } from './modules/mod_geomap/maps/edit-map/editmap.component';
import { EditpolyComponent } from './modules/mod_geomap/maps/edit-poly/editpoly.component';
// Forms
import { ListformsComponent } from './modules/mod_geomap/forms/forms/list-forms/listforms.component';
import { NewformComponent } from './modules/mod_geomap/forms/forms/new-form/newform.component';
import { FormbuilderComponent } from './modules/mod_geomap/forms/forms/form-builder/formbuilder.component';
import { NewFormEntryComponent } from './modules/mod_geomap/forms/forms/new-form-entry/new-form-entry.component';

// Package Table
import { ListPackageTablesComponent } from './modules/mod_geomap/forms/package-table/list-package-tables/list-package-tables.component';
import { NewpackagetableComponent } from './modules/mod_geomap/forms/package-table/newpackagetable/newpackagetable.component';
import { EditPackageTableComponent } from './modules/mod_geomap/forms/package-table/edit-package-table/edit-package-table.component';

import { AddresslistsComponent } from './modules/mod_geomap/address_lists/addresslists/addresslists.component';
import { NewaddresslistComponent } from './modules/mod_geomap/address_lists/newaddresslist/newaddresslist.component';
import { AddressEditorComponent } from './modules/mod_geomap/address_lists/address-editor/address-editor.component';


//Entries
import { ListFormEntriesComponent } from './modules/mod_geomap/forms/forms/list-form-entries/list-form-entries.component';

import { FormEntryDetailsComponent } from './modules/mod_geomap/forms/forms/form-entry-details/form-entry-details.component';

//Dashboard
import { DashboardGeofiComponent } from './modules/mod_dashboard/geofi/dashboard_geofi/dashboard.component';
//GeoFi
import { ListWorkflowsComponent } from './modules/mod_geomap/workflows/list-workflows/list-workflows.component';
import { FormEntriesViewerComponent } from './modules/mod_geomap/forms/forms/form-entries-viewer/form-entries-viewer.component';

//Users
import { ListUsersComponent } from './modules/mod_administration/list-users/list-users.component';
import { NewUserComponent } from './modules/mod_administration/new-user/new-user.component';
import { EditUserComponent } from './modules/mod_administration/edit-user/edit-user.component';
//Groups
import { ListGroupsComponent } from './modules/mod_administration/list-groups/list-groups.component';
import { NewGroupComponent } from './modules/mod_administration/new-group/new-group.component';
import { EditGroupComponent } from './modules/mod_administration/edit-group/edit-group.component';

import { ClientSettingsComponent } from './modules/mod_administration/client-settings/client-settings.component';


//Reports
import { ReportBuilderComponent } from './modules/mod_geomap/reports/report-builder/report-builder.component';
import { ListReportsComponent } from './modules/mod_geomap/reports/list-reports/list-reports.component';
import { ReportViewerComponent } from './modules/mod_geomap/reports/report-viewer/report-viewer.component';

//Email Templets
import { ListEmailTempletsComponent } from './modules/mod_geomap/forms/email-templets/list-email-templets/list-email-templets.component';
import { CreateEmailTempletComponent } from './modules/mod_geomap/forms/email-templets/create-email-templet/create-email-templet.component';

//Sch Reports
import { ListScheduledReportsComponent } from './modules/mod_geomap/reports/list-scheduled-reports/list-scheduled-reports.component';
import { CreateScheduledReportComponent } from './modules/mod_geomap/reports/create-scheduled-report/create-scheduled-report.component';

// Form Field Triggers
import { CreateFieldTriggerComponent } from './modules/mod_geomap/forms/forms/field-triggers/create-field-trigger/create-field-trigger.component';
import { ListFieldTriggerComponent } from './modules/mod_geomap/forms/forms/field-triggers/list-field-trigger/list-field-trigger.component';


//Workflows
import { NewSubscriberWorkflowComponent } from './modules/mod_geomap/workflows/third-party/nisc/new-subscriber-workflow/new-subscriber-workflow.component';
import { NewSubWithSoWorkflowComponent } from './modules/mod_geomap/workflows/third-party/nisc/new-sub-with-so-workflow/new-sub-with-so-workflow.component';
//
import { NewListEntriesComponent } from './modules/mod_geomap/forms/forms/new-list-entries/new-list-entries.component';
import { FileDownloadComponent } from './modules/mod_geomap/forms/forms/file-download/file-download.component';

// Support Tickets
import { NewTicketComponent } from './modules/mod_support/support_tickets/new-ticket/new-ticket.component';
//
import { ViewMapComponent } from './modules/mod_geomap/maps/view-map/view-map.component';


const routes: Routes = [
  //
 // { path: 'home', component: HomeComponent,canActivate: [AuthGuard], data: {permissionToken: "test123"} },
  { path: 'logon', component: LogonComponent },
  { path: '#' , redirectTo: '', pathMatch: 'full' },

  //Users
  { path: 'admin/users/listusers', component: ListUsersComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'admin/users/newusers', component: NewUserComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },
  { path: 'admin/users/editusers', component: EditUserComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },

  //Groups
  { path: 'admin/groups/listgroups', component: ListGroupsComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },
  { path: 'admin/groups/newgroup', component: NewGroupComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },
  { path: 'admin/groups/editgroup', component: EditGroupComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Client
  { path: 'admin/client/settings', component: ClientSettingsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Dashboard
  { path: 'dashboard/geofi', component: DashboardGeofiComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Maps
  { path: 'geomap/maps/listmaps', component: ListmapsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/maps/listpolys', component: ListpolysComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/maps/newMap', component: NewMapComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b50846ec6d10fc3738ba9"} },
  { path: 'geomap/maps/newpoly', component: NewPolyComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/maps/editmap', component: EditmapComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b524d6ec6d10fc3738bb3"} },
  { path: 'geomap/maps/editpoly', component: EditpolyComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Forms
  { path: 'geomap/maps/listforms', component: ListformsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/maps/listforms/geomap/forms/newform', component: NewformComponent, canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },
  { path: 'geomap/forms/formbuilder', component: FormbuilderComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/forms/entryviewer', component: FormEntriesViewerComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geofi/forms/newentry', component: NewFormEntryComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  // Package table
  { path: 'geomap/forms/listpackagetables', component: ListPackageTablesComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/forms/newpackagetable', component: NewpackagetableComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/forms/editpackagetable', component: EditPackageTableComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Address Lists
  { path: 'geomap/addresslist/addresslists', component: AddresslistsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b6b526ec6d10fc3738c25"} },
  { path: 'geomap/addresslist/newaddresslist', component: NewaddresslistComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/addresslist/addresslisteditor', component: AddressEditorComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Workflows
  { path: 'geomap/workflows/listworkflows', component: ListWorkflowsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Entries
  { path: 'geomap/form/listentries', component: ListFormEntriesComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/form/entrydetail', component: FormEntryDetailsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Reporting
  { path: 'geomap/reports/listreports', component: ListReportsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/reports/reportbuilder', component: ReportBuilderComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Reporting
  { path: 'geomap/reports/listscheduledreports', component: ListScheduledReportsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/reports/createscheduledreport', component: CreateScheduledReportComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/reports/reportviewer', component: ReportViewerComponent,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}  },
  //Email Templets
  { path: 'geomap/form/listemailtemplets', component: ListEmailTempletsComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/form/createemailtemplet', component: CreateEmailTempletComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Field-Triggers
  { path: 'geomap/form/listFieldtriggers', component: CreateFieldTriggerComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geomap/form/createFieldTrigger', component: ListFieldTriggerComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },

  //Workflow

  { path: 'geofi/workflows/third-party/nisc/create-sub', component:  NewSubscriberWorkflowComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
  { path: 'geofi/workflows/third-party/nisc/create-sub-with-so', component: NewSubWithSoWorkflowComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"} },
//
{ path: 'newTable', component: NewListEntriesComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}},

//
{ path: 'entry-file-download', component: FileDownloadComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}},
//
{ path: 'newTicket', component: NewTicketComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}},
//
{ path: 'geofi/maps/viewmap', component: ViewMapComponent ,canActivate: [AuthGuard], data: {permissionToken: "625b52196ec6d10fc3738bb2"}},

//Default
  { path: '', redirectTo: '/geomap/maps/listmaps', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
