import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-field-trigger',
  templateUrl: './list-field-trigger.component.html',
  styleUrls: ['./list-field-trigger.component.css']
})
export class ListFieldTriggerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
