import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MapsService {

  constructor() { }

  public InitMap(map: any,divid: string,lat: number,long: number,zoom: number){
    var L = require('leaflet');
    var map: any; 
    map = L.map(''+divid+'',{scrollWheelZoom: false}).setView([lat, long], zoom);
    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Insights',
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1
  }).addTo(map); 


  


   return map;
  }



  public InitMapWithCallback(map: any,divid: string,lat: number,long: number,zoom: number,callback){
    var L = require('leaflet');
    map = L.map(''+divid+'',{scrollWheelZoom: false}).setView([lat, long], zoom);
    var tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Insights',
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      scrollWheelZoom: false
  }).addTo(map).on('load', function() {callback(map);});
  }




  load_basic_polys(map: any, polygons: any, callback){
    var L = require('leaflet');  
    var tempPolyObjs: any = [];
        //this.rerender();
        for (const element of <any>polygons) {
          var polygon: any;
          var coords: any = [];
          polygon = L.geoJSON(<any>element['poly_geojson'],
           <any>{
              color: element['poly_color'],
              onEachFeature:  (feature: any, layer: any) => {
                for (const element of feature.geometry.coordinates) {
                  if(element[0] !== null && element[0] !== undefined){
                    for (const element1 of element) {
                      if(element1[0] !== null && element1[0] !== undefined){
                        for (const element2 of element1) {
                        //console.log(element2);
                             coords.push({lat:element2[1],lng:element2[0]});
                        }
                    }
                  }
                }
              }
              layer.bindPopup('<h5 style="color:black;">'+element['poly_name']+'</h5><h6 style="color:'+element['poly_color']+';">'+element['poly_status_name']+'</h6><p>Testing Button</p>',{closeButton: false})
                .on("popupopen", () => {
                 // this._elementRef.nativeElement
                   // .querySelector("#DelPoly_"+element['_id']+"")
                   // .addEventListener("click", (e: any) => {
                      //alert("Removing::"+ element['_id']);
                      //this.removePoly(element['_id']);
                     // window.location.replace(this.globalSetting['form_module_domain']+'/initform;formkey='+element['poly_inputdefaultform']+'');
                      //window.open(' https://geofiforms.myiinsights.com/initform;formkey=62963f030ce9b103ab8c2ad1', '_blank');
                   // });
                });
        
            }
            }).addTo(map)
            .on("popupopen", () => {
             // this.elementRef.nativeElement
              //  .querySelector("#DelPoly_"+element['_id']+"")
              //  .addEventListener("click", (e: any) => {
              //    //alert("Removing::"+ element['_id']);
              //    this.removePoly(element['_id']);
              //  });
            });
            tempPolyObjs.push({id:element['_id'],name:element['poly_name'],poly_input_form_type:element['poly_input_form_type'],poly_formLinkText:element['poly_inputformlink'],poly_form_url_id: element['poly_inputdefaultform'],poly_status_name:element['poly_status_name'],polyObj:polygon, coords:coords})
      }

     
     callback({map:map, polyObjs:tempPolyObjs});


  }


////////////////////////////////////////////////////////////////////////
///
///
///   
///
///
////////////////////////////////////////////////////////////////////////

  load_basic_polygon(map: any, polygon: any, callback){


  }

////////////////////////////////////////////////////////////////////////
///
///
///   
///
///
////////////////////////////////////////////////////////////////////////

  isMarkerInsidePolygon(marker: any, poly: any) {
    var polyPoints = poly;
    //console.log(polyPoints);
    //console.log(marker.getLatLng());
    var x = marker.getLatLng().lat, y = marker.getLatLng().lng;

    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
        var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
       // console.log(xi);
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
};

////////////////////////////////////////////////////////////////////////
///
///
///   
///
///
////////////////////////////////////////////////////////////////////////

isMarkerInsidePolygon2(marker:any, poly:any ) {
    var inside = false;
    var x = marker.getLatLng().lat, y = marker.getLatLng().lng;
    for (var ii=0;ii<poly.length;ii++){
        var polyPoints = poly[ii];
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
            console.log(xi);
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
    }

    return inside;
};





}
