import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
//import Global Setting Varitables
import globalSettings from '../../_settings/Globle_Settings.json';
@Injectable({
  providedIn: 'root'
})
export class LogControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;
 
  constructor(private http: HttpClient) { }


  submitGeneralLog(data: any): any{

    const headers = { 'content-type': 'application/json'} ;

    this.http.post(this.globalSetting['api_url_console']+'/logging/pri?api=submitLog',JSON.stringify(data),{'headers':headers}).subscribe(data => {});

  }
}
