import { Injectable } from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Observable } from 'rxjs';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class UserControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;
 
  constructor(private http: HttpClient) { }



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getUserList(percentElementId: string): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/admin/users?api=listusers',  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }
    
    });
      
       
   

  });
}


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getUserDetails(percentElementId: string, user_id: any): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/admin/users?api=getuserdetails&user_id='+user_id+'',  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }
    
    });
      
       
   

  });
}



createUser(userData: any): Observable<any>{

  return new Observable(observer => {

    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}  ;

    const body=JSON.stringify(userData);
      this.http.post(''+this.globalSetting['api_url_console']+'/admin/users?api=newuser',body,{'headers':headers}).subscribe(resp => {
        if(resp['error'] == false){
          console.log(resp);
          Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
            //this.router.navigate(['/geomap/maps/listmaps'])
            observer.next({error:resp['error']});
          }); 
        }
        else
        {
          console.log(resp);
          Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{
            observer.next({error:resp['error']});
          });
        }
      //this.rerender();
  });

  });
}





updateUser(userData: any): Observable<any>{

  return new Observable(observer => {

    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}  ;

    const body=JSON.stringify(userData);
      this.http.post(''+this.globalSetting['api_url_console']+'/admin/users?api=updateuser',body,{'headers':headers}).subscribe(resp => {
        if(resp['error'] == false){
          console.log(resp);
          Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
            //this.router.navigate(['/geomap/maps/listmaps'])
            observer.next({error:resp['error']});
          }); 
        }
        else
        {
          console.log(resp);
          Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{
            observer.next({error:resp['error']});
          });
        }
      //this.rerender();
  });

  });
}





///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  Remove a User
//
//  Service Call to Remove user from Authroized Corp using Insights API Call
//
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////



  removeUser(user_id: any): Observable<any>{

    return new Observable(observer => {
    
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete User!'
      }).then((result) => {
        if (result.isConfirmed) {
           //const { username, password } = this.form;
    
      const headers = { 'content-type': 'application/json'}  
      this.http.delete(''+this.globalSetting['api_url_console']+'/admin/users?api=removeuser&user_id='+user_id,{'headers':headers}).subscribe(
        data => {
        
          console.log('success', data); 
          if(!data['error'])
          {
          Swal.fire(
            'Deleted!',
            'User has been deleted.',
            'success'
          ).then(()=>{
            observer.next({error:data['error']});
          });
          }
          else
          {
            Swal.fire(
              'Failed!',
              'User has failed to be deleted.',
              'error'
            ).then(()=>{
              observer.next({error:data['error']});
            });
          }
          
        }
    );
    
        }else{
          observer.next({error:true});
        }
      })
    
    });
       
      //  return new Observable(observer => observer.next({error:true}));
    
      }




///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  Diasble or Enable a User
//
//  Service Call to Enable or Disable a user from Authroized
//  Corp using Insights API Call
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////



disEnUser(user_id: any, enable: boolean): Observable<any>{

  return new Observable(observer => {
    var buttonText = "";
  if(!enable){
    buttonText = "Disable";
  }else{
    buttonText = "Enable";
  }
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to "+buttonText+" this User!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: ''+buttonText+'!'
    }).then((result) => {
      if (result.isConfirmed) {
         //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}  
    var tempJson = {
      user_id:user_id,
      user_enable:enable
    };

    this.http.post(''+this.globalSetting['api_url_console']+'/admin/users?api=disenuser',JSON.stringify(tempJson),{'headers':headers}).subscribe(
      data => {
        console.log('success', data); 
        if(!data['error'])
        {
        Swal.fire(
          ''+buttonText+'!',
          'User has been '+buttonText+'.',
          'success'
        ).then(()=>{
          observer.next({error:data['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Your user has failed to be '+buttonText+'.',
            'error'
          ).then(()=>{
            observer.next({error:data['error']});
          });
        }
        
      }
  );
  
      }else{
        observer.next({error:true});
      }
    })
  
  });
  
  
  
     
    //  return new Observable(observer => observer.next({error:true}));
  
    }


}
