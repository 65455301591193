import { Component, OnInit, AfterViewInit,ViewChild} from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import {ReportControlService} from '../../../../_services/modules/geomap/reports/report-control.service';

import {FormControlService} from '../../../../_services/modules/geomap/forms/form-control.service'
import {FormEntryControlService} from '../../../../_services/modules/geomap/forms/form-entry-control.service'
import { BreakpointObserver } from '@angular/cdk/layout';

import {XlsxExportService} from '../../../../_services/file_services/excel/xlsx-file.service'
// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';
@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.css']
})
export class ReportViewerComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //dtOptions: DataTables.Settings = {};
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();
  displayTable = true;
  rows: any = [];

  showTableData = true;
  selectColumns: any = [];
  dataRows: any = [];

 loadedReportData: any = {
  report_name:''
 };

  constructor(private reportControlService:ReportControlService,
    private route: ActivatedRoute,
    private formControlService:FormControlService,
    private formEntryControlService: FormEntryControlService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private xlsxExportService:XlsxExportService
    ) {
    this.dtOptions = {
      "dom":
    "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
    //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
    "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
    "<'table-responsive'tr>" +
    "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
    buttons: {
      buttons: [
          { extend: 'csv',
            className: 'btn',
            title:"Maps",
            text: 'CSV',
            exportOptions: {
          columns: 'th:not(:last-child)'
          }
        },
          { extend: 'excel',
            className: 'btn',
            title:"Maps",
            text: 'Excel',
              exportOptions: {
               columns: 'th:not(:last-child)'
                  }
          },
            {
            extend: 'print',
            className: 'btn',
            title:"Maps",
            exportOptions: {
              columns: 'th:not(:last-child)'
                 }
            }
      ]
  },
      "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "stripeClasses": [],
      "lengthMenu": [7, 10, 20, 50,100],
      "pageLength": 7 ,
      "processing": true,
      //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
      responsive: true,
      "initComplete":function(settings, json) {
        let polyTable = document.getElementById('mapTable');
        polyTable!.style.display = 'block';
        let polyTableLoader = document.getElementById('polyTableLoader');
        polyTableLoader!.style.display = 'none';
      },
    };
   }

  ngAfterViewInit(): void {

    this.load_table();


  }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  //this.customcssservice.load_table_style_handsontable();
  this.customcssservice.load_switches_css();
  }



  load_table(): void{



    this.reportControlService.getReportDetails('loadingTablePercent',this.route.snapshot.paramMap.get('report_id')).subscribe((reportData)=>{

      this.loadedReportData = reportData;

      this.formControlService.getFormDetails('',reportData['report_form_id']).subscribe((returnFormData)=>{


        this.selectColumns = reportData['report_type_info']['report_Table_Header_Layout'];


        this.formEntryControlService.getFormEntriesWithFilter('',reportData['report_form_id'],reportData['report_filters'],2000,'none').subscribe((formEntryData)=>{
          var count = 0;
          formEntryData.forEach(async (data)=> {
            console.log(data['speedtest_data'])
            if(data['speedtest_data'] !== undefined){

              data['form_data']['dlStatus'] = parseFloat(data['speedtest_data']['dlStatus']);
              data['form_data']['ulStatus'] = parseFloat(data['speedtest_data']['ulStatus']);
              data['form_data']['pingStatus'] = parseFloat(data['speedtest_data']['pingStatus']);
              data['form_data']['jitterStatus'] = parseFloat(data['speedtest_data']['jitterStatus']);

            }

            if(data['form_data']['632c6b2be5360eab60bfbe33'] == '::file::'){
              data['form_data']['632c6b2be5360eab60bfbe33'] = 'http://myininsights.com/download;entry_id='+data['_id'];
            }

            var d = new Date(data['form_submit_datetime']);
            data['form_data']['form_submit_datetime'] = d.toLocaleString('en-US', {timeZone: 'America/New_York'});

            data['form_data']['poly_loc_id'] = data['form_longlat']['poly_loc_id'];


            this.dataRows.push(data['form_data']);
            count++;
            if(count >= formEntryData.length){
            this.dtTrigger.next(this.dtOptions);
            return;
           // this.rerender();
            }
          });

        });


      });


    });


  }


  table_reload(): void{




  }
  rerender(): void {
    //this.displayTable = false;
    console.log(this.dtElement);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
     // this.displayTable = true;
      this.dtTrigger.next(this.dtOptions);
    });

  }

  getSortedDataRow(data:any): Observable<any>{

    return new Observable(observer => {

      var returnArray: any = [];

      var count = 0;

      this.selectColumns.forEach(async (colume: any)=>{

        returnArray.push(data[''+colume['id']+'']);
        count++;
        if(count >= this.selectColumns.length){
          observer.next(returnArray);
        }
      });



  });


  }

  exportExcel(): void{
    console.log("Test Excport");
    this.xlsxExportService.exportTableAsExcelFile(document.getElementById('html5-extension'),'myexport');
  }



}
