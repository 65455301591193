import { Component, OnInit } from '@angular/core';

import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';


// Page Only Imports
import { UserControlService } from '../../../_services/modules/administration/users/user-control.service';
import { GroupControlService } from '../../../_services/modules/administration/groups/group-control.service';

declare var $: any;

declare var DualListbox: any;


export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

    matcher = new MyErrorStateMatcher();
    corpGroups: any;
    userForm: any = {
        user_firstname: new FormControl('', [Validators.required]),
        user_lastname: new FormControl('', [Validators.required]),
        user_username: new FormControl('', [Validators.required]),
        user_email: new FormControl('', [Validators.required]),
        user_password: new FormControl('', [Validators.required]),
        user_mfa: new FormControl('', [Validators.required]),
        user_position: new FormControl('', [Validators.required]),
        user_enable: new FormControl('', [Validators.required]),
        user_homepage: new FormControl('', [Validators.required]),
        user_profile_img: new FormControl('', [Validators.required]),
        user_groups: new FormControl([], [Validators.required])
      };


  constructor( 
    private userControlService: UserControlService,
    private groupControlService: GroupControlService,) { }

  ngOnInit(): void {




  
      $('.dropify').dropify({
          messages: { 'default': 'Click to Upload or Drag n Drop', 'remove':  '<i class="flaticon-close-fill"></i>', 'replace': 'Upload or Drag n Drop' }
      });
    
      // Save notification messagae
      $('#multiple-messages').on('click', function() {
          $.blockUI({
              message: $('.blockui-growl-message'), 
              fadeIn: 700, 
              fadeOut: 700, 
              timeout: 3000, //unblock after 3 seconds
              showOverlay: false, 
              centerY: false, 
              css: { 
                  width: '250px',
                  backgroundColor: 'transparent',
                  top: '12px',
                  left: 'auto',
                  right: '15px',
                  border: 0,
                  opacity: .95,
                  zIndex: 1200,
              } 
          }); 
      });
    
      setTimeout(function(){ $('.list-group-item.list-group-item-action').last().removeClass('active'); }, 100);
    this.getGlobalPermissions();

}

getGlobalPermissions(): any{
    this.groupControlService.getGroupList("").subscribe((corpGroups)=>{
      this.corpGroups = corpGroups;
      console.log(this.corpGroups);
     const myTimeout = setTimeout(()=>{this.selectBoxInit();}, 500);

    });
  }

selectBoxInit(): void{

    let dualListbox = new DualListbox("#lgroups", {

      addEvent: (value) => {
          // Should use the event listeners
          const myArray = value.split("'");
          const myArray1 = myArray[1].split("'");
          let word = myArray1[0];
          //console.log(word);
          this.userForm.user_groups.value.push(word);
          //console.log(this.groupForm.group_permissions.value)
      },
      removeEvent:  (value) => {
          // Should use the event listeners
          
          //console.log(value);
          const myArray = value.split("'");
          const myArray1 = myArray[1].split("'");
          let word = myArray1[0];
          //console.log(word);
          this.userForm.user_groups.value = this.userForm.user_groups.value.filter(e => e !== word);
          //console.log(this.groupForm.group_permissions.value);
          
      },
      availableTitle: "Groups",
      selectedTitle: "Selected Groups",
      addButtonText: "Add",
      removeButtonText: "Remove",
      addAllButtonText: "Add All",
      removeAllButtonText: "Remove All",

      sortable: true,
      upButtonText: "ᐱ",
      downButtonText: "ᐯ",

     // options: [
      //    { text: "Option 1", value: "OPTION1" },
      //    { text: "Option 2", value: "OPTION2" },
       //   { text: "Selected option", value: "OPTION3", selected: true },
     // ],
  });

  dualListbox.addEventListener("added", function (event) {
     // console.log(event);
     // console.log(event.addedElement);
  });
  dualListbox.addEventListener("removed", function (event) {
     // console.log(event);
      //console.log(event.removedElement);
  });

  }




onSubmit(): void{

console.log(this.userForm.user_profile_img.value);

var tempData = {
    user_firstname: this.userForm.user_firstname.value,
    user_lastname: this.userForm.user_lastname.value,
    user_username: this.userForm.user_username.value,
    user_email: this.userForm.user_email.value,
    user_password: this.userForm.user_password.value,
    user_mfa: this.userForm.user_mfa.value,
    user_position: this.userForm.user_position.value,
    user_enable: Boolean(true),
    user_homepage: this.userForm.user_homepage.value,
    user_profile_img: this.userForm.user_profile_img.value,
    user_groups: this.userForm.user_groups.value
  };

  this.userControlService.createUser(tempData).subscribe((Results)=>{



  });

}


getFile(e) {

    let extensionAllowed = {"png":true,"jpeg":true};
    //console.log(e.target.files);
    if (e.target.files[0].size / 1024 / 1024 > 20) {
      alert("File size should be less than 20MB")
      return;
    }
    if (extensionAllowed) {
      var nam = e.target.files[0].name.split('.').pop();
      if (!extensionAllowed[nam]) {
        alert("Please upload " + Object.keys(extensionAllowed) + " file.")
        return;
      }
    }
    //console.log(e.target.files[0]);
   this.getBase64(e.target.files[0]);
    
  }

    getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
    this.userForm.user_profile_img.value  = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

}
