import { Component, OnInit, AfterViewInit, ElementRef,ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest  } from '@angular/common/http';
import { UntypedFormArray,FormControl,FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as L from 'leaflet';

// Map Control
import { MapsService } from '../../../../_services/maps.service';
// Form Control
import { FormControlService } from '../../../../_services/modules/geomap/forms/form-control.service';
//New polygon Control
import {NewPolygonControlService} from '../../../../_services/modules/geomap/polys/new-polygon-control.service'

import {PolygonServicesService} from '../../../../_services/modules/geomap/polys/polygon-services.service'
// Globals
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';
import { AlertControlService } from '../../../../_services/components/alerts/alert-control.service';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-new-poly',
  templateUrl: './new-poly.component.html',
  styleUrls: ['./new-poly.component.css']
})


export class NewPolyComponent implements AfterViewInit,OnInit {
 // Global Settings
 private globalSetting: [] = globalSettings;


  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //dtOptions: DataTables.Settings = {};
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();
  displayTable = true;

  // The Polygon Default Values Form Group
  formDefaults: any = new FormGroup({
    default_poly_color: new FormControl('#0099da', Validators.required),
    default_poly_type: new FormControl('', Validators.required),
    default_poly_status: new FormControl('', Validators.required),
    default_poly_enable: new FormControl('true', Validators.required),
    default_poly_inputformlink: new FormControl('', Validators.required),
    default_poly_input_form_type: new FormControl('', Validators.required),
    default_poly_inputdefaultform: new FormControl('', Validators.required),
  });

// The Polygon Form Group Array that Stores the Values of all polygons That are Imported
form = this.fb.group({
    lessons: this.fb.array([])
});

map_id: any = this.route.snapshot.paramMap.get('map_id');

polyFileUploaded: any = true;

  rows: any = [];
  polyDTTable: any;
  mainPolyFile: any;
  polylist: any = [];
  form1: any = {};
  mapPolys: any = {};
  myMap: any;
  enableSubmit = true;
  enableImport = true;
  enableUpload = false;
  formsListArray: any = [];

  formLinkType = 'form';

  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private mapsservice: MapsService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private allpageGenerals: AllPageGeneralsService,
    private formControlService: FormControlService,
    private fb:UntypedFormBuilder,
    private newPolygonControlService:NewPolygonControlService,
    private polygonServicesService:PolygonServicesService,
    private alertControl: AlertControlService
    ) { }


  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////


  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
    this.customcssservice.load_Datatables_Default_Style();
    this.customcssservice.load_switches_css();
  // Change Top Nav Title on Page Change
  this.allpageGenerals.setTopNavTitle('New Poly');

  // Start Custom Init
 // this.myMap = this.mapsservice.InitMap(this.myMap,"map",38.676431,-86.913915,14);

  //Call to Load Forms
  this.loadFormsList();
  }




  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  ngAfterViewInit(): void {
    this.load_polys();
  }


  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //
  //
  //       Load Forms List For Seletion Option
  //
  //
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

    loadFormsList(){
      this.formControlService.getFormList('',false).subscribe((results)=>{

        //console.log(results);
        this.formsListArray = results;

      });

    }


  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////


  rerender(): void {
    console.log(this.dtElement);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(null);
    });
  }


  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  onSubmit(): void {
    this.newPolygonControlService.bulkPolygonImport(this.form['value']['lessons']).subscribe(async (importResults: any)=>{
      if(!importResults['error']){
        this.router.navigate(['/geomap/maps/listpolys',{map_id:this.route.snapshot.paramMap.get('map_id')}]);
      }
    });
  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////


  onFileChange(event) {
    this.enableUpload = true;
    this.polyFileUploaded = true;
    //this.displayTable = false;
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      // Base64 Output
      //reader.readAsDataURL(file);
      reader.readAsText(file);
      reader.onload = () => {



       // this.formGroup.patchValue({
       //   file: reader.result
       // });
       this.mainPolyFile = reader.result?.toString();
       var tempPolys = JSON.parse(this.mainPolyFile);
       this.polylist = tempPolys;
       const promises: any = [];
       //this.deleteAllPolyForms();
       this.enableImport = false;
      };
    }
  }



  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  onImport(): void{
    this.enableImport = true;
    const promises: any = [];

    this.polygonServicesService.convertGeojsonToPolygonArray(this.polylist).subscribe(async (PolygonReturn)=>{

      
      
      for(const polys of PolygonReturn){

        //console.log(resp);
       //this.displayTable = false;
  
       promises.push(new Promise(async (resolve: any) => {
             //console.log(polys["id"]);
          
             this.addPolyForm(polys['poly_name'],polys['poly_geojson'],polys['poly_id']);

                 this.mapPolys[''+polys['poly_id']+''] = L.geoJSON(<any>polys['poly_geojson'],
                <any>{
                   color: "blue"
                 }).addTo(this.myMap).bindPopup('<h6>'+polys['poly_name']+'</h6><a href="#" id="DelPoly_'+polys['poly_id']+'" class="closebtn edit" data-toggle="modal" data-target="#step-modal" target=""></a>')
                 .on("popupopen", () => {
                   this.elementRef.nativeElement
                     .querySelector("#DelPoly_"+polys['id']+"")
                     .addEventListener("click", (e: any) => {
                       //alert("Removing::"+ element['_id']);
                       this.removePoly(polys['id']);
                     });
                 });

                //!!!!Not the Best Way to Move Map View!!!!!!
                this.myMap.setView([this.mapPolys[''+polys['poly_id']+''].getBounds().getCenter()['lat'], this.mapPolys[''+polys['poly_id']+''].getBounds().getCenter()['lng']], 14);
               
                console.log(this.mapPolys[''+polys['poly_id']+''].getBounds().getCenter());
                 resolve("");
  
  
              }));
             }

    });
           Promise.all(promises).then((products: any) => {

             this.displayTable = true;
             this.dtOptions = this.newPolygonControlService.dtConfig();

               this.dtTrigger.next(this.dtOptions);

            this.enableSubmit = false;
            console.log(this.polylist);
             //alert(reader.result?.toString());
             // need to run CD since file load runs outside of zone
             //this.cd.markForCheck();
           });

  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  get lessons() {
    return this.form.controls["lessons"] as UntypedFormArray;
  }




  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  addPolyForm(poly_name: any, geojson: any, file_poly_id: any) {
    const lessonForm = this.fb.group({
      poly_name: [''+poly_name+'', Validators.required],
      poly_color: [''+this.formDefaults.get('default_poly_color').value+'', Validators.required],
      poly_fill_color:[''+this.formDefaults.get('default_poly_color').value+'', Validators.required],
      poly_fill_opacity: ['0.2', Validators.required],
      poly_radius:['500', Validators.required],
      poly_enable:[''+this.formDefaults.get('default_poly_enable').value+'', Validators.required],
      poly_type:[''+this.formDefaults.get('default_poly_type').value+'', Validators.required],
      poly_status:[''+this.formDefaults.get('default_poly_status').value+'', Validators.required],
      map_id: [''+this.route.snapshot.paramMap.get('map_id')+'', Validators.required],
      poly_input_form_type: [''+this.formDefaults.get('default_poly_input_form_type').value+'', Validators.required],
      poly_inputformlink: [''+this.formDefaults.get('default_poly_inputformlink').value+'', Validators.required],
      poly_inputdefaultform: [''+this.formDefaults.get('default_poly_inputdefaultform').value+'', Validators.required],
      poly_geojson:'{"type":"FeatureCollection", "features":[{"type":"Feature","id":"ID_00001","geometry":'+JSON.stringify(geojson)+',"properties":{"name":"'+poly_name+'","description":""}}]}',
      file_poly_id: file_poly_id
    });

    this.lessons.push(lessonForm);
    console.log();
  }


  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  formLinkTypeChange(el: any): any{
    this.formLinkType = (<HTMLSelectElement>document.getElementById("poly_input_form_type")).value;
  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  deletePolyForm(lessonIndex: string) {

    //console.log(parseInt(lessonIndex.replace('ID_', '')));
    this.removePoly(lessonIndex);
    this.lessons.removeAt(this.lessons.value.findIndex((item: any) => item.file_poly_id === lessonIndex));
    this.rerender();
  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  deleteAllPolyForms() {
    for (let step = 0; step < this.lessons.length; step++){
      this.lessons.removeAt(step);
    }
  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////


  viewLesson() {
   console.log(this.form['value']['lessons']);
  }

  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  removePoly(poly_id: string): void{
    
    if (this.mapPolys[''+poly_id+''] !== null) {
      this.myMap.removeLayer(this.mapPolys[''+poly_id+'']);
     }
  }


  ////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////

  
  load_polys(): void{


    const request = new HttpRequest('GET', this.globalSetting['api_url_console']+'/geomap?api=listpolygonsbymapwithgeojson&map_id='+this.route.snapshot.paramMap.get('map_id'),  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;
        let polyTableLoader = document.getElementById('loadingTablePercent');
        polyTableLoader!.innerHTML = Math.round(percentage)+'%';
        console.log(percentage);
    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        console.log(event.body);
        let polyMapLoader = document.getElementById('polyMapLoader');
        polyMapLoader!.style.display = 'none';
        this.polyFileUploaded = false;
        this.myMap = this.mapsservice.InitMap(this.myMap,"map",38.84242550792102,-86.9917137384425,14);

        //this.rerender();
        for (const element of <any>event.body) {

            this.mapPolys[''+element['_id']+''] = L.geoJSON(<any>element['poly_geojson'],
           <any>{
              color: "red"
            }).addTo(this.myMap).bindPopup('<h6>'+element['poly_name']+'</h6><a href="#" id="DelPoly_'+element['_id']+'" class="closebtn edit" data-toggle="modal" data-target="#step-modal"></a>')
            .on("popupopen", () => {
              this.elementRef.nativeElement
                .querySelector("#DelPoly_"+element['_id']+"")
                .addEventListener("click", (e: any) => {
                  //alert("Removing::"+ element['_id']);
                  this.removePoly(element['_id']);
                });
            })

            //console.log(resp1);
            //this.rerender();

      }
      }

    });

  }




}
