<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">Email Templet</li>
        </ol>
    </nav>
    </div>



    <div class="row layout-spacing">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
            <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
 
                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-complete-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-complete" role="tab" aria-controls="rounded-circle-pills-icon-complete" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                        <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
                      </svg></a>
                </li>

                <li class="nav-item ml-2 mr-2">
                    <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="''"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                      </svg> </a>
                </li>
            </ul> 
        </div>
    </div>

  

<div class="tab-content" id="rounded-circle-pills-icon-tabContent">

    <div class="tab-pane fade" id="rounded-circle-pills-icon-profile" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-profile-tab">

            <div class="row layout-spacing">

                    <!-- Content -->

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing " style="overflow-y: auto; overflow-x: hidden; height:70vh;">

                        <div class="skills layout-spacing ">
                            <div class="widget-content widget-content-area ">
                                
                            
   
                    </div>
                </div>
            </div>
        </div>
</div>





<div class="tab-pane fade active show" id="rounded-circle-pills-icon-complete" role="tabpanel" aria-labelledby="rounded-circle-pills-icon-complete-tab">



    <div class="row " id="cancel-row">



        <div  style="display:block;" id="mapTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h6 class="value">Templet Settings</h6>
                        </div>
                        

                    </div>


                    <div class="w-content">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row layout-spacing ">
                                <div class="col-md-6 mt-2">
                                   
                                   
                                    <label for="emailTempletName">Name</label>
                                    <input type="text" class="form-control" id="emailTempletName" placeholder="Name ">
             
                                    
                                </div>
                                <div class="col-md-6 mt-2">

                                  <!--  <label for="formGroupExampleInput">Example label</label>
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input"> -->

                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div style="display:none" class="w-progress-stats">    
                        <div class="">
                            <div class="w-lic-count">
                                <p>1\∞</p>
                            </div>
                        </div>                
                        <div class="progress">
                            <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        <div class="">
                            <div class="w-icon">
                                <p>0%</p>  
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>





        <div  style="" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h6 class="value">Templet Body</h6>
                        </div>
                        

                               

                    </div>


                    <div class="w-content">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="min-height:400px; margin-bottom:3%; padding-bottom:30px;">
                            <div id="email_templet" style="width:100%;" class="">
                               
                            </div>
                        </div>
                        
                    </div>


                </div>
            </div>
        </div>


    </div>
   



</div>


</div>