import { Component, Input,OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionBase } from './forms/_question_controltype/question-base';
import { QuestionControlService } from './forms/_service/question-control.service';
import { QuestionService } from './forms/_service/question.service';
import * as XLSX from 'xlsx';
// Page Defined Services

import { FormBuilderService } from '../../../../../_services/modules/geomap/forms/form-builder.service';
// Page Level Imports
import { FormEntryControlService } from '../../../../../_services/modules/geomap/forms/form-entry-control.service';
import { NiscApiService } from '../../../../../_services/third-party/nisc/nisc-api.service';

//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-form-entry-details',
  templateUrl: './form-entry-details.component.html',
  styleUrls: ['./form-entry-details.component.css']
})
export class FormEntryDetailsComponent implements OnInit {
 // Global Settings
 private globalSetting: [] = globalSettings;
currFormLayout: any;

entryData: any = {entryData: null};
questions$!: Observable<QuestionBase<any>[][][]>;
thisservice: any;

keyed_fields: any;
packageTables: any;

//Form Settings
displayForm: boolean = false;
formSettings: any;


globalExportTable: any = [];
globalExportTableHeaders: any = [];



 constructor(private qcs: QuestionControlService,private service: QuestionService,private route: ActivatedRoute, private router: Router,private http: HttpClient,
  private formbuilderservice: FormBuilderService,private formEntryControlService: FormEntryControlService,private niscApiService: NiscApiService) {

  }
  ngOnDestroy() {
    window.location.reload();
  }


  ngOnInit(): void {
    this.http.get(''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformdetails&formkey='+this.route.snapshot.paramMap.get('form_id'),{}).subscribe(async (resp: any) => {

      console.log(resp);

      var TempArray: any= [];
      var rowCount = 0;
      var arraylayout = resp.form_layout;
      console.warn(resp.form_layout);
      await arraylayout.forEach(async (row: any)=>{
          TempArray.push(row);

        rowCount++;
        if(rowCount >= arraylayout.length){
          resp.form_layout = TempArray;
          console.warn(resp.form_layout);
          this.questions$ =  this.service.getQuestions(resp);
          this.keyed_fields = resp['form_keyed_elements'];
          this.formSettings = resp['form_settings'];
          this.formSettings['entry_id'] = this.route.snapshot.paramMap.get('entry_id');
          this.formSettings['form_type'] = resp['form_type'];
          this.formSettings['form_headers'] = resp['form_headers']
          this.formSettings['complete_page_layout'] = resp['complete_page_layout'];
          this.getEntryData();
          //this.displayForm = true;
        }
      });

    });

  }


getEntryData():  void{
  this.formEntryControlService.getFormEntry('',this.route.snapshot.paramMap.get('entry_id'),"none").subscribe(async(entryData)=>{
  //console.log(entryData);
   this.entryData = entryData;
   this.formSettings['form_files_count'] = Object.values(entryData['form_files']).length || 0;
   this.formSettings['poly_type'] = entryData['form_longlat']['poly_type'] || 'n/a';
    this.displayForm = true;
  });
}



  NiscProv(): void{

    var fname = '';
    var lname = '';
    var dname = '';
    var address = '';
    var city = '';
    var state = '';
    var zip = '';


    JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('form_id')!)!)['form_keyed_elements'].forEach((keyvalue)=>{

      if(keyvalue['name'] == 'keyed_firstname'){
        fname = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
      if(keyvalue['name'] == 'keyed_lastname'){
        lname = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
      if(keyvalue['name'] == 'keyed_address'){
        address = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
      if(keyvalue['name'] == 'keyed_city'){
        city = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
      if(keyvalue['name'] == 'keyed_state'){
        state = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
      if(keyvalue['name'] == 'keyed_zip'){
        zip = JSON.parse(window.sessionStorage.getItem(this.route.snapshot.paramMap.get('entry_id')!)!)['form_data'][keyvalue['id']];
      }
    });


  var dname = fname+','+lname;
   var tempNiscData: any =     {
   firstName: ""+fname+"",
   lastName: ""+lname+"",
   displayName: ""+dname+"",
   salutation: "",
   middleName: "",
   generation: "",
   driverLicenseStateIssued: "",
   address: {
   lineOne:""+address+"",
   city: ""+city+"",
   state: ""+state+"",
   zipCode: ""+zip+"",
   zip4: "",
   addressFormat: "US",
   deliverable: "true",
   certifyAddress: "false",
   plus2: "0"

   }

  }

console.log(tempNiscData);
this.niscApiService.createNiscSubscriber(tempNiscData).subscribe((testing)=>{
console.log(testing);

});
}


}
