import { Component, OnInit,  AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import autoComplete from '@tarekraafat/autocomplete.js'
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';
import {FormControl, FormArray,FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';


import {FormControlService} from '../../../../_services/modules/geomap/forms/form-control.service'
import {FormEntryControlService} from '../../../../_services/modules/geomap/forms/form-entry-control.service'


// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';


import {GeneralEditorService} from './report_filter_editors/general-editor/general-editor.service'
import {DateRangeEditorService} from './report_filter_editors/date-range-editor/date-range-editor.service'

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.css']
})
export class ReportBuilderComponent implements OnInit, AfterViewInit {
  matcher = new MyErrorStateMatcher();


  reportData: any = {
    report_name: new FormControl('',[Validators.required]),
    report_type: new FormControl('',[Validators.required])
  };


  sortablesElements: any = [];


  //Init Form Data
  formData: any ={};
  formEntryData: any ={};





  autoCompleteJS: any;
  avalibleColumns: any = [
  ];
  selectColumns: any = [];
  selectedColumnLayout: any = [];

  showTableData = true;
  dataRows: any = [];



  autoCompleteFilterJS: any;
  selectedFilters: any = [];
  filterIDCount = 0;

  constructor(private formControlService:FormControlService, private route: ActivatedRoute, private formEntryControlService: FormEntryControlService,  private http: HttpClient,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private generalEditorService:GeneralEditorService,
    private dateRangeEditorService:DateRangeEditorService
    ) { }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  //this.customcssservice.load_table_style_handsontable();
  this.customcssservice.load_switches_css();
  }

  ngAfterViewInit(): void {
    //Setup AutoComplete
    // Defines reverence to This for use in Selection funtion that is out of scope;

    this.initFormData();




  var tempElemToolbox = document.querySelectorAll('[id=testNewDrag]');

  tempElemToolbox.forEach((element: any)=>{
    var temp: any = new Sortable(element, {
      group: {
          name: 'shared',
          pull:true
      },
      dataIdAttr: 'data-id',
      //scroll: true, // Enable the plugin. Can be HTMLElement.
     // forceAutoscrollFallback: false, // force autoscroll plugin to enable even when native browser autoscroll is available
     // scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
     // scrollSpeed: 10, // px, speed of the scrolling
    //  bubbleScroll: true,// apply autoscroll to all parent elements, allowing for easier movement
      animation: 100,

   onChange: (/**Event*/evt) =>  {
    console.log(  this.sortablesElements[0].toArray());
    this.selectedColumnLayout = this.sortablesElements[0].toArray();
    this.sortheader(this.selectColumns, this.selectedColumnLayout);
   //evt.newIndex // most likely why this event is used is to get the dragging element's current index
     //var element = document.getElementById('formBody');
     //this.formLayoutdata = htmlParser(element!.innerHTML);
     //this.formhtmltojsonService.convertjsontoinsightsjson( this.formLayoutdata).subscribe(()=>{
      ///
      // });
     //console.log(this.formLayoutdata);
     // same properties as onEnd
   },
  });
  //temp.mount(new AutoScroll());

  this.sortablesElements.push(temp);

   });

  }






////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


initFormData(): void{



  this.formControlService.getFormDetails('',this.route.snapshot.paramMap.get('form_id')).subscribe((returnFormData)=>{


    this.formData = returnFormData;

    this.avalibleColumns = returnFormData['form_headers'];

    if(this.avalibleColumns.find(x => x.id === 'form_submit_datetime') == undefined){
      console.log("added");
      this.avalibleColumns.push({id:"form_submit_datetime",name:"Date", filter_parent:'', data_type:'date'});
    }
    if(this.formData['form_type'] == 1){
        if(this.avalibleColumns.find(x => x.id === 'dlStatus') == undefined){
          console.log("added");
          this.avalibleColumns.push({id:"dlStatus",name:"Download Speed", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'ulStatus') == undefined){
          this.avalibleColumns.push({id:"ulStatus",name:"Upload Speed", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'pingStatus') == undefined){
          this.avalibleColumns.push({id:"pingStatus",name:"Ping Result", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'jitterStatus') == undefined){
          this.avalibleColumns.push({id:"jitterStatus",name:"Jitter Result", filter_parent:'speedtest_data.', data_type:'int'});
        }
      }

    if(this.formData['form_longlat'] !== undefined){
      if(this.avalibleColumns.find(x => x.id === 'poly_loc_id') == undefined){
        console.log("added");
        this.avalibleColumns.push({id:"poly_loc_id",name:"Polygon Location", filter_parent:'form_longlat.', data_type:'polygonid'});
      }
      if(this.avalibleColumns.find(x => x.id === 'lat') == undefined){
        console.log("added");
        this.avalibleColumns.push({id:"long",name:"Longitude", filter_parent:'form_longlat.', data_type:'long'});
      }
      if(this.avalibleColumns.find(x => x.id === 'long') == undefined){
        console.log("added");
        this.avalibleColumns.push({id:"lat",name:"Latitude", filter_parent:'form_longlat.', data_type:'lat'});
      }
    }


    this.initFormEntryData([]);
    this.initAutoSelect();
    this.initAutoSelectFilter();


  });


}



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


initFormEntryData(filters: any): void{

  this.dataRows =  [];

  this.formEntryControlService.getFormEntriesWithFilter('',this.route.snapshot.paramMap.get('form_id'),filters,20,'none').subscribe((formEntryData)=>{

    this.formEntryData = formEntryData;
    console.log(formEntryData);

    formEntryData.forEach(async (data)=> {
      // Add Date Data to Entry
      var d = new Date(data['form_submit_datetime']);
      data['form_data']['form_submit_datetime'] = d.toLocaleString('en-US', {timeZone: 'America/New_York'});

     // Add Speedtest Data to Entry
      console.log(data['speedtest_data'])
      if(data['speedtest_data'] !== undefined){

        if(this.avalibleColumns.find(x => x.id === 'dlStatus') == undefined){
          console.log("added");
          this.avalibleColumns.push({id:"dlStatus",name:"Download Speed", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'ulStatus') == undefined){
          this.avalibleColumns.push({id:"ulStatus",name:"Upload Speed", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'pingStatus') == undefined){
          this.avalibleColumns.push({id:"pingStatus",name:"Ping Result", filter_parent:'speedtest_data.', data_type:'int'});
        }
        if(this.avalibleColumns.find(x => x.id === 'jitterStatus') == undefined){
          this.avalibleColumns.push({id:"jitterStatus",name:"Jitter Result", filter_parent:'speedtest_data.', data_type:'int'});
        }
        data['form_data']['dlStatus'] = parseFloat(data['speedtest_data']['dlStatus']);
        data['form_data']['ulStatus'] = parseFloat(data['speedtest_data']['ulStatus']);
        data['form_data']['pingStatus'] = parseFloat(data['speedtest_data']['pingStatus']);
        data['form_data']['jitterStatus'] = parseFloat(data['speedtest_data']['jitterStatus']);

      }

      // Add Form Lat Long Data to Entry
      if(data['form_longlat'] !== undefined){

        if(this.avalibleColumns.find(x => x.id === 'poly_loc_id') == undefined){
          console.log("added");
          this.avalibleColumns.push({id:"poly_loc_id",name:"Polygon Location", filter_parent:'form_longlat.', data_type:'polygonid'});
        }

        data['form_data']['poly_loc_id'] = data['form_longlat']['poly_loc_id'];

        if(this.avalibleColumns.find(x => x.id === 'lat') == undefined){
          console.log("added");
          this.avalibleColumns.push({id:"long",name:"Longitude", filter_parent:'form_longlat.', data_type:'long'});
        }
        data['form_data']['lat'] = data['form_longlat']['lat'];

        if(this.avalibleColumns.find(x => x.id === 'long') == undefined){
          console.log("added");
          this.avalibleColumns.push({id:"lat",name:"Latitude", filter_parent:'form_longlat.', data_type:'long'});
        }
        data['form_data']['long'] = data['form_longlat']['long'];

      }
      // Push Object of Data to Rows Array
      this.dataRows.push(data['form_data']);
    });

  });

}




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////







  addColumnToList(columnData: any): void{
    this.selectColumns.push(columnData);
    this.autoCompleteJS.unInit();
    this.initAutoSelect();
    //Save the New Layout
    setTimeout(()=>{this.selectedColumnLayout = this.sortablesElements[0].toArray(); console.log(this.sortablesElements[0].toArray());},200);
  }

  deleteColumn(col_id: string): void{
    this.selectColumns = this.selectColumns.filter(data => data.id != ''+col_id+'');
    this.autoCompleteJS.unInit();
    this.initAutoSelect();
    //Save the New Layout
    setTimeout(()=>{this.selectedColumnLayout = this.sortablesElements[0].toArray(); console.log(this.sortablesElements[0].toArray());},200);
  }


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////







async addColumnToFilter(columnData: any): Promise<void>{

  await this.selectedFilters.push(Object.assign({},columnData));
  this.autoCompleteFilterJS.unInit();
  this.initAutoSelectFilter();
  this.initFormEntryData(this.selectedFilters);
  //Save the New Layout
 // setTimeout(()=>{this.selectedColumnLayout = this.sortablesElements[0].toArray(); console.log(this.sortablesElements[0].toArray()); this.sortheader(this.selectColumns, this.selectedColumnLayout)},200);
}


deleteFilter(filterIndex: number): void{
  this.selectedFilters.splice(filterIndex, 1);
  this.autoCompleteFilterJS.unInit();
  this.initAutoSelectFilter();
  this.initFormEntryData(this.selectedFilters);
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



  getDiff(ava: any, sel: any): any{

    const a = ava;
    const b = sel;

    // A comparer used to determine if two entries are equal.
const isSameUser = (a, b) => a.id === b.id;

// Get items that only occur in the left array,
// using the compareFunction to determine equality.
const onlyInLeft = (left, right, compareFunction) =>
  left.filter(leftValue =>
    !right.some(rightValue =>
      compareFunction(leftValue, rightValue)));

const onlyInA = onlyInLeft(a, b, isSameUser);
const onlyInB = onlyInLeft(b, a, isSameUser);

return [...onlyInA, ...onlyInB];
  }


getSortedDataRow(data:any): Observable<any>{

  return new Observable(observer => {

    var returnArray: any = [];

    var count = 0;

    this.selectColumns.forEach(async (colume: any)=>{

      returnArray.push(data[''+colume['id']+'']);
      count++;
      if(count >= this.selectColumns.length){
        observer.next(returnArray);
      }
    });



});


}



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




sortheader(currheader:any, currSort: any): void{
  this.showTableData = false;
  var tempHeader = currheader;

  this.selectColumns = [];
  var count = 0;
  currSort.forEach((sortID)=>{

    this.selectColumns.push(currheader.find(x => x.id === ''+sortID+''));
    count++;
    if(count >= this.selectColumns.length){
      this.showTableData = true;
    }
  })
}




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




sortRows(currheader:any, currSort: any): void{

  var tempRow = currheader;

  this.selectColumns = [];

  currSort.forEach((sortID)=>{

    this.selectColumns.push(currheader.find(x => x.id === ''+sortID+''));

  })




}










////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

  initAutoSelect(): void{
    var self = this;
    this.autoCompleteJS = new autoComplete({
      selector: "#autoComplete",
      placeHolder: "Search Columns",
      data: {
          src: this.getDiff(this.avalibleColumns,this.selectColumns),
          keys:['name'],
          cache: true
      },
      resultsList: {
          element: (list, data) => {
              if (!data.results.length) {
                  // Create "No Results" message element
                  const message = document.createElement("div");
                  // Add class to the created element
                  message.setAttribute("class", "no_result");
                  // Add message text content
                  message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                  // Append message element to the results list
                  list.prepend(message);
              }
          },
          noResults: true,
      },
      resultItem: {
          highlight: {
              render: true
          }
      },
      events: {
          input: {
              focus() {

              if (self.autoCompleteJS.input.value.length) self.autoCompleteJS.start();
              },
              selection(event) {

              const feedback = event.detail;

              self.addColumnToList(feedback.selection.value);
              // Prepare User's Selected Value
              const selection = '';

              // Replace Input value with the selected value
              self.autoCompleteJS.input.value = selection;
              },
          },
      },
  });

  }



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

initAutoSelectFilter(): void{
  var self = this;
  this.autoCompleteFilterJS = new autoComplete({
    selector: "#autoCompleteFilter",
    placeHolder: "Search Columns",
    data: {
        src: this.avalibleColumns,
        keys:['name'],
        cache: true
    },
    resultsList: {
        element: (list, data) => {
            if (!data.results.length) {
                // Create "No Results" message element
                const message = document.createElement("div");
                // Add class to the created element
                message.setAttribute("class", "no_result");
                // Add message text content
                message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                // Append message element to the results list
                list.prepend(message);
            }
        },
        noResults: true,
    },
    resultItem: {
        highlight: {
            render: true
        }
    },
    events: {
        input: {
            focus() {

            if (self.autoCompleteJS.input.value.length) self.autoCompleteJS.start();
            },
            selection(event) {

            const feedback = event.detail;

            self.findFilterType(feedback.selection.value);
            //self.addColumnToFilter(feedback.selection.value);
            // Prepare User's Selected Value
            const selection = '';

            // Replace Input value with the selected value
            self.autoCompleteJS.input.value = selection;
            },
        },
    },
});
}






////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


findFilterType(initFilterData: any): void{
var count = 0;
this.formData['form_keyed_elements'].forEach(async (keyed_field: any) => {
  if(keyed_field['id'] == initFilterData['id']){
    this.addGeneralFilter(initFilterData);
  }
  count++;
  if(count >= this.formData['form_keyed_elements'].length){
    if(initFilterData['data_type'] == 'date'){
      this.addDateRangeFilter(initFilterData);
    }
    else{
      this.addGeneralFilter(initFilterData);
    }
  }
});



}




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



addGeneralFilter(info: any): void{
 this.generalEditorService.filter_Editor(info).subscribe(async (EditorData)=>{
  this.addColumnToFilter(EditorData);
 });
}



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



addDateRangeFilter(info: any): void{
  this.dateRangeEditorService.filter_Editor(info).subscribe(async (EditorData)=>{
    this.addColumnToFilter(EditorData);

    //this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
  });
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



addPolygonFilter(info: any): void{
  this.dateRangeEditorService.filter_Editor(info).subscribe(async (EditorData)=>{
    this.addColumnToFilter(EditorData);

    //this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
  });
}





////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



onSubmit(): void {
  //const { username, password } = this.form;
  const headers = { 'content-type': 'application/json'}
  //this.form.poly_id = this.route.snapshot.paramMap.get('poly_id');
  var tempJson = {
    report_form_id: this.route.snapshot.paramMap.get('form_id'),
    report_name:this.reportData.report_name.value,
    report_type: this.reportData.report_type.value,
    report_type_info:{

      report_Table_Header_Layout: this.selectColumns,
      report_table_Header_Sort_Tool_Layout: this.selectedColumnLayout

    },
    report_filters:this.selectedFilters,
    report_enable: true
  }

  const body=JSON.stringify(tempJson);
    this.http.post('https://api.cloud.myininsights.com/geofi/reports/?api=createreport',body,{'headers':headers}).subscribe(resp => {
    console.log(resp);
    if(!resp['error']){
    Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
      //this.router.navigate(['/geomap/maps/listforms'])
    });
  }
  else{
    Swal.fire('Failed', 'Error!', 'error');
  }
    //this.rerender();
});


}




}
