<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listmaps']">Maps</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Map</li>
        </ol>
    </nav>
    </div>



    <div class="row layout-spacing">
      <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
          <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                    </svg></a>
              </li>

              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="f.ngSubmit.emit()" matTooltip="Save Map"  [matTooltipPosition]="'right'">                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/> </svg> </a>
              </li>
          </ul>
      </div>
  </div>
  <form name="form" #validform="ngForm" (ngSubmit)=" onSubmit()" #f="ngForm">
<div class="row layout-spacing">


        <div  style="" id="formTable" class="col-xl-7 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">New Map</h4>
                        </div>


                    </div>


                    <div class="w-content">

                        <div class="row g-3" style="min-height:400px; margin-bottom:3%;">



                      
                                    <div class="col-md-12">
                                        <label *ngIf="map_name.hasError('required')" for="inputName1" style="color:red">Name* (Required)</label>
                                        <label *ngIf="!map_name.hasError('required')" for="inputName1" >Name</label>
                                        <input type="text" [(ngModel)]="form.map_name" required #map_name="ngModel"  name="map_name" class="form-control" id="inputName1" placeholder="Map Name">
                                    </div>
                                    <div class="col-md-6">
                                        <label *ngIf="map_lat.hasError('required')" for="map_lat" style="color:red">Latitude* (Required)</label>
                                        <label *ngIf="!map_lat.hasError('required')" for="map_lat" >Latitude</label>
                                        <input type="text" [(ngModel)]="form.map_lat" required #map_lat="ngModel"  name="map_lat" class="form-control" id="map_lat" placeholder="Lat">
                                    </div>
                                    <div class="col-md-6">
                                        <label *ngIf="map_long.hasError('required')" for="map_long" style="color:red">Longitude* (Required)</label>
                                        <label *ngIf="!map_long.hasError('required')" for="map_long" >Longitude</label>
                                        <input type="text" [(ngModel)]="form.map_long" required #map_long="ngModel" name="map_long" class="form-control" id="map_long" placeholder="Long">
                                    </div>
                                    <div class="col-md-6">
                                        <label *ngIf="map_zoom.hasError('required')" for="map_zoom" style="color:red">Default Zoom Level* (Required)</label>
                                        <label *ngIf="!map_zoom.hasError('required')" for="map_zoom" >Default Zoom Level*</label>
                                        <input type="text" [(ngModel)]="form.map_zoom" required #map_zoom="ngModel"  name="map_zoom" class="form-control" id="inputZoom1" placeholder="Zoom">
                                    </div>
                                    <div class="col-md-6">
                                        <label *ngIf="map_addresslist_id.hasError('required')" for="inputName1" style="color:red">Address List* (Required)</label>
                                        <label *ngIf="!map_addresslist_id.hasError('required')" for="inputName1" >Address List*</label>
                                        <select class="form-control" [(ngModel)]="form.map_addresslist_id" required #map_addresslist_id="ngModel"  id="form_addresslist_id" name="form_addresslist_id">
                                            <option value="" selected>None</option>
                                            <option *ngFor="let AddressList of addressListArray" [value]="AddressList._id" >
                                                {{AddressList.addresslist_name}}
                                              </option>
                                    </select>
                                    </div>
                                    <div class="col-md-12">
                                        <label *ngIf="map_enable.hasError('required')" for="map_enable" style="color:red">Pubished* (Required)</label>
                                        <label *ngIf="!map_enable.hasError('required')" for="map_enable" >Pubished*</label>
                                        <select class="form-control" [(ngModel)]="form.map_enable" required #map_enable="ngModel"  id="map_enable" name="map_enable">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>

                                    </div>

                                    <div class="col-md-6">
                                        <label *ngIf="map_inputFormLink.hasError('required')" for="map_enable" style="color:red">Default Signup Text* (Required)</label>
                                        <label *ngIf="!map_inputFormLink.hasError('required')" for="map_enable" >Default Signup Text*</label>

                                        <input type="text" [(ngModel)]="form.map_inputFormLink" required #map_inputFormLink="ngModel"  class="form-control" id="map_inputFormLink" name="map_inputFormLink" placeholder="Signup Today?">
                                    </div>
                                        <div class=" col-md-6">
                                            <label *ngIf="map_inputDefaultForm.hasError('required')" for="map_inputDefaultForm" style="color:red">Default Form* (Required)</label>
                                            <label *ngIf="!map_inputDefaultForm.hasError('required')" for="map_inputDefaultForm" >Default Form*</label>

                                        <select class="form-control" [(ngModel)]="form.map_inputDefaultForm" required #map_inputDefaultForm="ngModel" id="map_inputDefaultForm" name="map_inputDefaultForm">

                                        <option value="">Select a Form</option>
                                        <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                                            {{FormsList.form_name}}
                                        </option>
                                    </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label *ngIf="map_default_interest_form_text.hasError('required')" for="map_enable" style="color:red">Interest Text* (Required)</label>
                                        <label *ngIf="!map_default_interest_form_text.hasError('required')" for="map_enable" >Interest Text*</label>
                                        <input type="text" [(ngModel)]="form.map_default_interest_form_text" required #map_default_interest_form_text="ngModel"  class="form-control" id="map_default_interest_form_text" name="map_default_interest_form_text"  placeholder="Our Interest Signup? ">
                                    </div>
                                        <div class="col-md-6">
                                            <label *ngIf="map_default_interest_form.hasError('required')" for="map_enable" style="color:red">Default Interest Form* (Required)</label>
                                            <label *ngIf="!map_default_interest_form.hasError('required')" for="map_enable" >Default Interest Form*</label>
                                        <select class="form-control" [(ngModel)]="form.map_default_interest_form" required #map_default_interest_form="ngModel" id="map_default_interest_form" name="map_default_interest_form">

                                        <option>Select a Form</option>
                                        <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                                            {{FormsList.form_name}}
                                        </option>
                                    </select>
                                    </div>

                                




                        </div>

                    </div>


                </div>
            </div>
        </div>
















    <!-- Content -->


    <div class="col-xl-5 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">

        <div class="skills layout-spacing ">
            <div class="widget-content widget-content-area">


            <div id="map" style="width:100%; min-height:650px; height:100%;"></div>


            </div>
        </div>


    </div>

</div>







<div id="toggleAccordion" class="accordion">

  <div class="row layout-top-spacing" id="cancel-row">

  <div class="card">
    <div class="card-header" id="headingOne1">
      <section class="mb-0 mt-0">
        <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionOne" aria-expanded="false" aria-controls="defaultAccordionOne">
          Optional Settings  <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
        </div>
      </section>
    </div>

    <div id="defaultAccordionOne" class="collapse" aria-labelledby="headingOne1" data-bs-parent="#toggleAccordion" style="">
      <div class="card-body">


        <div class="row g-3" style="min-height:400px; margin-bottom:3%;">




          <div class="row g-3">
              <div class="col-md-12">
                  <label *ngIf="map_polygon_hover_text.hasError('required')" for="map_polygon_hover_text" style="color:red">Polygon Hover Text* (Required)</label>
                  <label *ngIf="!map_name.hasError('required')" for="map_polygon_hover_text" >Polygon Hover Text</label>
                  <input type="text" [(ngModel)]="form.map_polygon_hover_text" required #map_polygon_hover_text="ngModel"  name="map_polygon_hover_text" class="form-control" id="inputName1" placeholder="Polygon Hover Text">
              </div>

              <div class="col-md-6">
                <label *ngIf="map_signup_marker_show_polygon_name.hasError('required')" for="map_signup_marker_show_polygon_name" style="color:red">signup_marker_show_polygon_name* (Required)</label>
                <label *ngIf="!map_signup_marker_show_polygon_name.hasError('required')" for="map_signup_marker_show_polygon_name" >signup_marker_show_polygon_name*</label>
                <select class="form-control" [(ngModel)]="form.map_signup_marker_show_polygon_name" required #map_signup_marker_show_polygon_name="ngModel"  id="map_signup_marker_show_polygon_name" name="map_signup_marker_show_polygon_name">
                    <option value="true" selected>True</option>
                    <option value="false" >False</option>
              </select>
              </div>

              <div class="col-md-6">
                  <label *ngIf="map_signup_polygon_text.hasError('required')" for="ap_signup_polygon_text" style="color:red">map_signup_polygon_text* (Required)</label>
                  <label *ngIf="!map_signup_polygon_text.hasError('required')" for="ap_signup_polygon_text" >map_signup_polygon_text</label>
                  <input type="text" [(ngModel)]="form.map_signup_polygon_text" required #map_signup_polygon_text="ngModel" name="map_signup_polygon_text" class="form-control" id="map_signup_polygon_text" placeholder="Long">
              </div>

              <div class="col-md-6">
                <label *ngIf="map_signup_marker_show_polygon_name.hasError('required')" for="map_signup_marker_show_polygon_name" style="color:red">signup_marker_show_polygon_name* (Required)</label>
                <label *ngIf="!map_signup_marker_show_polygon_name.hasError('required')" for="map_signup_marker_show_polygon_name" >signup_marker_show_polygon_name*</label>
                <select class="form-control" [(ngModel)]="form.map_signup_marker_show_polygon_name" required #map_signup_marker_show_polygon_name="ngModel"  id="map_signup_marker_show_polygon_name" name="map_signup_marker_show_polygon_name">
                    <option value="true" selected>True</option>
                    <option value="false" >False</option>
              </select>
              </div>

              <div class="col-md-6">
                  <label *ngIf="map_address_inside_poly_marker_color.hasError('required')" for="map_address_inside_poly_marker_color" style="color:red">map_address_inside_poly_marker_color* (Required)</label>
                  <label *ngIf="!map_address_inside_poly_marker_color.hasError('required')" for="map_address_inside_poly_marker_color" >map_address_inside_poly_marker_color*</label>
                  <select class="form-control" [(ngModel)]="form.map_address_inside_poly_marker_color" required #map_address_inside_poly_marker_color="ngModel"  id="map_address_inside_poly_marker_color" name="map_address_inside_poly_marker_color">
                      <option value="blue" selected>Blue</option>
                      <option value="green">Green</option>
                      <option value="red">Red</option>
                      <option value="orange">Orange</option>
              </select>
              </div>

              <div class="col-md-6">
                <label *ngIf="map_address_outside_poly_marker_color.hasError('required')" for="map_address_outside_poly_marker_color" style="color:red">map_address_outside_poly_marker_color* (Required)</label>
                <label *ngIf="!map_address_outside_poly_marker_color.hasError('required')" for="map_address_outside_poly_marker_color" >map_address_outside_poly_marker_color*</label>
                <select class="form-control" [(ngModel)]="form.map_address_outside_poly_marker_color" required #map_address_outside_poly_marker_color="ngModel"  id="map_address_outside_poly_marker_color" name="map_address_outside_poly_marker_color">
                    <option value="blue" selected>Blue</option>
                    <option value="green">Green</option>
                    <option value="red">Red</option>
                    <option value="orange">Orange</option>
            </select>
            </div>


              <div class=" col-md-6">
                <label *ngIf="map_address_not_found_link_type.hasError('required')" for="map_address_not_found_link_type" style="color:red">map_address_not_found_link_type* (Required)</label>
                <label *ngIf="!map_address_not_found_link_type.hasError('required')" for="map_address_not_found_link_type" >map_address_not_found_link_type*</label>

                <select class="form-control" [(ngModel)]="form.map_address_not_found_link_type" required #map_address_not_found_link_type="ngModel" id="map_address_not_found_link_type" name="map_address_not_found_link_type">
                <option value="form" selected>Form</option>
                <option value="url" >Web URL</option>
                </select>
              </div>


           <!-- If map_address_not_found_link_type is Equ to Form display Forms and Not Textbox-->

            <!-- IF Form -->
            <div *ngIf="form.map_address_not_found_link_type == 'form'" class=" col-md-6">
              <label *ngIf="map_address_not_found_link.hasError('required')" for="map_address_not_found_link" style="color:red">map_address_not_found_link* (Required)</label>
              <label *ngIf="!map_address_not_found_link.hasError('required')" for="map_address_not_found_link" >map_address_not_found_link*</label>

          <select class="form-control" [(ngModel)]="form.map_address_not_found_link" required #map_address_not_found_link="ngModel" id="map_address_not_found_link" name="map_address_not_found_link">

          <option value="">Select a Form</option>
          <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
              {{FormsList.form_name}}
          </option>
          </select>
          </div>
          <!-- END IF Form -->

         <!-- IF URL -->
         <div *ngIf="form.map_address_not_found_link_type == 'url'" class="col-md-6">
          <label *ngIf="map_address_not_found_link.hasError('required')" for="map_address_not_found_link" style="color:red">map_address_not_found_link* (Required)</label>
          <label *ngIf="!map_address_not_found_link.hasError('required')" for="map_address_not_found_link" >map_address_not_found_link*</label>

          <input type="text" [(ngModel)]="form.map_address_not_found_link" required #map_address_not_found_link="ngModel"  class="form-control" id="map_address_not_found_link" name="map_address_not_found_link" placeholder="map_address_not_found_link">
        </div>
         <!-- END IF URL -->

         <!-- END If map_address_not_found_link_type is Equ to Form display Forms and Not Textbox-->



              <div class="col-md-6">
                <label *ngIf="map_address_not_found_link_text.hasError('required')" for="map_address_not_found_link_text" style="color:red">map_address_not_found_link_text* (Required)</label>
                <label *ngIf="!map_address_not_found_link_text.hasError('required')" for="map_address_not_found_link_text" >map_address_not_found_link_text*</label>
                <input type="text" [(ngModel)]="form.map_address_not_found_link_text" required #map_address_not_found_link_text="ngModel"  class="form-control" id="map_address_not_found_link_text" name="map_address_not_found_link_text"  placeholder="Our Interest Signup? ">
              </div>




              <div class="col-md-6">
                <label *ngIf="map_address_not_found_text.hasError('required')" for="map_address_not_found_text" style="color:red">map_address_not_found_text* (Required)</label>
                <label *ngIf="!map_address_not_found_text.hasError('required')" for="map_address_not_found_text" >map_address_not_found_text*</label>
                <input type="text" [(ngModel)]="form.map_address_not_found_text" required #map_address_not_found_text="ngModel"  class="form-control" id="map_address_not_found_text" name="map_address_not_found_text"  placeholder="Our Interest Signup? ">
              </div>


              <div class="col-md-6">
                <label *ngIf="map_address_outside_marker_text.hasError('required')" for="map_address_outside_marker_text" style="color:red">map_address_outside_marker_text* (Required)</label>
                <label *ngIf="!map_address_outside_marker_text.hasError('required')" for="map_address_outside_marker_text" >map_address_outside_marker_text*</label>
                <input type="text" [(ngModel)]="form.map_address_outside_marker_text" required #map_address_outside_marker_text="ngModel"  class="form-control" id="map_address_outside_marker_text" name="map_address_outside_marker_text"  placeholder="Our Interest Signup? ">
              </div>

              <div class="col-md-6">
                <label *ngIf="map_address_outside_marker_link_text.hasError('required')" for="map_address_outside_marker_link_text" style="color:red">map_address_outside_marker_link_text* (Required)</label>
                <label *ngIf="!map_address_outside_marker_link_text.hasError('required')" for="map_address_outside_marker_link_text" >map_address_outside_marker_link_text*</label>
                <input type="text" [(ngModel)]="form.map_address_outside_marker_link_text" required #map_address_outside_marker_link_text="ngModel"  class="form-control" id="map_address_outside_marker_link_text" name="map_address_outside_marker_link_text"  placeholder="Our Interest Signup? ">
              </div>

              <div class="col-md-6">
                <label *ngIf="map_address_outside_marker_title.hasError('required')" for="map_address_outside_marker_title" style="color:red">map_address_outside_marker_title* (Required)</label>
                <label *ngIf="!map_address_outside_marker_title.hasError('required')" for="map_address_outside_marker_title" >map_address_outside_marker_title*</label>
                <input type="text" [(ngModel)]="form.map_address_outside_marker_title" required #map_address_outside_marker_title="ngModel"  class="form-control" id="map_address_outside_marker_title" name="map_address_outside_marker_title"  placeholder="Our Interest Signup? ">
              </div>

              <div class=" col-md-6">
                <label *ngIf="map_address_outside_marker_link_type.hasError('required')" for="map_address_outside_marker_link_type" style="color:red">map_address_outside_marker_link_type* (Required)</label>
                <label *ngIf="!map_address_outside_marker_link_type.hasError('required')" for="map_address_outside_marker_link_type" >map_address_outside_marker_link_type*</label>

                <select class="form-control" [(ngModel)]="form.map_address_outside_marker_link_type" required #map_address_outside_marker_link_type="ngModel" id="map_address_outside_marker_link_type" name="map_address_outside_marker_link_type">
                <option value="form" selected>Form</option>
                <option value="url" >Web URL</option>
                </select>
              </div>

            <!-- If map_address_outside_marker_link_type is Equ to Form display Forms and Not Textbox-->

            <!-- IF Form -->
            <div *ngIf="form.map_address_outside_marker_link_type == 'form'" class=" col-md-6">
                <label *ngIf="map_address_outside_marker_link.hasError('required')" for="map_address_outside_marker_link" style="color:red">map_address_outside_marker_link* (Required)</label>
                <label *ngIf="!map_address_outside_marker_link.hasError('required')" for="map_address_outside_marker_link" >map_address_outside_marker_link*</label>

            <select class="form-control" [(ngModel)]="form.map_address_outside_marker_link" required #map_address_outside_marker_link="ngModel" id="map_address_outside_marker_link" name="map_address_outside_marker_link">

            <option value="">Select a Form</option>
            <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                {{FormsList.form_name}}
            </option>
            </select>
            </div>
            <!-- IF Form -->

           <!-- IF URL -->
            <div *ngIf="form.map_address_outside_marker_link_type == 'url'"class="col-md-6">
              <label *ngIf="map_address_outside_marker_link.hasError('required')" for="map_address_outside_marker_link" style="color:red">map_address_outside_marker_link* (Required)</label>
              <label *ngIf="!map_address_outside_marker_link.hasError('required')" for="map_address_outside_marker_link" >map_address_outside_marker_link*</label>
              <input type="text" [(ngModel)]="form.map_address_outside_marker_link" required #map_address_outside_marker_link="ngModel"  class="form-control" id="map_address_outside_marker_link" name="map_address_outside_marker_link"  placeholder="Our Interest Signup? ">
            </div>
           <!-- IF URL -->

           <!-- If map_address_outside_marker_link_type is Equ to Form display Forms and Not Textbox-->

              <div class="col-md-6">
                <label *ngIf="map_address_external_search_toggle.hasError('required')" for="map_address_external_search_toggle" style="color:red">map_address_external_search_toggle* (Required)</label>
                <label *ngIf="!map_address_external_search_toggle.hasError('required')" for="map_address_external_search_toggle" >map_address_external_search_toggle*</label>
                <select class="form-control" [(ngModel)]="form.map_address_external_search_toggle" required #map_address_external_search_toggle="ngModel"  id="map_address_external_search_toggle" name="map_address_external_search_toggle">
                    <option value="google" selected>True</option>
                    <option value="false" >False</option>
              </select>
              </div>
          </div>




  </div>
<!-- Settings Here-->

      </div>
    </div>
  </div>
</div>



<div class="row layout-top-spacing" id="cancel-row">
  <div class="card">
    <div class="card-header" id="headingTwo1">
      <section class="mb-0 mt-0">
        <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionTwo" aria-expanded="false" aria-controls="defaultAccordionTwo">
          Optional Settings  <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
        </div>
      </section>
    </div>
    <div id="defaultAccordionTwo" class="collapse" aria-labelledby="headingTwo1" data-bs-parent="#toggleAccordion" style="">
        <div class="card-body">


<!-- Settings Here-->
        </div>
    </div>
  </div>



</div>

<div class="row layout-top-spacing" id="cancel-row">

  <div class="card">
    <div class="card-header" id="headingThree1">
      <section class="mb-0 mt-0">
        <div role="menu" class="collapsed" data-bs-toggle="collapse" data-bs-target="#defaultAccordionThree" aria-expanded="false" aria-controls="defaultAccordionThree">
          Optional Settings <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></div>
        </div>
      </section>
    </div>
    <div id="defaultAccordionThree" class="collapse" aria-labelledby="headingThree1" data-bs-parent="#toggleAccordion">
      <div class="card-body">
<!-- Settings Here-->
      </div>
    </div>
  </div>


</div>


</div>








</form>
