import {Component, Input, Output, OnInit,AfterViewInit,HostBinding,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionBase } from '../_question_controltype/question-base';
//import Global Setting Varitables
import * as globalSettings from '../../../../../../_settings/Globle_Settings.json';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-form-entry-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class FormEntryQuestionComponent implements AfterViewInit {
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;
  @Input() fileUploads!: any;
  @Input() formSettings!: any;
  //Conditonal Validation
  @Input() conditonalTargetValues!: any;
  @Output() conditonalTargetValuesChange = new EventEmitter<any>();
  @Output() validateConditionals = new EventEmitter<any>();
  @Output() onSuggest: EventEmitter<any> = new EventEmitter();

  get isValid() { return this.form.controls[this.question.key].valid; }
  get phoneVaild(){ return this.form.controls[this.question.key].errors == null }
  get emailVaild(){ return this.form.controls[this.question.key].errors == null}
// Establish the Default Global Parms.
private globalSetting: any = globalSettings;
 // get isValid() { return true; }
 //For Loading Text Block Html
 packageTables: any = {};
 autoCompleteJS: any;
 constructor(private http: HttpClient,private route: ActivatedRoute){}
 ngAfterViewInit(): void {




  console.log(this.question.options);
  if(this.question.type == 'paragraphbox'){

    document.getElementById(''+this.question.key+'')!.innerHTML = <string>this.question.html;
   }
  else if(this.question.type == 'htmlarea'){

    document.getElementById(''+this.question.key+'')!.innerHTML = <string>this.question.html;
   }
   else if(this.question.type == 'packagetable'){
    this.getPackageTable(this.formSettings['poly_type']);
   }
   else if(this.question.keyed_value == 'keyed_address'){

   }
   else if(this.question.keyed_value == 'keyed_city'){
    this.question.disabled = true;
   }
   else if(this.question.keyed_value == 'keyed_state'){
    this.question.disabled = true;
   }
   else if(this.question.keyed_value == 'keyed_zip'){
    this.question.disabled = true;
   }
   // Validate If Field has a Conditonal Requirement
   this.conditonalTargetValues[''+this.question.key+''] = this.question.value;
   this.validateConditionals.next(null);
}

fileChange(event: any): void {
console.log(event);
// get a reference to the file

if ((event.target.files[0].size / 1024 / 1024) > 5) {

  alert('File size exceeds 5 MiB');
  event.target.value = '';

} else {
  // Proceed further
  const file = event.target.files[0];
console.log('This file size is: ' + event.target.files[0].size / 1024 / 1024 + "MiB");
// encode the file using the FileReader API
const reader = new FileReader();
reader.onloadend = () => {

    // use a regex to remove data url part
    const base64String = reader.result

    // log to console
    // logs wL2dvYWwgbW9yZ...
    //console.log(base64String);
    //event.target.value=base64String;

    //
    this.fileUploads[''+event.target.id+''] = base64String;
    console.log(this.fileUploads);
}
reader.readAsDataURL(file);
}

}


getPackageTable(type: string){
  var tempType = '';
  if(type == 'fiber'){
    tempType = 'fiber';
  }
  else if(type == 'copper'){
    tempType = 'copper';
  }
  else{
    tempType = 'default';
  }
  this.http.get(''+this.globalSetting['api_url_console']+'/geofi/forms/packagetables?api=getpackagetable&package_table_id='+this.question.value,{}).subscribe(async (resp: any) => {
  this.onSuggest.emit([resp]);
  this.packageTables['headers'] = resp['package_table_headers'];
  this.packageTables['data'] = resp['package_table_rows'];

  });
}


check(): void{
  console.log(this.form.controls[this.question.key].errors!);
}


  conditionalValidation(element: any, type: string): void{
  console.log(element.target.getAttribute('ng-reflect-value'));
  if(type == 'radio'){
    this.conditonalTargetValues[''+this.question.key+''] = element.target.getAttribute('radio-value');
    this.validateConditionals.next(null);
  }
  else{
    this.conditonalTargetValues[''+this.question.key+''] = element.target.value;
    this.validateConditionals.next(null);
  }
  }

rowSelectRadio(row: any): void{
  if(row.target.parentElement.querySelectorAll("input[type=radio]").length > 1){
    row.target.querySelectorAll("input[type=radio]")[0].click();
  }
  else{
    row.target.parentElement.querySelectorAll("input[type=radio]")[0].click();
  }
}










}
