import { Component, OnInit } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-list-workflows',
  templateUrl: './list-workflows.component.html',
  styleUrls: ['./list-workflows.component.css']
})
export class ListWorkflowsComponent implements OnInit {
  // Global Settings
  private globalSetting: [] = globalSettings;



  displayTable = false;
  dtOptions: any = {};
  rows: any;
  constructor(private http: HttpClient,) { }

  ngOnInit(): void {




    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geomap?api=listmaps',  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;
        let polyTableLoader = document.getElementById('loadingTablePercent');
        polyTableLoader!.innerHTML = Math.round(percentage)+'%';
        console.log(percentage);
    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        this.rows = event.body;
       
        console.log(event.body);
        this.rows = event.body;
        this.displayTable = true;
      }
    
    })

    this.dtOptions = {
      "dom": 
    "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
    //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
    "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
    "<'table-responsive'tr>" +
    "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
    buttons: {
      buttons: [
          { extend: 'csv', 
            className: 'btn',
            title:"Maps",
            text: 'CSV',
            exportOptions: {
          columns: 'th:not(:last-child)'
          }
        },
          { extend: 'excel', 
            className: 'btn',
            title:"Maps",
            text: 'Excel',
              exportOptions: {
               columns: 'th:not(:last-child)'
                  }
          },
            { 
            extend: 'print', 
            className: 'btn',
            title:"Maps",
            exportOptions: {
              columns: 'th:not(:last-child)'
                 }
            }
      ]
  },
      "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "stripeClasses": [],
      "lengthMenu": [7, 10, 20, 50],
      "pageLength": 7 ,
      "processing": true,  
      //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
      responsive: true,
      "initComplete":function(settings, json) {
        let polyTable = document.getElementById('mapTable');
        polyTable!.style.display = 'block';
        let polyTableLoader = document.getElementById('polyTableLoader');
        polyTableLoader!.style.display = 'none';
      },
    };
  }

}
