<div class="form-group row col-12">
  <label for="input_label_edit" class="col-sm-3 col-form-label" style="color:black;">Label *</label>
  <div class="col-sm-9">
  <input type="text" data-lpignore="true" name="input_label_edit"  (change)="updateElement('label',$event);" class="form-control" id="input_label_edit" value="{{tempElementValues['input_label']}}" placeholder="">
  </div>
  </div>

  <div class="form-group row col-12">
    <label for="input_private_label_edit" class="col-sm-3 col-form-label" style="color:black;">Private Label *</label>
    <div class="col-sm-9">
    <input type="text" data-lpignore="true" name="input_private_label_edit"  (change)="updateElement('private_label',$event);" class="form-control" id="input_private_label_edit" value="{{tempElementValues['input_private_label']}}" placeholder="">
    </div>
    </div>

  <div class="form-group row col-12">
      <label for="input_package_table" class="col-sm-3 col-form-label" style="color:black;">Default Package Table *</label>
      <div class="col-sm-9">
      <select name="input_package_table" (change)="updateElement('default_package_table',$event);" class="form-control" id="input_package_table">
        <option *ngFor="let option of this.packageTables" value="{{option['_id']}}" [attr.selected]="option._id==tempElementValues['input_default_package_table_id'] ? true : null">{{option.package_table_name}}</option>
      </select>
      </div>
  </div>
  
  <div class="form-group row col-12">
    <label for="input_package_table" class="col-sm-3 col-form-label" style="color:black;">Fiber Package Table *</label>
    <div class="col-sm-9">
    <select name="input_package_table" (change)="updateElement('fiber_package_table',$event);" class="form-control" id="input_package_table">
      <option *ngFor="let option of this.packageTables" value="{{option['_id']}}" [attr.selected]="option._id==tempElementValues['input_fiber_package_table_id'] ? true : null">{{option.package_table_name}}</option>
    </select>
    </div>
  </div>

  <div class="form-group row col-12">
    <label for="input_package_table" class="col-sm-3 col-form-label" style="color:black;">Copper Package Table *</label>
    <div class="col-sm-9">
    <select name="input_package_table" (change)="updateElement('copper_package_table',$event);" class="form-control" id="input_package_table">
      <option *ngFor="let option of this.packageTables" value="{{option['_id']}}" [attr.selected]="option._id==tempElementValues['input_copper_package_table_id'] ? true : null">{{option.package_table_name}}</option>
    </select>
    </div>
  </div>

  <div class="form-group row col-12">
  <label for="input_required" class="col-sm-3 col-form-label" style="color:black;">Required *</label>
  <div class="col-sm-9">
  <select name="input_required" (change)="updateElement('required',$event);" class="form-control" id="input_required">
    <option *ngIf="tempElementValues['input_required'] == 'true'" value="true" selected> Yes</option>
    <option *ngIf="tempElementValues['input_required'] == 'false'" value="false" selected> No</option>
    <option *ngIf="tempElementValues['input_required'] == 'false'" value="true" > Yes</option>
    <option *ngIf="tempElementValues['input_required'] == 'true'" value="false" > No</option>
  </select>
  </div>
  </div>

  <div class="form-group row col-12">
  <label for="input_public" class="col-sm-3 col-form-label" style="color:black;">Public</label>
  <div class="col-sm-9">
  <select name="input_public" (change)="updateElement('is_public',$event);" class="form-control" id="input_public">
  <option *ngIf="tempElementValues['input_public'] == 'true'" value="true" selected> Yes</option>
  <option *ngIf="tempElementValues['input_public'] == 'false'" value="false" selected> No</option>
  <option *ngIf="tempElementValues['input_public'] == 'false'" value="true" > Yes</option>
  <option *ngIf="tempElementValues['input_public'] == 'true'" value="false" > No</option>
  </select>
  </div>
  </div>

  <div class="form-group row col-12">
  <label for="input_system_id" class="col-sm-3 col-form-label" style="color:black;">Field ID</label>
  <div class="col-sm-9">
  <input type="text" (change)="updateElement('system_id',$event);" data-lpignore="true" name="input_system_id" class="form-control" id="input_system_id" value="{{tempElementValues['input_system_id']}}">
  </div>
  </div>

  <div class="form-group row col-12">
  <label for="input_required" class="col-sm-3 col-form-label" style="color:black;">Keyed Field</label>
  <div class="col-sm-9">
  <select name="input_keyed_field" (change)="updateElement('keyed_field',$event);" class="form-control" id="input_keyed_field">
  <option value="keyed_none" >Not Selected</option>
  <option *ngFor="let option of this.tempElementValues['input_keyed_fields']" value="{{option.value}}" [attr.selected]="option.selected==true ? true : null">{{option.name}}</option>

  </select>
  </div>
  </div>
