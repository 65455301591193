import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";

import Swal from 'sweetalert2/dist/sweetalert2.js';

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {FormControl,FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';


declare var $: any;
declare var tinymce: any;

// Include on all pages
import { CustomcssService } from '../../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../../_services/all-page-generals.service';


// Page Defined Services

import { FormBuilderService } from '../../../../../_services/modules/geomap/forms/form-builder.service';
import { FormControlService } from '../../../../../_services/modules/geomap/forms/form-control.service';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}



@Component({
  selector: 'app-formbuilder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.css'],
})
export class FormbuilderComponent implements OnInit,AfterViewInit {
  infoBanner = true;

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  currFormLayout: any = [];
  quillCompletePage: any;
  form: any = {
    form_name: new FormControl('New Form', [Validators.required]),
    form_type: new FormControl('1', [Validators.required]),
    form_workflow_id: new FormControl('', [Validators.required]),
    form_enable: new FormControl('false', [Validators.required]),
    form_settings:{
      form_header_display: new FormControl('false', [Validators.required]),
      form_header_text:{
        default: new FormControl('Signup Today!', [Validators.required]),
        fiber: new FormControl('Fiber Signup!', [Validators.required]),
        copper: new FormControl('Copper Signup!', [Validators.required]),
      },
      form_header_text_color: new FormControl('#000', [Validators.required]),
      form_header_font_size: new FormControl('36', [Validators.required]),
      form_header_font_weight: new FormControl('bold', [Validators.required]),
      form_header_location: new FormControl('center', [Validators.required]),
      form_submit_button_text: new FormControl('Click to Continue', [Validators.required]),
      form_submit_button_text_color: new FormControl('', [Validators.required]),
      form_submit_button_color: new FormControl('white', [Validators.required]),
      form_element_label_font_color: new FormControl('black', [Validators.required]),
      form_element_label_font_weight: new FormControl('bold', [Validators.required]),
      form_element_text_color: new FormControl('black', [Validators.required]),
      form_element_background_color: new FormControl('white', [Validators.required]),
      form_element_required_text_color: new FormControl('red', [Validators.required]),
      form_package_tb_header_bg_color: new FormControl('#0099da', [Validators.required]),
      form_package_tb_header_text_color: new FormControl('white', [Validators.required]),
      form_resp_email_msg: new FormControl('6333666f363add312fbe3f5f', [Validators.required]),
      form_speedtest_continue_dialog_text: new FormControl('Complete Our Speedtest', [Validators.required]),
      form_speedtest_continue_dialog_button_color: new FormControl('#0099da', [Validators.required]),
      form_speedtest_continue_dialog_button_text: new FormControl('Click To Complete Our Speedtest', [Validators.required]),
      form_speedtest_success_dialog_text: new FormControl('Finished!', [Validators.required]),
      form_speedtest_success_dialog_button_color: new FormControl('#0099da', [Validators.required]),
      form_speedtest_success_dialog_button_text: new FormControl('Success', [Validators.required]),
    }
  };


  editOnSelectLast: any={
    element:null,
    previousBGColor:null,
    previousTextColor:null
  };
  selectedEditorType: any;

  tinymcehtml = 'test';

  constructor(private elementRef:ElementRef,
    private http: HttpClient,
    private route: ActivatedRoute,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private formbuilderservice: FormBuilderService,
    private _liveAnnouncer: LiveAnnouncer,
    private formControlService:FormControlService
    ) { }
// Convert Number into an array for use with ngFor for displaing dynamic columns for the rows in the Builder
columnCounter(i: number) {
      return new Array(i);
  }

getFormkeyedFields(): any{
return this.formbuilderservice.formKeyedFields;
}
    ngAfterViewInit(): void {

      $(function() {
        // Set this variable with the desired height
        var offsetPixels = 50;

        $(window).scroll(function() {
          if ($(window).scrollTop() > offsetPixels) {
            $( ".testscrool" ).css({
              "top":$(window).scrollTop() - 150
            });


          } else {
            $( ".testscrool" ).css({
              "top":$(window).scrollTop()
            });

          }
        });
      });
      console.log(this.route.snapshot.paramMap.get('form_id'));

      if(this.route.snapshot.paramMap.get('form_id') !== undefined && this.route.snapshot.paramMap.get('form_id') !== null){

         this.formbuilderservice.load_formLayout(this.route.snapshot.paramMap.get('form_id')!.toString()).subscribe(async (results: any)=>{

        this.currFormLayout = results['form_layout'];

        const myTimeout = setTimeout(()=>{this.setup_draggable();}, 1000);

        console.log(results);



       this.quillCompletePage = tinymce.init({
        selector: '#tinydefault',
        plugins: 'code',
        height : "600"
       // skin: "oxide-dark",
       // content_css: "dark"
      });

      //tinymce.get("tinydefault").setContent();


       });
      }
      else{
        this.quillCompletePage = tinymce.init({
          selector: '#tinydefault',
          plugins: 'code',
          height : "600"
         // skin: "oxide-dark",
         // content_css: "dark"
        });

        const myTimeout = setTimeout(()=>{this.setup_draggable();}, 1000);
      }
       this.setupEditOnSelect();

    }




  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('form_id') !== undefined && this.route.snapshot.paramMap.get('form_id') !== null){

    this.formControlService.getFormDetails('',this.route.snapshot.paramMap.get('form_id')).subscribe((data)=>{
      console.log(data);
      this.tinymcehtml = data['complete_page_layout'];
      this.form = {
        form_name: new FormControl(''+data['form_name']+'', [Validators.required]),
        form_type: new FormControl(''+data['form_type']+'', [Validators.required]),
        form_workflow_id: new FormControl('', [Validators.required]),
        form_enable: new FormControl(''+data['form_enable']+'', [Validators.required]),
        form_settings:{
          form_header_display: new FormControl(''+data['form_settings']['form_header_display']+'', [Validators.required]),
          form_header_text:{
            default: new FormControl(''+data['form_settings']['form_header_text']['default']+'', [Validators.required]),
            fiber: new FormControl(''+data['form_settings']['form_header_text']['fiber']+'', [Validators.required]),
            copper: new FormControl(''+data['form_settings']['form_header_text']['copper']+'', [Validators.required]),
          },
          form_header_text_color: new FormControl(''+data['form_settings']['form_header_text_color']+'', [Validators.required]),
          form_header_font_size: new FormControl(''+data['form_settings']['form_header_font_size']+'', [Validators.required]),
          form_header_font_weight: new FormControl(''+data['form_settings']['form_header_font_weight']+'', [Validators.required]),
          form_header_location: new FormControl(''+data['form_settings']['form_header_location']+'', [Validators.required]),
          form_submit_button_text: new FormControl(''+data['form_settings']['form_submit_button_text']+'', [Validators.required]),
          form_submit_button_text_color: new FormControl(''+data['form_settings']['form_submit_button_text_color']+'', [Validators.required]),
          form_submit_button_color: new FormControl(''+data['form_settings']['form_submit_button_color']+'', [Validators.required]),
          form_element_label_font_color: new FormControl(''+data['form_settings']['form_element_label_font_color']+'', [Validators.required]),
          form_element_label_font_weight: new FormControl(''+data['form_settings']['form_element_label_font_weight']+'', [Validators.required]),
          form_element_text_color: new FormControl(''+data['form_settings']['form_element_text_color']+'', [Validators.required]),
          form_element_background_color: new FormControl(''+data['form_settings']['form_element_background_color']+'', [Validators.required]),
          form_element_required_text_color: new FormControl(''+data['form_settings']['form_element_required_text_color']+'', [Validators.required]),
          form_package_tb_header_bg_color: new FormControl(''+data['form_settings']['form_package_tb_header_bg_color']+'', [Validators.required]),
          form_package_tb_header_text_color: new FormControl(''+data['form_settings']['form_package_tb_header_text_color']+'', [Validators.required]),
          form_resp_email_msg: new FormControl(''+data['form_settings']['form_resp_email_msg']+'', [Validators.required]),
          form_speedtest_continue_dialog_text: new FormControl(''+data['form_settings']['form_speedtest_continue_dialog_text']+'', [Validators.required]),
          form_speedtest_continue_dialog_button_color: new FormControl(''+data['form_settings']['form_speedtest_continue_dialog_button_color']+'', [Validators.required]),
          form_speedtest_continue_dialog_button_text: new FormControl(''+data['form_settings']['form_speedtest_continue_dialog_button_text']+'', [Validators.required]),
          form_speedtest_success_dialog_text: new FormControl(''+data['form_settings']['form_speedtest_success_dialog_text']+'', [Validators.required]),
          form_speedtest_success_dialog_button_color: new FormControl(''+data['form_settings']['form_speedtest_success_dialog_button_color']+'', [Validators.required]),
          form_speedtest_success_dialog_button_text: new FormControl(''+data['form_settings']['form_speedtest_success_dialog_button_text']+'', [Validators.required]),
        }
      };
    });
  }
    //this.customcssservice.load_new_map_poly_profile_css();

    // Change Top Nav Title on Page Change
    this.allpageGenerals.setTopNavTitle('Form Builder');






    $(document).on("click", ".edit-row-link", (ev) => {
      var $el = $(ev.target || ev.srcElement || ev.currentTarget).parent().parent();
      this.formbuilderservice.onEditRow($el,"row");


    });
    $(document).on("click", ".edit-link", (ev) => {
      var $el = $(ev.target || ev.srcElement || ev.currentTarget).parent().parent();
      var $el_copy = $el.clone();
      //var el_input = $el.find(':text,textarea,select').filter(':visible:first');

      //console.log(el_input);



      var $edit_btn = $el_copy.find(".edit-link").parent().remove();
      //console.log($el.find(':input').filter('[type=text]').filter(':first')[0]);

      console.log($el.find('div').filter('[type=paragraphbox]'));

      //$modal.find(":input:first").focus();
     // $modal.find(".btn-success").click(function(ev2) {

    //    var html = $modal.find("textarea").val();
   //     if(!html) {
   //       $el.remove();
   //     } else {
  //        $el.html(html);
  //        $edit_btn.appendTo($el);
    //    }
   //     $modal.modal("hide");
   //     return false;
    //  })
    });








    }




setupEditOnSelect(): void{

  $(document).on("click", ".click-to-edit", (ev) => {

    var $el = $(ev.currentTarget);
    console.log($el);
   // $el[0].style.backgroundColor = "rgb(155, 102, 102)";
    this.onEditOnSelect($el);
    //this.formbuilderservice.onEditRow($el,"row");
  });

}


onEditOnSelect(element: any): void{

if (this.editOnSelectLast['element'] !== null){
  this.editOnSelectResetPrevious(async ()=>{
    this.editOnSelectSetCurrent(element[0],async ()=>{
      this.editOnSelectChangeToSelected(element[0],async ()=>{

        this.switchToEditTab(element[0]);
      });
    });
  });
}else{
    this.editOnSelectSetCurrent(element[0],async ()=>{
      this.editOnSelectChangeToSelected(element[0],async ()=>{

        this.switchToEditTab(element[0]);
      });
    });
}

}

editOnSelectResetPrevious(callback){
  this.editOnSelectLast['element'].style.color = this.editOnSelectLast['previousTextColor'];
  this.editOnSelectLast['element'].style.backgroundColor = this.editOnSelectLast['previousBGColor'];
  if(this.editOnSelectLast['element'].getAttribute('type') == 'htmlarea'){
    document.getElementById(''+this.editOnSelectLast['element'].id.split("-")[1]+'')!.style.display = 'none';
    document.getElementById('htmlarea-'+this.editOnSelectLast['element'].id.split("-")[1]+'')!.style.display = 'block';
    document.getElementById('htmlarea-'+this.editOnSelectLast['element'].id.split("-")[1]+'')!.innerHTML = (<HTMLTextAreaElement>document.getElementById(''+this.editOnSelectLast['element'].id.split("-")[1]+'')!).value;
    this.formbuilderservice.form_paragraphbox_data[''+this.editOnSelectLast['element'].id.split("-")[1]+''] = (<HTMLTextAreaElement>document.getElementById(''+this.editOnSelectLast['element'].id.split("-")[1]+'')!).value;
  }
  callback();
}


editOnSelectSetCurrent(element: any,callback){
  this.editOnSelectLast['element'] = element;
  this.editOnSelectLast['elementID'] = element.getAttribute('id');
  this.editOnSelectLast['previousTextColor'] = element.style.color;
  this.editOnSelectLast['previousBGColor'] = element.style.backgroundColor;
  callback();
}
editOnSelectChangeToSelected(element: any,callback){
  element.style.color = "white" ;
  element.style.backgroundColor = "rgb(52, 152, 235,0.2)";
  if(element.getAttribute('type') == 'htmlarea'){
    document.getElementById(''+this.editOnSelectLast['element'].id.split("-")[1]+'')!.style.display = 'block';
    document.getElementById('htmlarea-'+this.editOnSelectLast['element'].id.split("-")[1]+'')!.style.display = 'none';



  }
  callback();
}

switchToEditTab(selectedEl){
  document.getElementById('underline-contact-tab')?.click();
  this.selectedEditorType = null;
  if(this.selectedEditorType == null){
  setTimeout(()=>{this.selectedEditorType = selectedEl?.getAttribute('type');}, 100);
  }

}

onSubmit(): void {
  if(this.route.snapshot.paramMap.get('form_id') !== undefined && this.route.snapshot.paramMap.get('form_id') !== null){

    this.formbuilderservice.onLayoutUpdate(this.route.snapshot.paramMap.get('form_id'),tinymce.get("tinydefault").getContent());

  }
  else{

var form_data = {
  form_name: this.form.form_name.value,
  form_type:parseInt(this.form.form_type.value),
  form_workflow_id:this.form.form_workflow_id.value,
  form_enable: this.form.form_enable.value,
  form_settings:{
    form_header_display: this.form.form_settings.form_header_display.value,
    form_header_text:{
      default:this.form.form_settings.form_header_text.default.value,
      fiber: this.form.form_settings.form_header_text.fiber.value,
      copper: this.form.form_settings.form_header_text.copper.value,
    },
    form_header_text_color: this.form.form_settings.form_header_text_color.value,
    form_header_font_size: this.form.form_settings.form_header_font_size.value,
    form_header_font_weight: this.form.form_settings.form_header_font_weight.value,
    form_header_location: this.form.form_settings.form_header_location.value,
    form_submit_button_text: this.form.form_settings.form_submit_button_text.value,
    form_submit_button_text_color: this.form.form_settings.form_submit_button_text_color.value,
    form_submit_button_color: this.form.form_settings.form_submit_button_color.value,
    form_element_label_font_color: this.form.form_settings.form_element_label_font_color.value,
    form_element_label_font_weight: this.form.form_settings.form_element_label_font_weight.value,
    form_element_text_color: this.form.form_settings.form_element_text_color.value,
    form_element_background_color: this.form.form_settings.form_element_background_color.value,
    form_element_required_text_color: this.form.form_settings.form_element_required_text_color.value,
    form_package_tb_header_bg_color: this.form.form_settings.form_package_tb_header_bg_color.value,
    form_package_tb_header_text_color: this.form.form_settings.form_package_tb_header_text_color.value,
    form_resp_email_msg: this.form.form_settings.form_resp_email_msg.value,
    form_speedtest_continue_dialog_text: this.form.form_settings.form_speedtest_continue_dialog_text.value,
    form_speedtest_continue_dialog_button_color: this.form.form_settings.form_speedtest_continue_dialog_button_color.value,
    form_speedtest_continue_dialog_button_text: this.form.form_settings.form_speedtest_continue_dialog_button_text.value,
    form_speedtest_success_dialog_text: this.form.form_settings.form_speedtest_success_dialog_text.value,
    form_speedtest_success_dialog_button_color: this.form.form_settings.form_speedtest_success_dialog_button_color.value,
    form_speedtest_success_dialog_button_text: this.form.form_settings.form_speedtest_success_dialog_button_text.value,
  }
}

    this.formbuilderservice.onFormNew(this.route.snapshot.paramMap.get('form_id'),form_data,tinymce.get("tinydefault").getContent());

  }

}

onEditInput(ElementID: any): void {

}


setup_draggable(): void{
       //this.formbuilderservice.setup_draggable_rows();
       this.formbuilderservice.setup_draggable();





}
setup(): void{
  //this.formbuilderservice.setup_draggable_rows();
  //this.formbuilderservice.setup_draggable();




}






  }

