<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listforms']">Forms</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Form</li>
        </ol>
    </nav>
    </div>

<div class="row layout-spacing">

    <!-- Content -->











    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
        <div class="widget widget-card-standard-dropdown">
            <div class="widget-content">
                <div class="w-header">
                    <div class="w-info">
                        <h4 class="value">Update Polygon</h4>
                    </div>
                    

                </div>


                <div class="w-content">
                    
                  
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <form 
                            name="form"
                            (ngSubmit)=" onSubmit()"
                            #f="ngForm">
                          
                                <div class="row g-3">
                                  
                                    <div class="col-md-6">
                                        <label for="form_name">Name</label>
                                        <input type="text" [(ngModel)]="form.form_name" required #form_name="ngModel"  name="form_name" class="form-control" id="form_name" placeholder="Form Name">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="form_type">Type</label>
                                        <select class="form-control" [(ngModel)]="form.form_type" required #form_type="ngModel"  id="form_type" name="form_type">
                                        <option value="0">Normal</option>
                                        <option value="1">Speedtest</option>
                                    </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="form_workflow_id">Workflow</label>
                                        <select class="form-control" [(ngModel)]="form.form_workflow_id" required #form_workflow_id="ngModel"  id="form_workflow_id" name="form_workflow_id">
                                        <option value="true"></option>
                                        <option value="false">Select Workflow</option>
                                    </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="form_enable">Pubished</label>
                                        <select class="form-control" [(ngModel)]="form.form_enable" required #form_enable="ngModel"  id="form_enable" name="form_enable">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    </div>

                                    <button type="submit" class="btn btn-primary mt-3">Add</button>
                                </div>
    
                            
                            </form>


</div>
                       
                    </div>
                </div>
            </div>
        </div>
        




















</div>
