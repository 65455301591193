import { Component, OnInit,AfterViewInit } from '@angular/core';

import { SimpleTableService } from '../../../../../_services/components/tables/simple-table/simple-table.service';
import { ActivatedRoute } from "@angular/router";
//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-new-list-entries',
  templateUrl: './new-list-entries.component.html',
  styleUrls: ['./new-list-entries.component.css']
})
export class NewListEntriesComponent implements OnInit,AfterViewInit {
 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(public simpleTableService: SimpleTableService,private route: ActivatedRoute,public dialog: MatDialog ) { }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('form_id') !== '' && this.route.snapshot.paramMap.get('form_id') !== null && this.route.snapshot.paramMap.get('form_id') !== undefined){
      this.simpleTableService.initTable('table',''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentrieswithfilters2&formkey='+this.route.snapshot.paramMap.get('form_id')+'','RowCountOnly');
    }



  }

  ngAfterViewInit(): void{
    this.simpleTableService.loadTable();
    console.log(this.simpleTableService.tableHeaders);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }



  }

  @Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: './dialog-content-example-dialog.html',
  })
  export class DialogContentExampleDialog {}
