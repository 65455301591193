import { Injectable } from '@angular/core';
import  html2canvas  from 'html2canvas';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class NewTicketControlService {

  constructor() {   document.onkeydown = this.KeyPress; }

  captureNetworkRequest(): void {
    var capture_network_request = [];
    var capture_resource = performance.getEntriesByType("resource");
    for (var i = 0; i < capture_resource.length; i++) {
        console.log(capture_resource);
    }
   
  }

  KeyPress(e) {

    var evtobj = window.event? event : e
    if (evtobj.keyCode == 81 && evtobj.ctrlKey) {
      const screenshotTarget = document.body;

  html2canvas(screenshotTarget).then(async (canvas) => {

      const base64image = canvas.toDataURL("image/png");

        for(var b in window) {
          if(window.hasOwnProperty(b)) console.log(b);
        }
        var n, arg, name;
        console.log(typeof this);

//alert("typeof this = " + typeof this);
for (name in this) {
  console.log("this[" + name + "]=");
  console.log(this[name]);
    //alert("this[" + name + "]=" + this[name]);
}

for (n = 0; n < arguments.length; ++n) {
    arg = arguments[n];
    console.log("typeof arguments[" + n + "] = ");
    console.log(typeof arg);
    //alert("typeof arguments[" + n + "] = " + typeof arg);
    for (name in arg) {
      console.log("arguments[" + n + "][" + name + "]=");
      console.log(arg[name]);
       // alert("arguments[" + n + "][" + name + "]=" + arg[name]);
    }
}

console.log(self.toString());

        //console.log(result.value);
      });
    }
}

}
