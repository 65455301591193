import { Component, OnInit, ViewChild } from '@angular/core';
import { MapsService } from '../../../../_services/maps.service';
declare var BoldBI: any;
import {  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions,
 } from "ng-apexcharts";

import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: any; // ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  tooltip: ApexTooltip;

};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardGeofiComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  myMap: any;
  constructor(private customcssservice: CustomcssService, 
    private customjsservice: CustomjsService,
    private mapsservice: MapsService,) { }

  ngOnInit(): void {
    this.myMap = this.mapsservice.InitMap(this.myMap,"map",38.84242550792102,-86.9917137384425,14);
    //this.customcssservice.load_apexcharts_css();
    //this.customcssservice.load_dash_1_css();
    //this.customjsservice.load_custom_apexcharts();
    //this.customjsservice.load_custom_dash_1();
    this.chartOptions = {
      series: [

        {
          name: "Map Address Searches",
          type: "area",
          color: "#0466C8",
          data: [144, 155, 141, 167, 122, 143, 121, 141, 156, 127, 143]
        },
        {
          name: "Forms Attempted",
          type: "column",
          color: "#023E7D",
          data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
        },
        {
          name: "Forms Completed",
          type: "column",
          color: "#979DAC",
          data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
        },
        
      ],
      title: {
        text: "My First Angular Chart"
      },
      chart: {
        height: 350,
        type: "line",
        stacked: true,
        background: "#fff",
        toolbar: {
          show: false,
          autoSelected: "pan"
        },
      },
      stroke: {
        width: [0, 2, 2],
        curve: "smooth"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },

      fill: {
        opacity: [0.25, 0.85,1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels: [
        "01/01/2003",
        "02/01/2003",
        "03/01/2003",
        "04/01/2003",
        "05/01/2003",
        "06/01/2003",
        "07/01/2003",
        "08/01/2003",
        "09/01/2003",
        "10/01/2003",
        "11/01/2003"
      ],
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        title: {
          text: ""
        },
        min: 0
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function(y) {
            if (typeof y !== "undefined") {
              return "<div style='color:black;'>"+ y.toFixed(0) + "</div> ";
            }
            return y;
          },
          title: {
            formatter: (seriesName) => {return '<font color="black">'+seriesName+'</font>'},
        },
        }
      },
     
    };
  


  
  }



}
