import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AllPageGeneralsService {

  constructor() { }

  setTopNavTitle(title: string): void{
  //var topNav_Title = document.getElementById("insights_top_navbar_title");
  //topNav_Title!.innerHTML = title;
  }
}
