


                <!-- CONTENT AREA -->

                <div class="flex-row layout-top-spacing">
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Maps</li>
                    </ol>
                </nav>
                </div>


                <!-- <div class="d-flex layout-top-spacing flex-row-reverse">
					<button id="add-work-platforms" [routerLink]="['/geomap/maps/newMap']" class="btn btn-rounded btn-primary">+ New Map</button>
				</div> -->


                <div style="display:block;" id="polyTableLoader" class="flex-row">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col">

                            </div>
                            <div class="col">
                                <div style="text-align:center;">

                                <div  class="lds-dual-ring"></div>
                                <h3 id="loadingTablePercent"></h3>
                                </div>
                            </div>
                            <div class="col">

                            </div>
                          </div>
                    </div>
                </div>

                <div class="row layout-top-spacing" id="cancel-row">

                    <div  style="display:none;" id="mapTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget widget-card-standard-dropdown">
                            <div class="widget-content">
                                <div class="w-header">
                                    <div class="w-info">
                                        <h6 class="value">Maps</h6>
                                    </div>

                                    <div class="task-action">

                                        <div class="dropdown pt-2" style="float:right;">
                                            <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                            </a>

                                            <div class="dropdown-menu left" aria-labelledby="expenses" style="will-change: transform;">
                                                <a class="dropdown-item" href="javascript:void(0);">This Week</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                                            </div>
                                        </div>
                                        <div class="" style="width:100px; float:right;">
                                            <button id="add-work-platforms" [routerLink]="['/geomap/maps/newMap']" style="font-size:14px;" class="btn btn-rounded btn-primary"> + Map </button>
                                    </div>
                                    </div>
                                </div>


                                <div class="w-content">

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <table *ngIf="displayTable" datatable [dtOptions]="dtOptions" id="html5-extension" class="table table-hover non-hover" style="width:100%; min-height:250px;">
                                            <thead>
                                                <tr>

                                                    <th>Name</th>
                                                    <th># of Polys</th>
                                                    <th># of Submits</th>
                                                    <th>Published</th>
                                                    <th class="dt-no-sorting">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="mapsTableBody">

                                                <tr *ngFor="let row of rows" >
                                                    <td (click)="onClick(row)">{{row.map_name}}</td>
                                                    <td (click)="onClick(row)">{{row.poly_count}}</td>
                                                    <td (click)="onClick(row)">0</td>
                                                    <td *ngIf="row.map_enable == true">
                                                      <label class="switch s-icons s-outline  s-outline-primary  mr-2">
                                                      <input type="checkbox"  (change)="disEnMap(row._id,false)" checked><span class="slider round"></span></label>
                                                    </td>
                                                    <td *ngIf="row.map_enable == false" >
                                                        <label class="switch s-icons s-outline  s-outline-primary  mr-2">
                                                        <input type="checkbox"  (change)="disEnMap(row._id,true)" ><span class="slider round"></span></label></td>
                                                        <td>
                                                            <div class="btn-group dropend">

                                                                <button type="button" [routerLink]="['/geomap/maps/editmap',{map_id: row._id}]" class="btn btn-primary btn-sm">Edit</button>
                                                                <button type="button" class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference1">
                                                                    <a class="dropdown-item" (click)="openMap(row._id)" href="javascript:void(0);">View Map</a>
                                                                    <a class="dropdown-item" (click)="openEmbedLink(row._id)" href="javascript:void(0);">Embed Link</a>
                                                                    <a class="dropdown-item" [routerLink]="['/geomap/maps/listpolys',{map_id: row._id}]" href="javascript:void(0);">List Polygons</a>

                                                                    <div class="dropdown-divider"></div>
                                                                    <a class="dropdown-item" [routerLink]="['/geomap/forms/entryviewer',{map_id: row._id}]" href="javascript:void(0);">Entry Map</a>

                                                                    <div class="dropdown-divider"></div>
                                                                    <a class="dropdown-item" (click)="removeMap(row._id)" href="javascript:void(0);">Remove</a>
                                                                </div>
                                                                </div>
                                                        </td>
                                                  </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <div style="display:none" class="w-progress-stats">
                                    <div class="">
                                        <div class="w-lic-count">
                                            <p>1\∞</p>
                                        </div>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>

                                    <div class="">
                                        <div class="w-icon">
                                            <p>0%</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <!-- CONTENT AREA -->





