import { Component, Input, OnInit,AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from './_question-control/question-base';
declare var value: string;
@Component({
  selector: 'app-form-elements',
  templateUrl: './form-elements.component.html',
  styleUrls: ['./form-elements.component.css']
})
export class FormElementsComponent implements OnInit,AfterViewInit {

  @Input() question!: QuestionBase<string>;
  @Input() type!: any;
  @Input() form!: FormGroup;
  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    if(this.question.field_type == 'paragraphbox'){
      document.getElementById(''+this.question.field_id+'')!.innerHTML = this.question.field_value;
     }
    else if(this.question.field_type == 'htmlarea'){
      document.getElementById(''+this.question.field_id+'')!.innerHTML = this.question.field_value;
      document.getElementById('htmlarea-'+this.question.field_id+'')!.innerHTML = this.question.field_value;
     }
  }
}


