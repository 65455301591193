import { Component, Input, OnInit,AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-textarea-editor',
  templateUrl: './textarea-editor.component.html',
  styleUrls: ['./textarea-editor.component.css']
})
export class TextareaEditorComponent implements OnInit {
  @Input() selectedElementID!: any;
  @Input() keyed_fields!: any;

  tempElementValues: any = {
    input_label:'',
    input_default_text:'',
    input_required: '',
    input_public:'',
    input_keyed_fields:[],
    input_system_id:''

  };
  constructor() { }

  ngOnInit(): void {

    this.tempElementValues['input_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML;
    this.tempElementValues['input_default_text'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.value;
    this.tempElementValues['input_required'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('required');
    this.tempElementValues['input_private_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('private_label');
    this.tempElementValues['input_public'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('insights-ispublic');


// Set Input Public Status
if(this.tempElementValues['input_public'] == 'true'){
  this.tempElementValues['input_public'] = 'true';
}
else{

  this.tempElementValues['input_public'] = 'false';
}
/////////////////////////////////////////////////////////////////


// Set Input Keyed Field Status
var temparray: string[] = [];
document.querySelectorAll('[keyed-field]').forEach(async (el: any)=>{
     temparray.push(el.getAttribute('keyed-field'));
 });

 var KeyedText = '';
 console.log( this.keyed_fields);
 this.keyed_fields.forEach(async(key: any)=>{
  if(!temparray.includes(key['name'])){
     this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: false});
  }
   else{
    if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('keyed-field') == key['name']){
       this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: true});
    }
  }

 });
 /////////////////////////////////////////////////////////////////


// Set System ID temapaery Var if Present
var system_id_val: any = "";
if((<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('system-id') !== null){
  this.tempElementValues['input_system_id'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.getAttribute('system-id');
}
else{
  this.tempElementValues['input_system_id'] = "";
}
 /////////////////////////////////////////////////////////////////

  }




  updateElement(name: string,el: any): void{
    if(name == 'label'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML = el.target.value;
    }
    else if(name == 'private_label'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.setAttribute('private_label',el.target.value);
    }


    else if(name == 'default_text'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.innerHTML = el.target.value;
    }

    else if(name == 'required'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.setAttribute('required',el.target.value);
    }

    else if(name == 'is_public'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.setAttribute('insights-ispublic',el.target.value);
    }

    else if(name == 'system_id'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.setAttribute('system-id',el.target.value);
    }

    else if(name == 'keyed_field'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('textarea')!.setAttribute('keyed-field',el.target.value);
    }

    }

}
