import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomjsService {

  constructor() { }


  public load_custom() {
    var url = "assets/assets/js/dashboard/dash_1.js";
    this.js_loader(url)
  }



  public load_formwizard_js() {

    var url = "https://cdn.jsdelivr.net/gh/AdrianVillamayor/Wizard-JS@1.8/src/wizard.min.js";
    var url1 = "assets/src/plugins/src/stepper/custom-bsStepper.min.js";

    this.js_loader(url);
    //this.js_loader(url1);
  }




  private js_loader(url: string) {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = url;
    chatScript.className = 'intempjsimport';
    document.body.appendChild(chatScript);


  }



}
