<div class="row layout-top-spacing layout-spacing">


        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">Update Polygon</h4>
                        </div>
                        
    
                    </div>
    
    
                    <div class="w-content">

                        <form>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                            <div class="form-group mb-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Group Name</mat-label>
                                    <input type="text" data-lpignore="true" [formControl]="groupForm.group_name" required matInput  name="user_firstname" id="group_name" placeholder="Group Name" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="groupForm.group_name.hasError('required')">
                                    Group Name <strong>Required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            </div>
                            <div class="pl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                            <div class="form-group mb-4">
                            <label for="lPermissions">Permissions</label>
                            <select class="custom-select" [formControl]="groupForm.group_permissions" style="height:200px;" id="lPermissions" multiple>
        
                    
                            <option *ngFor="let row of globalPermissions" [ngValue]="row._id" [attr.selected]="groupPermissions.includes(row._id) ? '' : null" >{{row.name}}</option>
                     
                                 
                                
                            </select>
                           
                            </div>
                            </div>
                            <small id="emailHelp" class="form-text text-muted">*Required Fields</small>
                            <br><br>
                            <button type="submit" (click)="onSubmit()" class="btn btn-primary mt-4">Update</button>
                        </form>
                    
                     <div class="row">
                        
                     </div>

                    </div>
                </div>
            </div>
        </div>
        

    </div>