import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';
// This Page Services
import { AddresslistControlService } from '../../../../_services/modules/geomap/addresslists/addresslist-control.service';


@Component({
  selector: 'app-address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.css']
})
export class AddressEditorComponent implements OnInit {

  form: any = {

  };
  displayTable = false;
  rows: any = [];
  dtOptions: any = {};
  polyFileUploaded = false;
  enableSubmit = true;
  mainPolyFile: any;
  polylist: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private addresslistcontrolservice: AddresslistControlService
    ) { }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();

      // Change Top Nav Title on Page Change
      this.allpageGenerals.setTopNavTitle('Address List');
      this.loadData();
  }


  onSubmit(): void {


    this.addresslistcontrolservice.editAddressList(''+this.route.snapshot.paramMap.get('addresslist_id')+'',this.form,this.rows).subscribe(async (resp)=>{
      this.router.navigate(['/geomap/addresslist/addresslists']);
    })
        //const { username, password } = this.form;
       /// const headers = { 'content-type': 'application/json'}
       // this.form.addresslist_table = this.rows;
       // this.form.addresslist_enable = Boolean(this.form.map_enable);
       // const body=JSON.stringify(this.form);
       //   this.http.post('https://api.myininsights.com/geofi/addresslists?api=newaddresslist',body,{'headers':headers}).subscribe(resp => {
       //   console.log(resp);
      //    Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
      //      this.router.navigate(['/geomap/addresslist/addresslists'])
          //});
          //this.rerender();
      //});
    
    
      }
    
    
      csvToArray(str, delimiter = ",") {
        // slice from start of text to the first \n index
        // use split to create an array from string by delimiter
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    
        // slice from \n index + 1 to the end of the text
        // use split to create an array of each csv value row
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    
        // Map the rows
        // split values from each row into an array
        // use headers.reduce to create an object
        // object properties derived from headers:values
        // the object passed as an element of the array
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;
        });
    
        // return the array
        return {headers:headers, content:arr};
      }
    
    
      onFileChange(event) {
    
        this.polyFileUploaded = true;
        //this.displayTable = false;
        let reader = new FileReader();
    
        if(event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          // Base64 Output
          //reader.readAsDataURL(file);
          reader.readAsText(file);
          reader.onload = () => {
    
    
            this.mainPolyFile = reader.result?.toString();
            console.log(this.csvToArray(this.mainPolyFile));
    
    
            Swal.fire({
              title: 'Address Selection',
              html: `
    
    
      <form>
      <div class="form-group row">
        <label for="temppop_address" class="col-sm-3 col-form-label" style="color:black;">Address*</label>
        <div class="col-sm-9">
        <select name="temppop_address" class="form-control" id="temppop_address">
        <option value="">Select Address Field</option>
        </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="temppop_city" class="col-sm-3 col-form-label" style="color:black;">City*</label>
        <div class="col-sm-9">
        <select name="temppop_city" class="form-control" id="temppop_city">
        <option value="">Select City Field</option>
        </select>
        </div>
      </div>
      <div class="form-group row">
      <label for="temppop_state" class="col-sm-3 col-form-label" style="color:black;">State*</label>
      <div class="col-sm-9">
      <select name="temppop_state" class="form-control" id="temppop_state">
      <option value="">Select State Field</option>
      </select>
      </div>
    </div>
    <div class="form-group row">
    <label for="temppop_zip" class="col-sm-3 col-form-label" style="color:black;">Zip*</label>
    <div class="col-sm-9">
    <select name="temppop_zip" class="form-control" id="temppop_zip">
    <option value="">Select Zip Field</option>
    </select>
    </div>
    </div>
    <div class="form-group row">
    <label for="temppop_long" class="col-sm-3 col-form-label" style="color:black;">Long*</label>
    <div class="col-sm-9">
    <select name="temppop_long" class="form-control" id="temppop_long">
    <option value="">Select Long Field</option>
    </select>
    </div>
    </div>
    <div class="form-group row">
    <label for="temppop_lat" class="col-sm-3 col-form-label" style="color:black;">Lat*</label>
    <div class="col-sm-9">
    <select name="temppop_lat" class="form-control" id="temppop_lat">
    <option value="">Select Lat Field</option>
    </select>
    </div>
    </div>
    </form>
              `,
              confirmButtonText: 'Import',
              focusConfirm: false,
              width: 550,
              preConfirm: () => {
                const temppop_address_val = Swal.getPopup().querySelector('#temppop_address').value;
                const temppop_city_val = Swal.getPopup().querySelector('#temppop_city').value;
                const temppop_state_val = Swal.getPopup().querySelector('#temppop_state').value;
                const temppop_zip_val = Swal.getPopup().querySelector('#temppop_zip').value;
                const temppop_long_val = Swal.getPopup().querySelector('#temppop_long').value;
                const temppop_lat_val = Swal.getPopup().querySelector('#temppop_lat').value;
    
               // if (!login || !password) {
              //    Swal.showValidationMessage(`Please enter login and password`)
               // }
               return ({test:"test", address: temppop_address_val, city: temppop_city_val, state: temppop_state_val, zip: temppop_zip_val, long: temppop_long_val, lat: temppop_lat_val});
             }
            }).then((result) => {
              console.log(result.value);
              this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
            })
    
            const temppop_address = Swal.getPopup().querySelector('#temppop_address');
            const temppop_city = Swal.getPopup().querySelector('#temppop_city');
            const temppop_state = Swal.getPopup().querySelector('#temppop_state');
            const temppop_zip = Swal.getPopup().querySelector('#temppop_zip');
            const temppop_long = Swal.getPopup().querySelector('#temppop_long');
            const temppop_lat = Swal.getPopup().querySelector('#temppop_lat');
            this.csvToArray(this.mainPolyFile)['headers'].forEach( (row: any)  => {
    
    
              var address_option = document.createElement("option");
              address_option.text = ""+row+"";
              address_option.value = ""+row+"";
    
    
              var city_option = document.createElement("option");
              city_option.text = ""+row+"";
              city_option.value = ""+row+"";
    
              var state_option = document.createElement("option");
              state_option.text = ""+row+"";
              state_option.value = ""+row+"";
    
              var zip_option = document.createElement("option");
              zip_option.text = ""+row+"";
              zip_option.value = ""+row+"";
    
    
    
              var long_option = document.createElement("option");
              long_option.text = ""+row+"";
              long_option.value = ""+row+"";
    
    
    
              var lat_option = document.createElement("option");
              lat_option.text = ""+row+"";
              lat_option.value = ""+row+"";
    
    
    
              temppop_address.add(address_option);
    
              temppop_city.add(city_option);
    
              temppop_state.add(state_option);
    
              temppop_zip.add(zip_option);
    
              temppop_long.add(long_option);
    
              temppop_lat.add(lat_option);
              //console.log(address_option);
            });
           // this.formGroup.patchValue({
           //   file: reader.result
           // });
    
           //var tempPolys = JSON.parse(this.mainPolyFile);
          // console.log(tempPolys);
          // this.polylist = tempPolys;
         //  const promises: any = [];
           //this.deleteAllPolyForms();
    
    
          };
        }
      }
    
     loadTableData(headers: any, data: any): void{
        var itemsProcessed = 0;
    
        data['content'].forEach(async (filerow, index, array) => {
    
            if(filerow[''+headers['address']+''] !== undefined && filerow[''+headers['city']+''] !== undefined && filerow[''+headers['state']+''] !== undefined && filerow[''+headers['zip']+''] !== undefined && filerow[''+headers['long']+''] !== undefined && filerow[''+headers['lat']+''] !== undefined){
              if(filerow[''+headers['address']+''] !== '' && filerow[''+headers['city']+''] !== '' && filerow[''+headers['state']+''] !== '' && filerow[''+headers['zip']+''] !== '' && filerow[''+headers['long']+''] !== '' && filerow[''+headers['lat']+''] !== ''){
    
                //console.log({address: filerow[''+headers['address']+''], city: filerow[''+headers['city']+''], state: filerow[''+headers['state']+''], zip: filerow[''+headers['zip']+''], long: filerow[''+headers['long']+''], lat: filerow[''+headers['lat']+'']});
                this.rows.push({full_address: ((filerow[''+headers['address']+''].replace(/\s+/g, ' ').trim()) + ' ' + filerow[''+headers['city']+'']+ ' '+ filerow[''+headers['state']+'']+' '+ filerow[''+headers['zip']+'']),address: (filerow[''+headers['address']+''].replace(/\s+/g, ' ').trim()), city: filerow[''+headers['city']+''], state: filerow[''+headers['state']+''], zip: filerow[''+headers['zip']+''], long: filerow[''+headers['long']+''], lat: filerow[''+headers['lat']+'']});
    
              }
          }
            itemsProcessed++;
            if(itemsProcessed === array.length) { 
             this.loadTable();
            }
        });
    
    
      }
    
      loadTable(): void{
    
    
        this.displayTable = false; 
    
    
        this.dtOptions = {
          "dom":  "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
          //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
          "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
          "<'table-responsive'tr>" +
          "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
    
    
          buttons: {
              buttons: [
                  { extend: 'copy', className: 'btn btn-sm' },
                  { extend: 'csv', className: 'btn btn-sm' },
                  { extend: 'excel', className: 'btn btn-sm' },
                  { extend: 'print', className: 'btn btn-sm' }
              ]
          },
          "oLanguage": {
              "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
              "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
              "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
              "sSearchPlaceholder": "Search...",
             "sLengthMenu": "Results :  _MENU_",
          },
          "stripeClasses": [],
          "lengthMenu": [7, 10, 20, 50],
          "pageLength": 7 ,
          "processing": true,
          //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
          responsive: true,
          "initComplete":function(settings, json) {
            let polyTable = document.getElementById('polyTable');
            polyTable!.style.display = 'block';
           // let polyTableLoader = document.getElementById('polyTableLoader');
           // polyTableLoader!.style.display = 'none';
          },
        };
    
         // this.dtTrigger.next(this.dtOptions);
    
       this.enableSubmit = false;
       //console.log(this.polylist);
        //alert(reader.result?.toString());
        // need to run CD since file load runs outside of zone
        //this.cd.markForCheck();
    
      }


loadData(): void{

  this.addresslistcontrolservice.getAddressList('', ''+this.route.snapshot.paramMap.get('addresslist_id')+'').subscribe(async (addresslistDetails)=>{
    this.form.addresslist_name = addresslistDetails['addresslist_name'];
    this.form.addresslist_enable = addresslistDetails['addresslist_enable'];
  });


}


}
