import { Component, OnInit, AfterViewInit,ElementRef } from '@angular/core';
import {FormControl, FormGroup,FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { CustomcssService } from '../../../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../../../_assets_insert/customjs.service';
import Stepper from 'bs-stepper'



@Component({
  selector: 'app-new-subscriber-workflow',
  templateUrl: './new-subscriber-workflow.component.html',
  styleUrls: ['./new-subscriber-workflow.component.css']
})
export class NewSubscriberWorkflowComponent implements OnInit,AfterViewInit {
  stepperDefault: any;


  provForm = {
    provison_first_name: new FormControl('123', [Validators.required,Validators.minLength(4)]),
  };




  constructor(private customcssservice:CustomcssService, private customjsservice:CustomjsService) { }

  ngOnInit(): void {
    this.customcssservice.load_formwizard_Styling();
    //this.customjsservice.load_formwizard_js();
  }

  ngAfterViewInit(): void {


    var stepperWizardDefault = document.querySelector('.stepper-form-one');
    this.stepperDefault = new Stepper(stepperWizardDefault!, {
        animation: true
    });
    this.stepperDefault.next();
    this.stepperDefault.previous();



  }

}
