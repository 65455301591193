import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest  } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Observable } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class PolyControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;
 
  constructor(private http: HttpClient) { }



 


///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
// 
//
//  
//
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////


bulkPolygonImport(polygonArray: any): Observable<any>{

  return new Observable(observer => {




const headers = new HttpHeaders({'Content-Type': 'application/json',Accept: 'application/json',});

const body=JSON.stringify({bulkPolys:polygonArray});

this.http.post(this.globalSetting['api_url_console']+'/geomap?api=bulkcreatepoly', body, {headers}).pipe(
  timeout(10000),
  catchError(e => {
    // do something on a timeout
    return of(observer.next({error:true,error_type:'timout',message:"Request Timeout"}));
  })
).subscribe(async (resp: any) => {
  if(resp !== undefined){
  console.log(resp);
  observer.next(resp);
  }
  else{
  console.log({error:true,error_type:'timout',message:"Request Timeout"});
  observer.next({error:true,error_type:'timout',message:"Request Timeout"});
  }
});

  });

 }



///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//  Purge All Polygons from a Map
//
//  
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////



purgeAllPolys(map_id: any): Observable<any>{

  return new Observable(observer => {
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Purge Polygons!'
    }).then((result) => {
      if (result.isConfirmed) {
         //const { username, password } = this.form;
  
    const headers = { 'content-type': 'application/json'}  
    this.http.delete(''+this.globalSetting['api_url_console']+'/geomap?api=purgeallpolys&map_id='+map_id,{'headers':headers}).subscribe(
      data => {
      
        console.log('success', data); 
        if(!data['error'])
        {
        Swal.fire(
          'Purged!',
          'All Polygons Purged.',
          'success'
        ).then(()=>{
          observer.next({error:data['error']});
        });
        }
        else
        {
          Swal.fire(
            'Purge Failed!',
            'Purge Failed to Complete.',
            'error'
          ).then(()=>{
            observer.next({error:data['error']});
          });
        }
        
      }
  );
  
      }else{
        observer.next({error:true});
      }
    })
  
  });
     
    //  return new Observable(observer => observer.next({error:true}));
  
    }







}
