import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';


import htmlParser from 'htmlstr-parser';

import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';

Sortable.mount(new AutoScroll());
//import Input Editors

// Row Editors
import { RowControlService } from './formbuilder-rows/row-control.service';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {
 // Global Settings
 private globalSetting: [] = globalSettings;


sortablesElements: any = [];
sortablesElementsRows: any = [];
sortablesElementsToolbox: any = [];
sortablesRowsToolbox: any = [];
// Form Data
form_paragraphbox_data: any = {};
formLayoutdata: any;
formKeyedFields: any = [
  {name:'keyed_firstname', text:"First Name",value:true},
  {name:'keyed_lastname', text:"Last Name",value:true},
  {name:'keyed_address', text:"Address",value:true},
  {name:'keyed_city', text:"City",value:true},
  {name:'keyed_state', text:"State",value:true},
  {name:'keyed_zip', text:"Zip",value:true},
  {name:'keyed_email', text:"Email",value:true},
  {name:'keyed_phone', text:"Phone",value:true},
  {name:'keyed_contact_auth', text:"Authorize Communication",value:true}
];



//Form Template

formTemplete: any = {
  elementCount:2,
  textbox:' <div type="textbox" id="contain-input-#" class="form-group click-to-edit p-2"><label for="text-input-#" id="label-input-#">Textbox</label><input disabled data-lpignore="true" type="text" class="form-control" id="text-input-#" placeholder="Enter a Placeholder" data-lpignore="true" system-id="" keyed-field="" required="false" required_type="false" insights-ispublic="true" private_label = "Text Input"><p class="help-block">Example block-level help text here.</p><p class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a> </p></div>',
  textarea:' <div type="textarea" id="contain-input-#" class="form-group click-to-edit p-2"><label for="textarea-input-#" id="label-textarea-input-#">Text Area</label><br><textarea disabled class="form-control" type="textarea" id="textarea-input-#" rows="4" cols="50" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "Text Area"></textarea><p class="help-block">Example block-level help text here.</p><p class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a> </p></div>',
  date:' <div type="date" class="form-group click-to-edit p-2" id="contain-input-#"><label for="date-input-#" id="label-date-input-#">Date Selector</label><input disabled type="date" id="date-input-#" name="Date Selector" value="2018-07-22" min="2018-01-01" max="2018-12-31" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "Date Field"><p class="help-block">Example block-level help text here.</p><p class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a> </p></div>',
  password:' <div type="password" class="form-group click-to-edit p-2" id="contain-input-#"><label for="password-input-#" id="label-password-input-#">Hidden Textbox</label><input disabled data-lpignore="true" type="password" class="form-control" id="password-input-#" placeholder="Password" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "Hidden Field"><p class="help-block">Example block-level help text here.</p><p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  select: '<div type="select" class="form-group click-to-edit p-2" id="contain-input-#"><label for="select-input-#" id="label-select-input-#">Select</label><select disabled type="select" class="form-control" id="select-input-#" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label="Private Label"><option value="Option 1">Option 1</option><option value="Option 2">Option 2</option><option value="Option 3">Option 3</option></select><p class="help-block">Example block-level help text here.</p><p class="tools"><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  file:'<div type="file" class="form-group click-to-edit p-2" id="contain-input-#"><label for="file-input-#" id="label-file-input-#">File input</label><input disabled type="file" accept="*" id="file-input-#" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "File Upload"><p class="help-block">Example block-level help text here.</p><p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  checkbox:' <div type="checkbox" class="form-group checkbox click-to-edit p-2" id="contain-input-#"><label><input disabled type="checkbox" id="checkbox-input-#" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "Private Label"> <span >Check me out</span><p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></label></div>',
  packagetable:' <div type="packagetable" class="form-group click-to-edit p-2" id="contain-input-#"><label for="packagetable-input-#" id="label-packagetable-input-#">Package Table</label><table type="packagetable" id="packagetable-input-#" default-package-table-id="" fiber-package-table-id="" copper-package-table-id="" system-id="" keyed-field="" required="false" insights-ispublic="true" private_label = "Private Label" style="width:100%; color:black;"><tr><th>{{Placeholder}}</th><th>{{Placeholder}}</th><th>{{Placeholder}}</th><th>{{Placeholder}}</th></tr><tr><td>{{Placeholder}}</td><td>{{Placeholder}}</td><td>{{Placeholder}}</td><td>{{Placeholder}}</td></tr></tr></table><p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  htmlarea:' <div type="htmlarea" class="form-group click-to-edit p-2" id="contain-input-#" insights-ispublic="true"><div type="htmlarea" id="htmlarea-input-#"><h1>Thank You</h1></div><textarea style="display:none;" type="htmlarea" id="input-#" class="form-control id-input-#" rows="12"><h1>Thank You</h1></textarea> <p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  // these two need to Be Fixed
  radio:' <div type="radio" class="form-group click-to-edit p-2" id="contain-input-#"><label for="radio-input-#" id="label-radio-input-#">File input</label><div type="radio" id="radio-input-#"><div><input disabled type="radio" id="html" name="fav_language" value="HTML"><label for="html">HTML</label><br><input disabled type="radio" id="css" name="fav_language" value="CSS"><label for="css">CSS</label><br></div></div><p class="help-block">Example block-level help text here.</p><p class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a> </p></div>',
  paragraphbox:'<div type="paragraphbox" class="form-group click-to-edit p-2" id="contain-input-#"><div type="paragraphbox" id="paragraphbox-input-#"><h4>Paragragh Block</h4><p>Example Text</p></div><p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p></div>',
  
};

rowTemplete: any = {
row1:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow"></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',
row2:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',
row3:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',
row4:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',
row5:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',
row6:'<div class="row draggableRow" insights-ispublic="true"><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><div id=insightsDropCol class="col-lg droppable sortable formbuilder-dynamicrow" ></div><p class="tools" ><a class="edit-link">Edit Row</a> | <a class="remove-link">Row Remove</a></p></div>',

};

  constructor(
    private http: HttpClient, private router: Router,
     private rowControlService:RowControlService
     ) { }









///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
// Setup to make the Elements Draggable and Sortable independently from the Row Elements.
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////




  setup_draggable() {


/////////////////////////////////////////////////////
//
// Find All Dom Elements to Setup Draggable
//
///////////////////////////////////////////////////

  var setup = ()=>{
    this.sortablesElements = [];
    var tempElem = document.querySelectorAll('[id=insightsDropCol]');
    var tempRowSort = document.querySelectorAll('[id=formBody]');
   // var tempElemRows = document.querySelectorAll('[id=insightsDropCol]');
    var tempElemToolbox = document.querySelectorAll('[id=testNewDrag]');
    var tempRowToolbox = document.querySelectorAll('[id=rowToolbox]');
   // var el = document.getElementById('testNewDrag');

   $(document).on("click", ".remove-link", (ev) => {
    $(ev.target || ev.srcElement || ev.currentTarget).parent().parent().remove();

  });


/////////////////////////////////////////////////////
//
// Setup Draggable on Elements Toolbox
//
///////////////////////////////////////////////////


  tempElemToolbox.forEach((element: any)=>{
    var temp: any = new Sortable(element, {
      group: {
          name: 'shared',
          pull: 'clone', // To clone: set pull to 'clone'
          put:false
      },
      sort:false,
      animation: 150,
     // scroll: true, // Enable the plugin. Can be HTMLElement.
     // forceAutoscrollFallback: false, // force autoscroll plugin to enable even when native browser autoscroll is available
     // scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
    //  scrollSpeed: 10, // px, speed of the scrolling
    //  bubbleScroll: true,// apply autoscroll to all parent elements, allowing for easier movement
      removeCloneOnHide: true,
        // Element dragging ended
         // Called when creating a clone of element
       onClone: function (/**Event*/evt) {
         var origEl = evt.item;
         var cloneEl = evt.clone;
        console.log(evt);
         //var id = cloneEl.find('div').filter('[type=paragraphbox]')![0].attr("id");
         var tempText = origEl.outerHTML.split('type="');
         var tempText1 = tempText[1].split('"');
         console.log(tempText1[0]);

},

  });
  //temp.mount(new AutoScroll());
  this.sortablesElementsToolbox.push(temp);

   });




/////////////////////////////////////////////////////
//
// Setup Draggable on New Row Toolbox
//
///////////////////////////////////////////////////


   tempRowToolbox.forEach((element: any)=>{
    var temp: any = new Sortable(element, {
      group: {
          name: 'rowSort',
          pull: 'clone', // To clone: set pull to 'clone'
          put:false
      },
      sort:false,
      animation: 150,
      removeCloneOnHide: true,
      //scroll: true, // Enable the plugin. Can be HTMLElement.
     // forceAutoscrollFallback: false, // force autoscroll plugin to enable even when native browser autoscroll is available
     // scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
     //scrollSpeed: 10, // px, speed of the scrolling
    //  bubbleScroll: true,// apply autoscroll to all parent elements, allowing for easier movement
        // Element dragging ended
         // Called when creating a clone of element
       onClone: function (/**Event*/evt) {
         var origEl = evt.item;
         var cloneEl = evt.clone;
        console.log(evt);
         //var id = cloneEl.find('div').filter('[type=paragraphbox]')![0].attr("id");
         var tempText = origEl.outerHTML.split('type="');
         var tempText1 = tempText[1].split('"');
         console.log(tempText1[0]);


},

  });
  //temp.mount(new AutoScroll());

  this.sortablesRowsToolbox.push(temp);

   });




///////////////////////////////////////////////////////////
//
// Setup Draggable Elemet Columes for Sorting on Form
//
///////////////////////////////////////////////////////////

  tempElem .forEach((element: any)=>{
   var temp: any = new Sortable(element, {
     group: {
         name: 'shared',
         pull:true
     },
     //scroll: true, // Enable the plugin. Can be HTMLElement.
    // forceAutoscrollFallback: false, // force autoscroll plugin to enable even when native browser autoscroll is available
    // scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
    // scrollSpeed: 10, // px, speed of the scrolling
   //  bubbleScroll: true,// apply autoscroll to all parent elements, allowing for easier movement
     animation: 100,
     	// Element is dropped into the list from another list
	onAdd: (/**Event*/evt) => {

    if(evt.item.outerHTML.includes("<div")){
    var tempText = evt.item.outerHTML.split('type="');
    var tempText1 = tempText[1].split('"');
    console.log(tempText1[0]);
    // Set ID value and Increst last number by one and store it to the Count Var
    var tempHTML = this.formTemplete[''+tempText1[0]+''];
    if(tempHTML !== undefined){
      if(tempHTML.search("input-#") !== -1 && tempHTML.search("input-#") !== undefined && tempHTML.search("input-#")){
          while(tempHTML.search("input-#") !== -1){
          tempHTML = tempHTML.replace("input-#", "input-"+this.formTemplete['elementCount']+"");
          }
        }

          this.formTemplete['elementCount'] = this.formTemplete['elementCount'] + 1;
          evt.item.outerHTML = tempHTML;
       }
    }

	},
  onChange: (/**Event*/evt) =>  {
	//evt.newIndex // most likely why this event is used is to get the dragging element's current index
    //var element = document.getElementById('formBody');
    //this.formLayoutdata = htmlParser(element!.innerHTML);
    //this.formhtmltojsonService.convertjsontoinsightsjson( this.formLayoutdata).subscribe(()=>{
///
   // });
    console.log(this.formLayoutdata);
		// same properties as onEnd
	},
 });
 //temp.mount(new AutoScroll());

 this.sortablesElements.push(temp);

  });


/////////////////////////////////////////////////////
//
// Setup Draggable Rows to allow sorting of Rows
//
///////////////////////////////////////////////////



tempRowSort.forEach((element: any)=>{
  var temp: any = new Sortable(element, {
    group: {
        name: 'rowSort',
        pull: ['rowSort'], // To clone: set pull to 'clone'
        put:['rowSort']
    },
    sort:false,
    animation: 150,
    removeCloneOnHide: true,
 //   scroll: true, // Enable the plugin. Can be HTMLElement.
  //  forceAutoscrollFallback: true, // force autoscroll plugin to enable even when native browser autoscroll is available
  //  scrollSensitivity: 300, // px, how near the mouse must be to an edge to start scrolling.
  //  scrollSpeed: 10, // px, speed of the scrolling
  //  bubbleScroll: true,// apply autoscroll to all parent elements, allowing for easier movement
      // Element dragging ended
       // Called when creating a clone of element
       onAdd: (/**Event*/evt) => {

        if(evt.item.outerHTML.includes("<div")){
        var tempText = evt.item.outerHTML.split('type="');
        var tempText1 = tempText[1].split('"');
        console.log(tempText1[0]);


        //this.formTemplete['elementCount'] = this.formTemplete['elementCount'] + 1;
        evt.item.outerHTML = this.rowTemplete[''+tempText1[0]+''];

          this.setup_draggable();
        }

      },

});
//temp.mount(new AutoScroll());

this.sortablesElementsRows.push(temp);

 });
};




if(this.sortablesElements.length < 1){
  setup();
}
else{
  this.sortablesElements.forEach((elem)=>{
    elem.destroy();
  });

  setup();
}


  }



///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Setup to make the Rows Sort and Dragable independently from the Elements
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Used to Convert Char's to another standard or Parsing without Error
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////


escapeHtml = (unsafe) => {
  return unsafe.replaceAll('&', '&').replaceAll('<', '<').replaceAll('>', '>').replaceAll('"', '\"').replaceAll("'", '\'');
}




///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Submit the Current Form Layout to The API for Conversion to Json and Save
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////



onLayoutUpdate(form_id: any,completePageLayout: any): void {



  var element = document.getElementById('formBody');
  this.formLayoutdata = htmlParser(element!.innerHTML);

    var $copy = $(".form-body").parent().clone().appendTo(document.body);
   // $copy.find(".tools, :hidden").remove();
   $copy.find(".tools").remove();
    $.each(["draggable", "droppable", "sortable", "dropped",
      "ui-sortable", "ui-draggable", "ui-droppable", "form-body"], (i, c) => {
      $copy.find("." + c).removeClass(c);
      console.log($copy);
    })
   // $copy.removeAttr("style");
  // console.log(this.escapeHtml($copy.html()));
  console.log(JSON.stringify($copy.html()));
  var formdata = {form_id:form_id,form_layout_data:this.formLayoutdata,form_paragraphbox_data:this.form_paragraphbox_data,complete_page_layout:completePageLayout};
  var html = this.escapeHtml($copy.html());
  $copy.remove();




   //  this.formbuilderservice.setup_draggable();

  //const { username, password } = this.form;
  const headers = { 'content-type': 'application/json'}
 // this.form.map_id = this.route.snapshot.paramMap.get('map_id');
 // this.form.map_lat = parseFloat(this.form.map_lat);
 // this.form.map_long = parseFloat(this.form.map_long);
//  this.form.map_zoom = parseFloat(this.form.map_zoom);
 // this.form.map_enable = Boolean(this.form.map_enable);
  const body=JSON.stringify(formdata);
    this.http.post(''+this.globalSetting['api_url_console']+'/geofi/forms?api=updateformlayout',body,{'headers':headers}).subscribe(resp => {
    console.log(resp);
    if(!resp['error']){
      Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
       // this.router.navigate(['/geomap/maps/listforms'])
      });
    }
    else{
      Swal.fire('Failed', 'Error!', 'error');
    }
    //this.rerender();
});

}




///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Submit the Current Form Layout to The API for Conversion to Json and Save
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////



onFormNew(form_id: any,form_data:any,completePageLayout: any): void {



  var element = document.getElementById('formBody');
  this.formLayoutdata = htmlParser(element!.innerHTML);

    var $copy = $(".form-body").parent().clone().appendTo(document.body);
   // $copy.find(".tools, :hidden").remove();
   $copy.find(".tools").remove();
    $.each(["draggable", "droppable", "sortable", "dropped",
      "ui-sortable", "ui-draggable", "ui-droppable", "form-body"], (i, c) => {
      $copy.find("." + c).removeClass(c);
      console.log($copy);
    })
   // $copy.removeAttr("style");
  // console.log(this.escapeHtml($copy.html()));
  console.log(JSON.stringify($copy.html()));
  if(form_data['form_enable'] == 'true'){form_data['form_enable'] = true}else{form_data['form_enable'] = false}
  form_data['form_id'] = form_id;
  form_data['form_layout_data'] = this.formLayoutdata;
  form_data['form_paragraphbox_data'] = this.form_paragraphbox_data;
  form_data['complete_page_layout'] = completePageLayout;

  var html = this.escapeHtml($copy.html());
  $copy.remove();




   //  this.formbuilderservice.setup_draggable();

  //const { username, password } = this.form;
  const headers = { 'content-type': 'application/json'}
 // this.form.map_id = this.route.snapshot.paramMap.get('map_id');
 // this.form.map_lat = parseFloat(this.form.map_lat);
 // this.form.map_long = parseFloat(this.form.map_long);
//  this.form.map_zoom = parseFloat(this.form.map_zoom);
 // this.form.map_enable = Boolean(this.form.map_enable);

  const body=JSON.stringify(form_data);
  https://api.myininsights.com/geofi/forms?api=newform
    this.http.post(''+this.globalSetting['api_url_console']+'/geofi/forms?api=newform',body,{'headers':headers}).subscribe(resp => {
    console.log(resp);
    if(!resp['error']){
      Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
       // this.router.navigate(['/geomap/maps/listforms'])
      });
    }
    else{
      Swal.fire('Failed', 'Error!', 'error');
    }
    //this.rerender();
});

}




///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Edit an Input Element with UI Popup Window
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////



onEditRow(ElementID: any, type: any): void{


  if(type=="row"){
  this.rowControlService.onEdit(ElementID,"").subscribe((html)=>{
    //this.form_paragraphbox_data[''+ElementID.find('div').filter('[type=paragraphbox]')![0].id+''] = html;
  });
  }


  }



///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Load Current FormLayout Data
//
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////


load_formLayout(form_id: string): Observable<any>{

  return new Observable(observer => {

      this.http.get(''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformdetails&formkey='+form_id,{}).subscribe( async (resp: any) => {

        //console.log(resp);

        // Load All Raw HTML and Storage DATA Storage Object
        this.load_paragraphbox_data(resp['form_layout']);

        observer.next(resp);
        //this.rerender();
    });

  });
}


///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Load All Paraghraph and Raw HTML Data into Storage Object or Save
//
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////


load_paragraphbox_data(formData: any): void{

  formData.forEach(async (formRows)=>{
    formRows['layout_fields'].forEach(async (formCols)=>{
      formCols['col'].forEach(async (formElements)=>{
        if(formElements['field_type'] == 'paragraphbox'){
          this.form_paragraphbox_data[''+formElements['field_id']+''] = formElements['field_value'];
          console.log(formElements['field_id']);
        }
        else if(formElements['field_type'] == 'htmlarea'){
          this.form_paragraphbox_data[''+formElements['field_id']+''] = formElements['field_value'];
          console.log(formElements['field_id']);
        }
      });
    });
  });

}




}
