import { Injectable } from '@angular/core';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
var L = require('leaflet');

//Import Form Entry Viewer Control Service
import { EntryViewerControlService } from '../../../../../../_services/modules/geomap/forms/formdata-viewer/entry-viewer-control.service';
// Maps Service Import 
import { MapsService } from '../../../../../../_services/maps.service';
// Maps Management Service Import 
import { MapMangementService } from '../../../../../../_services/modules/geomap/maps/map-management.service'; 
//Import Form Enrry Control Service
import { FormEntryControlService } from '../../../../../../_services/modules/geomap/forms/form-entry-control.service';
// Import Form Control Service
import { FormControlService } from '../../../../../../_services/modules/geomap/forms/form-control.service';
// Excel File Export Service
import { XlsxExportService } from '../../../../../../_services/file_services/excel/xlsx-file.service';



@Injectable({
  providedIn: 'root'
})
export class FormEntriesViewerService {
  // Global Slelect Polygon Object
  selectAreaPolygon: any;

  // Used for detecting changes for removing or adding form layers
  currentlyselectedFormLayers: any = [];
  previouslyselectedFormLayers: any = [];
  /////////////////////////////////////////////////////////////////

  // Used for detecting changes for Removing or Adding Polygon Filter
  currentlySelectedPolyFilter: any = [];
  previouslySelectedPolyFilter: any = [];
  /////////////////////////////////////////////////////////////////

  //Holds Polygon and Form Data to Display option for the user on the map Menu Bar 
  mapFormslist: any;
  mapPolylist: any;
  //////////////////////////////////////////////////////////////////

  // Stores speedtest thresholds for entry marker colors
  SpeedtestThresholds: any = {};
  //////////////////////////////////////////////////////////////////


  entryMap: any = {};
  entryMapPolys: any;

  formMarkerLayers: any={};
  formData: any = {};

constructor(
  private entryViewerControlService:EntryViewerControlService,
  private mapMangementService:MapMangementService,
  private mapsservice: MapsService,
  private formEntryControlService: FormEntryControlService,
  private formControlService: FormControlService,
  private xlsxExportService:XlsxExportService
  ) {this.entryMap = null;}



////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


initEntryMap(map_id: string):Observable<any>{

return new Observable(observer => {

  this.loadMap(map_id).then(async (mapData) => {

    this.loadPolygons(map_id).then(async (polyData) => {

      this.loadAreaSelect(map_id).then(async (areaData) => {
        
        this.initEntryMarkers(map_id);
       // this.loadMapMarkers(map_id).then(async (markerData) => {
        observer.next({error:false});
        observer.complete();
       // });

      });

    });

  });

});
}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

loadMap(map_id: string): Promise<any>{
return new Promise(async (resolve)=>{
this.mapMangementService.getMapDetails(map_id).subscribe((mapData)=>{

  this.mapsservice.InitMapWithCallback(this.entryMap,"map",mapData['map_lat'],mapData['map_long'],mapData['map_zoom'], async (returnMap)=>{
    //console.log(returnMap);
    this.entryMap = returnMap;
    //this.entryMapPolys = returnMap['polyObjs'];
    resolve(returnMap);

  });
});
});

}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

loadPolygons(map_id: string):Promise<any>{
return new Promise(async (resolve)=>{

var tempPolygonObjs; 
this.mapMangementService.getPolysWithGeojson("",map_id).subscribe(async (polyData)=>{


  // Neeed to display the Polygon Option in the Map Menu Bar
  this.mapPolylist = polyData;
  /////////////////////////////////////////

  polyData.forEach(async (poly) => {
    this.currentlySelectedPolyFilter.push(poly['_id']);
    this.previouslySelectedPolyFilter.push(poly['_id']);
  });

  this.mapsservice.load_basic_polys(this.entryMap, polyData, (returnMap)=> {

    this.entryMap = returnMap['map'];
    tempPolygonObjs = returnMap['polyObjs'];
    resolve(returnMap);
  });

});
});

}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

loadAreaSelect(map_id: string): Promise<any>{
    return new Promise(async (resolve)=>{

      /////////////////////////////////////////
        // Setup Dymaic Area Select on the Map
        /////////////////////////////////////////

        var { DrawAreaSelection } = require('@bopen/leaflet-area-selection');

        const areaSelection = new DrawAreaSelection({
          onPolygonReady: (polygon) => {
            const preview = document.getElementById('polygon');
            preview!.textContent = JSON.stringify(polygon.toGeoJSON(3), undefined, 2);
            preview!.scrollTop = preview!.scrollHeight;
            this.selectAreaPolygon = polygon;
          // this.selectedPolygonExport(this.selectAreaPolygon);
          },
          onPolygonDblClick: (polygon, control, ev) => {
          
            const geojson = L.geoJSON(polygon.toGeoJSON(), {
              style: {
                opacity: 0.5,
                fillOpacity: 0.2,
                color: 'red',
              },
            });
            geojson.addTo(this.entryMap);
            control.deactivate();
          },
          onButtonActivate: () => {
            const preview = document.getElementById('polygon');
            preview!.textContent = 'Please, draw your polygon';
            console.log('Please, draw your polygon');
          },
          onButtonDeactivate: (polygon) => {
            const preview = document.getElementById('polygon');
            console.log('Deactivated');
            preview!.textContent = `Deactivated! Current polygon is:
        ${polygon ? JSON.stringify(polygon.toGeoJSON(3), undefined, 2) : 'null'}`;
          },
          position: 'topleft',
        });

        this.entryMap.addControl(areaSelection);
        resolve(null);
      });

}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


initEntryMarkers(map_id: string): void{

this.getEntryMapFormList(map_id).then(async (formsList)=>{
  this.formData = formsList;
  this.getMapFormsData(formsList).then(async (formData)=>{

    this.getMapFormsEntryData(formsList).then(async (entryData)=>{

      formsList.forEach(async (form_id: any) => {
        console.log(entryData);
        this.processFormEntries(form_id['_id'], formData, entryData[''+form_id['_id']+'']).then(async (processedData)=>{


        });

      });

    });

  });

});

}





////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

getEntryMapFormList(map_id: string): Promise<any>{
return new Promise(async (resolve)=>{

  this.getMapFormsList(map_id).then(async (formsList)=>{

    // Needed to Display the Form Option in the menu Bar 
    this.mapFormslist = formsList['formList'];
    resolve(formsList['formList']);

  });

});

}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

getMapFormsData(forms_list: any): Promise<any>{
return new Promise(async (resolve)=>{
  //this.mapFormslist
  var tempFromData: any = {};
  var tempPromiseArray: any = [];
  forms_list.forEach(async (form_id: any, index)=>{

    tempPromiseArray.push(new Promise((formResolve)=>{

    this.currentlyselectedFormLayers.push(''+form_id['_id']+'');
    this.previouslySelectedPolyFilter.push(''+form_id['_id']+'');

      
        this.formControlService.getFormDetails('',form_id['_id']).subscribe( async (formDetailData: any) => {
          tempFromData[''+form_id['_id']+''] = formDetailData;
          formResolve(tempFromData);
        });
     // this.formData = tempFromData;
    }));
 });

  Promise.all(tempPromiseArray).then((data)=>{
    //console.log(tempFromData);
    resolve(tempFromData);
  });

});
}




////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

getMapFormsEntryData(forms_list: any): Promise<any>{

return new Promise(async (resolve)=>{
  var tempEntryData: any = {};
  var tempPromiseArray: any = [];

  forms_list.forEach(async (form_id: any,index)=>{
    tempPromiseArray.push(new Promise((entryResolve)=>{
    this.formEntryControlService.getFormEntries("",form_id['_id'],'none',1000).subscribe(async (formEntries: any)=>{
      console.log(formEntries);
      if(formEntries.length > 0){

        tempEntryData[''+form_id['_id']+''] = formEntries;
        entryResolve(null);
      }
      else{
        tempEntryData[''+form_id['_id']+''] = [];
        entryResolve(null);
        console.log('No Form Entries Found');
      }

    });
 }));
});
Promise.all(tempPromiseArray).then((data)=>{
  //console.log(tempFromData);
  resolve(tempEntryData);
});

});

}






////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

processFormEntries(form_id:string, form_data: any, enties_data: any): Promise<any>{
return new Promise(async (resolve)=>{
  console.log("Test: ",enties_data);
  var tempformMarkerLayer: any = [];

  enties_data.forEach(async (entry_data: any, index) => {
    console.log(form_data);
    //SpeedTest Form Entry
    if(form_data[''+entry_data['form_id']+'']['form_type'] == 1){
      console.log(entry_data['speedtest_data']);
      if(entry_data['speedtest_data'] !== undefined){

        this.customSpeedtestMapMarker(form_data, entry_data).then(async (entryMarkerData: any)=>{
          tempformMarkerLayer.push(entryMarkerData);
        });

      }
      else{

       this.customStandardMapMarker(form_data, entry_data).then(async (entryMarkerData: any)=>{
          tempformMarkerLayer.push(entryMarkerData);
        });

      }

    }
    // Standard Form Entry
    else if(form_data[''+entry_data['form_id']+'']['form_type'] == 0){

      this.customStandardMapMarker(form_data, entry_data).then(async (entryMarkerData: any)=>{
        tempformMarkerLayer.push(entryMarkerData);
      });

    }

    else{
      console.log('Form Type Not Found');
    }

    if((index+1) >= enties_data.length){
      // add the Form Markers Layer to the Total Form Layers array
      this.formMarkerLayers[''+form_id+''] = tempformMarkerLayer;
      resolve(tempformMarkerLayer);
    }

  });


});
}





////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

getSpeedtestThresholds(): Promise<any>{
return new Promise(async (resolve)=>{

  resolve({
    "green" : {
      "upload" : 50.0,
      "download" : 250.0
  },
  "yellow" : {
      "upload" : 10.0,
      "download" : 50.0
  },
  "orange" : {
      "upload" : 3.0,
      "download" : 25.0
  },
  "red" : {
      "upload" : 1.0,
      "download" : 10.0
  }
  });

});
}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

calculateSpeedestMarkerColor(speedtest_data:any, speedtestThresholds: any): Promise<any>{
return new Promise(async (resolve)=>{

  var markerColor = 'red';

  if(speedtest_data['dlStatus'] >= speedtestThresholds['green']['download'] && speedtest_data['ulStatus'] >= speedtestThresholds['green']['upload']){
    markerColor = 'green';
  }
  else if(speedtest_data['dlStatus'] >= speedtestThresholds['yellow']['download'] && speedtest_data['ulStatus'] >= speedtestThresholds['yellow']['upload']){
    markerColor = 'yellow';
  }
  else if(speedtest_data['dlStatus'] >= speedtestThresholds['orange']['download'] && speedtest_data['ulStatus'] >= speedtestThresholds['orange']['upload']){
    markerColor = 'orange';
  }
  else{
    markerColor = 'red';
  }

  resolve(markerColor);


});
}





////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

customSpeedtestMapMarker(form_data:any,entry_data: any): Promise<any>{
return new Promise(async (resolve)=>{

  this.getSpeedtestThresholds().then(async (threshhold: any)=>{

    this.calculateSpeedestMarkerColor(entry_data['speedtest_data'], threshhold).then(async (markerColor: any)=>{

      if(entry_data['form_longlat']['lat']!== null && entry_data['form_longlat']['lat'] !== '' && entry_data['form_longlat']['lat'] !== undefined){

        var greenIcon = new L.Icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-'+markerColor+'.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        });
  
  
        var tempfirstnameid = '';
        var templastnameid = '';
        var tempaddressid = '';
      
        form_data[''+entry_data['form_id']+'']['form_keyed_elements'].forEach((formData)=>{
  
          if(formData['name'] == 'keyed_firstname'){
            tempfirstnameid = formData['id'];
          }
          else if(formData['name'] == 'keyed_lastname'){
            templastnameid = formData['id'];
          }
          else if(formData['name'] == 'keyed_address'){
            tempaddressid = formData['id'];
          }
        });
        
      if(entry_data['form_longlat']['lat'] !== null && entry_data['form_longlat']['long'] !== null){
        resolve({id: entry_data['_id'],polylocid:entry_data['form_longlat']['poly_loc_id'],formData:entry_data['form_data'],poly_name: entry_data['form_longlat']['poly_name'],marker:L.marker([entry_data['form_longlat']['lat'], entry_data['form_longlat']['long']], {icon: greenIcon}).addTo(this.entryMap).bindPopup('<a href="javascript:void(0);" id="Poly_click" onclick="" class="closebtn edit" data-toggle="modal" data-target="#step-modal">'+entry_data['form_data'][''+tempfirstnameid+'']+','+entry_data['form_data'][''+templastnameid+'']+' <br> '+entry_data['form_data'][''+tempaddressid+'']+' </a>',{closeButton: false}).on("popupopen", () => {
         // this.elementRef.nativeElement
            //.querySelector("#Poly_click")
            //.addEventListener("click", (e: any) => {
              //alert("Removing::"+ element['_id']);
              //this.removePoly(element['_id']);
  
  
  
                  //  this.router.navigate(['/geomap/form/entrydetail',{form_id:entryData['form_id'], entry_id: entryData['_id']}]);
  
  
  
              //window.location.replace('https://geofiforms.myiinsights.com/initform;formkey='+isfoundPoly['poly_form_url_id']+';address='+item['address']+';city='+item['city']+';state='+item['state']+';zip='+item['zip']+';lat='+item['lat']+';long='+item['long']+';polylocid='+polyLocid);
              //window.open(' https://geofiforms.myiinsights.com/initform;formkey=62963f030ce9b103ab8c2ad1', '_blank');
            //});
        })
      });
    }
  }

    });

  });

});
}



////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

customStandardMapMarker(form_data:any, entry_data: any): Promise<any>{

return new Promise(async (resolve)=>{

  if(entry_data['form_longlat']['lat']!== null && entry_data['form_longlat']['lat'] !== '' && entry_data['form_longlat']['lat'] !== undefined){

    var greenIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });


    var tempfirstnameid = '';
    var templastnameid = '';
    var tempaddressid = '';
  
    form_data[''+entry_data['form_id']+'']['form_keyed_elements'].forEach((formData)=>{

      if(formData['name'] == 'keyed_firstname'){
        tempfirstnameid = formData['id'];
      }
      else if(formData['name'] == 'keyed_lastname'){
        templastnameid = formData['id'];
      }
      else if(formData['name'] == 'keyed_address'){
        tempaddressid = formData['id'];
      }
    });
    
  if(entry_data['form_longlat']['lat'] !== null && entry_data['form_longlat']['long'] !== null){
    resolve({id: entry_data['_id'],polylocid:entry_data['form_longlat']['poly_loc_id'],formId:entry_data['form_id'],formData:entry_data['form_data'], poly_name: entry_data['form_longlat']['poly_name'],marker:L.marker([entry_data['form_longlat']['lat'], entry_data['form_longlat']['long']], {icon: greenIcon}).addTo(this.entryMap).bindPopup('<a href="javascript:void(0);" id="Poly_click" onclick="" class="closebtn edit" data-toggle="modal" data-target="#step-modal">'+entry_data['form_data'][''+tempfirstnameid+'']+','+entry_data['form_data'][''+templastnameid+'']+' <br> '+entry_data['form_data'][''+tempaddressid+'']+' </a>',{closeButton: false}).on("popupopen", () => {
     // this.elementRef.nativeElement
        //.querySelector("#Poly_click")
        //.addEventListener("click", (e: any) => {
          //alert("Removing::"+ element['_id']);
          //this.removePoly(element['_id']);



              //  this.router.navigate(['/geomap/form/entrydetail',{form_id:entryData['form_id'], entry_id: entryData['_id']}]);



          //window.location.replace('https://geofiforms.myiinsights.com/initform;formkey='+isfoundPoly['poly_form_url_id']+';address='+item['address']+';city='+item['city']+';state='+item['state']+';zip='+item['zip']+';lat='+item['lat']+';long='+item['long']+';polylocid='+polyLocid);
          //window.open(' https://geofiforms.myiinsights.com/initform;formkey=62963f030ce9b103ab8c2ad1', '_blank');
        //});
    })
  });
}
}

});
}




////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


getMapFormsList(map_id: string): Promise<any>{
  return new Promise((resolve)=>{
      this.entryViewerControlService.getMapFormList('',map_id).subscribe(async (formsList)=>{
        console.log(formsList);
        resolve({error: false, formList: formsList['forms']})
    });
  });
}



////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////




updateFormLayers(): void{
  // alert("I changed");
 
   //let difference = this.previouslyselectedFormLayers.filter(x => !this.currentlyselectedFormLayers.includes(x)).concat(this.currentlyselectedFormLayers.filter(x => !this.previouslyselectedFormLayers.includes(x)));
  let removeMakers = this.previouslyselectedFormLayers.filter(x => !this.currentlyselectedFormLayers.includes(x));
  let addMakers = this.currentlyselectedFormLayers.filter(x => !this.previouslyselectedFormLayers.includes(x));
   //console.log(addMakers);
   removeMakers.forEach((removeMarker)=>{
     this.formMarkerLayers[''+removeMarker+''].forEach((marker)=>{
       this.entryMap.removeLayer(marker['marker']);
     });
   });
 
     addMakers.forEach((addMarker)=>{
       this.formMarkerLayers[''+addMarker+''].forEach((marker)=>{
         marker['marker'].addTo(this.entryMap);
       });
 
   });
 
 
 
 this.previouslyselectedFormLayers = this.currentlyselectedFormLayers;
 }
 
 
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


 updatePolygonFilter(): void{
 
   let removeMakers = this.previouslySelectedPolyFilter.filter(x => !this.currentlySelectedPolyFilter.includes(x));
   let addMakers = this.currentlySelectedPolyFilter.filter(x => !this.previouslySelectedPolyFilter.includes(x));
    //console.log(addMakers);
    removeMakers.forEach((removeMarker)=>{
     this.currentlyselectedFormLayers.forEach((formLayers)=>{
       this.formMarkerLayers[''+formLayers+''].forEach((marker)=>{
         if(marker['polylocid'] == removeMarker){
          this.entryMap.removeLayer(marker['marker']);
         }
        });
     });
 
    });
 
     addMakers.forEach((addMarker)=>{
     this.currentlyselectedFormLayers.forEach((formLayers)=>{
       this.formMarkerLayers[''+formLayers+''].forEach((marker)=>{
         if(marker['polylocid'] == addMarker){
           marker['marker'].addTo(this.entryMap);
         }
        });
     });
 
    });
 
    this.previouslySelectedPolyFilter = this.currentlySelectedPolyFilter;
 }
 
 
 
 
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
 
 
 
 selectedPolygonExport(Polygon: any): void{
 
   console.log(Polygon);
     var exportList: any = [];
       this.currentlyselectedFormLayers.forEach(async (formLayers)=>{
        if(this.formMarkerLayers[''+formLayers+''] !== undefined){
         this.formMarkerLayers[''+formLayers+''].forEach(async (marker: any)=>{
           this.currentlySelectedPolyFilter.forEach(async (polygon_id: any)=>{
             if(this.isMarkerInsidePolygon(marker['marker'], Polygon)) {
               if(polygon_id == marker['polylocid']) {
 
                 exportList.push(marker);
 
               }
           }
           });
          
         });
        }
       });
 
   console.log(exportList);
  var ExportTable: any = [];
   exportList.forEach(async (entry,index)=>{
 
       ExportTable.push(entry);
 
       if((index+1) >= exportList.length){
        this.formatExcelExport(ExportTable);
       }
   });
  // const myTimeout = setTimeout(()=>{this.exportexcel();}, 1000);
 }





////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
 
 
 
formatExcelExport(exportedData: any): void{
console.log(this.formData);

this.formData.forEach(async (form: any)=>{

var exportTableData: any = [];
var tempExportHeaderPromise: any = [];




Object.keys(exportedData[0]['formData']).forEach((key)=>{
      tempExportHeaderPromise.push(new Promise((headerResolve)=>{
        if(form['form_headers'] !== undefined){
          form['form_headers'].forEach((header: any)=>{
        
            if(header['id'].toString() == key){
              headerResolve(header['name']);
            }

          });
        }else{
          headerResolve("No Name");
        }
    }));
});

Promise.all(tempExportHeaderPromise).then(async (exportHeader: any)=>{
  exportHeader.push('Polygon Name');
  exportTableData.push(exportHeader);
  parseRowData();

});


const parseRowData = async ()=>{
  var exportTablePromise: any = [];
    exportedData.forEach(async (entry: any, index)=>{
      
      if(form['_id'] == entry['formId']){
  
      exportTablePromise.push(new Promise((tableResolve)=>{

      var tempRowPromises: any = [];

      Object.values(entry['formData']).forEach(async(rowData: any)=>{

        tempRowPromises.push(new Promise((rowResolve)=>{


          rowResolve(rowData);

        }));

      });
    
      Promise.all(tempRowPromises).then((rowValues: any)=>{
        rowValues.push(entry['poly_name']);
        tableResolve(rowValues);
      });

    }));
  }
  // ExportTable.push(Object.values(entry));
  if((index+1)>=exportedData.length){
   
    Promise.all(exportTablePromise).then((exportTable: any)=>{
     
      exportTable.forEach((row: any,index)=>{

        exportTableData.push(row);

        if((index+1)>=exportTable.length){
          console.log("Title: ",form['form_name'],"Data: ",exportTableData);
          this.xlsxExportService.exportAsExcelFile( exportTableData,form['form_name']);
        }

      });
     
    });  
  }
  });

}

});




// console.log(Object.entries(exportedData));
//this.xlsxExportService.exportAsExcelFile(Object.values(ExportTable), 'export');

}



////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
//
//
//
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
 
 
 isMarkerInsidePolygon(marker, poly) {
   var inside = false;
   var x = marker.getLatLng().lat,
     y = marker.getLatLng().lng;
   for (var ii = 0; ii < poly.getLatLngs().length; ii++) {
     var polyPoints = poly.getLatLngs()[ii];
     for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
       var xi = polyPoints[i].lat,
         yi = polyPoints[i].lng;
       var xj = polyPoints[j].lat,
         yj = polyPoints[j].lng;
 
       var intersect = ((yi > y) != (yj > y)) &&
         (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
       if (intersect) inside = !inside;
     }
   }
   console.log(inside);
   return inside;
 };
 




}
