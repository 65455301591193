import { Component, OnInit,OnDestroy,AfterViewInit,ViewChild,Inject,ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {MatMenuTrigger} from '@angular/material/menu';

import { Observable } from 'rxjs';
//////////////////////////////
//Page Level Service Import///
//////////////////////////////

// Global Imports
import { CustomcssService } from '../../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../../_services/all-page-generals.service';



//Import Form Entry Viewer
import { FormEntriesViewerService } from './form-entreies-viewer/form-entries-viewer.service';

declare var L: any;

@Component({
  selector: 'app-form-entries-viewer',
  templateUrl: './form-entries-viewer.component.html',
  styleUrls: ['./form-entries-viewer.component.css']
})
export class FormEntriesViewerComponent implements OnInit,AfterViewInit,OnDestroy {
// we create an object that contains coordinates
menuTopLeftPosition =  {x: '0', y: '0'}

// reference to the MatMenuTrigger in the DOM
//@ViewChild( MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
@ViewChild('clickHoverMenuTrigger') matMenuTrigger: MatMenuTrigger;

/**
 * Method called when the user click with the right button
 * @param event MouseEvent, it contains the coordinates
 * @param item Our data contained in the row of the table
 */
onRightClick(event: MouseEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = {item: item}

    // we open the menu
    this.matMenuTrigger.openMenu();

}



  entryMap: any;

  constructor(
    private route: ActivatedRoute,
    public formEntriesViewerServiceService: FormEntriesViewerService,
    ) { }


    ngOnInit(): void {

    
     this.formEntriesViewerServiceService.initEntryMap(this.route.snapshot.paramMap.get('map_id')!.toString()).subscribe(async (mapData) => {


     
     });

    }

    ngOnDestroy() {
      window.location.reload();
    }

    ngAfterViewInit(): void {



  }








}



