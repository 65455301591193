import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../_services/token-storage.service';
import { Observable } from 'rxjs';
import { from, lastValueFrom } from "rxjs";
import FingerprintJS  from '@fingerprintjs/fingerprintjs'
// const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end
const USER_FINGERPRINT_KEY = 'b-user-token';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.handle(req,next));



  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      // for Spring Boot back-end
      // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

      // for Node.js Express back-end

        // Get the visitor identifier when you need it.

       // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
        const fpPromise = FingerprintJS.load({monitoring: false});
       await fpPromise.then(async (test: any)=>{
        await test.get().then(async (tst2: any)=>{
         // console.log(tst2);
         // authReq = authReq.clone({ headers: req.headers.set(USER_FINGERPRINT_KEY, tst2.visitorId) });
          authReq = req.clone({
           setHeaders: {
            'x-access-token': token,
            'b-user-token':tst2.visitorId
            }
         });
    });
  });
    }
    else{
      const fpPromise = FingerprintJS.load({monitoring: false});
      await fpPromise.then(async (test: any)=>{
       await test.get().then(async (tst2: any)=>{
         console.log(tst2.visitorId);
        // authReq = authReq.clone({ headers: req.headers.set(USER_FINGERPRINT_KEY, tst2.visitorId) });
         authReq = req.clone({
          setHeaders: {
           'b-user-token':tst2.visitorId
           }
        });
   });
 });
    }


    return await lastValueFrom(next.handle(authReq));

}

}



export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
