import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomcssService {

  constructor() { }



  public load_switches_css() {
    var url = "";
    //this.css_loader(url);
  }

  // Default style for Datatables both Dark and Light Mode
  public load_Datatables_Default_Style() {
    var url = "assets/src/plugins/src/table/datatable/datatables.css";
    var url1 = "assets/src/plugins/css/light/table/datatable/dt-global_style.css";
    var url2 = "assets/src/plugins/css/dark/table/datatable/dt-global_style.css";
    //var url3 = "assets/src/plugins/src/table/datatable/datatables.css";
    //"src/assets/plugins/table/datatable/custom_dt_html5.css"
    this.css_loader(url);
    this.css_loader(url1);
    this.css_loader(url2);

  }


  // Default style for Vanilla Select Box both Dark and Light Mode
  public load_vanillaSelectBox_Style() {
    var url = "assets/src/plugins/src/vanillaSelectBox/vanillaSelectBox.css";
    var url1 = "assets/src/plugins/css/dark/vanillaSelectBox/custom-vanillaSelectBox.css";
    var url2 = "assets/src/plugins/css/light/vanillaSelectBox/custom-vanillaSelectBox.css";
    //var url3 = "assets/src/plugins/src/table/datatable/datatables.css";
    //"src/assets/plugins/table/datatable/custom_dt_html5.css"
    this.css_loader(url);
    this.css_loader(url1);
    this.css_loader(url2);

  }


    // Default style for Vanilla Select Box both Dark and Light Mode
  public load_formwizard_Styling() {
    var url = "https://cdn.jsdelivr.net/npm/bs-stepper/dist/css/bs-stepper.min.css";
   // var url1 = "assets/src/plugins/css/light/stepper/custom-bsStepper.css";
    //var url2 = "assets/src/plugins/src/stepper/bsStepper.min.css";
    //var url3 = "assets/src/plugins/src/table/datatable/datatables.css";
    //"src/assets/plugins/table/datatable/custom_dt_html5.css"
    this.css_loader(url);
   // this.css_loader(url1);
   // this.css_loader(url2);

  }

    // Default style for Vanilla Select Box both Dark and Light Mode
    public load_Auth_Page_Layout() {
      var url = "assets/src/assets/css/light/authentication/auth-cover.css";
      var url1 = "assets/src/assets/css/dark/authentication/auth-cover.css";
      this.css_loader(url);
      this.css_loader(url1);

    }



    // Default style for Datatables both Dark and Light Mode
    public load_table_style_handsontable() {
      var url = "assets/insights/css/tables/handsonTableStyle/handsontable.css";
      //var url3 = "assets/src/plugins/src/table/datatable/datatables.css";
      //"src/assets/plugins/table/datatable/custom_dt_html5.css"
      this.css_loader(url);

    }


 // Default CSS Link Injector
  private css_loader(url: string) {
    var head  = document.getElementsByTagName('head')[0];
    var link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    link.className = 'intempcssimport';
    head.appendChild(link);
  }


  public css_text_loader(text: string){

    const style = document.createElement("style");
    style.textContent = ""+text+"";
    //style.className = 'intempcssimport';
    document.getElementsByTagName('head')[0].appendChild(style);

    console.log("testing append");
  }




}
