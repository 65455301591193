import { Component, Input,OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionBase } from './_question_controltype/question-base';
import { QuestionControlService } from './_service/question-control.service';
import { QuestionService } from './_service/question.service';

//import Global Setting Varitables
import * as globalSettings from '../../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-new-form-entry',
  templateUrl: './new-form-entry.component.html',
  styleUrls: ['./new-form-entry.component.css']
})
export class NewFormEntryComponent implements OnInit,OnDestroy {

// Establish the Default Global Parms.
private globalSetting: any = globalSettings;

  @Input() addressData!: any;
  @Input() polygonResults!: any;
  @Input() map_form_id!: any;
  form_id: string='';

  questions$!: Observable<QuestionBase<any>[][][]> | any;
  thisservice: any;
  keyed_fields: any;
  packageTables: any;

  //Form Settings
  displayForm: boolean = false;
  formSettings: any = {};

  constructor(private qcs: QuestionControlService,private service: QuestionService,private route: ActivatedRoute, private router: Router,private http: HttpClient) { }

  ngOnDestroy() {
    window.location.reload();
  }

  ngOnInit(): void {

    if(this.route.snapshot.paramMap.get('form_id') !== '' && this.route.snapshot.paramMap.get('form_id') !== null && this.route.snapshot.paramMap.get('form_id') !== undefined){
      this.loadFormData(this.route.snapshot.paramMap.get('form_id')!.toString());
    }
    else if(this.map_form_id !== '' && this.map_form_id !== null && this.map_form_id !== undefined){
      this.loadFormData(this.map_form_id);
    }
  }


loadFormData(form_id: string): void{
  this.form_id = form_id;
  this.http.get(''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformdetails&formkey='+form_id,{}).subscribe(async (resp: any) => {

    console.log(resp);

    var TempArray: any= [];
    var rowCount = 0;
    var arraylayout = resp.form_layout;
    console.warn(resp.form_layout);
    await arraylayout.forEach(async (row: any)=>{
      if(row['layout_public'] == 'true'){
        TempArray.push(row);
      }
      rowCount++;
      if(rowCount >= arraylayout.length){
        resp.form_layout = TempArray;
        console.warn(resp.form_layout);
        this.questions$ = this.service.getQuestions(resp);
        this.keyed_fields = resp['form_keyed_elements'];
        this.formSettings = resp['form_settings'];
        this.formSettings['form_id'] = resp['_id'];
        this.formSettings['form_type'] = resp['form_type'];
        this.formSettings['form_name'] = resp['form_name'];
        this.formSettings['form_headers'] = resp['form_headers']
        this.formSettings['complete_page_layout'] = resp['complete_page_layout'];
        this.formSettings['form_conditonal_fields'] = resp['form_conditonal_fields'] || {};
        this.formSettings['poly_type'] = this.route.snapshot.paramMap.get('poly_type');
        this.formSettings['entry_status'] = this.route.snapshot.paramMap.get('entry_status');
        this.displayForm = true;
        //this.LoadingControlService.closeLoadingDialog();
      }
    });

  });

}

}
