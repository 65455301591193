import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//Import Logging Service

import { LogControlService } from '../../../../_services/log/log-control.service';



//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class MapMangementService {
// Global Settings
 private globalSetting: [] = globalSettings;




  constructor(private http: HttpClient, private loggingService: LogControlService) { }


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Maps for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getMapList(percentElementId: string): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geomap/?api=listmaps',  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });




  });
}




getMapDetails(map_id: any): Observable<any>{

  return new Observable(observer => {
    this.http.get(''+this.globalSetting['api_url_console']+'/geomap?api=getmapdetails&mapkey='+map_id,{}).subscribe((resp: any) => {

      observer.next(resp);

   // console.log(resp);
    //this.rerender();
});

  });
}






getPolysWithGeojson(percentElementId: string,map_id:any): Observable<any>{

  return new Observable(observer => {
  const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geomap?api=listpolygonsbymapwithgeojson&map_id='+map_id,  {
    reportProgress: true
  });

  this.http.request(request).subscribe(event => {

    // progress
    if (event.type === HttpEventType.DownloadProgress) {
      console.log(event.loaded, event.total);
      // event.loaded = bytes transfered
      // event.total = "Content-Length", set by the server
      if(percentElementId !== null && percentElementId !== undefined && percentElementId !== ''){
        if(typeof(event.total) !== 'undefined'){
            const percentage = 100 / event.total * event.loaded;
            let polyTableLoader = document.getElementById(percentElementId);
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
        }
    }
  }

    // finished
    if (event.type === HttpEventType.Response) {
      //console.log(event.body);
      observer.next(event.body);
    }

  });
 });


}














removeMap(map_id: any): Observable<any>{

return new Observable(observer => {

  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
       //const { username, password } = this.form;

  const headers = { 'content-type': 'application/json'}
  this.http.delete(''+this.globalSetting['api_url_console']+'/geomap?api=removemap&map_id='+map_id,{'headers':headers}).subscribe(
    data => {

      console.log('success', data);
      if(!data['error'])
      {
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      ).then(()=>{
        observer.next({error:data['error']});
      });
      }
      else
      {
        Swal.fire(
          'Failed!',
          'Your file has failed to be deleted.',
          'error'
        ).then(()=>{
          observer.next({error:data['error']});
        });
      }

    }
);

    }else{
      observer.next({error:true});
    }
  })

});

  //  return new Observable(observer => observer.next({error:true}));

  }












  disenMap(poly_id: any, enable: boolean): Observable<any>{

    return new Observable(observer => {
      var buttonText = "";
    if(!enable){
      buttonText = "Disable";
    }else{
      buttonText = "Enable";
    }
      Swal.fire({
        title: 'Are you sure?',
        text: "You are about to "+buttonText+" this map!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: ''+buttonText+'!'
      }).then((result) => {
        if (result.isConfirmed) {
           //const { username, password } = this.form;
      const headers = { 'content-type': 'application/json'}
      var tempJson = {
        map_id:poly_id,
        map_enable:enable
      };

      this.http.post(''+this.globalSetting['api_url_console']+'/geomap?api=disenmap',JSON.stringify(tempJson),{'headers':headers}).subscribe(
        data => {
          console.log('success', data);
          if(!data['error'])
          {
          Swal.fire(
            ''+buttonText+'!',
            'Your Map has been '+buttonText+'.',
            'success'
          ).then(()=>{
            observer.next({error:data['error']});
          });
          }
          else
          {
            Swal.fire(
              'Failed!',
              'Your file has failed to be '+buttonText+'.',
              'error'
            ).then(()=>{
              observer.next({error:data['error']});
            });
          }

        }
    );

        }else{
          observer.next({error:true});
        }
      })

    });




      //  return new Observable(observer => observer.next({error:true}));

      }





      removePoly(poly_id: any,map_id: any): Observable<any>{

        return new Observable(observer => {

          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
               //const { username, password } = this.form;
          const headers = { 'content-type': 'application/json'}

          this.http.delete(''+this.globalSetting['api_url_console']+'/geomap?api=removepoly&poly_id='+poly_id+'&map_id='+map_id,{'headers':headers}).subscribe(
            data => {
              console.log('success', data);
              if(!data['error'])
              {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              ).then(()=>{
                observer.next({error:data['error']});
              });
              }
              else
              {
                Swal.fire(
                  'Failed!',
                  'Your file has failed to be deleted.',
                  'error'
                ).then(()=>{
                  observer.next({error:data['error']});
                });
              }

            }
        );

            }else{
              observer.next({error:true});
            }
          })

        });

          //  return new Observable(observer => observer.next({error:true}));

          }












          disenPoly(poly_id: any, enable: boolean): Observable<any>{

            return new Observable(observer => {
              var buttonText = "";
            if(!enable){
              buttonText = "Disable";
            }else{
              buttonText = "Enable";
            }
              Swal.fire({
                title: 'Are you sure?',
                text: "You are about to "+buttonText+" this Polygon!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: ''+buttonText+'!'
              }).then((result) => {
                if (result.isConfirmed) {
                   //const { username, password } = this.form;
              const headers = { 'content-type': 'application/json'}
              var tempJson = {
                poly_id:poly_id,
                poly_enable:enable
              };

              this.http.post(''+this.globalSetting['api_url_console']+'/geomap?api=disenpoly',JSON.stringify(tempJson),{'headers':headers}).subscribe(
                data => {
                  console.log('success', data);
                  if(!data['error'])
                  {
                  Swal.fire(
                    ''+buttonText+'!',
                    'Your Polygon has been '+buttonText+'.',
                    'success'
                  ).then(()=>{
                    observer.next({error:data['error']});
                  });
                  }
                  else
                  {
                    Swal.fire(
                      'Failed!',
                      'Your Polygon has failed to be '+buttonText+'.',
                      'error'
                    ).then(()=>{
                      observer.next({error:data['error']});
                    });
                  }

                }
            );

                }else{
                  observer.next({error:true});
                }
              })

            });




              //  return new Observable(observer => observer.next({error:true}));

              }



              displayMap(map_url: any): Observable<any>{

                return new Observable(observer => {
                  Swal.fire({
                    title: '',

                    html:
                    '<iframe id="duo_iframe" style="height:650px; width:95%; overflow-y: auto;" overflow-x:hidden; frameBorder="0" scrolling="auto" '+
                   'title="Two-Factor Authentication"'+
                    'src="'+map_url+'"'+
                    ''+
                    '>'+
                    '</iframe>'+
                    ' ',

                    width: '90%',
                    showCloseButton: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    focusConfirm: false,
                    allowOutsideClick: false,
                    backdrop: true,
                    confirmButtonText:
                      '<i class="fa fa-thumbs-up"></i> Great!',
                    confirmButtonAriaLabel: 'Thumbs up, great!',
                    cancelButtonText:
                      '<i class="fa fa-thumbs-down"></i>',
                    cancelButtonAriaLabel: 'Thumbs down'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      observer.next({error:true});
                    }else{
                      observer.next({error:true});
                    }
                  })

                });




                  //  return new Observable(observer => observer.next({error:true}));

                  }




}
