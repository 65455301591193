import { Component, OnInit, OnDestroy,ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MapMangementService } from '../../../../_services/modules/geomap/maps/map-management.service'
import { MapsService } from '../../../../_services/maps.service';
import * as L from 'leaflet';

import { AddresslistControlService } from '../../../../_services/modules/geomap/addresslists/addresslist-control.service';
//
import { MapMarkerControlService } from './_service/map-marker-control.service'
//
import autoComplete from '@tarekraafat/autocomplete.js'

@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.css']
})
export class ViewMapComponent implements OnInit, OnDestroy {
  navigationSubscription;  
  //Display the Form
  showForm: any = {
    show:false,
    addressData: {},
    polygonResults: {},
    map_form_id: ""
  };

  Map:any;
  Polys:any;
  CurrentClickMarker: any;
  autoCompleteJS: any;
  private mapData: any = {map_name:''}; 

  constructor(private _elementRef: ElementRef,
    private MapsService:MapsService,
    private MapMangementService:MapMangementService, 
    private route: ActivatedRoute,
    private AddresslistControlService: AddresslistControlService,
    private MapMarkerControlService:MapMarkerControlService) { }
    ngOnDestroy() {
      this.showForm = {
        show:false,
        addressData: {},
        polygonResults: {},
        map_form_id: ""
      };
    }
  ngOnInit(): void {

    //Calling function to Init the Map and load the Polygons
    if(this.route.snapshot.paramMap.get('map_id') !== '' && this.route.snapshot.paramMap.get('map_id') !== null && this.route.snapshot.paramMap.get('map_id') !== undefined){
    this.initMap(async(mapData: any)=>{
      this.AddresslistControlService.getAddressListTable('',mapData['map_addresslist_id']).subscribe(async (AddressListResults: any)=>{
        this.addressSearchInit(AddressListResults['addresslist_table']);
      });
    });
    }
  }


// Get the Map Name
get getMapName(){return this.mapData['map_name'];}


////////////////////////////////////////////////////////////////////////
///
///
///   
///
///
////////////////////////////////////////////////////////////////////////



//Inits the Map and loads the Polygons
  initMap(callback): void{
    this.MapMangementService.getMapDetails(this.route.snapshot.paramMap.get('map_id')).subscribe(async (MapResults: any)=>{
      if(MapResults !== null && MapResults !== undefined){
        this.mapData = MapResults;
        this.MapsService.InitMapWithCallback(this.Map,'map',MapResults['map_lat'],MapResults['map_long'],MapResults['map_zoom'],async (map:any)=>{
          this.Map = map;
          this.MapMangementService.getPolysWithGeojson('',this.route.snapshot.paramMap.get('map_id')).subscribe((PolygonResults: any)=>{
            if(PolygonResults !== null && PolygonResults !== undefined && PolygonResults.length > 0){
              this.MapsService.load_basic_polys(this.Map,PolygonResults,(results: any)=>{
                console.log("done");
                this.Map = results['map'];
                this.Polys = results['polyObjs'];
                callback(MapResults);
              });
            }
          });
        });
      }
    });
  }



selectEvent(item: any) {

console.log(this.Polys)
  var polygonPromise: any = [];
  for (const element of <any> this.Polys) {
    polygonPromise.push(new Promise(async(polygonResolve) => {
    // console.log(this.isMarkerInsidePolygon(this.currentClickMarker, element['coords']));
     if(this.MapsService.isMarkerInsidePolygon(L.marker([item['lat'], item['long']]), element['coords'])){
       console.log("id:"+element['id']+" -- name:"+element['name']);
       polygonResolve(element);
     }
     else{
       polygonResolve(null);
     }
    }));
 }
  Promise.all(polygonPromise).then(async (polygonResults: any)=>{

    if(polygonResults.filter(n => n).length == 1){
     // setMarker(mapObj: any,currentMarker: any,mapData: any,polyData: any,addressData: any,polygonResults:any ,callback): void{
      this.MapMarkerControlService.setInsideMarker(this.Map,this.CurrentClickMarker,this.mapData,this.Polys,item,polygonResults.filter(n => n)[0],this._elementRef,this.showForm,async (results: any)=>{
        this.CurrentClickMarker = results['currentMarker'];
        this.Map = results['map'];
      });


      console.log({addressData: item, polygonResults: polygonResults.filter(n => n)[0]});
    } else if(polygonResults.filter(n => n).length > 1){
      // Address is located inside more than one Polygon

      //console.log({addressData: item, polygonResults: polygonResults.filter(n => n)[0]});
    }
    else{
      this.MapMarkerControlService.setOusideMarker(this.Map,this.CurrentClickMarker,this.mapData,this.Polys,item,null,this._elementRef,this.showForm,async (results: any)=>{
        this.CurrentClickMarker = results['currentMarker'];
        this.Map = results['map'];
      });
      // Address is not located inside any Polygon
      console.log({addressData: item, polygonResults: null});
    }
  });

}



////////////////////////////////////////////////////////////////////////
///
///
///   Address Search Init for Dynamic Address Searches
///
///
////////////////////////////////////////////////////////////////////////


addressSearchInit(address_list: any): void{
  var self = this;
  this.autoCompleteJS = new autoComplete({
    selector: "#autoComplete",
    placeHolder: "Address Search",
    data: {
        src: address_list,
        keys:['full_address'],
        cache: true,
        // autoComplete.js Config Options
          filter: (list) => {
            const results = list.filter((item) => {

                const inputValue = self.autoCompleteJS.input.value.toLowerCase();

                const itemValue = item.value['full_address'].toLowerCase();

                if (itemValue.startsWith(inputValue)) {

                    return item.value;
                }
            });

            return results;
          },
    },

    resultsList: {
        element: (list, data) => {
         // console.log(data);
        //  console.log(list);
            if (!data.results.length) {
                // Create "No Results" message element

              //Run Secondary Search if Address not found in the lis

                   const message1 = document.createElement("li");
                    message1.id = 'autoComplete_result_1';
                    message1.setAttribute('role','option');

                    

                      message1.innerHTML = `<p>Testings</p>`;

         
                    //message1.innerHTML = ``+this.mapData['map_address_not_found_text']+` <a href="`+this.mapData['map_address_not_found_link']+`">`+this.mapData['map_address_not_found_link_text']+`</a>`;
                    //data['matches'].push({key:'full_address', match:'', value:{address:'test'}});
                    //data['results'].push({key:'full_address', match:'', value:{address:'test'}});
                    //message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                    // Append message element to the results list
                    list.append(message1);

        


            }
            else{

            }
        },
        noResults: true,
    },
    resultItem: {
      element: (item, data) => {
        //console.log(item);
        item.innerHTML = item.innerHTML;
        //console.log(data);
      },
      highlight: {
        render: true
    }
  },
    events: {
        input: {
            focus() {

            if (self.autoCompleteJS.input.value.length) self.autoCompleteJS.start();
            },
            selection(event) {

            const feedback = event.detail;

            //self.addColumnToList(feedback.selection.value);

            // Send User Selection to Select Event Function that Maps the Mapker to Address
            console.log(feedback.selection.value);
            self.selectEvent(feedback.selection.value);

            //self._mapSearch.selectEvent(feedback.selection.value, self.mapPolys, self.mapPolyHover, self.currentClickMarker, self.myMap, self.mapData, self.mapPolyData);

          //  const selection = feedback.selection.value['address']+ ' ' + feedback.selection.value['city']+ ' ' + feedback.selection.value['state']+ ' ' + feedback.selection.value['zip'];
          const selection = '';
            // Replace Input value with the selected value
            //self.autoCompleteJS.input.value = selection;
            },
        },
    },
});
}



}
