import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { AddresslistControlService } from '../../../../../_services/modules/geomap/addresslists/addresslist-control.service';

// Include on all pages
import { CustomcssService } from '../../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../../_services/all-page-generals.service';

@Component({
  selector: 'app-editform',
  templateUrl: './editform.component.html',
  styleUrls: ['./editform.component.css']
})
export class EditformComponent implements OnInit {
  
  addressListArray: any = {};
  form: any = {
    map_enable:true
  };
  constructor(private route: ActivatedRoute,
    private router: Router,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService, 
    private customjsservice: CustomjsService,
    private addresslistcontrolservice: AddresslistControlService,
    private http: HttpClient) { }

  ngOnInit(): void {
      // Load Custom CSS onto page from Custom Css Service

      // Change Top Nav Title on Page Change
      this.allpageGenerals.setTopNavTitle('Edit Form');

      this.loadAddressList();

  }


  loadAddressList(){

    this.addresslistcontrolservice.getAddressLists('').subscribe((results)=>{
      this.addressListArray = results;
    });
  }


  onSubmit(): void {
    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}  
    //this.form.poly_id = this.route.snapshot.paramMap.get('poly_id');
    this.form.form_type = parseFloat(this.form.form_type);
    this.form.form_workflow = Boolean(false); 
    this.form.form_addresslist = Boolean(false); 
    this.form.form_enable = Boolean(this.form.form_enable); 
    const body=JSON.stringify(this.form);
      this.http.post('https://api.myininsights.com/geofi/forms?api=editform',body,{'headers':headers}).subscribe(resp => {
      console.log(resp);
      if(!resp['error']){
      Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
        this.router.navigate(['/geomap/maps/listforms'])
      });
    }
    else{
      Swal.fire('Failed', 'Error!', 'error');
    }
      //this.rerender();
  });

  
  }



  load_formvalues(): void{
    this.http.get('https://api.myininsights.com/geomap?api=getformdetails&formkey='+this.route.snapshot.paramMap.get('form_id'),{}).subscribe((resp: any) => {
      this.form.map_name = resp["map_name"];
      this.form.map_lat = resp["map_lat"];
      this.form.map_long = resp["map_long"];
      this.form.map_zoom = resp["map_zoom"];
      this.form.map_enable = resp["map_enable"];
      this.form.map_inputFormLink = resp["map_inputFormLink"];
      console.log(resp);
      //this.rerender();
  });
  }

}
