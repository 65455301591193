import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

//import Global Setting Varitables
import * as globalSettings from '../../../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class FormDataControlService {
// Establish the Default Global Parms.
private globalSetting: any = globalSettings;


  constructor(private http: HttpClient) { }




////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//
//
// Submitting Form Data
//
//
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////



 submit_Form_Data(form_id: string, form_data: any,form_fields: any, long_lat: any,fileUploads: any,gtoken: any): Observable<any>{

  return new Observable(observer => {

  const headers = { 'content-type': 'application/json'}
  var tempJson = {
    form_id:form_id,
    form_type:form_fields['form_type'],
    keyed_fields:form_fields['keyed_fields'],
    form_data:form_data,
    form_longlat:long_lat,
    form_files:fileUploads,
    recaptcha_token:gtoken
  };

  this.http.post(this.globalSetting['api_url_public']+'/geofi/forms?api=submitformdata',JSON.stringify(tempJson),{'headers':headers}).pipe(
    timeout(10000),
    catchError(e => {
      // do something on a timeout
      return of(observer.next({error:true,error_type:'timout'}));
    })
  ).subscribe(async (data: any) => {
      //console.log('success', data);
      observer.next(data);

    }
);

  });

}



parseHeadersToMatchData(form_data: any ,form_headers: any): Observable<any>{

  return new Observable(observer => {
    var parsedData: any = {};
    var count = 0;
    form_headers.forEach(async (headerInfo: any)=>{
      parsedData[''+headerInfo['id']+''] = form_data[''+headerInfo['id']+''];
      count++;
      if(count >= form_headers.length){
      //  console.log(form_headers);
      //  console.log(parsedData);
        observer.next(parsedData);
      }
    });

});

}


}
