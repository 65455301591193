import { Component, OnInit } from '@angular/core';
declare var Quill: any;
@Component({
  selector: 'app-create-email-templet',
  templateUrl: './create-email-templet.component.html',
  styleUrls: ['./create-email-templet.component.css']
})
export class CreateEmailTempletComponent implements OnInit {
  quillCompletePage: any;
  constructor() { }

  ngOnInit(): void {
    this.quillCompletePage = new Quill('#email_templet', {
      theme: 'snow',
      modules: {
        toolbar:  [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
        
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
        
          
          [{ 'header': [1, 2, 3, 4, false] }],
        
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
        
          ['clean']                                         // remove formatting button
        ],
      }
    });
  }

}
