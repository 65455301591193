<div *ngIf="!displayForm" id="polyTableLoader" class="flex-row layout-top-spacing">
  <div class="container">
      <div class="row align-items-center">
          <div class="col">

          </div>
          <div class="col">
              <div style="text-align:center;">

              <div  class="lds-dual-ring"></div>
              <h3 id="loadingTablePercent"></h3>
              </div>
          </div>
          <div class="col">

          </div>
        </div>
  </div>
</div>



    <app-dynamic-form *ngIf="displayForm" [questions]="questions$ | async " [keyed_fields]="keyed_fields" [packageTables]="packageTables" [formSettings]="this.formSettings" [entryData]="entryData"></app-dynamic-form>


