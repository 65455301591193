import { Component, OnInit,AfterViewInit } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';

import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
// Page Level import { ActivatedRoute } from "@angular/router";Imports
import { FormEntryControlService } from '../../../../../_services/modules/geomap/forms/form-entry-control.service';
import { FormBuilderService } from '../../../../../_services/modules/geomap/forms/form-builder.service';
// Page Level Imports
import { SimpleTableService } from '../../../../../_services/components/tables/simple-table/simple-table.service';

// Include on all pages
//import { CustomcssService } from '../../../../../_assets_insert/customcss.service';
//import { CustomjsService } from '../../../../../_assets_insert/customjs.service';
//import { AllPageGeneralsService } from '../../../../../_services/all-page-generals.service';

//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-list-form-entries',
  templateUrl: './list-form-entries.component.html',
  styleUrls: ['./list-form-entries.component.css']
})
export class ListFormEntriesComponent implements OnInit,AfterViewInit {
  // Global Settings
  private globalSetting: [] = globalSettings;

  displayTable = false;
  dtOptions: any = {};
  rows: any;
  headers: any;
  form_id: any = '';

  constructor(public simpleTableService: SimpleTableService,private route: ActivatedRoute, private router: Router,public dialog: MatDialog) { }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('form_id') !== '' && this.route.snapshot.paramMap.get('form_id') !== null && this.route.snapshot.paramMap.get('form_id') !== undefined){
      this.form_id = this.route.snapshot.paramMap.get('form_id');
      this.simpleTableService.initTable('table',''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformentrieswithfilters2&formkey='+this.route.snapshot.paramMap.get('form_id')+'','RowCountOnly');
    }



  }

  ngAfterViewInit(): void{
    this.simpleTableService.loadTable();
    console.log(this.simpleTableService.tableHeaders);
  }


  loadDetails(form_id: any, entry_id: any){

    this.router.navigate(['/geomap/form/entrydetail',{form_id: form_id, entry_id:entry_id}]);


}



}
