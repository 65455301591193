
<ng-container *ngIf="!showForm['show']">

    <div class="flex-row layout-top-spacing">
      <nav class="breadcrumb-one" aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
              <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
              <li class="breadcrumb-item" [routerLink]="['/geomap/maps/listforms']" aria-current="page">Maps</li>
              <li class="breadcrumb-item active" aria-current="page">Map View</li>
          </ol>
      </nav>
      </div>


<div class="row layout-top-spacing" id="cancel-row">

<div  style="display:block;" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
<div class="widget widget-card-standard-dropdown">
    <div class="widget-content">
        <div class="w-header">
            <div class="w-info">
                <h6 class="value">{{getMapName}}</h6>
            </div>

            <div class="task-action">

                <div class="dropdown pt-2" style="float:right;">
                    <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <div class="dropdown-menu left" aria-labelledby="expenses" style="will-change: transform;">

                    </div>
                </div>
              </div>

            </div>
        </div>
        <div class="w-content">

          <div class="col-12">
            <div class="row">
                <nav class="navbar navbar-expand-lg navbar-light bg-light" style="height: 60px; z-index:1050; background-color:transparent;">
                      
    
                    <div class=" mr-sm-2" style=" margin-right: 10px; margin-left: 10px; width:100%; background-color:transparent;">
                      <input id="autoComplete" class="form-control">
                        <!-- <ng-autocomplete
                          [style]="'z-index:999999;'"
                          [data]="data"
                          [searchKeyword]="keyword"
                          placeholder="Search Address"
                          (selected)='selectEvent($event)'
                          (inputChanged)='onChangeSearch($event)'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
                
                        <ng-template #itemTemplate let-item  [style]="'z-index:999999;'">
                          <a [style]="'z-index:999999;'" [innerHTML]="item.address+' '+ item.city+', '+item.state"></a>
                        </ng-template>
                
                        <ng-template #notFoundTemplate let-notFound  [style]="'z-index:999999;'">
                          <div [style]="'z-index:999999;'" ><a [style]="'z-index:999999;'" (click)="interestFormLoad" href="javascript:void(0);">Address Not Found? Complete Our Interest Form!</a></div>
                        </ng-template> -->
                
                      </div>
                  </nav>
                <div  id="map" style="width:100%; min-height:50px; height:calc(70vh - 60px); z-index:10;" ></div>
            </div>
        </div>

        </div>
    </div>
</div>
</div>
  
</ng-container>

<app-new-form-entry *ngIf="showForm['show']" [map_form_id]="showForm['map_form_id']" [addressData]="showForm['addressData']" [polygonResults]="showForm['polygonResults']"></app-new-form-entry>





















