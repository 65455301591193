<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoMap</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listmaps']">Maps</a></li>
            <li class="breadcrumb-item active" aria-current="page">Data Viewer</li>
        </ol>
    </nav>
</div>
<div id="polygon" style="min-height:60px; display:none;"></div>

<div class="row layout-spacing layout-top-spacing">
    <mat-toolbar color="primary" style="">


          <button mat-button matTooltip="Data Export Functions"  matTooltipPosition="below" matTooltipHideDelay="500" [matMenuTriggerFor]="menu"> Data Export <mat-icon>keyboard_arrow_down</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="formEntriesViewerServiceService.selectedPolygonExport(formEntriesViewerServiceService.selectAreaPolygon)">Export Selected Data</button>
            <button mat-menu-item>Export All Data</button>
          </mat-menu>
        <span class="example-spacer"></span>




      <mat-form-field style="height: 60px; font-size:14px;">
        <mat-label>Polygon Filter</mat-label>
        <mat-select [(value)]="formEntriesViewerServiceService.currentlySelectedPolyFilter" (selectionChange)="formEntriesViewerServiceService.updatePolygonFilter()" multiple>
          <mat-option  *ngFor="let option of formEntriesViewerServiceService.mapPolylist" [value]="option['_id']">{{option.poly_name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="height: 60px; margin-left: 20px;font-size:14px;">
        <mat-label>Form Layers</mat-label>
        <mat-select [(value)]="formEntriesViewerServiceService.currentlyselectedFormLayers" (selectionChange)="formEntriesViewerServiceService.updateFormLayers()" multiple>
          <mat-option  *ngFor="let option of formEntriesViewerServiceService.mapFormslist" [value]="option['_id']">{{option.form_name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-button [matMenuTriggerFor]="menu1"><mat-icon> select_all </mat-icon></button>
      <mat-menu #menu1="matMenu">

      </mat-menu>
  </mat-toolbar>

<div (contextmenu)="onRightClick($event, {content: 'Item '})" id="map" style="width:100%; min-height:650px; height:70vh;">

</div>




<div id="mapsMenu" style="visibility: hidden; position: fixed;"
[style.left]="menuTopLeftPosition.x"
[style.top]="menuTopLeftPosition.y"
#clickHoverMenuTrigger="matMenuTrigger"
[matMenuTriggerFor]="rightMenu">
</div>
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent >
    <button mat-menu-item (click)="formEntriesViewerServiceService.selectedPolygonExport(formEntriesViewerServiceService.selectAreaPolygon)">Export Selected Data</button>
    <button mat-menu-item>Export All Data</button>
  </ng-template>
</mat-menu>

</div>


