import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

// Page Only Imports
import { GroupControlService } from '../../../_services/modules/administration/groups/group-control.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var DualListbox: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.css']
})
export class NewGroupComponent implements OnInit, AfterViewInit {

globalPermissions: any;
matcher = new MyErrorStateMatcher();
groupForm: any = {
  group_name: new FormControl('', [Validators.required]),
  group_permissions: new FormControl([]),
  group_enable: new FormControl('', [Validators.required])
};
  constructor( 
    private groupControlService: GroupControlService,
    private http: HttpClient,
    ) { }

  ngOnInit(): void {
    this.getGlobalPermissions();
   
  }
  ngAfterViewInit(): void {
  
   
  }

  selectBoxInit(): void{

    let dualListbox = new DualListbox("#lPermissions", {

      addEvent: (value) => {
          // Should use the event listeners
          const myArray = value.split("'");
          const myArray1 = myArray[1].split("'");
          let word = myArray1[0];
          //console.log(word);
          this.groupForm.group_permissions.value.push(word);
          //console.log(this.groupForm.group_permissions.value)
      },
      removeEvent:  (value) => {
          // Should use the event listeners
          
          //console.log(value);
          const myArray = value.split("'");
          const myArray1 = myArray[1].split("'");
          let word = myArray1[0];
          //console.log(word);
          this.groupForm.group_permissions.value = this.groupForm.group_permissions.value.filter(e => e !== word);
          //console.log(this.groupForm.group_permissions.value);
          
      },
      availableTitle: "Groups",
      selectedTitle: "Selected Groups",
      addButtonText: "Add",
      removeButtonText: "Remove",
      addAllButtonText: "Add All",
      removeAllButtonText: "Remove All",

      sortable: true,
      upButtonText: "ᐱ",
      downButtonText: "ᐯ",

     // options: [
      //    { text: "Option 1", value: "OPTION1" },
      //    { text: "Option 2", value: "OPTION2" },
       //   { text: "Selected option", value: "OPTION3", selected: true },
     // ],
  });

  dualListbox.addEventListener("added", function (event) {
     // console.log(event);
     // console.log(event.addedElement);
  });
  dualListbox.addEventListener("removed", function (event) {
     // console.log(event);
      //console.log(event.removedElement);
  });

  }


  getGlobalPermissions(): any{
    this.groupControlService.getGroupGlobalPermissions("").subscribe((GlobalPermissions)=>{
      this.globalPermissions = GlobalPermissions;
      console.log(this.globalPermissions);
     const myTimeout = setTimeout(()=>{this.selectBoxInit();}, 500);

    });
  }


  onSubmit(): void {

    var tempValues = {
      group_name:this.groupForm.group_name.value ,
      group_permissions:this.groupForm.group_permissions.value ,
      group_enable: true,
    };
    
    this.groupControlService.createGroup(tempValues).subscribe(()=>{

    });

  }

}
