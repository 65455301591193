import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';


// Include on all pages
import { CustomcssService } from '../../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../../_services/all-page-generals.service';

@Component({
  selector: 'app-newform',
  templateUrl: './newform.component.html',
  styleUrls: ['./newform.component.css']
})
export class NewformComponent implements OnInit {
  map_id: any = this.route.snapshot.paramMap.get('map_id');
  popup: any;
  myMap: any;
  currentClickMarker: any = null;
  mapLoaded = false;

  addressListArray: any = [];
  form: any = {
    map_enable:true
  };
  constructor(private route: ActivatedRoute,
    private router: Router,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService, 
    private customjsservice: CustomjsService,
    private http: HttpClient
    ) { }

  

  ngOnInit(): void {
      // Load Custom CSS onto page from Custom Css Service


      // Change Top Nav Title on Page Change
      this.allpageGenerals.setTopNavTitle('New Form');

      // Call method to Load Address List
     
  }





//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
//
//
//  Submit Form to new Form API 
//
//
//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////


  onSubmit(): void {
    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}  
    //this.form.poly_id = this.route.snapshot.paramMap.get('poly_id');
    this.form.form_type = parseFloat(this.form.form_type);
    this.form.form_workflow = Boolean(false); 
    this.form.form_addresslist = Boolean(false); 
    this.form.form_enable = Boolean(this.form.form_enable); 
    const body=JSON.stringify(this.form);
      this.http.post('https://api.myininsights.com/geofi/forms?api=newform',body,{'headers':headers}).subscribe(resp => {
      console.log(resp);
      if(!resp['error']){
      Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
        this.router.navigate(['/geomap/maps/listforms'])
      });
    }
    else{
      Swal.fire('Failed', 'Error!', 'error');
    }
      //this.rerender();
  });

  
  }

}
