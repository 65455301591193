// Global Requirments
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

import { MapMangementService } from '../../../../_services/modules/geomap/maps/map-management.service';


// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

class DataTablesResponse {
name: string = "";
}
@Component({
  selector: 'app-listmaps',
  templateUrl: './listmaps.component.html',
  styleUrls: ['./listmaps.component.css']
})
export class ListmapsComponent implements  OnInit {
  // Global Settings
  private globalSetting: [] = globalSettings;



  displayTable = false;
  dtOptions: any = {};
  rows: any;


  constructor(private http: HttpClient,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private mapmangementservice: MapMangementService,
    private router: Router
    ) { }


  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  this.customcssservice.load_switches_css();

  // Change Top Nav Title on Page Change
  this.allpageGenerals.setTopNavTitle('Maps');




  const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geomap?api=listmaps',  {
    reportProgress: true
  });

  this.http.request(request).subscribe(event => {

    // progress
    if (event.type === HttpEventType.DownloadProgress) {
      console.log(event.loaded, event.total);
      // event.loaded = bytes transfered
      // event.total = "Content-Length", set by the server
  if(typeof(event.total) !== 'undefined'){
      const percentage = 100 / event.total * event.loaded;
      let polyTableLoader = document.getElementById('loadingTablePercent');
      polyTableLoader!.innerHTML = Math.round(percentage)+'%';
      console.log(percentage);
  }
    }

    // finished
    if (event.type === HttpEventType.Response) {
      this.rows = event.body;

      console.log(event.body);
      this.rows = event.body;
      this.displayTable = true;
    }

  })



  this.dtOptions = {
    "dom":
  "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
  //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
  "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
  "<'table-responsive'tr>" +
  "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
  buttons: {
    buttons: [
        { extend: 'csv',
          className: 'btn',
          title:"Maps",
          text: 'CSV',
          exportOptions: {
        columns: 'th:not(:last-child)'
        }
      },
        { extend: 'excel',
          className: 'btn',
          title:"Maps",
          text: 'Excel',
            exportOptions: {
             columns: 'th:not(:last-child)'
                }
        },
          {
          extend: 'print',
          className: 'btn',
          title:"Maps",
          exportOptions: {
            columns: 'th:not(:last-child)'
               }
          }
    ]
},
    "oLanguage": {
        "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
        "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
        "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        "sSearchPlaceholder": "Search...",
       "sLengthMenu": "Results :  _MENU_",
    },
    "stripeClasses": [],
    "lengthMenu": [7, 10, 20, 50],
    "pageLength": 7 ,
    "processing": true,
    //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
    responsive: true,
    "initComplete":function(settings, json) {
      let polyTable = document.getElementById('mapTable');
      polyTable!.style.display = 'block';
      let polyTableLoader = document.getElementById('polyTableLoader');
      polyTableLoader!.style.display = 'none';
    },
  };





  }



openMap(map_id){

  this.mapmangementservice.displayMap(''+this.globalSetting['pub_maps_url']+'/?map_id='+map_id).subscribe((results)=>{

  });


  //window.open(''+this.globalSetting['pub_maps_url']+'/?map_id='+map_id, '_blank')
}

onClick(data: any): void{
  this.router.navigate(['geofi/maps/viewmap',{map_id: data._id}]);
  console.log(data);
    }


openEmbedLink(map_id: any){

  Swal.fire({
    title: 'Embed Link',
    html: `
    <textarea rows="8" cols="60"><script id="id-`+map_id+`" src='https://geofi.myiinsights.com/assets/maps/map_loader.js' data-width="100%" data-height="750px" data-mapId="`+map_id+`" map-delay="1000"></script></textarea>
    `,
    width: 500,
    confirmButtonText: 'Close',
    focusConfirm: false,

  }).then((result) => {
    console.log(result.value);
  })
}

openYourWebMap(){
  window.open('https://www.rtccom.com/36349-2/', '_blank')
}



  removeMap(map_id){
    this.mapmangementservice.removeMap(map_id).subscribe((results)=>{
      if(!results['error']){
      window.location.reload();
      }
      else{
        window.location.reload();
      }
      console.log("tesing::"+results);
      console.log(results);
    });
  }

  disEnMap(map_id,enable){
    //console.log(event);
    this.mapmangementservice.disenMap(map_id,enable).subscribe((results)=>{
      if(!results['error']){
      window.location.reload();
      }
      else{
        window.location.reload();
       // event.checked
      }
      console.log("tesing::"+results);
      console.log(results);
    });
  }




}
