import { Component, Input, OnInit,AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-radio-editor',
  templateUrl: './radio-editor.component.html',
  styleUrls: ['./radio-editor.component.css']
})
export class RadioEditorComponent implements OnInit {

  @Input() selectedElementID!: any;
  @Input() keyed_fields!: any;

  tempElementValues: any = {
    input_label:'',
    input_radio_buttons:'',
    input_required: '',
    input_public:'',
    input_keyed_fields:[],
    input_system_id:''

  };

  constructor() { }

  ngOnInit(): void {

    this.tempElementValues['input_label'] = (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML;

    this.tempElementValues['input_required'] = (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('required');
    this.tempElementValues['input_private_label'] = (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('private_label');
    this.tempElementValues['input_public'] = (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('insights-ispublic');

  // Get Array of Radio Input from Div

  var radio_buttons = (<any>document.getElementById(this.selectedElementID))!.querySelectorAll("input[type='radio']");
  //parse then into string for the Textarea
  var radio_options = '';
  for (var option of radio_buttons) {
    radio_options = radio_options + option.getAttribute('value') + "\n";
  }
  this.tempElementValues['input_radio_buttons'] = radio_options.slice(0, -1);


// Set Input Public Status
if(this.tempElementValues['input_public'] == 'true'){
  this.tempElementValues['input_public'] = 'true';
}
else{

  this.tempElementValues['input_public'] = 'false';
}
/////////////////////////////////////////////////////////////////


// Set Input Keyed Field Status
var temparray: string[] = [];
document.querySelectorAll('[keyed-field]').forEach(async (el: any)=>{
     temparray.push(el.getAttribute('keyed-field'));
 });

 var KeyedText = '';
 console.log( this.keyed_fields);
 this.keyed_fields.forEach(async(key: any)=>{
  if(!temparray.includes(key['name'])){
     this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: false});
  }
   else{
    if( (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('keyed-field') == key['name']){
       this.tempElementValues['input_keyed_fields'].push({name: key['text'], value: key['name'], selected: true});
    }
  }

 });
 /////////////////////////////////////////////////////////////////


// Set System ID temapaery Var if Present
var system_id_val: any = "";
if( (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('system-id') !== null){
  this.tempElementValues['input_system_id'] = (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('system-id');
}
else{
  this.tempElementValues['input_system_id'] = "";
}
 /////////////////////////////////////////////////////////////////


  }



  updateElement(name: string,el: any): void{
    if(name == 'label'){
    console.log(el.target.value);
    (<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('label')!.innerHTML = el.target.value;
    }

    else if(name == 'radio_buttons'){
    console.log(el.target.value);
    //(<HTMLElement>document.getElementById(this.selectedElementID))!.querySelector('input')!.setAttribute('placeholder',el.target.value);.


    var radio_id = (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.getAttribute('id');
    // Add div spacer for page reload Pasrser
     var temp_Radio_html = '<div>';

     for (var option of el.target.value.split(String.fromCharCode(10))) {
       temp_Radio_html = temp_Radio_html + '<input type="radio" id="radio-'+option+'" name="radio-'+radio_id+'" value="'+option+'"><label for="radio-'+option+'">'+option+'</label><br>';
     }
     // Add div spacer for page reload Pasrser
     temp_Radio_html = temp_Radio_html + '</div>';

     (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.innerHTML = temp_Radio_html;


    }
    else if(name == 'private_label'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.setAttribute('private_label',el.target.value);
    }

    else if(name == 'required'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.setAttribute('required',el.target.value);
    }

    else if(name == 'is_public'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.setAttribute('insights-ispublic',el.target.value);
    }

    else if(name == 'system_id'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.setAttribute('system-id',el.target.value);
    }

    else if(name == 'keyed_field'){
      console.log(el.target.value);
      (<HTMLElement>document.getElementById(this.selectedElementID)!.querySelector("div[type='radio']"))!.setAttribute('keyed-field',el.target.value);
    }

    }

}
