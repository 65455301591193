import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { AuthService } from '../../../_services/auth.service';
import { TokenStorageService } from '../../../_services/token-storage.service';

import globalSettings from '../../../_settings/Globle_Settings.json';

import {CustomcssService} from '../../../_assets_insert/customcss.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LogonComponent implements OnInit {
  private globalSetting: [] = globalSettings;
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  myusername = "";

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,private router: Router,private http: HttpClient, private customcssService: CustomcssService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.loadcustomcss();
    //this.loadcustomcss1();
    this.loadcustomcss2();
    this.customcssService.load_Auth_Page_Layout();
    //this.loadcustomjs();
    //this.loadcustomjs1();
    //this.loadcustomjs2();
    //this.loadcustomjs3();
  }


  onSubmit(): void {
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe({
      next: data => {
        console.log(data);
        if(data['passedAuth']){

  // clearInterval(tid);
  var tfaModal;

  var checkForTFAAuth = setInterval(() => {

    this.http.get(this.globalSetting['api_url_console']+'/auth?api=checkfortwofaauth&usertoken='+data['userToken'],{}).subscribe((resp: any) => {

      console.log(resp);
      if(resp['valid'] == true){
        // Close the 2 Factor Modal that is Open
        clearInterval(checkForTFAAuth);
        tfaModal.close();


        this.tokenStorage.saveToken(data.userToken);
        this.myusername = username;
        //this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        //this.roles = this.tokenStorage.getUser().roles;
       // this.reloadPage();
        //this.router.navigate(['/geomap/maps/listmaps']);
        var returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        window.location.replace(returnUrl);
      }
      //this.rerender();
  });
      //this.rerender();



   }, 3000);

          if(data["MFA"] == 'duo'){

          // Two Factor Modal Init Var




            tfaModal = Swal.fire({
              title: '',

              html:
              '<iframe id="duo_iframe" style="height:500px; width:400px; overflow: hidden;" frameBorder="0" scrolling="no" '+
             'title="Two-Factor Authentication"'+
              'src="'+data["MFA_DUO_URL"]+'"'+
              ''+
              '>'+
              '</iframe>'+
              ' ',

              width: '550px',
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Great!',
              confirmButtonAriaLabel: 'Thumbs up, great!',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i>',
              cancelButtonAriaLabel: 'Thumbs down'
            })
            //$('#duo_iframe').on('load', function(e) {
              //alert('frame has (re)loaded ');
         // });










          }
          else{

        if(data['success']){
        this.tokenStorage.saveToken(data.userToken);
        this.myusername = username;
        //this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        //this.roles = this.tokenStorage.getUser().roles;
       // this.reloadPage();
        //this.router.navigate(['/geomap/maps/listmaps']);
       var returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        window.location.replace(returnUrl);
        }
      }
  }
        else{
          this.errorMessage = "Incorrect User/Password";
          this.isLoginFailed = true;
        }
      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }






  private loadcustomcss2() {
    var head  = document.getElementsByTagName('head')[0];
    var link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = 'assets/assets/css/authentication/form-2.css';
    link.media = 'all';
    head.appendChild(link);


  }








}
