import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Observable } from 'rxjs';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class PackagetablesService {
 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(private http: HttpClient) { }




//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Package Lists for the CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getPackageTables(percentElementId: string): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms/packagetables?api=listpackagetables',  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }
    
    });
      
       
   

  });
}







//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Package Lists for the CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getPackageTableDetails(percentElementId: string, package_table_id: any): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms/packagetables?api=getpackagetable&package_table_id='+package_table_id,  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }
    
    });
      
       
   

  });
}





//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Package Table API to Create a New Table    
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

createPackageTable(percentElementId: string, tableData): Observable<any>{

  return new Observable(observer => {

        var tempJson = {

          package_table_name:tableData['tableData'].package_table_name.value,
          package_table_type:tableData['tableData'].package_table_type.value,
          package_table_headers:tableData['tableHeaders'],
          package_table_rows:tableData['tableRows'],
         
        };
        
        const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/forms/packagetables?api=createpackagetable',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});
    
    this.http.request(request).subscribe((event: any) => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;

          if(percentElementId !== '' && percentElementId !== undefined){
            let polyTableLoader = document.getElementById(''+percentElementId+'');
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
          }

      }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
          if(!event.body['error'])
          {
          Swal.fire(
            'Successful!',
            'Your Package Table has been updated.',
            'success'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
          }
          else
          {
            Swal.fire(
              'Failed!',
              ''+event.body['message']+'',
              'error'
            ).then(()=>{
              observer.next({error:event.body['error']});
            });
          }
        //console.log(event.body);
      }
    }
    
    });

    });

  }





//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Package Table API to Create a New Table    
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

updatePackageTable(percentElementId: string, package_table_id: any, tableData): Observable<any>{

  return new Observable(observer => {

        var tempJson = {
          package_table_id:package_table_id,
          package_table_name:tableData['tableData'].package_table_name.value,
          package_table_type:tableData['tableData'].package_table_type.value,
          package_table_headers:tableData['tableHeaders'],
          package_table_rows:tableData['tableRows'],
         
        };
        
        const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/forms/packagetables?api=updatepackagetable',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});
    
    this.http.request(request).subscribe((event: any) => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
      if(typeof(event.total) !== 'undefined'){
          const percentage = 100 / event.total * event.loaded;

          if(percentElementId !== '' && percentElementId !== undefined){
            let polyTableLoader = document.getElementById(''+percentElementId+'');
            polyTableLoader!.innerHTML = Math.round(percentage)+'%';
            console.log(percentage);
          }

      }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
          if(!event.body['error'])
          {
          Swal.fire(
            'Successful!',
            'Your Package Table has been updated.',
            'success'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
          }
          else
          {
            Swal.fire(
              'Failed!',
              ''+event.body['message']+'',
              'error'
            ).then(()=>{
              observer.next({error:event.body['error']});
            });
          }

        //console.log(event.body);
      }
    }
    
    });

    });

  }
  
  
}
