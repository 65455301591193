        <!-- CONTENT AREA -->


        <div class="flex-row layout-top-spacing">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Forms</li>
              </ol>
          </nav>
          </div>

      <!--<div class="d-flex layout-top-spacing flex-row-reverse">
<button id="add-work-platforms" [routerLink]="['geomap/forms/newform']" class="btn btn-rounded btn-primary">+ New Form</button>
</div>-->


      <div style="display:none;" id="polyTableLoader" class="flex-row">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col">

                  </div>
                  <div class="col">
                      <div style="text-align:center;">

                      <div  class="lds-dual-ring"></div>
                      <h3 id="loadingTablePercent"></h3>
                      </div>
                  </div>
                  <div class="col">

                  </div>
                </div>
          </div>
      </div>









      <div class="row layout-top-spacing" id="cancel-row">

          <div  style="display:block;" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
              <div class="widget widget-card-standard-dropdown">
                  <div class="widget-content">
                      <div class="w-header">
                          <div class="w-info">
                              <h6 class="value">Forms</h6>
                          </div>

                          <div class="task-action">

                              <div class="dropdown pt-2" style="float:right;">
                                  <a class="dropdown-toggle" href="#" role="button" id="expenses" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                  </a>

                                  <div class="dropdown-menu left" aria-labelledby="expenses" style="will-change: transform;">
                                      <a class="dropdown-item" href="javascript:void(0);">This Week</a>
                                      <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                                      <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                                  </div>
                              </div>
                              <div class="" style="width:150px; float:right;">
                                <button mat-button (click)="openDialog()">Open dialog</button>
                                  <button id="add-work-platforms" [routerLink]="['/geomap/forms/formbuilder']" class="btn btn-rounded btn-primary">+ New Form</button>
                          </div>
                          </div>
                      </div>
                      <div class="w-content">

                          <div  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


                            <div *ngIf="this.simpleTableService.isTblLoading" class="row align-items-center">
                              <div class="col">

                              </div>
                              <div class="col">
                                  <div style="text-align:center;">

                                  <div  class="lds-dual-ring"></div>
                                  <h3 id="loadingTablePercent"></h3>
                                  </div>
                              </div>
                              <div class="col">

                              </div>
                            </div>
                            <div *ngIf="!this.simpleTableService.isTblLoading" >
                            <div  class="table-responsive">
                              <table id="ofawf-simple-table" class="ofawf-simple-table table table-hover">
                                  <thead>

                                      <tr>
                                          <th scope="col" width="5%">
                                              <div class="form-check form-check-primary">
                                                  <input class="form-check-input" id="hover_parent_all" type="checkbox">
                                              </div>
                                          </th>
                                          <ng-container *ngFor="let header of simpleTableService.tableHeaders">
                                            <th class="ps-0" scope="col">
                                              <div class="btn-group">
                                                <a href="javascript:void(0);"  *ngIf="header.filter_parent !== 'form_data.'">{{header.value}} </a>
                                                <a href="javascript:void(0);"  *ngIf="header.filter_parent == 'form_data.'" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{header.value}} </a>
                                                <div *ngIf="header.filter_parent == 'form_data.'" class="dropdown-menu">
                                                  <div style="padding-left:2px; padding-right:2px;"><p>Filter</p><input type="text" style="height:25px; width:100%;" class="form-control" (change)="this.simpleTableService.modifyFilter(header.id,header.filter_parent,$event.target)"></div>
                                                </div>
                                            </div>
                                            </th>
                                          </ng-container>
                                          <th></th>
                                      </tr>
                                      <tr aria-hidden="true" class="mt-3 d-block table-row-hidden"></tr>
                                  </thead>
                                  <tbody *ngIf="!this.simpleTableService.isTblReLoading">
                                      <ng-container *ngFor="let data of simpleTableService.tableData">
                                      <tr>
                                        <td>
                                          <div class="form-check form-check-primary">
                                              <input class="form-check-input hover_child" type="checkbox">
                                          </div>
                                        </td>
                                          <td *ngFor="let dataRow of data">
                                              {{dataRow.value}}
                                          </td>
                                      </tr>
                                    </ng-container>
                                  </tbody>
                              </table>


                            </div>

                            <div class="row layout-spacing">
                              <div class="ml-1 col-3">
                                <div class="">
                                    <p class="mb-0">Showing page {{this.simpleTableService.currentPageIndex+1}} of {{this.simpleTableService.pageCount}} Total Entries: {{this.simpleTableService.getTotalRows}} </p>
                                </div>
                            </div>
                            </div>
                            <div class="row layout-spacing">
                            <div class="col-12 text-right pagination-custom_solid">
                              <a href="javascript:void(0);" (click)="this.simpleTableService.prevPage()" class="ofwaf-prev-button prev">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,492.00405883789074,492.0039672851562)">
                                        <g>
                                          <g>
                                            <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" fill="#000000" data-original="#000000" class=""/>
                                          </g>
                                        </g>
                                  </g>
                                 </svg>
                                </a>
                              <ul class=" pagination">
                                <ng-container *ngFor="let page of this.simpleTableService.getPagination">
                                  <li><a href="javascript:void(0);" [ngClass]="{'active' : page == this.simpleTableService.currentPageIndex+1}" (click)="this.simpleTableService.setPage(page-1)">{{page}}</a></li>
                                </ng-container>
                                 </ul>
                              <a href="javascript:void(0);" (click)="this.simpleTableService.nextPage()"  class="ofwaf-next-button next">

                              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                                        c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                                        c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                                        c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
                                    </g>
                                  </g>
                             </svg>

                            </a>
                          </div>

                        </div>
                      </div>



                          </div>

                      </div>

                      <div style="display:none" class="w-progress-stats">
                          <div class="">
                              <div class="w-lic-count">
                                  <p>1\∞</p>
                              </div>
                          </div>
                          <div class="progress">
                              <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>

                          <div class="">
                              <div class="w-icon">
                                  <p>0%</p>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>

