import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

import { FormEntryFileControlService } from '../../../../../_services/modules/geomap/forms/form-entry-file-control.service';
import { FormEntryControlService } from '../../../../../_services/modules/geomap/forms/form-entry-control.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent implements OnInit {

constructor(private entryFileControl:FormEntryFileControlService,private formEntryControl:FormEntryControlService,private route: ActivatedRoute) { }

ngOnInit(): void {

 // this.entryFileControl.base64FileDownload("","");
    if(this.route.snapshot.paramMap.get('entry_id') !== undefined && this.route.snapshot.paramMap.get('entry_id') !== '' && this.route.snapshot.paramMap.get('entry_id') !== null){
      this.formEntryControl.getFormEntry("",this.route.snapshot.paramMap.get('entry_id'),"filesonly").subscribe(async (formData: any)=>{
        Object.entries(formData).forEach(async(formFiles: any) => {
          this.entryFileControl.base64FileDownload(formFiles[0],formFiles[1]);
        });
      });
    }
  }

}
