import { Injectable } from '@angular/core';
import * as L from 'leaflet';


//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class MapMarkerControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor() { }

  setInsideMarker(mapObj: any,currentMarker: any,mapData: any,polyData: any,addressData: any,polygonResults:any ,_elementRef,showForm: any,callback): void{
    if (currentMarker !== null && currentMarker !== undefined) {
      mapObj.removeLayer(currentMarker);
     }
   // Change center point of the map to the address selected
   mapObj.setView([addressData['lat'], addressData['long']], 17);

   var greenIcon = new L.Icon({
    iconUrl: 'assets/img/marker-icon-2x-'+mapData['map_address_inside_poly_marker_color']+'.png',
    shadowUrl: 'assets/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  currentMarker = L.marker([addressData['lat'], addressData['long']], {icon: greenIcon}).bindPopup('<h5>'+polygonResults['name']+'</h5><p>'+  addressData['address'] +'<br>'+ addressData['city']+', '+ addressData['state']+' '+ addressData['zip']+'</p><button id="Poly_click" class="btn btn-outline-info btn-rounded mb-2 me-4">Continue</button>',{closeButton: false})
  .on("popupopen", () => {
    _elementRef.nativeElement
      .querySelector("#Poly_click")
      .addEventListener("click", (e: any) => {
        //alert("Removing::"+ element['_id']);
        //this.removePoly(element['_id']);

        if(polygonResults['poly_input_form_type'] == 'chkpt') {


          if (window.self !== window.top) { // checking if it is an iframe
           // window.parent.location.href = polygonResults['poly_form_url_id'];
           // window.open(isfoundPoly['poly_form_url_id'], '_blank');
          } else {
           // window.location.href = polygonResults['poly_form_url_id'];
           // window.open(isfoundPoly['poly_form_url_id'], '_blank');
          }

        }
        else if (polygonResults['poly_input_form_type'] == 'url'){

          if (window.self !== window.top) { // checking if it is an iframe
           // window.parent.location.href = polygonResults['poly_form_url_id'];
            //window.open(isfoundPoly['poly_form_url_id'], '_blank');
          } else {
            //window.location.href = polygonResults['poly_form_url_id'];
            //window.open(isfoundPoly['poly_form_url_id'], '_blank');
          }
        }
        else{
         // Show form and Provide the proper Vars to the Form
          showForm['addressData'] = addressData;
          showForm['polygonResults'] = polygonResults;
          showForm['map_form_id'] = polygonResults['poly_form_url_id'];
          showForm['show'] = true;
         // window.location.replace(this.globalSetting['public_form_url']+'/form;form_id='+polygonResults['poly_form_url_id']+';address='+ addressData['address']+';city='+item['city']+';state='+item['state']+';zip='+item['zip']+';lat='+item['lat']+';long='+item['long']+';polylocid='+polyLocid);

          }
        //window.location.replace(this.globalSetting['api_url_public']+'/initform;formkey='+isfoundPoly['poly_form_url_id']+';address='+item['address']+';city='+item['city']+';state='+item['state']+';zip='+item['zip']+';lat='+item['lat']+';long='+item['long']+';polylocid='+polyLocid);
        //window.open(' https://geofiforms.myiinsights.com/initform;formkey=62963f030ce9b103ab8c2ad1', '_blank');
      });
  }).on("add", function (event) {
    event.target.openPopup();
 }).addTo(mapObj);


 callback({map: mapObj, currentMarker: currentMarker});
  }



  setOusideMarker(mapObj: any,currentMarker: any,mapData: any,polyData: any,addressData: any,polygonResults:any ,_elementRef,showForm: any,callback): void{
    if (currentMarker !== null && currentMarker !== undefined) {
      mapObj.removeLayer(currentMarker);
     }
   // Change center point of the map to the address selected
   mapObj.setView([addressData['lat'], addressData['long']], 17);

   var greenIcon = new L.Icon({
    iconUrl: 'assets/img/marker-icon-2x-'+mapData['map_address_outside_poly_marker_color']+'.png',
    shadowUrl: 'assets/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  currentMarker = L.marker([addressData['lat'], addressData['long']], {icon: greenIcon}).bindPopup('<h5>'+mapData['map_address_outside_marker_title']+'</h5><p>'+ addressData['address'] +' '+addressData['city']+', '+addressData['state']+' '+addressData['zip']+'</p><p>'+mapData['map_address_outside_marker_text']+'</p><a href="javascript:void(0);" id="Poly_click" onclick="" class="closebtn edit" data-toggle="modal" data-target="#step-modal" >'+mapData['map_address_outside_marker_link_text']+'</a>',{closeButton: false})
  .on("popupopen", () => {
    _elementRef.nativeElement
      .querySelector("#Poly_click")
      .addEventListener("click", (e: any) => {
        //alert("Removing::"+ element['_id']);
        //this.removePoly(element['_id']);

        if(mapData['map_address_outside_marker_link_type'] == 'chkpt') {

          if (window.self !== window.top) { // checking if it is an iframe
           // window.parent.location.href = this.mapData['map_address_outside_marker_link'];
            //window.open(isfoundPoly['poly_form_url_id'], '_blank');
          } else {
            //window.location.href = this.mapData['map_address_outside_marker_link'];
           // window.open(isfoundPoly['poly_form_url_id'], '_blank');
          }

          //window.location.replace(this.mapData['map_address_outside_marker_link']);

        }
        else if (mapData['map_address_outside_marker_link_type'] == 'url'){

          if (window.self !== window.top) { // checking if it is an iframe
           // window.parent.location.href = mapData['map_address_outside_marker_link'];
           // window.open(isfoundPoly['poly_form_url_id'], '_blank');
          } else {
            //window.location.href = mapData['map_address_outside_marker_link'];
           // window.open(isfoundPoly['poly_form_url_id'], '_blank');
          }
          //window.location.replace(this.mapData['map_address_outside_marker_link']);
        }
        else{
          showForm['addressData'] = addressData;
          showForm['polygonResults'] = polygonResults;
          showForm['map_form_id'] = polygonResults['poly_form_url_id'];
          showForm['show'] = true;
         // window.location.replace(this.globalSetting['public_form_url']+'/form;form_id='+mapData['map_address_outside_marker_link']+';address='+addressData['address']+';city='+addressData['city']+';state='+addressData['state']+';zip='+addressData['zip']+';lat='+addressData['lat']+';long='+addressData['long']+';polylocid='+polyLocid);

          }
      });
  }).on("add", function (event) {
    event.target.openPopup();
 }).on("popupclose",  (event) => {
}).addTo(mapObj);


 callback({map: mapObj, currentMarker: currentMarker});
  }

}
