import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';


@Injectable({
  providedIn: 'root'
})
export class FormEntriesControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;


  constructor(private http: HttpClient) { }


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// 
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////



updateFormData(entry_id: any, form_data: any): Observable<any>{

  return new Observable(observer => {
      const headers = { 'content-type': 'application/json'}
      var formData = {
        "entry_id": entry_id,
        "form_data": form_data
      }
      const body=JSON.stringify(formData);
        this.http.post(''+this.globalSetting['api_url_console']+'/geofi/forms?api=updateformentry',body,{'headers':headers}).subscribe(resp => {
        console.log(resp);
        if(resp['error'] == false){
        Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
          //this.router.navigate(['/geomap/addresslist/addresslists'])
          observer.next(resp);
        });
      }else{
        Swal.fire('Error', 'Error Occured!', 'error').then(()=>{
          observer.next(resp);
        });
      }
        //this.rerender();
    });
  });
}
}
