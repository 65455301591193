import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import globalSettings from '../_settings/Globle_Settings.json';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Access-Control-Allow-Origin',
}),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private globalSetting: [] = globalSettings;
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.globalSetting['api_url_console'] + '/auth?api=userauth', {
      username,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(this.globalSetting['api_url_console'] + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }
}
