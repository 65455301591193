import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import * as L from 'leaflet';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

//Address Control
import { AddresslistControlService } from '../../../../_services/modules/geomap/addresslists/addresslist-control.service';
// Form Control
import { FormControlService } from '../../../../_services/modules/geomap/forms/form-control.service';
//Map Control Service
import { MapsService } from '../../../../_services/maps.service';


// Globals
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';


import globalSettings from '../../../../_settings/Globle_Settings.json';
@Component({
  selector: 'app-editmap',
  templateUrl: './editmap.component.html',
  styleUrls: ['./editmap.component.css']
})
export class EditmapComponent implements OnInit,AfterViewInit {
  // Global Settings
private globalSetting: [] = globalSettings;


  popup: any;
  myMap: any;
  currentClickMarker: any = null;

  mapLoaded = false;
  form: any = {
    map_enable:true
  };

  addressListArray: any = [];
  formsListArray: any = [];

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private mapsservice: MapsService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private addresslistcontrolservice: AddresslistControlService,
    private formControlService: FormControlService,
    private allpageGenerals: AllPageGeneralsService
    ) { }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service


  // Change Top Nav Title on Page Change
   this.allpageGenerals.setTopNavTitle('Edit Map');
    // Call method to Load Address List
    this.loadAddressList().then(async ()=>{
          //Call to Load Forms
        this.loadFormsList().then(async ()=>{
          
          this.load_formvalues();

        });
    });
  }


  ngAfterViewInit(): void {
   // this.initMap();
  }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //    
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


  private initMap(): void {
    var greenIcon = new L.Icon({
      iconUrl: 'assets/insights/mapping/img/marker-icon-2x-blue.png',
      shadowUrl: 'assets/insights/mapping/img/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
        this.currentClickMarker = L.marker([this.form.map_lat, this.form.map_long],{icon: greenIcon}).addTo(this.myMap); // add the marker onclick


    this.myMap.on("click", (e: any) => {
      //console.log(e.latlng); // get the coordinates
     // L.popup().setLatLng(e.latlng).setContent('Default Center Point: <br> ' + e.latlng.toString()).openOn(this.myMap);
     if (this.currentClickMarker !== null) {
      this.myMap.removeLayer(this.currentClickMarker);
     }

      this.currentClickMarker = L.marker([e.latlng.lat, e.latlng.lng], {icon: greenIcon}).addTo(this.myMap); // add the marker onclick

        this.form.map_long = e.latlng.lng.toString()

        this.form.map_lat = e.latlng.lat.toString();

     // window.document.getElementById('inputLat1').value = e.latlng.lat.toString();

    });
    this.myMap.on("zoomend", (e: any) => {
     // console.log(this.myMap.getZoom().toString()); // get the coordinates

        this.form.map_zoom = this.myMap.getZoom().toString();


    });


  }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Address List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    loadAddressList(): Promise<any>{
      return new Promise(async (resolve) =>{
        this.addresslistcontrolservice.getAddressLists('').subscribe((results)=>{
          this.addressListArray = results;
          resolve(null);
        });
      });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //       Load Forms List For Seletion Option
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    loadFormsList(): Promise<any>{
      return new Promise(async (resolve) =>{

      this.formControlService.getFormList('',false).subscribe((results)=>{

        //console.log(results);
        this.formsListArray = results;
        resolve(null);
      });
    });

    }


    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //      
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


  onSubmit(): void {
    //const { username, password } = this.form;
    const headers = { 'content-type': 'application/json'}
    this.form.map_id = this.route.snapshot.paramMap.get('map_id');
    this.form.map_lat = parseFloat(this.form.map_lat);
    this.form.map_long = parseFloat(this.form.map_long);
    this.form.map_zoom = parseFloat(this.form.map_zoom);
    if(this.form.map_enable = "true"){this.form.map_enable = true;}else{this.form.map_enable = false;}
    if(this.form.map_signup_marker_show_polygon_name = "true"){this.form.map_signup_marker_show_polygon_name = true;}else{this.form.map_signup_marker_show_polygon_name = false;}
    if(this.form.map_address_external_search_toggle = "true"){this.form.map_address_external_search_toggle = true;}else{this.form.map_address_external_search_toggle = false;}
   
    const body=JSON.stringify(this.form);
      this.http.post(''+this.globalSetting['api_url_console']+'/geomap?api=updatemap',body,{'headers':headers}).subscribe(resp => {
      console.log(resp);
      if(resp['error'] == false){
        console.log(resp);
        Swal.fire('Success', 'Completed Successfully!', 'success').then(()=>{
          this.router.navigate(['/geomap/maps/listmaps'])
        });
      }
      else
      {
        console.log(resp);
        Swal.fire('Failed', ''+JSON.stringify(resp['message'])+'', 'error').then(()=>{
          this.router.navigate(['/geomap/maps/listmaps'])
        });
      }
      //this.rerender();
  });


  }

    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    //
    //
    //     
    //
    //
    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


  load_formvalues(): void{
    this.http.get(''+this.globalSetting['api_url_console']+'/geomap?api=getmapdetails&mapkey='+this.route.snapshot.paramMap.get('map_id'),{}).subscribe((resp: any) => {
      this.form.map_name = resp["map_name"];
      this.form.map_lat = resp["map_lat"];
      this.form.map_long = resp["map_long"];
      this.form.map_zoom = resp["map_zoom"];
      this.form.map_enable = resp["map_enable"];
      this.form.map_addresslist_id = resp["map_addresslist_id"];
      this.form.map_addresslist_backup_id = resp["map_addresslist_backup_id"];
      this.form.map_polyLinkAction = resp["map_polyLinkAction"];
      this.form.map_inputFormLink = resp["map_inputFormLink"];
      this.form.map_inputDefaultForm = resp["map_inputDefaultForm"];
      this.form.map_default_interest_form_text = resp["map_default_interest_form_text"];
      this.form.map_default_interest_form = resp["map_default_interest_form"];
      this.form.map_polygon_hover_text = resp["map_polygon_hover_text"];
      this.form.map_signup_marker_show_polygon_name = resp["map_signup_marker_show_polygon_name"];
      this.form.map_signup_polygon_text = resp["map_signup_polygon_text"];
      this.form.map_address_inside_poly_marker_color = resp["map_address_inside_poly_marker_color"];
      this.form.map_address_outside_poly_marker_color = resp["map_address_outside_poly_marker_color"];
      this.form.map_address_not_found_link = resp["map_address_not_found_link"];
      this.form.map_address_not_found_link_text = resp["map_address_not_found_link_text"];
      this.form.map_address_not_found_link_type = resp["map_address_not_found_link_type"];
      this.form.map_address_not_found_text = resp["map_address_not_found_text"];
      this.form.map_address_outside_marker_text = resp["map_address_outside_marker_text"];
      this.form.map_address_outside_marker_link = resp["map_address_outside_marker_link"];
      this.form.map_address_outside_marker_link_text = resp["map_address_outside_marker_link_text"];
      this.form.map_address_outside_marker_title = resp["map_address_outside_marker_title"];
      this.form.map_address_outside_marker_link_type = resp["map_address_outside_marker_link_type"];
      this.form.map_address_external_search_toggle = resp["map_address_external_search_toggle"];
      this.mapLoaded = true;
      this.myMap = this.mapsservice.InitMap(this.myMap,"map",resp["map_lat"],resp["map_long"],resp["map_zoom"]);
      this.initMap();
      console.log(resp);
      //this.rerender();
  });
  }

}
