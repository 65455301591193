import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

//import Global Setting Varitables
import globalSettings from '../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
// Global Settings
private globalSetting: [] = globalSettings;


  constructor(private http: HttpClient) { }

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Forms for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getFormList(percentElementId: string,nameOnly: boolean): Observable<any>{
  var request;
  return new Observable(observer => {
    if(nameOnly){
    request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms/?api=listforms&nameonly=true',  {
      reportProgress: true
    });
  }else
  {
    request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms/?api=listforms&nameonly=false',  {
      reportProgress: true
      });
    
  }

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }

    });




  });
}



getFormDetails(percentElementId: string, form_id: any): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=getformdetails&formkey='+form_id,  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }

    });




  });
}



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Form Remove API to Delete List by Form ID
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

removeForm(form_id: string, withdialog: boolean, percentElementId: string): Observable<any>{

  return new Observable(observer => {


if(withdialog){


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        const request = new HttpRequest('DELETE', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=removeform&formkey='+form_id+'',  {
          reportProgress: true
         });

    this.http.request(request).subscribe((event: any) => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
        Swal.fire(
          'Deleted!',
          'Your Form has been Deleted.',
          'success'
        ).then(()=>{
          observer.next({error:event.body['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Your Address List has failed to be Deleted.',
            'error'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
        }
        observer.next(event.body);
        //console.log(event.body);
      }

    });

      }else{
        observer.next({error:true});
      }
    })



  }
  else{



    const request = new HttpRequest('DELETE', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=removeform&formkey='+form_id+'',  {
      reportProgress: true
    });

    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });

  }









  });
}



//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
// Call the Address List Disable or Enable API by AddressList ID
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

disEnAddressList(addresslist_id: string, disEn: boolean, withdialog: boolean, percentElementId: string): Observable<any>{

  return new Observable(observer => {



if(withdialog){
var DialogButtoneText = "";
if(disEn){DialogButtoneText = "Enable";}else{DialogButtoneText = "Disable";}
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, '+DialogButtoneText+' it!'
    }).then((result) => {
      if (result.isConfirmed) {

        var tempJson = {
          addresslist_id:addresslist_id,
          addresslist_enable: disEn
        };
        const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=disenaddresslist',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});

    this.http.request(request).subscribe((event: any) => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        if(!event.body['error'])
        {
        Swal.fire(
          ''+DialogButtoneText+'d!',
          'Your Form has been '+DialogButtoneText+'.',
          'success'
        ).then(()=>{
          observer.next({error:event.body['error']});
        });
        }
        else
        {
          Swal.fire(
            'Failed!',
            'Your Form has failed to be '+DialogButtoneText+'.',
            'error'
          ).then(()=>{
            observer.next({error:event.body['error']});
          });
        }
        observer.next(event.body);
        //console.log(event.body);
      }

    });

      }else{
        observer.next({error:true});
      }
    })



  }
  else{



    var tempJson = {
      addresslist_id:addresslist_id,
      addresslist_enable: disEn
    };
    const request = new HttpRequest('POST', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=disenaddresslist',JSON.stringify(tempJson),{headers: new HttpHeaders().set('content-type', 'application/json'), reportProgress: true});


    this.http.request(request).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total);
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }

      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        //console.log(event.body);
      }

    });

  }









  });
}


}
