import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {FormControl, FormArray,FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { ActivatedRoute } from "@angular/router";

import {PackagetablesService} from '../../../../../_services/modules/geomap/packagetables/packagetables.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}






@Component({
  selector: 'app-edit-package-table',
  templateUrl: './edit-package-table.component.html',
  styleUrls: ['./edit-package-table.component.css']
})
export class EditPackageTableComponent implements OnInit {

  matcher = new MyErrorStateMatcher();




  // Show Header
  showTableHeader = true;
  // Init Table Columns
    TableCols: any = [
      {id:'id_0' ,name:"Catalog ID",type:'catalog' , removeable:'no', showtb:'no', showcol:'no'},
  
      {id:'id_1' ,name:"Package Name",type:'pkgName' , removeable:'no' , showtb:'yes', showcol:'yes'},
  
      {id:'id_2' ,name:"Price", type:'price', removeable:'no', showtb:'yes', showcol:'yes'},
  
    ];
  
  // Init Table Column ID Counter
    TableColId: number = 3;
  
    // Array of the Table Row Form Controls
    TableRows: any = [];
  
     form = new FormGroup({
      tableRowValues: new FormArray([
      
      ])
    });
  
  // Init Table Row ID Counter
  TableRowId: number = 1;
  
  tableData: any;
  
    constructor(private packagetablesService:PackagetablesService,private route: ActivatedRoute) { }
  
    ngOnInit(): void {
      this.getPackageTableData();
  //console.log(this.tableRowValues?.controls);
  //Object.keys(this.tableRowValues?.controls[0].value)
    }
  
    
  


///////////////////////////////////////////////////////////////////////////////////////
///
///
/// 
///
///
///////////////////////////////////////////////////////////////////////////////////////


  addcol(name: any ): void{
  
  if(name !== ''){
  var temp_id = 'id_'+(this.TableColId);
  var temp_name: any = name;
  }
  else{
  var temp_id = 'id_'+(this.TableColId);
  var temp_name: any = 'New Col';
  }

    this.TableCols.push({id:''+temp_id+'' ,name:""+temp_name+"",type:'none', removeable:'yes', showtb:'yes', showcol:'yes'});
  
    console.log(this.tableRowValues);
  
  
    this.tableRowValues?.controls.forEach((element: any) => {
  
      element.addControl(''+temp_id+'',new FormControl(''));
      
    });
    //var node = document.getElementById('rowToolbox');
    //node?.insertAdjacentHTML('afterend', '<li type="col" class="list-group-item list-group-item-action"><div class="media"><div class="col-12"><div class="row"><div class="col-md-10"><input type="text" class="form-control" name="col_name_2" id="form_name" placeholder="Column Name"> </div><div class="col-md-2 pt-3 pb-3"><a>Del</a></div></div> </div></div></li>');
    this.TableColId++;
  }
  
  
   get tableRowValues(): FormArray {
    return this.form.get('tableRowValues') as FormArray;
  }



///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
/////////////////////////////////////////////////////////////////////////////////////// 
  
  addRow(rowData: any | null) {

if(rowData !== null){
  
  var tempFormObject: any = {};

    var count = 0;
  this.TableCols.forEach(async(colItem)=>{
    
    tempFormObject[''+colItem['id']+''] = new FormControl(rowData['cols_data'][count]['value']);
    count++;
  });

// Add and ID to the Row
tempFormObject['rowId'] = new FormControl(''+this.TableRowId+'');
this.TableRowId++;

console.log(this.tableRowValues);
this.tableRowValues.push(new FormGroup(tempFormObject));


    
    
}
else{
  var tempFormObject: any = {};
  this.TableCols.forEach(async(colItem)=>{
    tempFormObject[''+colItem['id']+''] = new FormControl('');
  });
    // Add and ID to the Row
    tempFormObject['rowId'] = new FormControl(''+this.TableRowId+'');
    this.TableRowId++;
  
    console.log(this.tableRowValues);
    this.tableRowValues.push(new FormGroup(tempFormObject)); 
}

  }
  
///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////


  getObjectArray(): any{
    return Object.keys(this.tableRowValues?.controls[0].value);
  }
  

///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////

  updateTableValue(inputData: any,id: any): void{
  
    this.TableCols.forEach((colValue)=>{
  
      if(colValue['id'] == id){
        colValue['name'] =''+inputData.target.value+'';
      }
    });
  
  }


///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////

updateTableHeader(inputData: any,id: any): void{
  
  this.TableCols.forEach((colValue)=>{

    if(colValue['id'] == id){
      colValue['name'] =''+inputData+'';
    }
  });

}

///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////
  
  delColumn(colId: any): void{
  //Deletes the Column FormControl from all Rows
    this.tableRowValues?.controls.forEach((element: any) => {
   
      element.removeControl(''+colId+'');
      
    });
    //Deletes the Column from Gobal Table Col Array
    this.TableCols = this.TableCols.filter(data => data.id != ''+colId+'');
  
  
  }

///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////
  
  delRow(rowId: any): void{
     this.tableRowValues?.removeAt(this.tableRowValues?.controls.findIndex(image => image.value.rowId === rowId));
    //this.tableRowValues.controls = this.tableRowValues?.controls.filter(function(el) { return el.value['rowId'] != ""+rowId+""; });
  
  }
  


///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////


  onTypeChange(): void{
    this.TableCols.forEach((colValue)=>{
  
      if(colValue['id'] == 'id_0'){
        if(this.tableData.package_table_type.value == 'autoprovcap'){
        colValue['showtb'] = 'yes';
        }
        else
        {
        colValue['showtb'] = 'no';
        }
      }
    });
  
  }
  

///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////



  getTableShow(id: any): Observable<any>{
  
      return new Observable(observer => {
  
      this.TableCols.forEach(async (colValue: any)=>{
  
        if(colValue['id'] == ''+id+''){
          observer.next(colValue['showtb']);
          
        }
  
      });
    });
  }
  









///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////





getPackageTableData(): void{


  this.packagetablesService.getPackageTableDetails('',this.route.snapshot.paramMap.get('package_table_id')).subscribe((returnTableData)=>{
    var count = 0;
    // Set Table Type and Name Field
    this.tableData = {
      package_table_name: new FormControl(returnTableData['package_table_name'],[Validators.required]),
      package_table_type: new FormControl(returnTableData['package_table_type'],[Validators.required])
    };
    //Show the Catlog Field if it is enabled 
    this.TableCols.forEach((colValue)=>{
  
      if(colValue['id'] == 'id_0'){
        if(returnTableData['package_table_type'] == 'autoprovcap'){
        colValue['showtb'] = 'yes';
        }
        else
        {
        colValue['showtb'] = 'no';
        }
      }
    });
    // Add teh Headers to the Table 
    returnTableData['package_table_headers'].forEach(async (headerData) => {

      if(headerData['type'] == 'catalog'){
        this.updateTableHeader(headerData['name'],'id_0')
      }

      else if(headerData['type'] == 'pkgName'){
        this.updateTableHeader(headerData['name'],'id_1')
      }

      else if(headerData['type'] == 'price'){
        this.updateTableHeader(headerData['name'],'id_2')
      }

      else{
        this.addcol(headerData['name']);
      }
      count++;
      // once Headers are added Load the Row Data
      if(count >= returnTableData['package_table_headers'].length){
        returnTableData['package_table_rows'].forEach(async (rowData) => {

          this.addRow(rowData);
        
        });
      }
    });

  });



}



///////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
///////////////////////////////////////////////////////////////////////////////////////




onSubmit(): void{
  var tempHeaders: any = [];
  var tempRows: any = [];
  this.form.value.tableRowValues?.forEach(async (rowData)=>{
  
    var temprowData: any = [];
    console.log(Object.entries(rowData));

    Object.entries(rowData).forEach(async (value)=>{
      if(value[0] !== 'rowId'){
          if(value[0] == 'id_0'){ 
            temprowData.push({id:value[0], value:value[1],public:false});
          }
          else{
            temprowData.push({id:value[0], value:value[1],public:true});
          }
      }
    });
  
    console.log(temprowData);
    tempRows.push({col_prov_package_id:rowData['id_0'],cols_data:temprowData});
  
  });
  
  this.TableCols.forEach(async (colValue)=>{

    if(colValue['id'] == 'id_0'){ 
      tempHeaders.push({id:colValue['id'],name:colValue['name'], type:'catalog', public:false});
    }
    else if(colValue['id'] == 'id_1'){
      tempHeaders.push({id:colValue['id'],name:colValue['name'], type:'pkgName', public:true});
    }
    else if(colValue['id'] == 'id_2'){
      tempHeaders.push({id:colValue['id'],name:colValue['name'], type:'price', public:true});
    }
    else{
      tempHeaders.push({id:colValue['id'], name:colValue['name'], public:true});
    }
  });
  console.log("Table Data:", tempRows);
  this.packagetablesService.updatePackageTable('',this.route.snapshot.paramMap.get('package_table_id'),{tableData:this.tableData,tableHeaders:tempHeaders,tableRows:tempRows}).subscribe(()=>{
  
  });
  //console.log(this.tableRowValues.value);
  //console.log(this.TableCols);
  //console.log(this.tableData);
  
  
  }
  
  }
  