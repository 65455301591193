import { Component, Input, OnInit,HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

import { QuestionBase } from '../_question_controltype/question-base';
import { QuestionControlService } from '../_service/question-control.service';
declare var grecaptcha: any;
// Form Controls and Data Mangement Imports
import {FormControlService } from '../../_service/form-control.service';
import {FormDataControlService } from '../../_service/form-data-control.service';

import {FormEntriesControlService} from '../../../../../../../_services/modules/geomap/forms/form-entries-control/form-entries-control.service'; 
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {

// Host Binding for Color Propertys of Package Table Headers
  @HostBinding("style.--pk-tb-header-bg-color") pk_tb_header_bg_color: string = "#0099da";
  @HostBinding("style.--pk-tb-header-text-color") pk_tb_header_text_color: string = "white";

// Inputs Varitables from Parent Component
  @Input() questions: QuestionBase<any>[][][] | null = [];
  @Input() keyed_fields: any;
  @Input() packageTables: any;
  @Input() formSettings: any;
  @Input() LoadingControlService: any;
  @Input() entryData: any;
// Global Vars
  showCompletePage = false;
  showSpeedtestPage = false;
  speedtestFormID = '';
  showFormPage = true
  form!: FormGroup;
  payLoad = '';
  showform = false
  fileUploads: any = {};

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private formControl: FormControlService,
    private formDataControl: FormDataControlService,
    private formEntriesControlService: FormEntriesControlService
    ) {}

  ngOnInit() {
    console.log("testing");
    console.log(this.formSettings);
    //console.log(this.questions);
    if (this.entryData['form_data'] !== null && this.entryData['form_data'] !== undefined){
      console.log(this.formSettings['form_headers']);
      console.log(this.entryData['form_data']);
      this.formControl.keyedElementSearchandAddValue(this.questions,this.formSettings['form_headers'],this.entryData['form_data']
        ).subscribe(()=>{

      });

  }
  this.removeNonInputRows(async()=>{
       this.loadForm();
  });
    // Init Default Display Styling
    this.loadDefaultStyleing();
    //Initlize the Form Display Element
   // this.loadForm();
    //this.form = this.qcs.toFormGroup(this.questions! as QuestionBase<string>[][][]);
  }


removeNonInputRows(callback): void{
  var inputConstructors = ['CheckboxQuestion','DateSelectorQuestion','DropdownQuestion',
  'FileUploadQuestion','HiddenTextboxQuestion','PackageTableQuestion','RadioQuestion',
  'TextAreaQuestion','TextboxQuestion'];




  var rowPromise: any = [];
  this.questions?.forEach(async(rowArray: any, rowIndex)=>{

    rowPromise.push(new Promise(async(rowResolve) => {

    var colPromise: any = [];
    //console.log(this.questions);
    rowArray.forEach(async(colArray:any, colIndex)=>{

      colPromise.push(new Promise(async(colResolve) => {
          var elementPromise: any= [];
          colArray.forEach(async(elementArray:any, elementIndex)=>{

              elementPromise.push(new Promise(async(elementResolve: any) => {
              //console.log(elementArray);
              //console.log(elementArray.constructor.name);
              elementResolve(elementArray.constructor.name);
              //this.questions!.splice(rowIndex, 1);
              }));
          });
          //setInterval(()=>{ console.log("Level 3");console.log(elementPromise)},10000);
          Promise.all(elementPromise).then(async(elementConstructorNames) => {
            if(elementConstructorNames.length > 0){
            elementConstructorNames.forEach(async(constructName, constIndex)=>{
              if(inputConstructors.includes(constructName)){
                colResolve(true);

              }
              else if((constIndex+1) >= elementConstructorNames.length){
                colResolve(false);

              }
            });
          }
          else{
            colResolve(false);
          }
                  // console.log('Products are ->', products);
          });

        }));
      });
       //setInterval(()=>{ console.log("Level 2");console.log(colPromise)},10000);
      Promise.all(colPromise).then(async(colResults) => {
        colResults.forEach(async(colResult, colIndex)=>{
          if(colResults.includes(true)){
            rowResolve(true);
          }
          else if((colIndex+1) >= colResults.length){
            rowResolve(false);
          }
        });
      });

    }));

  });
   //setInterval(()=>{ console.log("Level 1");console.log(rowPromise)},10000);
  Promise.all(rowPromise).then(async(rowResults) => {
    var removeOffset = 0;
    rowResults.forEach(async(rowResult, rowIndex)=>{
      if(!rowResult){
        this.questions!.splice((rowIndex - removeOffset), 1);
        removeOffset++;
      }
      if((rowIndex+1) >= rowResults.length){
       //console.log(this.questions);
       //console.log(rowPromise);
       callback();
      }
    });
  });
}


  loadForm(): void{

    this.form = this.qcs.toFormGroup(this.questions! as QuestionBase<string>[][][]);
    console.log( this.form);
    this.showform = true;
  }

  loadDefaultStyleing(): void{
    // Load Styleing Defaults
    this.pk_tb_header_bg_color =  this.formSettings['form_package_tb_header_bg_color'];
    this.pk_tb_header_text_color = this.formSettings['form_package_tb_header_text_color'];
  }

  onSubmit() {
   // this.LoadingControlService.LoadingDialog();
    if(this.form.valid !== false){

        this.formDataControl.parseHeadersToMatchData(this.form.getRawValue(),this.formSettings['form_headers']).subscribe(async (parsedData: any)=>{

          console.log(parsedData);
          this.formEntriesControlService.updateFormData(this.formSettings['entry_id'],parsedData).subscribe(async (response: any)=>{
          });
      });

  }
  else{
    //this.LoadingControlService.errorWithButtonLoadingDialog("Error", "Required Fields Not Complete",{confirmButtonColor:this.formSettings['form_continue_dialog_button_color']});
  }
}
}
