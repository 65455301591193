import { Injectable } from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Observable } from 'rxjs';

//import Global Setting Varitables
import globalSettings from '../../../../../_settings/Globle_Settings.json';

@Injectable({
  providedIn: 'root'
})
export class EntryViewerControlService {
 // Global Settings
 private globalSetting: [] = globalSettings;

  constructor(private http: HttpClient) { }

  //////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//
//
//    Call API to return Array of Users for the Users CORP_ID
//    set in the Session Token Record
//
//
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

getMapFormList(percentElementId: string, map_id: any): Observable<any>{

  return new Observable(observer => {

    const request = new HttpRequest('GET', ''+this.globalSetting['api_url_console']+'/geofi/forms?api=getallformsofmapdetailed&mapkey='+map_id,  {
      reportProgress: true
    });
    
    this.http.request(request).subscribe(event => {
    
      // progress
      if (event.type === HttpEventType.DownloadProgress) {
        console.log(event.loaded, event.total); 
        // event.loaded = bytes transfered 
        // event.total = "Content-Length", set by the server
    if(typeof(event.total) !== 'undefined'){
        const percentage = 100 / event.total * event.loaded;

        if(percentElementId !== '' && percentElementId !== undefined){
          let polyTableLoader = document.getElementById(''+percentElementId+'');
          polyTableLoader!.innerHTML = Math.round(percentage)+'%';
          console.log(percentage);
        }

    }
      }
    
      // finished
      if (event.type === HttpEventType.Response) {
        observer.next(event.body);
        console.log(event.body);
      }
    
    });
      
       
   

  });
}
}
