<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listmaps']">Maps</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listpolys',{map_id: map_id}]">List Polygons</a></li>
            <li class="breadcrumb-item active" aria-current="page">New Polygons</li>
        </ol>
    </nav>
    </div>


    <div class="row layout-spacing">
      <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
          <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                    </svg></a>
              </li>

              <li class="nav-item ml-2 mr-2">
                  <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()"  matTooltip="Save Table"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                    </svg> </a>
              </li>
          </ul>
      </div>
  </div>


<div class="row layout-spacing">

    <!-- Content -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">





        <div  style="" *ngIf="!this.enableUpload" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">Upload File (Shape, KMZ, KML, Geojson)</h4>
                        </div>


                    </div>


                    <div class="w-content">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row g-3">


                                <div class="form-row col-md-12">
                                    <div class="form-group col-md-11">


                                        <div class="upload-btn-wrapper">
                                            <button class="btn btn-outline-primary mb-2">Upload a Polygon File</button>
                                            <input type="file" [disabled]="polyFileUploaded" (change)="onFileChange($event)" name="poly_geojson" id="fileInput" accept=".geojson" />
                                          </div>


                                </div>
                                <div class="form-group col-md-1">

                                        </div>
                                </div>



                            </div>
                        </div>




                    </div>


                </div>
            </div>
        </div>




        <div  style="" *ngIf="!this.enableImport" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
    
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">Default Settings</h4>
                        </div>


                    </div>


                    <div class="w-content">
                        
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div [formGroup]="formDefaults" class="row"> 

                                <div class="col-lg-1">
                                    <label *ngIf="formDefaults.get('default_poly_color').hasError('required')" for="default_poly_color" style="color:red">Color*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_color').hasError('required')" for="default_poly_color" >Color</label>
                                    <input type="color" formControlName="default_poly_color"  class="form-control" id="default_poly_color" style="height:50px;" placeholder="Polygon Color">
                                </div>
                                <!--<div class="col-lg-1" style="display:none;">
                                    <label for="inputFillColor1">Fill Color</label>
                                    <input type="color" class="form-control"  name="poly_fill_color" id="defaultInputFillColor1" placeholder="Fill Color" value="#0099da">
                                </div>
                                <div class="col-lg-1" style="display:none;">
                                    <label for="inputFillOpacity1">Fill Opacity</label>
                                    <input type="number" min="0.1" step="0.1" max="1.0" class="form-control" name="poly_fill_opacity" id="defaultInputFillOpacity1" placeholder="Fill Opacity" value="0.2">
                                </div>-->
                                <div class="col-lg-1">
                                    <label *ngIf="formDefaults.get('default_poly_type').hasError('required')" for="default_poly_color" style="color:red">Type*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_type').hasError('required')" for="default_poly_color" >Type</label>
                                    <select formControlName="default_poly_type" required  class="selectpicker form-control" id="default_poly_type" required>
                                        <option value="" >Select an Option</option>
                                        <option value="none">N/A</option>
                                        <option value="fiber">Fiber</option>
                                        <option value="copper">Copper</option>
                                    </select>

                                </div>
                              <!-- <div class="col-lg-1"  style="display:none;">
                                    <label for="inputRadius1">Radius</label>
                                    <input type="number" class="form-control"  name="poly_radius" id="defaultInputRadius1" placeholder="Radius" value="500">
                                </div> -->
                                <div class="col-lg-2">

                                    <label *ngIf="formDefaults.get('default_poly_status').hasError('required')" for="default_poly_status" style="color:red">Status*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_status').hasError('required')" for="default_poly_status" >Status</label>
                                    <select formControlName="default_poly_status" required  class="selectpicker form-control" id="default_poly_status" required>
                                        <option value="">Select an Option</option>
                                        <option value="none" >N/A</option>
                                        <option value="62dedc487c70006b58936cd8">Interest</option>
                                        <option value="62dedc487c70006b58936cd9">Pre-Signup</option>
                                        <option value="62dedc487c70006b58936cda">Signup</option>
                                        <option value="62dedc487c70006b58936cdb">Pre-Construction</option>
                                        <option value="62dedc487c70006b58936cdc">Construction</option>
                                        <option value="62dedc487c70006b58936cdd">Complete</option>
                                        <option value="62dedc487c70006b58936cde">Install Ready</option>
                                    </select>

                                </div>

                                <div class="form-group col-lg-1">

                                    <label *ngIf="formDefaults.get('default_poly_enable').hasError('required')" for="default_poly_enable" style="color:red">Enabled*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_enable').hasError('required')" for="default_poly_enable" >Enabled</label>
                                    <select formControlName="default_poly_enable" required  class="selectpicker form-control" id="default_poly_enable" required>
                                        <option>Select an Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                      
                                    </select>

                                </div>

                                <div class="form-group col-lg-3">

                                    <label *ngIf="formDefaults.get('default_poly_inputformlink').hasError('required')" for="default_poly_inputformlink" style="color:red">Form Link Text*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_inputformlink').hasError('required')" for="default_poly_inputformlink" >Form Link Text</label>
                                    <input type="text" formControlName="default_poly_inputformlink" class="form-control" id="default_poly_inputformlink" placeholder="Form Link Text">
                                </div>

                                <div class="form-group col-lg-2">

                                    <label *ngIf="formDefaults.get('default_poly_input_form_type').hasError('required')" for="default_poly_input_form_type" style="color:red">Link Type*</label>
                                    <label *ngIf="!formDefaults.get('default_poly_input_form_type').hasError('required')" for="default_poly_input_form_type" >Link Type</label>
                                    <select formControlName="default_poly_input_form_type"  class="selectpicker form-control" id="default_poly_input_form_type" required>
                                        <option>Select an Option</option>
                                        <option value="url">URL</option>
                                        <option value="form">Form</option>
                                      
                                    </select>

                              </div>

                               <div *ngIf="formDefaults.get('default_poly_input_form_type').value == 'form'" class="form-group col-lg-2">

                                <label *ngIf="formDefaults.get('default_poly_inputdefaultform').hasError('required')" for="default_poly_inputdefaultform" style="color:red">Form*</label>
                                <label *ngIf="!formDefaults.get('default_poly_inputdefaultform').hasError('required')" for="default_poly_inputdefaultform" >Form</label>
                                <select formControlName="default_poly_inputdefaultform" class="selectpicker form-control" id="default_poly_inputdefaultform" required>
                                    <option selected>Select a Form</option>
                                    <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                                        {{FormsList.form_name}}
                                    </option>
                                  
                                </select>

                              </div>

                              <div *ngIf="formDefaults.get('default_poly_input_form_type').value == 'url'" class="form-group col-lg-2">

                                <label *ngIf="formDefaults.get('default_poly_inputdefaultform').hasError('required')" for="default_poly_inputformlink" style="color:red">Link URL*</label>
                                <label *ngIf="!formDefaults.get('default_poly_inputdefaultform').hasError('required')" for="default_poly_inputformlink" >Link URL</label>
                                <input type="text" formControlName="default_poly_inputdefaultform"  class="form-control" id="default_poly_inputdefaultform" value="http://example.com" placeholder="Link URL">

                            </div>

                            </div>



                            <div class="row col-lg-12">
                                <div class="form-group col-lg-11">
                                </div>
                                <div class="form-group col-lg-1">

                                    <button type="submit" (click)="onImport()" [disabled]="!formDefaults.valid && !enableImport" class="btn btn-primary mt-3">Import</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
   
        </div>




        <div style="display:none;" id="polyTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
          <div class="widget widget-card-standard-dropdown">
              <div class="widget-content">
                  <div class="w-header">
                      <div class="w-info">
                          <h4 class="value">Imported Polygons</h4>
                      </div>


                  </div>


                  <div class="w-content">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="row g-3">



                            <div  class="">
                              <div class="add-lessons-form" [formGroup]="form">
                                  <ng-container formArrayName="lessons">
                              <table *ngIf="displayTable"  datatable="polyDTTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="html5-extension" class="table table-hover non-hover" style="width:100%; min-height:250px;">
                                                  <thead>
                                                      <tr>

                                                          <th>Name</th>
                                                          <th>Color</th>
                                                         <!-- <th style="display:none;">Fill Color</th>
                                                          <th style="display:none;">Fill Opacity</th>
                                                          <th style="display:none;">Radius</th> -->

                                                          <th>Enable</th>
                                                          <th>Polygon Type</th>
                                                          <th>Status</th>
                                                          <th>Link Text</th>
                                                          <th>link type</th>
                                                          <th>Form</th>
                                                          <th>Remove</th>


                                                      </tr>
                                                  </thead>

                                                  <tbody>


                                              <tr id="mapsTableBody" *ngFor="let lessonForm of lessons.controls; let i = index" [formGroup]="lessonForm | asFormGroup" >

                                                <td>
                                                  <div style="display:none;">{{lessonForm['value']['poly_name']}}</div>
                                                 <input type="text" class="form-control" style="width:200px;" formControlName="poly_name" name="poly_name" id="poly_name" placeholder="Poly Name" [value]="lessonForm['value']['poly_name']">
                                                </td>

                                                  <td>
                                                    <input type="color" class="form-control" style="width:100px;" formControlName="poly_color"  name="poly_color" id="poly_color" placeholder="Color">
                                                  </td>

                                                  <!-- <td>
                                                  <input type="color" class="form-control" style="width:100px; display:none;" formControlName="poly_fill_color"  name="poly_fill_color" id="poly_fill_color" placeholder="Fill Color">
                                                  </td>

                                                  <td>
                                                  <input type="number" min="0.1" step="0.1" max="1.0" class="form-control"style="width:100px; display:none;" formControlName="poly_fill_opacity"  name="poly_fill_opacity" id="poly_fill_opacity" placeholder="Fill Opacity" >
                                                  </td>

                                                  <td>
                                                  <input type="number" class="form-control" style="width:100px; display:none;" formControlName="poly_radius"  name="poly_radius" id="poly_radius" placeholder="Radius">
                                                  </td> -->


                                                  <td>
                                                      <select name="poly_enable" formControlName="poly_enable" style="width:100px;" name="poly_enable" id="poly_enable" class="selectpicker form-control">
                                                          <option value="true">Yes</option>

                                                          <option value="false">No</option>

                                                          </select>
                                                  </td>
                                                  
                                                  <td>
                                                    <select name="poly_type" formControlName="poly_type" style="width:175px;" name="poly_type" id="poly_type" class="selectpicker form-control">
                                                        <option value="none" selected>N/A</option>
                                                        <option value="fiber">Fiber</option>
                                                        <option value="copper">Copper</option>

                                                        </select>
                                                </td>

                                                  
                                                  <td>
                                                      <select name="poly_status" formControlName="poly_status" style="width:175px;" name="poly_status" id="poly_status" class="selectpicker form-control">
                                                          <option value="">Select Status</option>
                                                          <option value="none">N/A</option>
                                                          <option value="62dedc487c70006b58936cd8">Interest</option>
                                                          <option value="62dedc487c70006b58936cd9">Pre-Signup</option>
                                                          <option value="62dedc487c70006b58936cda">Signup</option>
                                                          <option value="62dedc487c70006b58936cdb">Pre-Construction</option>
                                                          <option value="62dedc487c70006b58936cdc">Construction</option>
                                                          <option value="62dedc487c70006b58936cdd">Complete</option>
                                                          <option value="62dedc487c70006b58936cde">Install Ready</option>

                                                          </select>
                                                  </td>



                                                  <td>
                                                      <input type="text" class="form-control" formControlName="poly_inputformlink"style="width:300px;" name="poly_inputformlink" id="poly_inputformlink" placeholder="Signup Today?">
                                                  </td>


                                                 <td>
                                                    <select name="poly_input_form_type" formControlName="poly_input_form_type" style="width:100px;" name="poly_input_form_type" id="poly_input_form_type" class="selectpicker form-control">
                                                        <option value="url">URL</option>

                                                        <option value="form">Form</option>

                                                        </select>
                                                </td>

                                                  <td >
                                                      <select *ngIf="lessonForm['value']['poly_input_form_type'] == 'form'" class="form-control" style="width:175px;" ng-model="form.model" formControlName="poly_inputdefaultform" name="poly_inputdefaultform" id="poly_inputdefaultform">
                                                          <option>Select a Form</option>
                                                          <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                                                              {{FormsList.form_name}}
                                                          </option>
                                                      </select>
                                                      <input *ngIf="lessonForm['value']['poly_input_form_type'] == 'url'" type="text" style="width:175px;" class="form-control"  name="poly_inputdefaultform" id="poly_inputdefaultform" placeholder="http://mysite.com/signup">
                                                  </td>

                                                  <td>
                                                      <button class="btn btn-danger mb-2 mr-2" (click)=" deletePolyForm(lessonForm['value']['file_poly_id'])">
                                                      Delete
                                                      </button>
                                                  </td>



                                              </tr>
                               </tbody>
                              </table>
                            </ng-container>
                          </div>
                      </div>



                          </div>



                          <div class="row col-lg-12">
                              <div class="form-group col-lg-11">
                              </div>
                              <div class="form-group col-lg-1">



                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>





        </div>

  <!--<div class="col-xl-7 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">

         <div class="add-lessons-form" [formGroup]="form">
            <ng-container formArrayName="lessons">
                <ng-container *ngFor="let lessonForm of lessons.controls; let i = index">
                    <div class="lesson-form-row" [formGroup]="lessonForm | asFormGroup">
                        <div class="user-profile layout-spacing">
                            <div class="widget-content widget-content-area pl-2 pr-2 pb-4 pt-2">
                                <div class="d-flex justify-content-between">
                                    <h3 class="">Poly</h3>
                                    <a href="user_account_setting.html" class="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></a>
                                </div>

                                <div class="widget-content layout-top-spacing">

                                        <div class="form-row mb-4">
                                            <div class="form-group col-md-6">
                                                <label for="inputName1">Poly Name</label>
                                                <input type="text" class="form-control" formControlName="poly_name" name="poly_name" id="poly_name" placeholder="Poly Name" >
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="inputColor1">Color</label>
                                                <input type="text" class="form-control" formControlName="poly_color"  name="poly_color" id="inputColor1" placeholder="Color" value="blue">
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="inputFillColor1">Fill Color</label>
                                                <input type="text" class="form-control" formControlName="poly_fill_color"  name="poly_fill_color" id="inputFillColor1" placeholder="Fill Color" value="#f03">
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="inputFillOpacity1">Fill Opacity</label>
                                                <input type="text" class="form-control" formControlName="poly_fill_opacity"  name="poly_fill_opacity" id="inputFillOpacity1" placeholder="Fill Opacity" value="0.2">
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="inputRadius1">Radius</label>
                                                <input type="text" class="form-control" formControlName="poly_radius"  name="poly_radius" id="inputRadius1" placeholder="Radius" value="500">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="map_id">MAP Name</label>
                                                <select name="map_id" formControlName="map_id" name="map_id" id="map_id" class="selectpicker form-control">
                                                <option>Select Map</option>

                                                <option value="">asdf</option>

                                                </select>

                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="poly_enable">Publish Polygon</label>
                                                <select name="poly_enable" formControlName="poly_enable" name="poly_enable" id="poly_enable" class="selectpicker form-control">
                                                <option value="true">Yes</option>

                                                <option value="false">No</option>

                                                </select>

                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="poly_inputformlink">Form Link</label>
                                                <input type="text" class="form-control" formControlName="poly_inputformlink" name="poly_inputformlink" id="poly_inputformlink" placeholder="Signup Today?">
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="poly_inputdefaultform">Form</label>
                                                <select class="form-control" formControlName="poly_inputdefaultform" name="poly_inputdefaultform" id="poly_inputdefaultform">
                                                <option>Select a Form</option>
                                            </select>
                                            </div>


                                        </div>

                                        <div class="form-group">


                                        </div>



                                </div>
                             </div>
                            </div>




                    </div>
                </ng-container>
            </ng-container>

            <button mat-mini-fab (click)="addPolyForm('',{})">
                <mat-icon class="add-course-btn">add</mat-icon>
            </button>
            <button mat-mini-fab (click)="viewLesson()">
                <mat-icon class="add-course-btn">add</mat-icon>
            </button>

    </div>
    </div> -->




    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

        <div class="skills layout-spacing ">
            <div class="widget-content widget-content-area">
                <div style="text-align:center;">

                    <div style="display:block;" id="polyMapLoader" class="flex-row">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col">

                                </div>
                                <div class="col">
                                    <div style="text-align:center;">
                                        <br>
                                    <h4>Loading...</h4>
                                    <div  class="lds-dual-ring"></div>
                                    <h4 id="loadingTablePercent"></h4>
                                    </div>
                                </div>
                                <div class="col">

                                </div>
                              </div>
                        </div>
                    </div>

            <div id="map"  style="width:100%; min-height:650px; height:100%;"></div>


            </div>
        </div>


    </div>

</div>
