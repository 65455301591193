import { Component, OnInit, AfterViewInit,ViewChild} from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { Subject } from 'rxjs';

import {ReportControlService} from '../../../../_services/modules/geomap/reports/report-control.service';

declare var $: any;
declare var vanillaSelectBox: any;
// Global Imports
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';
import { FormControlService } from '../../../../_services/modules/geomap/forms/form-control.service'
@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.css']
})
export class ListReportsComponent implements OnInit,AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //dtOptions: DataTables.Settings = {};
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();
  displayTable = false;
  rows: any;

  constructor(private reportControlService:ReportControlService,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private formControlService: FormControlService,
    private router: Router,
    ) {
    this.dtOptions = {
      "dom":
    "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
    //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
    "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
    "<'table-responsive'tr>" +
    "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
    buttons: {
      buttons: [
          { extend: 'csv',
            className: 'btn',
            title:"Maps",
            text: 'CSV',
            exportOptions: {
          columns: 'th:not(:last-child)'
          }
        },
          { extend: 'excel',
            className: 'btn',
            title:"Maps",
            text: 'Excel',
              exportOptions: {
               columns: 'th:not(:last-child)'
                  }
          },
            {
            extend: 'print',
            className: 'btn',
            title:"Maps",
            exportOptions: {
              columns: 'th:not(:last-child)'
                 }
            }
      ]
  },
      "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "stripeClasses": [],
      "lengthMenu": [7, 10, 20, 50],
      "pageLength": 7 ,
      "processing": true,
      //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
      responsive: true,
      "initComplete":function(settings, json) {
        let polyTable = document.getElementById('mapTable');
        polyTable!.style.display = 'block';
        let polyTableLoader = document.getElementById('polyTableLoader');
        polyTableLoader!.style.display = 'none';
      },
    };
   }

  ngAfterViewInit(): void {

    this.rows = [];
    // console.log(userList);
    this.displayTable = true;
    this.dtTrigger.next(this.dtOptions);


  }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  this.customcssservice.load_switches_css();

    this.load_table();

  }

  onClick(data: any): void{
    //console.log(data);
    this.router.navigate(['/geomap/reports/reportviewer',{report_id: data._id}]);
  }

  load_table(): void{



    this.reportControlService.getReports('loadingTablePercent').subscribe((userList)=>{
      this.rows = userList;
      console.log(userList);
      this.displayTable = true;
      this.dtTrigger.next(this.dtOptions);
    });


  }


  table_reload(): void{


    this.reportControlService.getReports('loadingTablePercent').subscribe((userList)=>{
      this.rows = userList;
      console.log(userList);
      this.displayTable = true;
      this.rerender();
    });

  }
  rerender(): void {
    //this.displayTable = false;
    console.log(this.dtElement);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
     // this.displayTable = true;
      this.dtTrigger.next(this.dtOptions);
    });

  }


 newReport(): void{


  var tempSelectForms = '';
  var count = 0;

  this.formControlService.getFormList("",false).subscribe(async (formListData: any)=>{
    formListData.forEach(async (formDetails: any)=>{
      tempSelectForms = tempSelectForms + '<option value="'+formDetails['_id']+'">'+formDetails['form_name']+'</option>';
      count++;
    if(count >= formListData.length){
    this.customcssservice.load_vanillaSelectBox_Style();
    Swal.fire({
      title: 'New Report',
      html: `<div style="height:300px; width:px;">
      <select id="selectReportForm">
      `+tempSelectForms+`
      </select>
      </div>
  `,
      width: 500,
      confirmButtonText: 'Continue',
      focusConfirm: false,
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: `Cancel`,
      preConfirm: () => {
        const form_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#selectReportForm')!).value;
       // element.hasAttribute('foo');
       // element.getAttribute('foo');
       // element.setAttribute('foo', value);
      //  element.removeAttribute('foo');
       // if (!login || !password) {
      //    Swal.showValidationMessage(`Please enter login and password`)
       // }
       return ({selected_form:form_val});
     }
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.router.navigate(['/geomap/reports/reportbuilder', {form_id:result.value['selected_form']}]);
      //  /geomap/reports/reportbuilder;form_id=632c6974bf758009c5809eaa
      } else if (result.isDenied) {

      }
      console.log(result.value);

    });

    var selectBox = new vanillaSelectBox("#selectReportForm", {
      "keepInlineStyles":true,
      "maxHeight": 200,
      "minWidth":300,
      "search": true,
      "placeHolder": "Choose a Form"
  });
  }
});
  });
 }


 removeReport(report_id: string): void{
  this.reportControlService.removeReport(report_id,true,'').subscribe(async ()=>{
    this.table_reload();
  });
 }


}
