
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing " >
  <div class="row">
    <mat-toolbar color="primary" style="">


        <button mat-button matTooltip="Data Export Functions"  matTooltipPosition="below" matTooltipHideDelay="500" [matMenuTriggerFor]="menu"> Data Export <mat-icon>keyboard_arrow_down</mat-icon></button>
       <mat-menu #menu="matMenu">
         <button mat-menu-item (click)="''">Export All Data</button>
       </mat-menu>
               <span class="example-spacer" style="flex: 1 1 auto;"></span>




             <button mat-button [matMenuTriggerFor]="menu1"><mat-icon>select_all</mat-icon></button>
             <mat-menu #menu1="matMenu">
               <button mat-menu-item (click)="''">NISC Provisioning</button>
             </mat-menu>
              <button mat-button (click)="onSubmit()"><img src="assets/insights/light/img/forms/form-entry-details/save.svg" width="25px" height="25px"></button>
             </mat-toolbar>
    </div>

    <div *ngIf="entryData['speedtest_data']" class="row layout-top-spacing">

      <div class="statbox widget box box-shadow">

        <div class="widget-content widget-content-area">
         <div class="col-lg-12">
           <div class="row">
            <div class="col-lg-3">
            <p>Download Speed</p>
            <p>{{entryData['speedtest_data']['dlStatus']}} Mbps</p>
            </div>
            <div class="col-lg-3">
              <p>Upload Speed</p>
              <p>{{entryData['speedtest_data']['ulStatus']}} Mbps</p>
            </div>
            <div class="col-lg-3">
              <p>Ping Speed</p>
              <p>{{entryData['speedtest_data']['pingStatus']}} Mbps</p>
            </div>
            <div class="col-lg-3">
              <p>Jitter Speed</p>
              <p>{{entryData['speedtest_data']['jitterStatus']}} Mbps</p>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>

<form *ngIf="showform" (ngSubmit)="onSubmit()" [formGroup]="form">


  <div class="row layout-top-spacing" *ngFor="let questionrow of questions ; let i = index" >

      <div class="statbox widget box box-shadow">

       <div class="widget-content widget-content-area">
        <div class="col-lg-12">
          <div class="row">
        <div *ngFor="let questioncol of questionrow" class="col-lg">

          <div *ngFor="let question of questioncol" >

              <app-question [question]="question" [form]="form" [fileUploads]="fileUploads" [formSettings]="formSettings"></app-question>

         </div>

      </div>
    </div>
       </div>
      </div>
   </div>


</div>




</form>


</div>
