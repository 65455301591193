



        <!-- TextBox Template -->
        <div type="textbox" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'text'">
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <input disabled data-lpignore="true" type="text" class="form-control" id="{{question.field_id}}" placeholder="{{question.field_placeholder}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.required_type]="question.field_required_type || 'false'" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
            <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p><p *ngIf="type == 'builder'" class="tools">
                <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
            </p>
        </div>

        <!-- Hidden Textbox Template -->
        <div type="password" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'password'">
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <input disabled data-lpignore="true" type="password" class="form-control" id="{{question.field_id}}" placeholder="{{question.field_placeholder}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
            <p class="help-block">Example block-level help text here.</p>
            <p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
            </p>
        </div>



        <!-- Select Box Template -->
        <div type="select" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'select'">
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <select disabled type="select" class="form-control" id="{{question.field_id}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
                <option *ngFor="let item of question.field_value" value="{{item.value}}">{{item.name}}</option>
            </select>
            <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
            <p *ngIf="type == 'builder'" class="tools"><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p>
        </div>


         <!-- Select Box Multi Template -->
        <div type="select" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'selectmultiple'" >
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <select disabled type="select" class="form-control" id="{{question.field_id}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name" multiple>
                <option *ngFor="let item of question.field_value" value="{{item.value}}">{{item.name}}</option>
            </select>
            <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
            <p *ngIf="type == 'builder'" class="tools"><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p>
        </div>



        <!-- TextArea Template -->
        <div type="textarea" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'textarea'" >
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <br>
            <textarea disabled type="textarea" class="form-control" id="{{question.field_id}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">{{question.field_value['defaultText']}}</textarea>
            <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
            <p *ngIf="type == 'builder'" class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
            </p>
        </div>


<!-- Date Selector Template -->
        <div type="date" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'date'">
            <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <input disabled type="date" class="form-control" id="{{question.field_id}}" name="{{question.field_id}}" [value]="question.field_value['value']" [attr.value]="question.field_value['value']" min="{{question.field_value['min']}}" max="{{question.field_value['max']}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
            <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
            <p *ngIf="type == 'builder'" class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
            </p>
        </div>

<!-- Radio Option Template -->
        <div type="radio" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'radio'">
            <label [attr.for]="'o-'+question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
            <div type="radio" id="o-{{question.field_id}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
                <div *ngFor="let item of question.field_value">
                <input disabled type="radio" id="id-{{question.field_id}}" name="name-{{question.field_id}}" value="{{item.value}}">
                <label for="id-{{question.field_id}}">{{item.name}}</label><br>
                </div>
            </div>
                <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
                <p *ngIf="type == 'builder'" class="tools"> <a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
                </p>
            </div>


<!-- File Input Template -->
            <div type="file" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'file'">
                <label [attr.for]="question.field_id" id="label-{{question.field_id}}">{{question.field_name}}</label>
                <input disabled type="file" accept="*" id="{{question.field_id}}" accept="{{question.field_value['accept']}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
                <p *ngIf="type == 'builder'" class="help-block">Example block-level help text here.</p>
                <p *ngIf="type == 'builder'" class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
                </p>
            </div>


<!-- Checkbox Template -->
            <div type="checkbox" id="contain-{{question.field_id}}" class="form-group checkbox click-to-edit p-2" *ngIf="question.field_type == 'checkbox'">
                <label>
                    <input disabled type="checkbox" id="{{question.field_id}}" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.private_label] = "question.field_pri_name">
                    <span >{{question.field_name}}</span>
                    <p *ngIf="type == 'builder'" class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a>
                    </p>
                </label>
            </div>


<!-- Package Table Template -->
            <div type="packagetable" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'packagetable'">
                <label [attr.for]="question.field_id" id="label-{{question.field_id}}">Package Table</label>
                <table type="packagetable" id="{{question.field_id}}" style="width:100%; color:black;" [attr.system-id]="question.field_id" [attr.keyed-field]="question.field_keyed_field" [attr.required]="question.field_required" [attr.insights-ispublic]="question.field_public" [attr.default-package-table-id]="question.field_value.default" [attr.fiber-package-table-id]="question.field_value.fiber" [attr.copper-package-table-id]="question.field_value.copper" [attr.visable-on-interest]="question.field_value.visableOnInterest" [attr.private_label] = "question.field_pri_name">
                    <tr>
                        <th>[[Placeholder]]</th>
                        <th>[[Placeholder]]</th>
                        <th>[[Placeholder]]</th>
                        <th>[[Placeholder]]</th>
                    </tr>
                        <tr>
                            <td>[[Placeholder]]</td>
                            <td>[[Placeholder]]</td>
                            <td>[[Placeholder]]</td>
                            <td>[[Placeholder]]</td>
                        </tr>
                </table>
                <p *ngIf="type == 'builder'" class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p>
            </div>


<!-- Paragraph Box Template -->
            <div type="paragraphbox" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'paragraphbox'" [attr.insights-ispublic]="question.field_public">
                <div type="paragraphbox" id="{{question.field_id}}" class="{{'id-'+question.field_id}}" style="overflow-wrap: break-word;">

                </div>
                <p *ngIf="type == 'builder'" class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p>
            </div>




<!-- HTML Area Template -->
            <div type="htmlarea" id="contain-{{question.field_id}}" class="form-group click-to-edit p-2" *ngIf="question.field_type == 'htmlarea'" [attr.insights-ispublic]="question.field_public">
              <div type="htmlarea" id="htmlarea-{{question.field_id}}">
              <h1>Thank You</h1>
               </div>
              <textarea style="display:none;" type="htmlarea" class="form-control" id="{{question.field_id}}" class="{{'id-'+question.field_id}}" rows="12"><h1>Thank You</h1></textarea>
              <p class="tools" ><a class="edit-link">Edit</a> | <a class="remove-link">Remove</a></p>
            </div>








    <!--  <input class="form-control" *ngSwitchCase="'textbox'" [formControlName]="question.key"
              [id]="question.key" [type]="question.type">






      <select class="form-control" [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
        <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
      </select> -->





