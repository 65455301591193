import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Field Type Imports
import { DropdownQuestion } from '../_question_controltype/question-dropdown';
import { QuestionBase } from '../_question_controltype/question-base';
import { TextboxQuestion } from '../_question_controltype/question-textbox';
import { CheckboxQuestion } from '../_question_controltype/question-checkbox';
import { HiddenTextboxQuestion } from '../_question_controltype/question-hiddentextbox';
import { PackageTableQuestion } from '../_question_controltype/question-packagetable';
import { ParagraphQuestion } from '../_question_controltype/question-paragraph';
import { TextAreaQuestion } from '../_question_controltype/question-textarea';
import { DateSelectorQuestion } from '../_question_controltype/question-dateselector';
import { RadioQuestion } from '../_question_controltype/question-radio';
import { FileUploadQuestion } from '../_question_controltype/question-fileupload';
import { HtmlQuestion } from '../_question_controltype/question-html';

import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class QuestionService {
questionArray: any = [];
  constructor() { }


    // TODO: get from a remote source of question metadata
    getQuestions(formData: any) {

      formData.form_layout.forEach((formLayout:any) => {
        var rowCols: any = [];

        formLayout.layout_fields.forEach((layoutCol:any) => {

          var questions: QuestionBase<string>[] = [];
            // console.log(layoutField);
          layoutCol['col'].forEach((elementField:any) => {

              //console.log(elementField);
            if(elementField['field_type'] == 'text'){
              questions.push(new TextboxQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                value: '',
                keyed_value:''+elementField['field_keyed_field']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type: elementField['field_required_type'],
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'textarea'){
              questions.push(new TextAreaQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                value: '',
                keyed_value:''+elementField['field_keyed_field']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'htmlarea'){
              questions.push(new HtmlQuestion({
                key: ''+elementField['field_id']+'',
                type: elementField['field_type'],
                disabled:false,
                required_type:'',
                html:''+elementField['field_value']+'',
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'email'){
              questions.push(new TextboxQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                value: ''+elementField['field_value']+'',
                keyed_value:''+elementField['field_keyed_field']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'password'){
              questions.push(new HiddenTextboxQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                value: ''+elementField['field_value']+'',
                keyed_value:''+elementField['field_keyed_field']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'date'){
              questions.push(new DateSelectorQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                settings: elementField['field_value'],
                
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                value:elementField['field_value']['value'],
                keyed_value:''+elementField['field_keyed_field']+'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));
            }

            else if(elementField['field_type'] == 'select'){
              questions.push(new DropdownQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                options: elementField['field_value'],
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                value:'',
                keyed_value:''+elementField['field_keyed_field']+'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));

            }

            else if(elementField['field_type'] == 'radio'){
              questions.push(new RadioQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                disabled:false,
                required_type:'',
                keyed_value:''+elementField['field_keyed_field']+'',
                settings: elementField['field_value'],
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));

            }

            else if(elementField['field_type'] == 'checkbox'){
              questions.push(new CheckboxQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                html:''+elementField['field_name']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                keyed_value:''+elementField['field_keyed_field']+'',
                type: elementField['field_type'],
                public: elementField['field_public'],
                order: 1
              }));

            }

            else if(elementField['field_type'] == 'file'){
              questions.push(new FileUploadQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                keyed_value:''+elementField['field_keyed_field']+'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));

            }

            else if(elementField['field_type'] == 'packagetable'){
              questions.push(new PackageTableQuestion({
                key: ''+elementField['field_id']+'',
                label: ''+elementField['field_name']+'',
                value: elementField['field_value'],
                disabled:false,
                required: elementField['field_required'],
                required_type:'',
                keyed_value:''+elementField['field_keyed_field']+'',
                type: elementField['field_type'],
                public:elementField['field_public'],
                order: 1
              }));

            }

            else if(elementField['field_type'] == 'paragraphbox'){
              questions.push(new ParagraphQuestion({
                key: ''+elementField['field_id']+'',
                type: elementField['field_type'],
                disabled:false,
                required_type:'',
                html:''+elementField['field_value']+'',
                public:elementField['field_public'],
                order: 1
              }));

            }

        });
      rowCols.push(questions.sort((a, b) => a.order - b.order));
    });
  this.questionArray.push(rowCols);
});






      return of(this.questionArray);

  }
  }
