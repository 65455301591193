import { Component, OnInit,AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
// Map Control
import { MapMangementService } from '../../../../_services/modules/geomap/maps/map-management.service';
import { PolyControlService } from 'src/app/_services/modules/geomap/polys/poly-control.service';

import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { ArrayType } from '@angular/compiler';

import globalSettings from '../../../../_settings/Globle_Settings.json';

@Component({
  selector: 'app-listpolys',
  templateUrl: './listpolys.component.html',
  styleUrls: ['./listpolys.component.css']
})
export class ListpolysComponent implements AfterViewInit,OnInit {
  private globalSetting: [] = globalSettings;

  polygonLimit: number = 3000;

  map_id: any = this.route.snapshot.paramMap.get('map_id');
  displayTable = false;

  dtOptions: any = {};
  rows: any = [];
  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private customcssservice: CustomcssService,
    private customjsservice: CustomjsService,
    private mapmangementservice: MapMangementService,
    private polyControlService:PolyControlService,
    private router: Router

    ) {}

    ngAfterViewInit(): void {


    }


  ngOnInit(): void {


  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  this.customcssservice.load_switches_css();

  // Load Custom Javascipt onto page from Custom JS Service




  const request = new HttpRequest('GET', this.globalSetting['api_url_console']+'/geomap?api=listpolysbymap&mapkey='+ this.route.snapshot.paramMap.get('map_id'),  {
    reportProgress: true
  });

  this.http.request(request).subscribe(event => {

    // progress
    if (event.type === HttpEventType.DownloadProgress) {
      console.log(event.loaded, event.total);
      // event.loaded = bytes transfered
      // event.total = "Content-Length", set by the server
  if(typeof(event.total) !== 'undefined'){
      const percentage = 100 / event.total * event.loaded;
      let polyTableLoader = document.getElementById('loadingTablePercent');
      polyTableLoader!.innerHTML = Math.round(percentage)+'%';
      console.log(percentage);
  }
    }

    // finished
    if (event.type === HttpEventType.Response) {
      this.rows = event.body;

      this.displayTable = true;
      //this.rerender();
      console.log(event.body);
    }

  })



this.dtOptions = {
  "dom":
  "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
  //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
  "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
  "<'table-responsive'tr>" +
  "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
  buttons: {
      buttons: [
          { extend: 'copy', className: 'btn btn-sm' },
          { extend: 'csv', className: 'btn btn-sm' },
          { extend: 'excel', className: 'btn btn-sm' },
          { extend: 'print', className: 'btn btn-sm' }
      ]
  },
  "oLanguage": {
      "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
      "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
      "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
      "sSearchPlaceholder": "Search...",
     "sLengthMenu": "Results :  _MENU_",
  },
  "stripeClasses": [],
  "lengthMenu": [5, 10, 20, 50],
  "pageLength": 5 ,
  "processing": true,
  //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
  responsive: true,
  "initComplete":function(settings, json) {
    let polyTable = document.getElementById('polyTable');
    polyTable!.style.display = 'block';
    let polyTableLoader = document.getElementById('polyTableLoader');
    polyTableLoader!.style.display = 'none';
  },
};







  }

  round(x: number): Number{
    return(Math.round(x));
  }


purgeAllPolys(): void{

  this.polyControlService.purgeAllPolys(this.route.snapshot.paramMap.get('map_id')).subscribe(async ()=>{
    window.location.reload();
  });

}


  removePoly(poly_id){
    this.mapmangementservice.removePoly(poly_id,this.route.snapshot.paramMap.get('map_id')).subscribe((results)=>{
      if(!results['error']){
      window.location.reload();
      }
      else{
        window.location.reload();
      }
      console.log("tesing::"+results);
      console.log(results);
    });
  }

  disEnPoly(poly_id,enable){
    //console.log(event);
    this.mapmangementservice.disenPoly(poly_id,enable).subscribe((results)=>{
      if(!results['error']){
      window.location.reload();
      }
      else{
        window.location.reload();
       // event.checked
      }
      console.log("tesing::"+results);
      console.log(results);
    });
  }

  onClick(data: any): void{
    this.router.navigate(['/geomap/maps/editpoly',{poly_id: data._id,map_id:this.map_id}]);
    console.log(data);
      }

}
