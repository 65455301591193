import { Component, OnInit, AfterViewInit,ViewChild} from '@angular/core';
import {  HttpClient,HttpEventType, HttpRequest } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';

import { Subject } from 'rxjs';


@Component({
  selector: 'app-list-scheduled-reports',
  templateUrl: './list-scheduled-reports.component.html',
  styleUrls: ['./list-scheduled-reports.component.css']
})
export class ListScheduledReportsComponent implements OnInit,AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //dtOptions: DataTables.Settings = {};
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject();
  displayTable = true;
  rows: any;


  constructor() { this.dtOptions = {
      "dom": 
    "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
    //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
    "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
    "<'table-responsive'tr>" +
    "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
    buttons: {
      buttons: [
          { extend: 'csv', 
            className: 'btn',
            title:"Maps",
            text: 'CSV',
            exportOptions: {
          columns: 'th:not(:last-child)'
          }
        },
          { extend: 'excel', 
            className: 'btn',
            title:"Maps",
            text: 'Excel',
              exportOptions: {
               columns: 'th:not(:last-child)'
                  }
          },
            { 
            extend: 'print', 
            className: 'btn',
            title:"Maps",
            exportOptions: {
              columns: 'th:not(:last-child)'
                 }
            }
      ]
  },
      "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "stripeClasses": [],
      "lengthMenu": [7, 10, 20, 50],
      "pageLength": 7 ,
      "processing": true,  
      //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
      responsive: true,
      "initComplete":function(settings, json) {
        let polyTable = document.getElementById('mapTable');
        polyTable!.style.display = 'block';
        let polyTableLoader = document.getElementById('polyTableLoader');
        polyTableLoader!.style.display = 'none';
      },
    };
  }

  ngAfterViewInit(): void {

    this.rows = [];
    // console.log(userList);
    this.displayTable = true;
    this.dtTrigger.next(this.dtOptions);
  
    
  }

  ngOnInit(): void {
  }


  load_table(): void{



    //this.userControlService.getUserList('loadingTablePercent').subscribe((userList)=>{
  
    //});
  
  
  }
  
  
  table_reload(): void{
  
  
    ////this.userControlService.getUserList('loadingTablePercent').subscribe((userList)=>{
    //  this.rows = userList;
    ////  console.log(userList);
    //  this.displayTable = true;
     // this.rerender();
    //});
  
  }
    rerender(): void {
      //this.displayTable = false; 
      console.log(this.dtElement);
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
       // this.displayTable = true;
        this.dtTrigger.next(this.dtOptions);
      });
  
    }

}
