import { Component, AfterViewInit,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from './_services/token-storage.service';
import { LogControlService } from './_services/log/log-control.service';
import { NewTicketControlService } from './_services/support/new-tickets/new-ticket-control.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewInit {
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  title = "";
  showRouter: boolean = true;
  constructor(private tokenStorageService: TokenStorageService,private router: Router,private loggingService: LogControlService,private NewTicketControlService:NewTicketControlService) { }

  ngOnInit(): void {

    this.isLoggedIn = !!this.tokenStorageService.getToken();


      this.loggingService.submitGeneralLog({type:'info', message:'User Loaded a Page'});

  }

  ngAfterViewInit():void{
    this.router.events.subscribe(val => {
      //reVerify Login Status
      this.isLoggedIn = !!this.tokenStorageService.getToken();
      //console.log(document.getElementsByClassName("intempcssimport"));
      //Remove existing style settings on page change
      Array.from(document.getElementsByClassName("intempcssimport")).forEach((element) => {
        element.remove();
      });

      //console.log(document.getElementsByClassName("intempjsimport"));
      //Remove existing style settings on page change
      Array.from(document.getElementsByClassName("intempjsimport")).forEach((element) => {
        element.remove();
      });

    });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }


  


}
