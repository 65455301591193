import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FormControlService {

  constructor() { }

     keyedElementSearchandAddValue(questions: any, search_element_id: any, value: any): Observable<any>{

      return new Observable(observer => {
    Array.from(search_element_id).forEach((searchEl: any)=>{
      var location0 = 0;
      Array.from(questions).forEach(async (formRows: any)=>{
        var location1 = 0;
        Array.from(formRows).forEach(async (formRowCol: any)=>{
          var location2 = 0;
          Array.from(formRowCol).forEach(async (formRowColEl: any)=>{
              if(formRowColEl['key'] == searchEl['id']){
                console.log(location0+':'+location1+':'+location2);
                questions[location0][location1][location2]['value'] = value[searchEl['id']];
                if(value[searchEl['name']] !== '' && value[searchEl['name']] !== null && value[searchEl['name']] !== undefined){
                  console.log(value[searchEl['name']]);
                  questions[location0][location1][location2]['disabled'] = true;
                }
                observer.next(questions);
              }
              location2 = location2 + 1;
            })
            location1 = location1 + 1;
          })
          location0 = location0 + 1;
        });

      });
    });

    }

}
