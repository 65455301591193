<div class="row layout-top-spacing" id="cancel-row">

  <div id="wizard_Default" class="col-lg-12 layout-spacing">
      <div class="statbox widget box box-shadow">
          <div class="widget-header">
              <div class="row">
                  <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                      <h4>Default</h4>
                  </div>
              </div>
          </div>
          <div class="widget-content widget-content-area">
              <div class="bs-stepper stepper-form-one linear">
                  <div class="bs-stepper-header" role="tablist">
                      <div class="step active" data-target="#defaultStep-one">
                          <button type="button" class="step-trigger" role="tab" aria-selected="true">
                              <span class="bs-stepper-circle">1</span>
                              <span class="bs-stepper-label">Step One</span>
                          </button>
                      </div>
                      <div class="line"></div>
                      <div class="step" data-target="#defaultStep-two">
                          <button type="button" class="step-trigger" role="tab" aria-selected="false" disabled="disabled">
                              <span class="bs-stepper-circle">2</span>
                              <span class="bs-stepper-label">Step Two</span>
                          </button>
                      </div>
                      <div class="line"></div>
                      <div class="step" data-target="#defaultStep-three">
                          <button type="button" class="step-trigger" role="tab" aria-selected="false" disabled="disabled">
                              <span class="bs-stepper-circle">3</span>
                              <span class="bs-stepper-label">
                                  <span class="bs-stepper-title">Step Three</span>
                              </span>
                          </button>
                      </div>
                  </div>
                  <div class="bs-stepper-content">



                    <div id="defaultStep-one" class="content fade dstepper-block active" role="tabpanel">
                        <div class="col-lg-12">



                    <div class="row">

                      <div class="col-lg-4">
                      <div class="form-group mb-4">
                      <label for="defaultEmailAddress">First Name</label>
                      <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                        <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                          <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                          <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                        </div>
                      </div>

                    </div>

                    <div class="col-lg-4">
                      <div class="form-group mb-4">
                      <label for="defaultEmailAddress">Last Name</label>
                      <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                        <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                          <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                          <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group mb-4">
                      <label for="defaultEmailAddress">Display Name</label>
                      <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                        <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                          <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                          <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row">

                    <div class="col-lg-4">
                    <div class="form-group mb-4">
                    <label for="defaultEmailAddress">Salutation</label>
                    <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                      <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                        <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                        <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                      </div>
                    </div>

                  </div>

                  <div class="col-lg-4">
                    <div class="form-group mb-4">
                    <label for="defaultEmailAddress">Middle Name</label>
                    <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                      <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                        <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                        <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group mb-4">
                    <label for="defaultEmailAddress">Generation</label>
                    <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                      <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                        <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                        <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-12">
                  <div class="form-group mb-4">
                  <label for="defaultEmailAddress">Address</label>
                  <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                    <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                      <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                      <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="row">


                <div class="col-lg-4">
                  <div class="form-group mb-4">
                  <label for="defaultEmailAddress">City</label>
                  <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                    <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                      <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                      <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group mb-4">
                  <label for="defaultEmailAddress">State</label>
                  <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                    <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                      <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                      <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                    </div>
                  </div>
                </div>

              <div class="col-lg-4">
                <div class="form-group mb-4">
                <label for="defaultEmailAddress">Zip</label>
                <input type="text" id="provison_first_name" name="provison_first_name"  class="form-control" required minlength="6" [formControl]="this.provForm.provison_first_name">
                  <div *ngIf="this.provForm.provison_first_name.invalid && (this.provForm.provison_first_name.dirty || this.provForm.provison_first_name.touched)" class="alert">
                    <div *ngIf="this.provForm.provison_first_name.errors?.['required']" style="color:red;"> Name is required.</div>
                    <div *ngIf="this.provForm.provison_first_name.errors?.['minlength']" style="color:red;">Name must be at least 4 characters long.</div>
                  </div>
                </div>
              </div>

            </div>

                      <div class="button-action mt-5">
                        <button class="btn btn-secondary btn-prev me-3" (click)="stepperDefault.previous()">Prev</button>
                        <button class="btn btn-secondary btn-nxt" (click)="stepperDefault.next()">Next</button>
                    </div>


                  </div>
                </div>





                      <div id="defaultStep-two" class="content fade dstepper-block dstepper-none" role="tabpanel">
                          <form>
                              <div class="form-group mb-4">
                                  <label for="defaultEmailAddress">Email Address</label>
                                  <input type="email" class="form-control" id="defaultEmailAddress">
                              </div>
                          </form>

                          <div class="button-action mt-5">
                            <button class="btn btn-secondary btn-prev me-3"  (click)="stepperDefault.previous()">Prev</button>
                            <button class="btn btn-secondary btn-nxt" (click)="stepperDefault.next()">Next</button>
                          </div>
                      </div>

                      <div id="defaultStep-three" class="content fade dstepper-block dstepper-none" role="tabpanel">
                          <form class="row g-3">
                              <div class="col-12">
                                  <label for="defaultInputAddress" class="form-label">Address</label>
                                  <input type="text" class="form-control" id="defaultInputAddress" placeholder="1234 Main St">
                              </div>
                              <div class="col-12">
                                  <label for="defaultInputAddress2" class="form-label">Address 2</label>
                                  <input type="text" class="form-control" id="defaultInputAddress2" placeholder="Apartment, studio, or floor">
                              </div>
                              <div class="col-md-6">
                                  <label for="defaultInputCity" class="form-label">City</label>
                                  <input type="text" class="form-control" id="defaultInputCity">
                              </div>
                              <div class="col-md-4">
                                  <label for="defaultInputState" class="form-label">State</label>
                                  <select id="defaultInputState" class="form-select">
                                      <option selected="">Choose...</option>
                                      <option>...</option>
                                  </select>
                              </div>
                              <div class="col-md-2">
                                  <label for="defaultInputZip" class="form-label">Zip</label>
                                  <input type="text" class="form-control" id="defaultInputZip">
                              </div>
                              <div class="col-12">
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" id="defaultGridCheck">
                                      <label class="form-check-label" for="defaultGridCheck">
                                          Check me out
                                      </label>
                                  </div>
                              </div>
                          </form>

                          <div class="button-action mt-3">
                              <button class="btn btn-secondary btn-prev me-3" (click)="stepperDefault.previous()">Prev</button>
                              <button class="btn btn-success me-3" (click)="stepperDefault.next()">Submit</button>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>


  </div>
