import { Component, Input, OnInit, OnDestroy , HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

import { QuestionBase } from '../_question_controltype/question-base';
import { QuestionControlService } from '../_service/question-control.service';
declare var grecaptcha: any;
// Form Controls and Data Mangement Imports
import {FormControlService } from '../_service/form-control.service';
import {FormDataControlService } from '../_service/form-data-control.service'
import {FormEntryControlService } from '../../../../../../_services/modules/geomap/forms/form-entry-control.service';
@Component({
  selector: 'app-entry-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class EntryFormComponent implements OnInit, OnDestroy  {

// Host Binding for Color Propertys of Package Table Headers
  @HostBinding("style.--pk-tb-header-bg-color") pk_tb_header_bg_color: string = "#0099da";
  @HostBinding("style.--pk-tb-header-text-color") pk_tb_header_text_color: string = "white";

// Inputs Varitables from Parent Component
  @Input() questions: QuestionBase<any>[][][] | null = [];
  @Input() keyed_fields: any;
  @Input() packageTables: any;
  @Input() addressData: any;
  @Input() polygonResults: any;
  @Input() formSettings: any;
  @Input() LoadingControlService: any;

// Global Vars
  showCompletePage = false;
  showSpeedtestPage = false;
  speedtestFormID = '';
  showFormPage = true;
  form!: FormGroup;
  payLoad = '';
  showform = false
  fileUploads: any = {};

//Conditonal Field Validation Vars
conditonalTargetValues: any = {};
conditonalTargets: any = {};

//Package Table Array

PackageTableArray: any = [];

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private formControl: FormControlService,
    private formDataControl: FormDataControlService,
    private formEntryControlService:FormEntryControlService) {}


    ngOnDestroy() {

// Inputs Varitables from Parent Component
this.questions = null;
this.keyed_fields = null;
this.packageTables = null;
this.formSettings = null;
this.LoadingControlService = null;

// Global Vars
this.showCompletePage = false;
this.showSpeedtestPage = false;
this.speedtestFormID = '';
this.showFormPage = true;
this.form! = new FormGroup('');
this.payLoad = '';
this.showform = false
this.fileUploads = {};

//Conditonal Field Validation Vars
this.conditonalTargetValues = {};
this.conditonalTargets = {};

//Package Table Array

this.PackageTableArray = [];

    }

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//
//
//
//
//
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////


  ngOnInit() {
    //console.log(this.formSettings);
    //console.log(this.questions);
    if (this.route.snapshot.paramMap.get('address') !== '' && this.route.snapshot.paramMap.get('address') !== null){

     

      this.formEntryControlService.removeNonInputRows(this.questions,async()=>{
        this.formControl.keyedElementSearchandAddValue(this.questions,this.keyed_fields,{
          keyed_address:"123",
          keyed_city:""+this.route.snapshot.paramMap.get('city')+"",
          keyed_state:""+this.route.snapshot.paramMap.get('state')+"",
          keyed_zip:""+this.route.snapshot.paramMap.get('zip')+""
        }
          ).subscribe(()=>{
            this.loadForm();
        });
      });

  }
  
    //Initlize the Form Display Element

    //this.form = this.qcs.toFormGroup(this.questions! as QuestionBase<string>[][][]);

  }

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//
//
//
//
//
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

  loadForm(): void{
    this.form = this.qcs.toFormGroup(this.questions! as QuestionBase<string>[][][]);
    console.log( this.form);
   this.showform = true;
  }

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//
//
//
//
//
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

  loadDefaultStyleing(): void{
    // Load Styleing Defaults
    this.pk_tb_header_bg_color =  this.formSettings['form_package_tb_header_bg_color'];
    this.pk_tb_header_text_color = this.formSettings['form_package_tb_header_text_color'];
  }


//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//
//
//
//
//
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////


  onSubmit() {
   // this.LoadingControlService.LoadingDialog();
    if(this.form.valid !== false){
    grecaptcha.ready(() => {
      grecaptcha.execute('6LfnmDoiAAAAAL9mk3P5f7GUNp5St4hl5PDW6yTO', {action: 'submit'}).then((token: any) => {
        this.formDataControl.parseHeadersToMatchData(this.form.getRawValue(),this.formSettings['form_headers']).subscribe(async (parsedData: any)=>{

        this.formDataControl.submit_Form_Data(""+this.route.snapshot.paramMap.get('form_id')+"",parsedData,{form_type:this.formSettings['form_type'],keyed_fields:this.keyed_fields,packageTableData:this.PackageTableArray,form_headers:this.formSettings['form_headers']},{long:this.route.snapshot.paramMap.get('long'),lat:this.route.snapshot.paramMap.get('lat'),poly_loc_id:this.route.snapshot.paramMap.get('polylocid')},this.fileUploads,token).subscribe(async (data)=>{
          //console.log(token);
         // console.log(data);
           if(!data['error']){


          if(this.formSettings['form_type'] == '1'){
            if(this.formSettings['form_speedtest_continue_dialog']){
              //this.LoadingControlService.continueDialog(this.formSettings['form_speedtest_continue_dialog_text'],'',{confirmButtonColor:this.formSettings['form_speedtest_continue_dialog_button_color'],confirmButtonText:this.formSettings['form_speedtest_continue_dialog_button_text']}).subscribe(async (statusResults: any)=>{
                this.speedtestFormID = data['insertedId'];

                if(this.speedtestFormID !== ''){

                  this.showSpeedtestPage = true;

                  this.showFormPage = false;

                }
                else{
                 // this.LoadingControlService.errorLoadingDialog("Save Error", "Failed to save your submission");
                }

          //  });
          }
          else{
            this.LoadingControlService.successTimerOnly().subscribe(async (statusResults: any)=>{
              this.speedtestFormID = data['insertedId'];

                if(this.speedtestFormID !== ''){

                  this.showSpeedtestPage = true;

                  this.showFormPage = false;

                }
                else{
                 // this.LoadingControlService.errorLoadingDialog("Save Error", "Failed to save your submission");
                }
              });
          }
        }
          else{
           // this.LoadingControlService.successDialog(this.formSettings['form_speedtest_success_dialog_text'],'',{confirmButtonColor:this.formSettings['form_speedtest_success_dialog_button_color'],confirmButtonText:this.formSettings['form_speedtest_success_dialog_button_text']}).subscribe(async (statusResults: any)=>{
            this.showCompletePage = true;
            this.showFormPage = false;
          //  });
          }
          this.payLoad = JSON.stringify(this.form.getRawValue());
        }
        else{
          if(data['error_type'] == 'timout'){
           // this.LoadingControlService.errorWithButtonLoadingDialog("Connection Timeout", "Server Failed to Respond Please Check Your Internet Connection and Try Again",{confirmButtonColor:this.formSettings['form_speedtest_continue_dialog_button_color'],confirmButtonText:"Try Again"});
          }
          else
          {
          console.log(data);
          if(data['recaptcha_error']){
            //this.LoadingControlService.errorLoadingDialog("Bot Error", "Failed to Submit");
          }
          else{
           // this.LoadingControlService.errorLoadingDialog("Error", "Failed to Submit Entry:");
          }
        }
        }
        }
      );
      });
    });
    });

  }
  else{
    this.LoadingControlService.errorWithButtonLoadingDialog("Error", "Required Fields Not Complete",{confirmButtonColor:this.formSettings['form_continue_dialog_button_color']});
  }
}



//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//
//
//  Validates Hide/Show Conditonal Fields based on the Value
//  of the Conditonal Target Field
//
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

validateConditionals(): void{


  // Setup Local Change State Functions for Modifying the Conditonal Fields Visability
  var hideField = (id: string) =>{
    if(document.getElementById('el-container-'+id)){
      document.getElementById('el-container-'+id)!.style.display = 'none';
      }
  }

  var showField = (id: string) =>{
    if(document.getElementById('el-container-'+id)){
      document.getElementById('el-container-'+id)!.style.display = 'block';
      }
  }


  // Ittorate of the Object of Conditonal target Fields as Verify there status compared to the current Field Status
  Object.entries(this.conditonalTargets).forEach(async (targetData: any)=>{

    // If condition type is Equal
    if(targetData[1]['conditonal'] == '='){
      if(targetData[1]['conditonal_value'] == this.conditonalTargetValues[''+targetData[1]['conditonal_field_id']+'']){
        showField(targetData[0]);
      }
      else{
        hideField(targetData[0]);
      }
    }
  // If condition type is Not Equal
    else if(targetData[1]['conditonal'] == '!='){
      if(targetData[1]['conditonal_value'] == this.conditonalTargetValues[''+targetData[1]['conditonal_field_id']+'']){
        hideField(targetData[0]);
      }
      else{
        showField(targetData[0]);
      }
    }
  });


}

packageTablesArray(packagetable: any): void{
  this.PackageTableArray.push(packagetable);
}


}
