<div class="flex-row layout-top-spacing">
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" >GeoFi</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listmaps']">Maps</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/geomap/maps/listpolys',{map_id: map_id}]">List Polygons</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit Polygons</li>
        </ol>
    </nav>
</div>

<div class="row layout-spacing">
  <div class="col-xl-12 col-md-12 col-sm-12 col-12 rounded-circle-pills-icon">
      <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-circle-pills-icon-tab" role="tablist">
          <li class="nav-item ml-2 mr-2">
              <a class="nav-link mb-1 rounded-circle active" id="rounded-circle-pills-icon-profile-tab" data-bs-toggle="pill" href="#rounded-circle-pills-icon-profile" role="tab" aria-controls="rounded-circle-pills-icon-contact" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                </svg></a>
          </li>

          <li class="nav-item ml-2 mr-2">
              <a class="nav-link mb-1 rounded-circle" href="javascript:void(0);" (click)="onSubmit()" matTooltip="Save Table"  [matTooltipPosition]="'right'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                </svg> </a>
          </li>
      </ul>
  </div>
</div>

<div class="row layout-spacing">

    <!-- Content -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
            <div class="widget widget-card-standard-dropdown">
                <div class="widget-content">
                    <div class="w-header">
                        <div class="w-info">
                            <h4 class="value">Update Polygon</h4>
                        </div>


                    </div>


                    <div class="w-content">

                            <div class="row g-3">
                                <form
                                name="form"
                                (ngSubmit)=" onSubmit()"
                                #f="ngForm">
                                <div class="row g-3">
                            <div class="col-md-6">
                                <label for="inputName1">Name</label>
                                <input type="text" [(ngModel)]="form.poly_name" required #poly_name="ngModel"  name="poly_name" class="form-control" id="poly_name" placeholder="Map Name">
                            </div>
                            <div class=" col-md-6">
                                <label for="inputName1">Polygon Color</label>
                                <input type="text" [(ngModel)]="form.poly_color" required #poly_color="ngModel"  name="poly_color" class="form-control" id="poly_color" placeholder="Lat">
                            </div>
                            <div class=" col-md-6">
                                <label for="inputName1">Polygon Fill Color</label>
                                <input type="text" [(ngModel)]="form.poly_fill_color" required #poly_fill_color="ngModel" name="poly_fill_color" class="form-control" id="poly_fill_color" placeholder="Long">
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_fill_opacity">Polygon Fill Opacity</label>
                                <input type="text" [(ngModel)]="form.poly_fill_opacity" required #poly_fill_opacity="ngModel"  name="poly_fill_opacity" class="form-control" id="poly_fill_opacity" placeholder="Zoom">
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_radius">Polygon Radius</label>
                                <input type="text" [(ngModel)]="form.poly_radius" required #poly_radius="ngModel"  name="poly_radius" class="form-control" id="poly_radius" placeholder="Zoom">
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_status">Current Status</label>
                                <select class="form-control" [(ngModel)]="form.poly_status" required #poly_status="ngModel"  id="poly_status" name="poly_status">

                                    <option value="null">Select Status</option>
                                    <option value="none">N/A</option>
                                    <option value="62dedc487c70006b58936cd8">Interest</option>
                                    <option value="62dedc487c70006b58936cd9">Pre-Signup</option>
                                    <option value="62dedc487c70006b58936cda">Signup</option>
                                    <option value="62dedc487c70006b58936cdb">Pre-Construction</option>
                                    <option value="62dedc487c70006b58936cdc">Construction</option>
                                    <option value="62dedc487c70006b58936cdd">Complete</option>
                                    <option value="62dedc487c70006b58936cde">Install Ready</option>

                            </select>
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_enable">Published</label>
                                <select class="form-control" [(ngModel)]="form.poly_enable" required #poly_enable="ngModel"  id="poly_enable" name="poly_enable">
                                    <option  value="true" >Yes</option>
                                    <option  value="false">No</option>
                            </select>
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_enable">Polygon Type</label>
                                <select class="form-control" [(ngModel)]="form.poly_type" required #poly_type="ngModel"  id="poly_type" name="poly_type">
                                    <option  value="" >Select Option</option>
                                    <option  value="none" >N/A</option>
                                    <option  value="fiber" >Fiber</option>
                                    <option  value="copper">Copper</option>
                            </select>
                            </div>
                            <div class=" col-md-6">
                                <label for="poly_inputformlink">Form Link</label>
                                <input type="text" [(ngModel)]="form.poly_inputformlink" required #poly_inputformlink="ngModel"  class="form-control" id="poly_inputformlink" name="poly_inputformlink" placeholder="Signup Today?">
                            </div>



                              <div class="col-md-6">

                                    <label for="poly_input_form_type" style="color:red">Link Type({{form.poly_input_form_type}})*</label>
                                     <select [(ngModel)]="form.poly_input_form_type" #poly_input_form_type="ngModel"  class="selectpicker form-control" id="poly_input_form_type" name="poly_input_form_type"required>
                                        <option>Select an Option</option>
                                        <option value="url">URL</option>
                                        <option value="form">Form</option> 
                                      
                                    </select>

                              </div>

                            <div *ngIf="form.poly_input_form_type == 'form'" class="col-md-6">

                                <label  for="poly_inputdefaultform" style="color:red">Form*</label>
                                
                                <select [(ngModel)]="form.poly_inputdefaultform" #poly_inputdefaultform="ngModel" class="selectpicker form-control" id="poly_inputdefaultform" name="poly_inputdefaultform" required>
                                    <option>Select a Form</option>
                                    <option *ngFor="let FormsList of formsListArray" [value]="FormsList._id" >
                                        {{FormsList.form_name}}
                                    </option>
                                  
                                </select>

                              </div>

                              <div *ngIf="form.poly_input_form_type == 'url'" class="col-md-6">

                                <label  for="poly_inputformlink" style="color:red">Link URL*</label>
                                
                                <input type="text" [(ngModel)]="form.poly_inputdefaultform" #poly_inputdefaultform="ngModel"  class="form-control" id="poly_inputdefaultform" name="poly_inputdefaultform" value="http://example.com" placeholder="Link URL">

                            </div>



                        </div>
                    </form>

                </div>
            </div>



        </div>
    </div>
</div>

<div  style="" id="formTable" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
  <div class="widget widget-card-standard-dropdown">
      <div class="widget-content">
          <div class="w-header">
              <div class="w-info">
                  <h4 class="value">Upload File (Shape, KMZ, KML, Geojson)</h4>
              </div>


          </div>


          <div class="w-content">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row g-3">


                      <div class="form-row col-md-12">
                          <div class="form-group col-md-11">


                              <div class="upload-btn-wrapper">

                                  <input type="file" (change)="onFileChange($event)" name="poly_geojson" id="fileInput" accept=".geojson" />
                                </div>


                      </div>
                      <div class="form-group col-md-1">

                              </div>
                      </div>



                  </div>
              </div>




          </div>


      </div>
  </div>
</div>



<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

  <div class="skills layout-spacing ">
      <div class="widget-content widget-content-area">
          <div style="text-align:center;">

              <div style="display:block;" id="polyMapLoader" class="flex-row">
                  <div class="container">
                      <div class="row align-items-center">
                          <div class="col">

                          </div>
                          <div class="col">
                              <div style="text-align:center;">
                                  <br>
                              <h4>Loading...</h4>
                              <div  class="lds-dual-ring"></div>
                              <h4 id="loadingTablePercent"></h4>
                              </div>
                          </div>
                          <div class="col">

                          </div>
                        </div>
                  </div>
              </div>

      <div id="map"  style="width:100%; min-height:650px; height:100%;"></div>


      </div>
  </div>


</div>

</div>


    </div>
