import { Component, OnInit } from '@angular/core';
import {  HttpClient, HttpHeaders,HttpEventType, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';


// This Page Services
import { AddresslistControlService } from '../../../../_services/modules/geomap/addresslists/addresslist-control.service';

// Include on all pages
import { CustomcssService } from '../../../../_assets_insert/customcss.service';
import { CustomjsService } from '../../../../_assets_insert/customjs.service';
import { AllPageGeneralsService } from '../../../../_services/all-page-generals.service';

@Component({
  selector: 'app-addresslists',
  templateUrl: './addresslists.component.html',
  styleUrls: ['./addresslists.component.css']
})
export class AddresslistsComponent implements OnInit {

  displayTable = false;
  dtOptions: any = {};
  rows: any;
 
  constructor(
    private http: HttpClient,
    private allpageGenerals: AllPageGeneralsService,
    private customcssservice: CustomcssService, 
    private customjsservice: CustomjsService,
    private addresslistcontrolservice: AddresslistControlService) { }

  ngOnInit(): void {
  // Load Custom CSS onto page from Custom Css Service
  this.customcssservice.load_Datatables_Default_Style();
  this.customcssservice.load_switches_css();
  
    // Change Top Nav Title on Page Change
    this.allpageGenerals.setTopNavTitle('Address Lists');


// Call Get Address list Method from the Address list control Service and return 
//array of Address lists data based on the users current Token Corp_+id

    this.addresslistcontrolservice.getAddressLists('').subscribe((results)=>{

      console.log(results);
      this.rows = results;
      this.displayTable = true;

    });

//////////////////////////////////////////////////////////////////////////
  
  
  
    this.dtOptions = {
      "dom":  "<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'l>"+
      //"<'dt--top-section'<'row'<'col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'B>"+
      "<'col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-1'f>>>" +
      "<'table-responsive'tr>" +
      "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>",
      buttons: {
          buttons: [
              { extend: 'copy', className: 'btn btn-sm' },
              { extend: 'csv', className: 'btn btn-sm' },
              { extend: 'excel', className: 'btn btn-sm' },
              { extend: 'print', className: 'btn btn-sm' }
          ]
      },
      "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_ Total Rows: _TOTAL_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "stripeClasses": [],
      "lengthMenu": [7, 10, 20, 50],
      "pageLength": 7 ,
      "processing": true,  
      //"ajax": "https://api.myininsights.com/geomap?api=listpolysbymap&mapkey="+ this.route.snapshot.paramMap.get('map_id'),
      responsive: true,
      "initComplete":function(settings, json) {
        let polyTable = document.getElementById('formTable');
        polyTable!.style.display = 'block';
        let polyTableLoader = document.getElementById('polyTableLoader');
        polyTableLoader!.style.display = 'none';
      },
    };

  }




  removeAddresslist(addresslist_id): void{


 this.addresslistcontrolservice.removeAddressList(''+addresslist_id+'',true,'').subscribe((results)=>{

      console.log(results);
      window.location.reload(); 


    });


  }




  disEnAddresslist(addresslist_id: string, disEn: boolean): void{

    this.addresslistcontrolservice.disEnAddressList(''+addresslist_id+'', disEn, true, '').subscribe((results)=>{
   
         console.log(results);
   
         window.location.reload(); 
       });
   
   
     }
  

}
