import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-scheduled-report',
  templateUrl: './create-scheduled-report.component.html',
  styleUrls: ['./create-scheduled-report.component.css']
})
export class CreateScheduledReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
