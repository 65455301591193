import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from '../_question_controltype/question-base';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {

  constructor() { }
  toFormGroup(questions1: QuestionBase<string>[][][] ) {
    const group: any = {};

    questions1.forEach(questionsRow => {
      questionsRow.forEach(questionCol => {
        questionCol.forEach(question => {
          if(question.required_type == 'email'){
            group[question.key] = question.required ? new FormControl(question.value || '', [Validators.required,Validators.email])  :  new FormControl(question.value || '',Validators.email);
          }
          else if(question.required_type == 'phone'){
            group[question.key] = question.required ? new FormControl(question.value || '', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(11)])  :  new FormControl(question.value || '',[Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(11)]);
          }
          else if(question.type == 'packagetable'){
            group[question.key] = question.required ? new FormControl('', [Validators.required])  :  new FormControl('');
          }
          else{
            group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)  :  new FormControl(question.value || '');
          }


        });
    });
    });
    return new FormGroup(group);
  }
}
