import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class GeneralEditorService {

  constructor() { }



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
///
///
///
///
///
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



filter_Editor(info: any): Observable<any>{

  return new Observable(observer => {

    console.log(info);
  Swal.fire({
    title: 'Filter',
    html: `

    <div class="row g-3 pb-2">
    <div class="col-md-5">
        <label for="fieldvalue" class="form-label">Field</label>
        <input type="text" class="form-control" disabled value="`+info['name']+`" id="fieldvalue">
    </div>
    <div class="col-md-2">
        <label for="fieldcondition" class="form-label">Condition</label>
        <select id="fieldcondition" class="form-select">
            <option selected value="=">=</option>
            <option value="!=">!=</option>
            <option value="<">\<</option>
            <option value=">">\></option>
            <option value=">=">\>=</option>
            <option value="<=">\<=</option>

        </select>
    </div>
    <div class="col-md-5">
    <label for="conditionvalue" class="form-label">Value</label>
    <input type="text" class="form-control" id="conditionvalue">
</div>

</div>

    `,
    confirmButtonText: 'Add Filter',
    focusConfirm: false,
    width: 650,
    preConfirm: () => {

    const temp_input_field_condition_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#fieldcondition')).value;
    const temp_input_condition_value_val = (<HTMLSelectElement>Swal.getPopup()!.querySelector('#conditionvalue')).value;
     // element.hasAttribute('foo');
     // element.getAttribute('foo');
     // element.setAttribute('foo', value);
    //  element.removeAttribute('foo');
     // if (!login || !password) {
    //    Swal.showValidationMessage(`Please enter login and password`)
     // }
     return ({field_conditional:temp_input_field_condition_val, condition_value:temp_input_condition_value_val});
   }
  }).then((result: any) => {
    console.log(info);
    let tempValue = info;
    tempValue['filter_type'] = result.value['field_conditional'];
    tempValue['filter_value'] = result.value['condition_value'];
    if(tempValue['filter_parent'] == undefined){info['filter_parent'] = 'form_data.'}
    tempValue['filter_id'] =  tempValue['filter_parent']+""+tempValue['id'];
    if(tempValue['entry_type'] == undefined){tempValue['entry_type'] = 'string';} else {tempValue['entry_type'] = tempValue['data_type'];}
    tempValue['entry_type']  = "string";
    console.log("Filter Value");
    console.log(tempValue);
    observer.next(tempValue);

    //this.loadTableData(result.value,this.csvToArray(this.mainPolyFile));
  });

});

}

}
